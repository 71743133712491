import React, { Component} from 'react';
import ServiceListBoxAutogas from './ServiceListBoxAutogas.js';

import QuestionOrange from '../img/question_orange.svg';
import SolicitudAyudaImg from '../img/solicitud_ayuda_ok.png';
import Button1 from './Button1';
import {postSendHelpMail, getRegions,
	autogasBussinesLineRecommended, autogasBussinesLineAdd, autogasBussinesLineSearch} from '../api.js';
import ContentBox from './ContentBox.js';
import Isotipo from '../img/isotipo.svg';
import PopupInfoClientCode from './PopupInfoClientCode.js';
import {formatRut} from '../utils.js';

export default class AsociarServiciosAutogas extends Component {
	constructor(props) {
	  	super(props);
	  	this.state = {
	  		search_message: "",
			helpBoxCollapse: false,
			helpBoxDone: false,
			id_person: this.props.user.id,			
			street: "",
			number: "",
			second_numbering: "",
			region: '',
			regions: [],
			comune: '',
			patent: '',
		  	services: null,
		  	searchServices: null,
		  	searchType: "Patente",
		  	searchTerm: "",
		  	loadingSearch: false,
		  	tabs: [
				/*{
					title: "Cilindro",
					to: "/adminitracion_cuentas/cilindro"
				},*/
				{
					title: "Granel Habitacional",
					to: "/adminitracion_cuentas/granel/asociar"
				},
				/*{
					title: "Autogas",
					to: "/adminitracion_cuentas/autogas"
				},*/
				{
					title: "Medidor",
					to: "/adminitracion_cuentas/medidor/asociar"
				}
			]
		};
		this.myRef = React.createRef();
	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadRecommended();
		this.loadRegions();
	}
	
	scrollToMyRef = () => {
		var top  = window.pageYOffset || document.documentElement.scrollTop
		 var i = 20;
		  var int = setInterval(() => {
		    window.scrollTo(0, top + i);
		    i += 20;
		    if (top + i >= this.myRef.current.offsetTop) clearInterval(int);
		  }, 10);
	}
	loadRecommended() {
		var getFunction = null;
		
			this.setState({searchType: 'Patente'});
			getFunction = autogasBussinesLineRecommended;
		getFunction((error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			this.setState({services: result.data});
		});
	}
	loadRegions() {
		getRegions((error, result) => {
			if(error) {
				return console.log("ocurrio un error con el request");
			}
			this.setState({
				regions: result.data
			});
		});
	}
	resetSearch() {
		this.setState({searchTerm: "", searchType: "Patente", searchServices: null});
	}
	addService(service, patentes, from) {
	
		autogasBussinesLineAdd(service.client_cod, patentes,
			(error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			if(from ===  "suggestions") this.loadRecommended();
			if(from ===  "search") this.resetSearch();
			if(result.status === 'error'){
				this.props.createActionNotification("", result.message, 'error');
			}
			if(result.status === 'ok'){
				this.props.createActionNotification("", "El servicio se ha vinculado a tu cuenta correctamente");
				this.props.checkMenuOptions();
				this.props.redirect("/");
			}
		});
	}
	searchService() {
		this.setState({searchServices: null, search_message: null, loadingSearch: true});
		
		var terms = {};
		
		autogasBussinesLineSearch(this.state.searchType, this.state.searchTerm, (error, result) => {
			if(error) {
				this.setState({loadingSearch: false});
				return console.log("ocurrio un error con el request", error);
			}
				if(result.message) this.setState({search_message: result.message, loadingSearch: false});
			else this.setState({searchServices: result.data, loadingSearch: false});
		});

		
	}
	sendEmailHelp = (event) => {

		event.preventDefault();
		var business_line_id = 5;

		postSendHelpMail(this.state.id_person, business_line_id, this.state.street, this.state.number, this.state.second_numbering, this.state.region, this.state.comune, (error, result) => {
			if(error) {
			  return alert("error al registrar");
			}
			this.setState({helpBoxDone: true});
		}, this.state.patent);
	}
	renderBuscador(searchOptions) {
		var fieldsContainerClass = "flex-row col-100 left clearfix";
		var searchTerms;
		if(this.state.searchType === "Patente") {
			searchTerms = <input value={this.state.searchTerm} onChange={(e)=>{this.setState({searchTerm: e.target.value})}} placeholder={"(ej: ABCD12)"} style={{flex: 1, marginRight: "20px"}} />;
		} else if(this.state.searchType === "Rut") {
			searchTerms = <input value={this.state.searchTerm} onChange={(e)=>{this.setState({searchTerm: formatRut(e.target.value)})}} placeholder={"(ej: 12345678-k)"} style={{flex: 1, marginRight: "20px"}} />;
		}
		
		
		return(
		<div className="subcontent-box subcontent-box-asociar-servicios col-100 left clearfix margin-top-10 margin-bottom-30">
			<h1>BUSCAR POR</h1>
			<div className={fieldsContainerClass}>	
				<select value={this.state.searchType} onChange={(e)=> {this.setState({searchType: e.target.value, searchTerm: ""});}}>
					{searchOptions.map((option) => <option key={option}>{option}</option>)}
				</select>			
				{searchTerms}
				<div className="margin-top-20-under650">
				<div className="button orange" onClick={()=>this.searchService()}>
					<p>BUSCAR</p>
				</div>
				</div>
			</div>
		</div>
		);
	}
	render() {
		var title = "";
		var subTitle = "";
		var searchOptions = [
			"Patente", "Rut"
		];
	
		title = "Autogas";
		subTitle = "Asociar Servicios";

		var helpContent = null;
		if(!this.state.helpBoxDone) helpContent =
						<form onSubmit={this.sendEmailHelp}>
							<div className="form-need-help">
								<select>
									<option>{title}</option>
								</select> *
								<select value={this.state.region} onChange={(e) => {this.setState({region: e.target.value})}} required>
									<option value="" disabled>Región</option>
									{this.state.regions && this.state.regions.length > 0 ? this.state.regions.map((element, i) => {
										return(
											<option value={element.id} key={i}>{element.name}</option>
										);
									}) : null}
								</select> *
								<select value={this.state.comune} onChange={(e) => {this.setState({comune: e.target.value})}} required>
									<option value="" disabled>Comuna</option>
									{this.state.region && this.state.regions.length > 0 ? 
										this.state.regions.map((element) => {
											if (element.id === parseInt(this.state.region)) {
												return element.communes.map((comune, i) => {
													return(
														<option value={comune.id} key={i}>{comune.name}</option>
													)
												})
											}
											
										}) : null}
								</select> *
								<input type="text" value={this.state.street} onChange={(e) => {this.setState({street: e.target.value})}} placeholder="Calle" required/> *
								<input type="text" value={this.state.number} onChange={(e) => {this.setState({number: e.target.value})}} placeholder="Número" required/> *
								<input type="text" value={this.state.second_numbering} onChange={(e) => {this.setState({second_numbering: e.target.value})}} placeholder="Casa"/>
								<input className="uppercase" type="text" value={this.state.patent} onChange={(e) => {this.setState({patent: e.target.value})}} placeholder="Patente" required/> *
							</div>
							<p className="information-need-help">Un ejecutivo revisará tus datos y enviará  la informacion  requerida a tu correo {this.props.user.email}</p>
							<button type="submit" className="button orange button-need-help">Enviar solicitud</button>
						</form>;
		else helpContent =
						<div>
							<div className="information-need-help-ok-title">
								<p>Solicitud enviada con éxito</p>
							</div>
							<div className="information-need-help-ok-image">
								<img className="question-orange" src={SolicitudAyudaImg} alt="Ícono ayuda" />
							</div>
							<div className="information-need-help-ok-button-container">
								<Button1 className="button" label="Finalizar" onClick={() => this.setState({helpBoxCollapse: false, helpBoxDone: false})} />
							</div>
						</div>;
		
		var optionsCommune = [];
		this.state.regions.forEach(element => {
			if(element.id === parseInt(this.state.region)){
				element.communes.forEach(commune => {
					optionsCommune.push(
						<option value={commune.id}>{commune.name}</option>
					);
				});
			}
		});

		return (
			<ContentBox key={"autogas"} style={{marginBottom: "3000px"}}>
				<h1>{subTitle}</h1>
				{!this.state.services ? null :
				<div>
					<h2 className="asociar-servicios-sugerencias margin-top-20 col-100 left clearfix">SUGERENCIAS</h2>
					<div className={"list-boxes-container col-100 left clearfix autogas"}>
					{this.state.services.map((service) => {				
							return(<ServiceListBoxAutogas key={service.client_cod} service={service} type="asociar" onButtonClick={(patentes) => {this.addService(service, patentes, "suggestions")}} />);			
											
					})}
					</div>
					<h3 className="col-100 left clearfix subtitle-asociar-servicios margin-top-20">¿Tu cuenta no está entre las sugeridas? Búscala a continuación.</h3>
				</div>}
				{this.renderBuscador(searchOptions)}
				{this.state.search_message && this.state.search_message.trim() !== ""?<div className="search-result-message italic col-100 left clearfix">
					<p className={"font-15 p-autogas-message"}>{this.state.search_message}</p>
				</div>:null}

				{!this.state.searchServices || this.state.searchServices.length === 0?null:<div className={"col-100 left list-boxes-container margin-top-20 asociar-servicios-container autogas"} style={{marginTop: "20px"}}>
				{!this.state.searchServices ? null : this.state.searchServices.map((service) => {
				
						return(<ServiceListBoxAutogas key={service.client_cod} service={service} type="asociar" onButtonClick={(patentes) => {this.addService(service, patentes, "search")}} />);			
									
				})}
				</div>}
				{this.state.loadingSearch?
				<div className="margin-bottom-100" style={{position: "relative"}}>
					<div className={"content-box-loading"}>
						<div className="content-box-loading-content">
							<div className="loading-full-image">
								<img alt="Abastible" src={Isotipo} />
							</div>
							<div className="loading-full-text">
								<p>Cargando ...</p>
							</div>
						</div>
					</div>
				</div>:null}
				<div className="need-help margin-top-30 col-100 left clearfix">
					<button className="title-need-help" onClick={() => {
						if(!this.state.helpBoxCollapse) {
							setTimeout(() => this.scrollToMyRef(), 300);
						}
						this.setState({helpBoxCollapse: !this.state.helpBoxCollapse}) }}>
						<img className="question-orange" src={QuestionOrange} alt="help" />
						<span>Necesitas ayuda para asociar tus servicios</span>
					</button>
					<div ref={this.myRef} className={"slidedown " + (this.state.helpBoxCollapse ? 'show' : 'collapsed')}>
						{helpContent}
					</div>
				</div>
				<PopupInfoClientCode cancel={()=>this.setState({client_cod_info_popup: null})} confirm={null} show={this.state.client_cod_info_popup} />
			</ContentBox>
		);
	}
}
