import React, { Component, Fragment, } from 'react';
import {Link} from 'react-router-dom';
import Button2 from './Button2';
import ContentBox from './ContentBox.js';
import defaultAvatar from '../img/default_avatar2.png';
import {available, urls} from '../config.js';
import {getServiceAddressString} from '../utils.js';
import TanqueIcon from '../img/icon_tanque.png';
import {getDebtHousing} from '../api.js';
import privilegios from '../privilegios_empresa.js';
import EmpresasMisClientes from './EmpresasMisClientes'
import EmpresasEnvasadoInstitucionalCard from './EmpresasEnvasadoInstitucionalCard'
import Campaigns from "./Campaigns"
import ModalCampain from "./ModalCampain"
import Tooltip from 'rc-tooltip';

export default class EmpresasMisServicios extends Component {
	constructor(props) {
	  	super(props);
	  	this.state = {
			bills: [],
			debtHousing: null,
			loadingMedidor: false,
			requestReposition: false,
			meterInstalled: false,
			modal:false, //0302186334
			iframelinkError: false,
	  	};
	}
	componentDidMount() {
		this.props.changeHeaderClass(null);
		if(this.props.selectedIndustrial) this.handleDebtHousing();
	}
	componentDidUpdate(prevProps, prevState) {
		
		if(prevProps.selectedIndustrial !== this.props.selectedIndustrial) {
			this.handleDebtHousing();
		}
	}
	handleSetSelectedHousing(e){
		this.props.services.forEach((housing)=> {
			if(housing.client_cod === e.target.value) this.props.setSelectedIndustrial(housing);
		});
	}
	getDebtHousing(client_cod, cb){
		this.setState({loadingHousing: true});
		getDebtHousing(client_cod, (error, result) => {
			if(result){
				if(result.status === "ok") {
					this.setState({
						debtHousing: result.data,
						loadingHousing: false

					}, () => {}/*console.log("listo")*/);
				}
				if(result.status === "empty") {
					this.setState({
						debtHousing: null,
						loadingHousing: false

					});
				}
				if(result.status === "exception") {
					this.setState({
						debtHousing: null,
						loadingHousing: false

					});
				}
			}else{
				this.setState({
					debtHousing: null,
					loadingHousing: false

				});
			}
			if(cb) cb();
		}, "empresas");
	}
	handleDebtHousing(cb){
		let industrialService = null;
		var services = this.props.services?this.props.services.filter((s)=>parseInt(s.business_line_id) === 6):null;

		if(services){
			services.forEach(element => {
				if(this.props.selectedIndustrial && element.client_cod === this.props.selectedIndustrial.client_cod){
					industrialService = element;
				}
			});
			if(industrialService) {
				this.getDebtHousing(industrialService.client_cod, cb?cb:null);
			}
		}
	}
	renderDebtHousing(){
		var service_priv = this.props.selectedIndustrial.pivot.privileges;
		if(service_priv.indexOf(privilegios.granel_industrial_detalle_deuda) < 0) return null;
		if(this.state.debtHousing){
			return(
				<div className="servicios-servicio-box-details-right">
					<div className="servicios-servicio-box-details-boleta-num">
						<p className="uppercase bold">
							<span className="light font-14">Crédito aprobado: </span>
							${parseInt(this.state.debtHousing.aproved_credit).toLocaleString("de")}
						</p>
						<p className="uppercase bold">
							<span className="light font-14">Crédito utilizado: </span>
							${parseInt(this.state.debtHousing.used_credit).toLocaleString("de")}
						</p>
						<p className="uppercase bold">
							<span className="light font-14">Crédito disponible: </span>
							${parseInt(this.state.debtHousing.available_credit).toLocaleString("de")}
						</p>
					</div>
				</div>
			);
		}else{
			return(
				<div className="servicios-servicio-box-details-right">
					<p className="text-green fw700">No tienes cuentas por pagar</p>
				</div>
			);
		}
	}
	renderSelectHousing(){
		if(this.props.services){
			var services = this.props.services.filter((s)=>parseInt(s.business_line_id) === 6);
			if(services.length > 1){
				return(
					<div className="options-number-customer">
						<select value={this.props.selectedIndustrial ? this.props.selectedIndustrial.client_cod : ""} onChange={(e) => this.handleSetSelectedHousing(e)}>
							{services.map((service, i) =>
								<option key={service.client_cod} value={service.client_cod}>
									N° de Cliente {parseInt(service.client_cod)}
								</option>)}
						</select>
					</div>
				);
			}else{
				return(
					<div className="box-border margin-top-40">
						N° de Cliente {parseInt(services[0].client_cod)}
					</div>
				);
			}
		}
	}
	renderGranel(){
		var selectedIndustrial = null;
		var services = this.props.services?this.props.services.filter((s)=>parseInt(s.business_line_id) === 6):null;

		if(services) {
			if(services.length === 0) return null;
			services.forEach((service) => {
				if(service.client_cod === this.props.selectedIndustrial) selectedIndustrial = service;
			});
		}
		var service = this.props.selectedIndustrial;

		var service_priv = null;
		if(service) {
			service_priv = service.pivot.privileges;

		} else return null;
		var client_address = getServiceAddressString(service);		
		return (
		<ContentBox className="margin-top-40 margin-bottom-40 overflow-hidden" loading={this.state.loadingHousing}>
			<div className="servicios-servicio-box-leftside">
      			<img className="servicios-servicio-box-type-img" src={TanqueIcon} alt="granel" />
      			<div className="servicios-servicio-box-type">
      				<p>Granel Industrial</p>
  				</div>
				{this.renderSelectHousing()}
			</div>

			<div className="servicios-servicio-box-links block-important">
				{!available.granel_industrial_resumen_tanques||service_priv.indexOf(privilegios.granel_industrial_resumen_tanques)<0?null:<div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/granel/resumen-tanques"}>Resumen de Tanques</Link>
				</div>}
				{!available.granel_industrial_realizar_pedido||service_priv.indexOf(privilegios.granel_industrial_realizar_pedidos)<0?null:<div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/granel/realizar-pedidos"}>Realizar Pedidos</Link>
				</div>}
				{!available.granel_industrial_pedidos||service_priv.indexOf(privilegios.granel_industrial_historial_pedidos)<0?null:<div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/granel/pedidos"}>Historial de Pedidos</Link>
				</div>}
				{!available.granel_industrial_historial_pago||service_priv.indexOf(privilegios.granel_industrial_historial_pagos)<0?null:<div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/granel/historial-pagos"}>Historial de Pagos</Link>
				</div>}
				{!available.granel_industrial_detalle_deuda||service_priv.indexOf(privilegios.granel_industrial_detalle_deuda)<0?null:<div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/granel/detalle-deuda"}>Detalle de Deuda</Link>
				</div>}
				{!available.granel_industrial_cuenta_corriente||service_priv.indexOf(privilegios.granel_industrial_cuenta_corriente)<0?null:<div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/granel/cuenta-corriente"}>Cuenta Corriente</Link>
				</div>}
				{!available.granel_industrial_telemetria||service_priv.indexOf(privilegios.granel_industrial_telemetria)<0?null:<div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/granel/telemetria"}>Telemetría</Link>
				</div>}
				{!available.granel_industrial_requerimientos||service_priv.indexOf(privilegios.granel_industrial_requerimientos)<0?null:<div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/granel/requerimientos"}>Requerimientos</Link>
				</div>}
				{!available.granel_industrial_tarifas||service_priv.indexOf(privilegios.granel_industrial_tarifas)<0?null:<div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/granel/variacion-precio"}>Variación de Precio</Link>
				</div>}
			</div>
			<div className="servicios-servicio-box-details">
			{!selectedIndustrial && !this.props.selectedIndustrial?null:
				<div className="servicios-servicio-box-details-left">
					{service.pivot && service.pivot.company_name?<p className="uppercase font-20 fw700 lh-130pct margin-top-10">{service.pivot.company_name}</p>:null}
					{service.pivot && service.pivot.company_dni?<p className="font-15 fw700 lh-100pct margin-top-5">Rut: {service.pivot.company_dni}</p>:null}
					<div className="servicios-servicio-box-details-address margin-top-20">
						<p>{client_address}</p>
					</div>
					<div className="servicios-servicio-box-details-city">
						<p>{!selectedIndustrial?this.props.selectedIndustrial.client_city:selectedIndustrial.client_city}, {!selectedIndustrial?this.props.selectedIndustrial.client_region_cod:selectedIndustrial.client_region_cod}</p>
					</div>
				</div>
			}
				{this.renderDebtHousing()}
			</div>
		</ContentBox>
		);
	}

	renderGranelTrack(){
		return(
			<>
				<ContentBox  className="servicios-header-track-container overflow-hidden margin-bottom-40 no-top-border-radius" >
					<span className="track-title">SEGUIMIENTO DE MI PEDIDO GRANEL</span>
					<Tooltip placement="bottom" visible={this.state.iframelinkError}
				 		overlay={<span>INGRESA TU N° DE PEDIDO</span>}>
						<input className="track-input" value={this.state.iframelink} onChange={(e) => {this.setState({iframelinkError: false}); this.setState({iframelink:e.target.value})}} type={'text'} placeholder={"Ingresa tu n° de pedido"} />
					</Tooltip>
					<Button2 onClick={()=>{this.state.iframelink?this.setState({modal: true}):this.setState({iframelinkError: true}) }} className="track-button" label="Buscar" className="orange" />
				</ContentBox>
				{this.state.modal?<ModalCampain closeRight={true} expandFull={true} renderContent={()=>this.renderContentModal()} cancel={()=>this.cancelModal()} show={this.state.modal}/>:null}
			</>
		);
	}
	renderContentModal(){
		document.body.style.overflow = "hidden";
		return(
			<iframe className="track-modal-iframe" src={urls.track_pedidos+this.state.iframelink}></iframe>
		);
	}
	cancelModal(){
		document.body.style.overflow = "";
		this.setState({
			modal: false,
		});
	}

	render() {
		var profilePicture = this.props.user.profile_picture?this.props.user.profile_picture:defaultAvatar;
		let show_granel = false;
		if(this.props.menuOptions)
		{
			for (let i = 0; i < this.props.menuOptions.length ; i++)
			{
				if(this.props.menuOptions[i] && this.props.menuOptions[i].name 
					&& this.props.menuOptions[i].name == 'Mis Servicios' && this.props.menuOptions[i].sub)
				{
					for (let j = 0; j < this.props.menuOptions[i].sub.length; j++)
					{
						if(this.props.menuOptions[i].sub[j] && this.props.menuOptions[i].sub[j].name && this.props.menuOptions[i].sub[j].name == 'Granel Industrial'){
							show_granel = true; 
							break;
						}
					}

				}
			}
		}



		return (
			<Fragment>
				<ContentBox className={(!show_granel?"overflow-hidden margin-bottom-40 ":"overflow-hidden no-bottom-border-radius")}>
					<div className="servicios-header-avatar-container">
						<div
							className="servicios-header-avatar"
							style={{backgroundImage: "url(" + profilePicture + ")"}}>
						</div>
					</div>
					<div className="servicios-header-welcome-container">
						<div className="servicios-header-welcome-subtext">
							<p className="thin">Bienvenido a tu sucursal virtual</p>
						</div>
						<div className="servicios-header-welcome-text">
							<p>{this.props.user.name} {this.props.user.last_name}</p>
						</div>
					</div>
				</ContentBox>
				{show_granel?this.renderGranelTrack():null}
				<Campaigns custom="Empresas"/>
				{this.props.user_role=="Funcionario"?this.renderGranel():null}
				{available.envasado_institucional_resumen
				&& this.props.user_role=="Funcionario" 
				&& this.props.services
				&& this.props.services.filter((s)=>parseInt(s.business_line_id) === 2).length > 0 ?
				<EmpresasEnvasadoInstitucionalCard 
					services={this.props.services?this.props.services.filter((s)=>parseInt(s.business_line_id) === 2):null}
					selectedCilindro={this.props.selectedCilindro}
					setSelectedCilindro={this.props.setSelectedCilindro}
				/>
				:null}				
				{this.props.user_role=="Administrador"?<EmpresasMisClientes/>:null}				
			</Fragment>
		);
	}
}