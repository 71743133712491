import React, { Component } from 'react';
export default class ServiceListBoxAutogas2 extends Component {
	render() {
		var type = "";
		var buttonText = "";
		if(this.props.type === "asociar") {
			type = " asociar";
			buttonText = "ASOCIAR"
		} else if(this.props.type === "desvincular") {
			type = " desvincular";
			buttonText = "DESVINCULAR";
		} else if(this.props.type === "pedido") {
			type = " desvincular";
			buttonText = "HACER PEDIDO";
		} else if(this.props.type === "activar"){
			type = " asociar";
			buttonText = "ACTIVAR";
		} else if(this.props.type === "detalle"){
			type = " desvincular";
			buttonText = "VER DETALLE";
		}
		var service = this.props.service;
		var displayNumber = null;
		if(this.props.displayNumberLabel) displayNumber = service[this.props.displayNumberLabel] !== ''  ? service[this.props.displayNumberLabel] : parseInt(service.client_cod);;

		return (
			<div className={"servicio-list-box text-align-left" + type} onClick={this.props.onButtonClick}>
				{this.props.hideClientCode?null:<h1>Patente {displayNumber}</h1>}


				{this.props.onlyHeader ? null : <p className="servicio-list-box-address">
					CONTRATO Nº {parseInt(service.conversion_contract_number)}
				</p>}
				{/* this.props.onlyHeader ? null : <p className="servicio-list-box-city">
					CLIENTE Nº {parseInt(service.client_cod)}
				</p> */}
				{!buttonText ? null : <div className="servicio-list-box-button">
					 <p>{buttonText}</p>
				</div>}
			</div>
		);
	}
}