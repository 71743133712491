import React, {Component, Fragment} from 'react';
import ReactDOM from 'react-dom';
import ContentBox from './ContentBox.js';
import {housingBussinesLineList} from '../api.js';
import ServiceListBox from './ServiceListBox.js';
import ModalConfirmation from "./ModalConfirmation.js"
import {Link} from 'react-router-dom';
import {urls} from '../config.js';
import {getServiceAddressString} from '../utils.js';
import WhatsAppButton from './WhatsAppButton.js';
export default class GranelRealizarPedido extends Component {
	constructor(props) {
		super(props);
		this.state = {
            housingServices: null,
			openModal: false
		};
	}
	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();		
		this.getList();
	}
	getList(cb) {
		this.setState({loading: true, housingServices: []});
		if(!this.props.isEmpresas) {
	        housingBussinesLineList((error, result) => {
				if(error) {
					return console.log("ocurrio un error con el request");
				}
				var housingServices = null;
				if(result.status !== "empty") {
					housingServices = result.data;
	                this.setState({
	                    lecturasMessage: 'No existen clientes asociados'
	                });
				} else {
					housingServices = [];
				}
				this.setState({
					housingServices: housingServices,
	                loading: false,
				});
			});
		} else {
			this.setState({
				parentServices: true,
				loading: false
			});
		}
	}
	filter() {
		this.getList();
	}
	renderServices(services) {
		return(
			<div className="list-boxes-container col-100 left clearfix">
                {!services?null:services.map((service) => {
					return(
					<FormTomaPedidos openModal={() => {this.setState({openModal: true})}} user={this.props.user} key={service.client_cod} service={service} type="pedido" onButtonClick={() => {}} />
					);
				})}
				{!this.state.housingServices?<p>{this.state.lecturasMessage}</p>:null}
			</div>
		); 
	}
	confirmModal() {
		this.setState({openModal: false})
	}
	renderServicesEmpresas(services) {
		if(!services)return null;
		var groupedServices = [];
		services.forEach((s) => {
			var found = false;
			
			groupedServices.forEach((gs) => {
				if(gs.company_id === s.pivot.company_id) {
					gs.services.push(s);
					found = true;
				}
			});
			if(!found) {
				groupedServices.push({company_id: s.pivot.company_id, company_name: s.pivot.company_name, company_dni: s.pivot.company_dni, services: [s]});
			}
		});
		
		return groupedServices.map((gs) => {
			return(
				<Fragment key={gs.company_id}>
					<p className="fw700 font-18">{gs.company_name} (RUT: {gs.company_dni})</p>
					<div className="list-boxes-container col-100 left clearfix">
		                {gs.services.map((service) => {
							return(
							<FormTomaPedidos openModal={() => {this.setState({openModal: true})}} user={this.props.user} key={service.client_cod} service={{...service, pivot: undefined}} type="pedido" onButtonClick={() => {}} />
							);
						})}
						{!this.state.housingServices?<p>{this.state.lecturasMessage}</p>:null}
					</div>
				</Fragment>
			);
		});
	}
	render() {
		var services = this.state.housingServices;
		if(this.state.parentServices && this.props.services) services = this.props.services.filter((s)=>parseInt(s.business_line_id) === 6);
		
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Realizar Pedidos</h1>
				</div>
				<div className="message margin-bottom-40">
					<p>Una vez realizado tu pedido, podrás verlo en la sección <Link to={"/mis-servicios/granel/pedidos"}>Historial de Pedidos</Link></p>
				</div>
				{this.props.isEmpresas?this.renderServicesEmpresas(services):this.renderServices(services)}
				<ModalConfirmation 
						confirm={this.confirmModal.bind(this)} 
						isAceptar={true}
						renderContent={() => 
						<p class="text-align-center" style={{padding: "0 5%"}}>
							Existe un inconveniente con el ingreso de tu pedido. Por favor verifica que no tengas facturas pendientes de pago en sección detalle deuda.
						</p>}
						show={this.state.openModal}/>
				<WhatsAppButton text={"Pide Gas Tanque Aquí"}  />
			</ContentBox>
		);
	}
}
export class FormTomaPedidos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: ""
		};
	}
	submit() {
		if (this.props.service.has_bloqueo) {
			this.props.openModal()
		}	else {
			this._form.submit();
		}	
	}
	attachNode(node) {
    	this._form = ReactDOM.findDOMNode(node);
  	}	  
	render() {
		return (
			<div>
				<form ref={this.attachNode.bind(this)} target="_blank"
				method="POST" action={urls.portal_pedidos + "/pedidos/granel/info-cliente-sap-sucursal"}>
					<input type="hidden" name="tipo_servicio" value="GR" />
					<input type="hidden" name="cl_codigo" value={this.props.service.client_cod} />
					<input type="hidden" name="direccion" value={getServiceAddressString(this.props.service) + ", " + this.props.service.client_city} />

    				<input type="hidden" name="telefono" value={this.props.user.phone} />
				</form>				
				<ServiceListBox	displayNumberLabel={"client_cod"} service={this.props.service} type="pedido" onButtonClick={() => {this.submit()}} />		
			</div>
		);
	}
}