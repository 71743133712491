import React, { useState, useEffect } from 'react'
import CustomSelect from "./CustomSelect"
import ServiceListBoxAutogas2 from "./ServiceListBoxAutogas2"

export default function ServicioAutogasSelectorPatente({patents, setSelectedPatenteAutogas, selectedPatenteAutogas}) {
	const [patentesOrdenadas, setPatentesOrdenadas] = useState([])
	const [patentesSelect, setPatentesSelect] = useState([])

	useEffect(()=>{
		if (selectedPatenteAutogas && patents && patents.length > 0) {
			let _patentesSelect = patents
			_patentesSelect = _patentesSelect.filter(patente => patente.patent !== selectedPatenteAutogas.patent)
			setPatentesSelect(_patentesSelect)
		}
	}, [selectedPatenteAutogas, patents])

    useEffect(() => {		
        if (patentesSelect) {
            let _patents = []
            _patents =  patentesSelect.sort((a, b) => {
                if (a.patent.toLowerCase() < b.patent.toLowerCase()) {
                    return -1;
                } else if (a.patent.toLowerCase() > b.patent.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            })
            setPatentesOrdenadas(_patents)
        }
	}, [patentesSelect])	
    
		if(patentesOrdenadas != null && patentesOrdenadas.length > 1){
			return(
				<CustomSelect
					options={patentesOrdenadas ? patentesOrdenadas : []}
					selected={selectedPatenteAutogas}
					placeholder="Selecciona Patente"
					onChange={(option) => {
						setSelectedPatenteAutogas(option);
					}}
					renderSelected={(option, opened) => {
						return <ServiceListBoxAutogas2 displayNumberLabel={"patent"} service={option} onlyHeader={false} />
					}}
					renderOption={(option, i) => {
						return <ServiceListBoxAutogas2 displayNumberLabel={"patent"} service={option} hideClientCode={false} />
					}}
				/>
			);
		} else {
			if(selectedPatenteAutogas){
				return(
					<div className="CustomSelect cursor-default">
						<div className="CustomSelect-icon"></div>
						<div className="servicio-list-box text-align-left">
							<h1 className="font-15">Patente {selectedPatenteAutogas.patent}</h1>
							<p className="servicio-list-box-address">CONTRATO Nº {parseInt(selectedPatenteAutogas.conversion_contract_number)}</p>
							{/* <p className="servicio-list-box-city">CLIENTE Nº {parseInt(selectedPatenteAutogas.client_cod)}</p> */}
						</div>
					</div>
				);
			}
		}
		return null;
}
