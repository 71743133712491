import React, { Component, Fragment, } from 'react';
import ContentBox from './ContentBox.js';
import Tooltip  from 'rc-tooltip';
import Toggle from 'react-toggle';
import {formatRut} from '../utils';
import { adminEmpresasClientesList, getPrivileges, getFuncionario, editFuncionario} from '../api';
import PopupPrivilegiosFuncionario from './PopupPrivilegiosFuncionario';
import Button1 from './Button1';
import ArrowRightWhite from '../img/arrow_right_white.png';
import defaultAvatar2 from '../img/default_avatar2.png';
import "react-toggle/style.css";	
import "../css/react-toggle-custom.css";	

export default class EmpresasFuncionariosEditar extends Component {
	constructor(props) {
	  	super(props);
	  	this.state = {
			name: "",
			last_name: "",
			dni: "",
			email: "",
			phone: "",
			status: null,
			password_check: "0",
			loading: true,
			options: [
		  		{step: 1, label: "Crear funcionario"},
		  		{step: 2, label: "Datos del funcionario"},
		  		{step: 3, label: "Clientes habilitados"}
		  	],
		  	empresa_selected: null,
		  	client_codes: {}

	  	};
	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadFuncionario((error) => {
			if(error) {
					console.log("error this.getPrivileges", error);
					return;			
				}
				
			adminEmpresasClientesList((error, result) => {
				if(error) {
					console.log("error", error);
				}
				if (result) {
					this.setState({loading: false, empresas: result.data});
				}
				
			});			
				//this.goToStep3();
			
			
		});
	}
	loadFuncionario(cb) {
		getFuncionario(this.props.funcionario_id, (error, result) => {
			if(error) {
					console.log("error this.getPrivileges", error);
					if(cb) cb(error);
					return;			
			}
			var funcionario = result.data.company_user;
			var client_codes = {};
			if(funcionario.sap_clients) {
				funcionario.sap_clients.forEach((client) => {
					if(client.pivot && client.pivot.privileges && client.pivot.privileges !== "") {
						client_codes[client.id] = JSON.parse(client.pivot.privileges);
					}

				});
			}
			this.setState({
				funcionario, 
				dni: funcionario.dni, 
				name: funcionario.name, 
				last_name: funcionario.last_name, 
				phone: funcionario.phone, 
				email: funcionario.email,
				status: funcionario.status,
				client_codes});
			if(cb) cb();
		});
	}
	sendForm(cb) {
		if(JSON.stringify(this.state.client_codes) === JSON.stringify({})) {
			return this.setState({no_client_codes_error: true});
		}	
		this.setState({loading: true});
		editFuncionario(
		{
			id: this.props.funcionario_id, 
			validation_only: 0, 
			name: this.state.name, 
			last_name: this.state.last_name, 
			phone: this.state.phone, 
			dni: this.state.dni, 
			email: this.state.email, 
			client_codes: this.state.client_codes,
			password_check: this.state.password_check,
			status: this.state.status
		}, 
		(error, result) => {
			if(error) {
					console.log("error this.getPrivileges", error);
					return;			
				}
			if(result.errors) {
				return this.setState({loading: false, errors: result.errors},()=> {if(cb) cb(false)});
			}
			this.loadFuncionario(() => {
				this.setState({loading: false},()=> {

					this.props.createActionNotification("", "Se ha editado el funcionario correctamente");
					if(cb) cb(false);					
				});
			});

		});
	}	
	getPrivileges(client_id, cb) {
		getPrivileges(client_id, (error, result) => {
			if(error) {
				console.log("error getPrivileges", error);
				if(cb) cb(error);
				return;
			}			
			this.setState({privileges: result.data}, () => {if(cb) cb()});			
		});
	}
	openPrivilegesPopup(cliente) {
		this.getPrivileges(""+cliente.abastible_client_id, (error) => {
			if(error) {
				console.log("error this.getPrivileges", error);
				return;			
			}
			this.setState({selected_client: cliente, no_client_codes_error: false});
		});
	}
	privilegesPopupConfirm(privileges) {
		this.updateClientCodes(this.state.selected_client.id,privileges);
		this.setState({selected_client:null});
	}
	updateClientCodes(client_id, codes) {
		var client_codes = {...this.state.client_codes};
		if(!codes || codes.length === 0) delete client_codes[client_id];
		else client_codes[client_id] = [...codes];
		this.setState({client_codes, no_client_codes_error: false});
	}
	toggleEmpresaSelected(selected) {
		if(selected === this.state.empresa_selected) this.setState({empresa_selected: null, no_client_codes_error: false});
		else this.setState({empresa_selected: selected, no_client_codes_error: false});
	}
	deleteFormError(field_name) {
		var formErrors = {...this.state.errors};
		delete formErrors[field_name];
		this.setState({errors: formErrors})
	}		
	
		
	render() {
		var empresas = !this.state.empresas?null:this.state.empresas.map((empresa, i) => {
			return( 

			<div key={empresa.id}  className={"clientes-habilitados-empresa-container margin-top-30" + (this.state.empresa_selected === empresa.id? " selected":"")}>
				<div className="clientes-habilitados-empresa-title" onClick={() => this.toggleEmpresaSelected(empresa.id)}>
					<p className="text-align-center color-white uppercase margin-right-40"><strong>{empresa.name}</strong> {empresa.dni}</p>
					<div className="clientes-habilitados-empresa-container-arrow"></div>
				</div>
					<div className={"table-container" + (this.state.empresa_selected === empresa.id? " selected":"")}>
						<table className="table1">
							<thead className="table-header-gray">
								<tr>
									<td>N° de Cliente</td>
									<td>Servicios</td>
									<td>Dirección</td>
									<td></td>	
									<td></td>									
								</tr>
							</thead>
							<tbody>
							{empresa.sap_clients.map((cliente, j) => {
									var servicio = "";
									if(cliente.business_line_id === "3") servicio = "Granel Habitacional";
									if(cliente.business_line_id === "1") servicio = "Medidor";
									if(cliente.business_line_id === "6") servicio = "Granel Industrial";



									return(
								<tr key={cliente.id}>
									<td>{cliente.abastible_client_id}</td>
									<td>{servicio}</td>
									<td>{cliente.address}, {cliente.comune}</td>
									<td>
										<div className={"inline-block " + (cliente.merchandise_recipients && cliente.merchandise_recipients.length > 0 ? "icon-tanque": "")}></div>
										<div className={"inline-block " + (cliente.payment_responsibles && cliente.payment_responsibles.length > 0 ? "icon-dinero": "")}></div>
									</td>
									<td>
										<div className="linkA-container" onClick={() => {this.openPrivilegesPopup(cliente)}}>
											<p className={"cliente-funcionario-habilitar-administrar" + (this.state.client_codes[cliente.id]?" administrar":"")}>{this.state.client_codes[cliente.id]?"Administrar":"Habilitar"}</p>
										</div>
									</td>									
								</tr>
									);

								})}
							</tbody>
						</table>
					</div>					
		</div>)
		});
		return (
			<Fragment>
				<div className="section-title">
					<h1>Mis Funcionarios</h1>
				</div>
				<ContentBox loading={this.state.loading} className="text-align-center">
					
					<Fragment>

						<ContentBox className="text-align-left">
								<form onSubmit={this.submitUpdateProfile}>
									<div className="edit-profile-container edit-profile-funcionario d-flex">
										<div className="name d-flex flex-column justify-content-center col-40">
											<div className="d-flex justify-content-center margin-bottom-10">
												<img className="col-30" src={defaultAvatar2} alt="Imagen Perfil"/>
											</div>
											<div className="d-flex align-items-center flex-column">
												<h3 className="font-20">{!this.state.funcionario?"":this.state.funcionario.name} {!this.state.funcionario?"":this.state.funcionario.last_name}</h3>
												<p className="margin-bottom-40">{!this.state.funcionario?"":this.state.funcionario.dni}</p>									
												<p className="margin-bottom-40">{!this.state.funcionario?"":this.state.funcionario.email}</p>
												<p className="margin-bottom-40">{!this.state.funcionario?"":this.state.funcionario.phone}</p>

											</div>
										</div>
										<div className="col-60">
											<div className="margin-bottom-40">
												<h2 className="margin-bottom-10 font-25">Editar Perfil</h2>
												<div className="grid-column-1">
													<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.name ? true: false}
												 	overlay={<span>{this.state.errors ? this.state.errors.name : ""}</span>}>
														<input placeholder="Nombre" value={this.state.name} onChange={e => {this.setState({name: e.target.value}); this.deleteFormError("name");}} required/>
													</Tooltip>
													<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.last_name ? true: false}
												 	overlay={<span>{this.state.errors ? this.state.errors.last_name : ""}</span>}>
														<input placeholder="Apellido" value={this.state.last_name} onChange={e => {this.setState({last_name: e.target.value}); this.deleteFormError("last_name");}} required/>
													</Tooltip>
													<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.dni ? true: false}
												 	overlay={<span>{this.state.errors ? this.state.errors.dni : ""}</span>}>
														<input placeholder="Rut" value={this.state.dni} onChange={e => {this.setState({dni:formatRut(e.target.value)}); this.deleteFormError("dni");}} required/>
													</Tooltip>
													<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.email ? true: false}
												 	overlay={<span>{this.state.errors ? this.state.errors.email : ""}</span>}>
														<input placeholder="Email" value={this.state.email} onChange={e => {this.setState({email: e.target.value}); this.deleteFormError("email");}} required/>
													</Tooltip>
													<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.phone ? true: false}
												 	overlay={<span>{this.state.errors ? this.state.errors.phone : ""}</span>}>
														<input placeholder="Teléfono" value={this.state.phone} onChange={e => {this.setState({phone: e.target.value}); this.deleteFormError("phone");}} required/>
													</Tooltip>
												</div>
											</div>
											
											<div className="col-100 left clearfix">
												<label htmlFor="password_check" className="checkbox-container">
													<input id="password_check" 	
														type="checkbox" checked={parseInt(this.state.password_check) === 1} 
														onChange={(e) => {this.setState({password_check: e.target.checked?"1":"0"})}} 
													/>
													<span className="checkmark checkbox-with-border"></span>
													<span>Generar nueva contraseña</span>
												</label>
												
											</div>
											<div className="col-100 left clearfix margin-top-15">												
												<label>
												<span>Estado: </span>
												  <Toggle
												  	icons={false}
												    checked={parseInt(this.state.status) === 1}
												    onChange={(e) => {this.setState({status: e.target.checked?"1":"0"})}} />
												  <span>{parseInt(this.state.status) === 1?"Habilitado":"Deshabilitado"}</span>
												</label>
											</div>
										</div>
									</div>
								</form>
							</ContentBox>

						<div className="margin-top-80">{empresas}</div>
						<Tooltip 
					placement="bottom" visible={this.state.no_client_codes_error}
			 		overlay={<span>Debes activar al menos un privilegio</span>}>
						<Button1
									label="GUARDAR"
									className="margin-top-50 center" icon={ArrowRightWhite}
									onClick={() => {this.sendForm()}} /></Tooltip></Fragment>
					<PopupPrivilegiosFuncionario 
						cancel={()=>this.setState({selected_client: null})} 
						confirm={this.privilegesPopupConfirm.bind(this)} 
						show={this.state.selected_client}
						client_privileges={this.state.selected_client?this.state.client_codes[this.state.selected_client.id]:null}
						privileges={this.state.privileges && this.state.selected_client?this.state.privileges.filter((p)=> p.business_lines_id === this.state.selected_client.business_line_id):null} />
						
				</ContentBox>
			</Fragment>
		);
	}
}