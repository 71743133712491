import React, { Component } from 'react';
import {Route, Switch, Redirect, Link} from "react-router-dom";

import {housingCuentaCorriente} from '../api.js';

import ContentBox from './ContentBox.js';
import ClientSelectGranel from './ClientSelectGranel';
import Paginator from './Paginator';
import ServiciosGranelCuentaCorrienteDocumento from './ServiciosGranelCuentaCorrienteDocumento';

export default class ServiciosGranelCuentaCorriente extends Component {
	constructor(props) {
		super(props);
		this.state = {
			period: "3",
			page: 1
		};
	}
	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();
		
		
		if(this.props.selectedService && !this.props.redirect_data) {
			this.getList();
		}
	}
	componentDidUpdate(prevProps) {

		if(prevProps.selectedService !== this.props.selectedService) {
			this.getList();
		}
		if(prevProps.services !== this.props.services) {
			if(this.state.preload_data){
				this.processPreloadData(this.state.preload_data);
			}
		}
		if(this.props.redirect_data && this.props.services) {
			this.processPreloadData(this.props.redirect_data);
		} else if(this.props.redirect_data && !this.props.services ){
			this.setState({preload_data: this.props.redirect_data});
		}

	}
	processPreloadData(data) {
		var service = null;
		this.props.services.forEach((s)=>{
			if(parseInt(s.client_cod) === parseInt(data.client_code)) service = s;
		} );
		if(!service) return this.props.createActionNotification("Error cargando notificación", "El cliente no está asociado a la cuenta", "error");
		this.setState({period: data.filters.period}, () => {
			this.props.setSelectedService(service);
			this.setState({preload_data: null, pedidosMessage: null});
		});
	}
	getList(cb) {
		this.setState({loading: true, docs: null, pedidosMessage: null});		
		housingCuentaCorriente(this.props.selectedService.client_cod, parseInt(this.state.period), (error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
			}
			if(result.status === "error") {
				if(cb) cb();
				return this.setState({loading: false, formErrors: result.errors});
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({loading: false, pedidosMessage: result.message, docs: null});
			}
			this.setState({docs: result.data, loading: false, page: 1, pedidosMessage: null});
		}, this.props.isEmpresa?"empresas":null);
	}
	filter() {
		this.getList();
	}
	changePage(page) {
		this.setState({page});
	}
	renderCuentaCorriente() {
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Cuenta Corriente</h1>
					<div className="client-number">
						<ClientSelectGranel
							selected={this.props.selectedService}
							services={this.props.services}
							setSelectedService={this.props.setSelectedService}
							number_field={"client_cod"} />
					</div>
				</div>
				<div className="col-100 left d-flex justify-between margin-bottom-40">
					<div className="filter-container grid-column-2">
						
						<div className="position-relative">
							<select value={this.state.period} onChange={(e) => {this.setState({period: e.target.value})}}>
								<option value="3">3 meses</option>
								<option value="6">6 meses</option>
								<option value="9">9 meses</option>
								<option value="12">12 meses</option>
							</select>
						</div>
						<button className="button orange" onClick={() => {this.filter()}}>Ver documentos</button>
					</div>
				</div>
				<div className="table-container">
					<table className="table1">
						<thead>
							<tr>
								<td>Clase de documento</td>
								<td>N° doc</td>							
								<td>Fecha de emisión</td>
								<td>Fecha de Vencimiento</td>
								<td>Valor</td>
								<td style={{maxWidth: '80px', padding: '0px'}}>Morosidad</td>
								<td>Condición de Pago</td>
								{/*<td>Dirección Despacho</td>*/}
								<td style={{maxWidth: '70px', padding: '0px'}}></td>
							</tr>
						</thead>
						<tbody>
							{!this.state.docs?null:this.state.docs.slice((this.state.page - 1)*10, (this.state.page - 1)*10 + 10).map((doc, i) => {
								console.log(doc);
								return(
							<tr key={doc.doc_number}>
								<td>{doc.class_description}</td>
								<td>{doc.folio_number}</td>							
								<td>{doc.issue_date}</td>								
								<td>{doc.expiration_date}</td>
								<td>${parseInt(doc.value).toLocaleString("de")}</td>
								<td>{parseInt(doc.late_payment) > 0?doc.late_payment:""}</td>
								<td>{doc.payment_condition_desc}</td>
								{/*<td>{doc.address}</td>*/}
								<td>								
								{doc.class_description === "FACTURA" || doc.class_description === "NOTA DE CREDITO"?
									<div className="linkA-container">
										<Link to={"/mis-servicios/granel/cuenta-corriente/" + doc.doc_number + "/" + doc.doc_year + "/" + doc.client_cod}>Ver Detalle</Link>
									</div>
								:null}
								</td>
							</tr>);

							})}
						</tbody>
					</table>
					{!this.state.pedidos?<p>{this.state.pedidosMessage}</p>:null}
				</div>
				{this.state.docs && this.state.docs.length > 10?<Paginator page={this.state.page} items={this.state.docs?this.state.docs.length:0} itemsPerPage={10} onPageChange={(page) => this.changePage(page)} />:null}
				
			</ContentBox>
		);
	}
	render() {
		return(<Switch>
			<Route exact path="/mis-servicios/granel/cuenta-corriente/">
				{this.renderCuentaCorriente()}
			</Route>
			<Route exact path="/mis-servicios/granel/cuenta-corriente/:id/:doc_year/:client_cod"
      				render={(data)=> 
					<ServiciosGranelCuentaCorrienteDocumento
            			createActionNotification={this.props.createActionNotification}      					 
						doc_number={data.match.params.id}
						client_cod={data.match.params.client_cod}
            			selectedService={this.props.selectedService}      
            			isEmpresa={this.props.isEmpresa}						
						doc_year={data.match.params.doc_year} />}>
            </Route>
		</Switch>);
	}
}
