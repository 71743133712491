import React, { Component } from 'react';
import Isotipo from '../img/isotipo.svg';

export default class LoadingFull extends Component {
	render() {
		return (
			<div className="loading-full">
				<div className="loading-full-content">
					<div className="loading-full-image">
						<img alt="Abastible" src={Isotipo} />
					</div>
					<div className="loading-full-text">
						<p>Cargando ...</p>
					</div>
				</div>
			</div>
		);
	}
}
