import React, { Component } from 'react';
import ContentBox from './ContentBox.js';
import ClientSelectMedidor from './ClientSelectMedidor';
import calendar from '../img/calendar.svg';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {getPaymentHistory} from '../api.js';

export default class ServiciosMedidorBASE extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lecturas: null,
			lecturasMessage: null,
			times: 0,
			loading: false,
			from: moment().subtract(12, "months").toDate(),
			to: moment().toDate()
		};
	}
	componentDidMount() {
		if(this.props.selectedService) {
			this.getList();
		}
	}	
	componentDidUpdate(prevProps) {
		if(prevProps.selectedService !== this.props.selectedService && this.props.selectedService) {
			this.getList();
		}
	}
	getList() {
		this.setState({formErrors: null, loading: true});
		var start = moment(this.state.from).format("YYYY-MM-DD");
		var end = moment(this.state.to).format("YYYY-MM-DD");
		this.getReadings(start, end, () => {
			this.setState({loading: false});
		});
	}
	getReadings(from, to, cb) {
		if(!from || !to) {
			cb();
			return alert("Debes seleccionar Fecha de inicio y de Término");
		}
		getPaymentHistory(moment(from).format("YYYY-MM-DD"), moment(to).format("YYYY-MM-DD"), this.props.selectedService != null ? Number(this.props.selectedService.contract_cod).toString() : Number(this.props.selectedMeter.contract_cod).toString(), (error, result) => {
            if(error) {
				cb(error);
				return console.log(error);
				//return console.log("ocurrio un error con el request");
			}
			if(result.status === "error") {
				cb();
				this.setState({lecturasMessage: result.message});
			}
			if(result.status === "empty") {
				cb();
				this.setState({lecturas: null});
				return this.setState({times: 1, lecturasMessage: this.state.times > 0 ? result.data : "No se registran pagos realizados durante el período seleccionado"});
            }
			this.setState({lecturas: result.data}, cb?cb:null);
		});
	}
	filter() {
		if(this.state.from && this.state.to){
			this.getList();
		}else{
			this.setState({lecturas: null, lecturasMessage: 'No se registran pagos realizados durante el período seleccionado'});
		}
	}
	render() {
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
		          <h1>Historial de Pagos</h1>
		          <div className="client-number">
		            <ClientSelectMedidor 
						selected={this.props.selectedService} 
						services={this.props.services}
						setSelectedService={this.props.setSelectedService} />
		          </div>
		        </div>

		        <div className="col-100 left d-flex justify-between margin-bottom-40">
					<div className="filter-container grid-column-3">
						<div className="position-relative">
							<img className="calendar" src={calendar} alt="calendario" />
							<DatePicker popperModifiers={{flip: {enabled: false}}} maxDate={this.state.to} dateFormat="dd/MM/yyyy" placeholderText="Desde" selected={this.state.from} locale="es" onChange={(date) => {this.setState({from: date})}} />
						</div>
						<div className="position-relative">
							<img className="calendar" src={calendar} alt="calendario" />
							<DatePicker popperModifiers={{flip: {enabled: false}}} minDate={this.state.from} maxDate={new Date()} dateFormat="dd/MM/yyyy" placeholderText="Hasta" selected={this.state.to} locale="es" onChange={(date) => {this.setState({to: date})}} />
						</div>
						<button className="button orange" onClick={() => {this.filter()}}>Filtrar</button>
					</div>
				</div>

				<div className="table-container">
					<table className="table1">
						<thead>
							<tr>
								{<td>N° de Boleta</td>}
								<td>Fecha de Pago</td>
								<td>Monto</td>
								<td>Pagado en</td>
							</tr>
						</thead>
						<tbody>
							{!this.state.lecturas?null:this.state.lecturas.map((lectura, i) => {
								return(
									<tr key={'lectura-' + i}>
										{<td>{lectura.XBLNR??''}</td>}
										<td>{moment(lectura.FECHA_PAGO, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
										<td>${parseInt(lectura.MONTO).toLocaleString("de")}</td>
										<td>{lectura.TXT_RECAUDADOR}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					{!this.state.lecturas?<p>{this.state.lecturasMessage}</p>:null}
				</div>				
			</ContentBox>
		);
	}
}