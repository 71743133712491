import React, { useState, useEffect } from 'react';
import {
  meterBussinesLineSubsSync,
  meterBussinesLineSubsList,
  meterBussinesSaveSubs,
  meterBussinesLineFormIds,
} from '../api';
import ContentBox from './ContentBox.js';
import { useHistory } from 'react-router-dom';
import { ServiciosMedidorToggle } from './ServiciosMedidorToggle.js';
import Button2 from './Button2.js';

export default function ServiciosMedidorSuscripcion({
  services,
  user,
  setSolicitudRequerimientoPreloadData,
  solicitud_requerimiento_preload,
}) {
  const [loading, setLoading] = useState(true);
  const [subs, setSubs] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [masterError, setMasterError] = useState(false);
  const [solicitudPreload, setSolicitudPreload] = useState(false);
  const [formData, setFormData] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentSub, setCurrentSub] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [remainingContacts, setRemainingContacts] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (services) {
      meterBussinesLineSubsSync((err, res) => {
        if (err) {
          setMasterError('Existió un error, por favor reintente más tarde');
          setLoading(false);
        }
        if (res && res.status === 'error') {
          setMasterError(res.message);
          setLoading(false);
        }
        if (res && res.status === 'ok') {
          setMasterError(false);
          meterBussinesLineSubsList((err, res) => {
            let settingSubs = [];
            if (err) {
              setLoading(false);
            }
            if (res) {
              res.contacts.map((sub) => {
                settingSubs = [
                  ...settingSubs,
                  {
                    sap_client_id: sub.id,
                    client_id: sub.abastible_client_id,
                    suscribed: sub.suscribed,
                    contract_cod: sub.abastible_contract_id,
                    suscriber_type: sub.suscriber_type,
                    doc_type: sub.doc_type,
                    client_address: sub.address,
                  },
                ];
              });
              setSubs(settingSubs);
              setLoading(false);
            }
          });
          let crmId = 'C325';
          meterBussinesLineFormIds(crmId, (err, res) => {
            if (res && res.status === 'ok') {
              setFormData(res.data);
            }
          });
        }
      });
    }
  }, [services]);

  useEffect(() => {
    if (solicitud_requerimiento_preload && solicitudPreload) {
      history.push({ pathname: '/mis-servicios/medidor/requerimientos' });
    }
  }, [solicitud_requerimiento_preload]);

  const handleSuscribedType = (e, id) => {
    setSubs(
      subs.map((object) => {
        if (object.sap_client_id === id) {
          return {
            ...object,
            suscriber_type: e.target.value !== '' ? e.target.value : null,
          };
        } else {
          return object;
        }
      })
    );
  };

  const saveSubs = (newSubs, success = true) => {
    setLoading(true);
    setErrorMessage(false);
    meterBussinesSaveSubs(newSubs, (err, res) => {
      if (err) {
        setErrorMessage(
          'Ha ocurrido un error al intentar actualizar los datos, intentélo más tarde.'
        );
        setLoading(false);
      }
      if (res) {
        if (res.status === 'ok') {
          if (success) {
            setSuccessModal(true);
          }
          setRemainingContacts(res.remaining_contacts);
          setErrorMessage(res.message);
          setLoading(false);
        }
        if (res.status === 'some_ok') {
          let msg = ``;
          if (
            res.new_requirement_suscriptions &&
            res.new_requirement_suscriptions.length > 0
          ) {
            msg += `Para los siguientes códigos de cliente se alcanzó el máximo de usuarios suscritos por lo que la solicitud de suscripción pasará a revisión:\n`;
            res.new_requirement_suscriptions.map((x) => {
              msg += `- ${parseInt(x.NumeroContrato)} ${x.Direccion}\n`;
            });
          }
          if (
            res.has_requirement_suscriptions &&
            res.has_requirement_suscriptions.length > 0
          ) {
            msg +=
              'Para los siguientes números de cliente ya se registra una solicitud de suscripción en curso:\n';
            res.has_requirement_suscriptions.map((x) => {
              msg += `- ${parseInt(x.NumeroContrato)} ${x.Direccion} ${
                x.NroRequerimiento
              } ${x.FechaPlazo}\n`;
            });
          }
          if (
            res.error_requirement_suscriptions &&
            res.error_requirement_suscriptions.length > 0
          ) {
            msg += `Para los siguientes códigos de cliente se alcanzó el máximo de usuarios suscritos pero no se pudo generar una  solicitud de revisión suscripción:\n`;
            res.error_requirement_suscriptions.map((x) => {
              msg += `- ${parseInt(x.NumeroContrato)} ${x.Direccion}\n`;
            });
          }
          setErrorMessage(msg);
          setLoading(false);
        }
        if (res.status === 'error') {
          setErrorMessage(res.message);
          setLoading(false);
        }
      }
    });
  };

  const handleChange = () => {
    if (!currentSub.suscriber_type) return;
  
    const newSubs = subs.map((object) => {
      if (object.sap_client_id === currentSub.sap_client_id) {
        return {
          ...object,
          suscribed: !currentSub.suscribed,
        };
      } else {
        return object;
      }
    });
  
    const updatedCurrentSub = {
      ...currentSub,
      suscribed: !currentSub.suscribed,
    };
  
    saveSubs([updatedCurrentSub]);
    setSubs(newSubs);
    setOpenModal(false);
  };
  

  return (
    <ContentBox loading={loading}>
      <div className='title-client-container clearfix'>
        <h1>Boleta / Factura Electrónica</h1>
      </div>
      {masterError ? (
        <div
          className='filter-container grid-column-1'
          style={{ marginTop: '30px' }}
        >
          <div className='message' style={{ whiteSpace: 'pre-line' }}>
            <p>{masterError}</p>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className='col-100' style={{ margin: '0 auto 30px' }}>
            <p className='message'>
              Quiero recibir boleta/factura electrónica en mi correo{' '}
              <strong>{user.email}</strong> para las siguientes direcciones:
            </p>
          </div>
          <div className='table-container'>
            <table className='table1'>
              <thead>
                <tr>
                  <td>Suscripciones</td>
                  <td>N° de Cliente</td>
                  <td>Dirección</td>
                  <td>Categoría</td>
                  <td>Tipo de Documento</td>
                  <td style={{ width: '90px' }}></td>
                </tr>
              </thead>
              <tbody>
                {subs && subs.length > 0
                  ? subs.map((sub, i) => {
                      return (
                        <tr key={sub.sap_client_id}>
                          <td>
                            <ServiciosMedidorToggle
                              subscribed={sub.suscribed}
                              setOpen={setOpenModal}
                              onClick={() => {
                                setCurrentSub(sub);
                              }}
                              disabled={!sub.suscriber_type}
                            />
                          </td>
                          <td>{parseInt(sub.contract_cod)}</td>
                          <td>{sub.client_address}</td>
                          <td>
                            <select
                              value={
                                sub.suscriber_type ? sub.suscriber_type : ''
                              }
                              onChange={(e) => {
                                handleSuscribedType(e, sub.sap_client_id);
                              }}
                            >
                              <option style={{ color: '#ccc' }} value=''>
                                Selecciona
                              </option>
                              <option value='DUEÑO'>Dueño</option>
                              <option value='ARRENDATARIO'>Arrendatario</option>
                            </select>
                          </td>
                          <td>{sub.doc_type}</td>
                          <td>
                            <span
                              className='press changeTo uppercase'
                              onClick={() => {
                                setSolicitudRequerimientoPreloadData(
                                  {
                                    selectedService: services.find(
                                      (x) => x.client_id === sub.sap_client_id
                                    ),
                                    selectedCategory: parseInt(
                                      formData.request_category_id
                                    ),
                                    selectedType: parseInt(formData.id),
                                  },
                                  setSolicitudPreload(true)
                                );
                              }}
                            >
                              {' '}
                              {sub.doc_type === 'FACTURA'
                                ? 'cambiar a boleta'
                                : sub.doc_type === 'BOLETA'
                                ? 'cambiar a factura'
                                : null}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
          <div
            className='filter-container grid-column-3'
            style={{ marginTop: '30px' }}
          >
            <div></div>
            <div></div>
            <button
              className='button orange'
              onClick={() => {
                saveSubs(subs, false);
              }}
            >
              Guardar
            </button>
          </div>
          {errorMessage ? (
            <div
              className='filter-container grid-column-1'
              style={{ marginTop: '30px' }}
            >
              <div className='message' style={{ whiteSpace: 'pre-line' }}>
                <p>{errorMessage}</p>
              </div>
            </div>
          ) : null}
        </React.Fragment>
      )}
      <ModalConfirmation
        onClick={handleChange}
        open={openModal}
        setOpen={setOpenModal}
        sub={currentSub}
      />
      <ModalSuccess
        open={successModal}
        setOpen={setSuccessModal}
        status={currentSub ? !currentSub.suscribed : false}
        remainingContacts={remainingContacts}
      />
    </ContentBox>
  );
}

function ModalConfirmation({ onClick, open, setOpen, sub }) {
  const [email, setEmail] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setEmail(user.email);
  }, []);

  if (!sub) return null;

  return (
    <div className={`full-screen-veil ${open ? 'show-modal' : ''}`}>
      <div className='modal1 space-y-4'>
        <p>¡Hola!</p>
        <p>
          Estás a punto de{' '}
          {sub.suscribed ? (
            <>
              <b>cancelar la suscripción</b> de
            </>
          ) : (
            <b>suscribir</b>
          )}{' '}
          tu boleta electrónica asociada a:
        </p>
        <br />
        <p>
          N° de Cliente:{' '}
          <b>{parseInt(sub.contract_cod) ?? 'Sin información'}</b>
        </p>
        <p>
          Correo: <b>{email ?? 'Sin información'}</b>
        </p>
        <br />
        <p className='text-sm opacity-80'>
          Te recordamos que, si tu boleta no se encuentra suscrita a un correo
          electrónico, la recibiras de forma física en el domicilio.
        </p>
        <div className='col-100 left clearfix margin-top-20'>
          <Button2
            label='Cancelar'
            className='button button-border-orange left'
            onClick={() => setOpen(false)}
          />
          <Button2
            label='Aceptar'
            className='green right w-full md:w-fit'
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
}

function ModalSuccess({ open, setOpen, status, remainingContacts }) {
  return (
    <div className={`full-screen-veil ${open ? 'show-modal' : ''}`}>
      <div className='modal1 space-y-4'>
        <p>
          Tu <b>suscripción</b> a la boleta electrónica fue{' '}
          <b>{status ? 'activada con éxito' : 'cancelada con éxito'}</b>.
        </p>
        {remainingContacts === true && status === false ? (
          <p>
            La <b>boleta</b> electrónica <b>seguirá siendo enviada</b> a los{' '}
            <b>otros correos</b> electrónicos <b>suscritos</b> para este número
            de cliente.
          </p>
        ) : (
          <p>
            Recibirás la <b>boleta</b> en{' '}
            <b>
              {status
                ? 'tu correo electrónico'
                : 'formato físico en el domicilio'}
            </b>{' '}
            a contar del siguiente o subsiguiente mes de facturación.
          </p>
        )}
        <p>
          Si quieres{' '}
          <b>{status ? 'cancelar tu suscripción' : 'volver a suscribirte'}</b>{' '}
          puedes hacerlo a través de nuestros <b>canales digitales</b>.
        </p>
        <Button2
          label='Aceptar'
          className='orange'
          onClick={() => setOpen(false)}
        />
      </div>
    </div>
  );
}
