import React, { Component } from 'react';
import 'chartjs-plugin-datalabels';
import ContentBox from './ContentBox.js';
import ClientSelectMedidor from './ClientSelectMedidor';
import moment from 'moment';
import es from 'date-fns/locale/es';
import { registerLocale } from  "react-datepicker";
import {getAgreementsList} from '../api.js';
import {Link} from "react-router-dom";
registerLocale('es', es);

export default class ServiciosMedidorConvenioPago extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphData: null,
			agreements: null,
			agreementsMessage: null,
			times: 0,
			loading: false,
			newMsj: true
		};
	}	
	componentDidMount() {
		if(this.props.selectedService) {
			this.getList();
		}
	}	
	componentDidUpdate(prevProps) {
		if(prevProps.selectedService !== this.props.selectedService && this.props.selectedService) {
			this.getList();
		}
	}
	getList(cb) {
		this.setState({formErrors: null, loading: true, agreements: null, agreementsMessage: null, newMsj: true});
        this.getAgreements(() => {
            this.setState({loading: false, newMsj: true});
        });
	}
	getAgreements(cb) {
		getAgreementsList(this.props.selectedService != null ? Number(this.props.selectedService.contract_cod).toString() : Number(this.props.selectedMeter.contract_cod).toString(), (error, result) => {
			if(error) {
				cb(error);
				return console.log(error);
			}
			if(result.status === "error") {
				cb();
				return this.setState({agreementsMessage: result.message});
			}
			if(result.status === "empty") {
				cb();
				return this.setState({agreements: null, agreementsMessage: result.message, newMsj: false});
			}
			this.setState({agreements: result.data}, cb?cb:null);
		});
	}
	getState(agreement){
		let status = '';
		let agreementStatus = agreement.status.toUpperCase()
		if (agreement.is_agreement_valid === true) {
			switch(agreementStatus) {
				case "CONVENIO CREADO SAP":
					status = 'Convenio Creado';
					break;
				case "PAGADO":
				case "ERROR CREACIÓN CONVENIO":
					status = 'Pago pie en verificación';
					break;
				case "CREADO SAP":
				case "ANULADO":
				case "ERROR ANULACIÓN":
				case "PENDIENTE CREACIÓN SAP":
					status = 'Pago pie pendiente';
					break;
				case "PENDIENTE APROBACIÓN":
					status = 'En revisión';
					break;
				case "RECHAZADO":
					status = 'Rechazado';
					break;
				default:
					status = ''
				
			}
		} else if (agreement.is_agreement_valid === false) {
			status = 'Solicitud Rechazada';
		}
		return status;
	}
	render() {
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
						<h1>Convenio de Pago</h1>
						<div className="client-number">
							<ClientSelectMedidor 
								selected={this.props.selectedService} 
								services={this.props.services}
								setSelectedService={this.props.setSelectedService} 
								number_field={"contract_cod"} />	
						</div>
					</div>
					{this.state.agreements || this.state.newMsj?
					<div className="table-container">
						<table className="table1">
							<thead>
								<tr>
									<td>Solicitado el</td>
									<td>ID requerimiento</td>
									<td>Deuda a la fecha</td>
									<td>Monto pie</td>
									<td>Cuotas</td>
									<td>Estado</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								{!this.state.agreements?null:this.state.agreements.map((agreement, i) => {
									return(
								<tr key={'agreements-' + i}>
									<td>{moment(agreement.created_at).format("DD-MM-YYYY")}</td>
									<td>{agreement.request_crm_id}</td>
									<td>${parseInt(agreement.current_debt).toLocaleString("de")}</td>
									<td>${parseInt(agreement.down_payment).toLocaleString("de")}</td>
									<td>{agreement.payments}</td>
									<td>{this.getState(agreement)}</td>
									<td>
										<div className="linkA-container">
											<Link to={"/mis-servicios/medidor/convenio-pago/detalle/" + agreement.id}>Ver Detalle</Link>
										</div>
									</td>
								</tr>
									);

								})}
							</tbody>
						</table>
						{!this.state.agreements?<p>{this.state.agreementsMessage}</p>:null}
					</div>
					:
					<div className="message margin-bottom-40">
						<p className="mensajeConvenioMedidor">Para solicitar un convenio de pagos deber dirigirte a la sección  "Requerimientos", indicar tu código de cliente y seleccionar la categoría "Solicitud Convenio de Pago".</p>
					</div>}
					
			</ContentBox>
		);
	}
}