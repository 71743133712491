import React, { Component } from 'react';
import {Route, Switch, Redirect} from "react-router-dom";

import Frame from './Frame';
import EmpresasMyServicesMain from './EmpresasMyServicesMain';
import Solicitudes from './Solicitudes';
import InformacionInteres from './InformacionInteres';
import EmpresasFuncionarios from './EmpresasFuncionarios';
import EmpresasFuncionariosForm from './EmpresasFuncionariosForm';
import EmpresasFuncionariosEditar from './EmpresasFuncionariosEditar';
import EditarPerfil from './EditarPerfil';


import {
  getRequestList, userClientsPrivileges
} from '../api.js';
import {available} from '../config.js';

import menuServicios from '../img/menu-mis-servicios.svg';
import menuGranel from '../img/menu-granel.svg';
import menuAdministrar from '../img/menu-administrar.svg';
import menuRequerimientos from '../img/menu-requerimientos.svg';
import menuInformaciones from '../img/menu_informacion.png';


const initialMenuOptions = [
  {
    name: "Mis Servicios",
    to: ["/mis-servicios"],
    icon: menuServicios,
    sub: [      
      {
        name: "Granel Industrial",
        to: [
          "/mis-servicios/granel/resumen-tanques",
          "/mis-servicios/granel/*"
        ],
        icon: menuGranel
      },
      {
        name: "Envasado Institucional",
        to: [
          "/mis-servicios/cilindro/cotizar-pedido",
          "/mis-servicios/cilindro/*"
        ],
        icon: menuGranel
      }
    ]
  }
  ,
  {
    name: "Mis Requerimientos",
    to: [
      "/requerimientos",
      "/requerimientos/*"
    ],
    icon: menuRequerimientos,
  },
  {
    name: "Información de Interés",
    to: [
      "/informacion_interes",
      "/informacion_interes/*"

    ],
    icon: menuInformaciones
  },
  
  {
    name: "Mis funcionarios",
    to: [
      "/funcionarios",
      ],
    icon: menuAdministrar,
  }
];

export default class UserEmpresas extends Component {
	constructor(props) {
		super(props);
		this.state = {
  			menuOptions: [...initialMenuOptions]
		};
	}
	componentDidMount() {
        this.checkMenuOptions((error) => {});
	}	
	checkMenuOptions(cb) {
        this.hideFromMenu("/requerimientos");
        this.hideFromMenu("/mis-servicios/granel/*");        
        this.hideFromMenu("/mis-servicios/cilindro/*");        
		this.hideFromMenu("/funcionarios");

		if(this.props.user_role !== "Administrador")  {
			this.hideFromMenu("/funcionarios");
		} else {
			this.unhideFromMenu("/funcionarios");
		}
		userClientsPrivileges((error, resultClients) => {
			if(error) {
	            if(cb) cb(error);
				return console.log("ocurrio un error con el request", error);
			}
	        getRequestList(null, null, null, null, null, (error, resultRequests) => {
	          if(error) {
	             if(cb) cb(error);
	             return console.log("error en requestList para menu", error, resultRequests);
			  }	          
	          if(resultClients.status === "empty" || resultClients.data.filter(c=>parseInt(c.business_line_id)===6).length === 0) {
	            this.hideFromMenu("/mis-servicios/granel/*");
	          } else {
	            this.unhideFromMenu("/mis-servicios/granel/*");
	          }
	          if(!available.envasado_institucional_menu || resultClients.status === "empty" || resultClients.data.filter(c=>parseInt(c.business_line_id)===2).length === 0) {
	            this.hideFromMenu("/mis-servicios/cilindro/*");
	          } else {
	            this.unhideFromMenu("/mis-servicios/cilindro/*");
	          }
	          if(!available.menu_mis_requerimientos || resultRequests.status === "empty" || resultRequests.data.length === 0) {
	            this.hideFromMenu("/requerimientos");
	          } else {
	            this.unhideFromMenu("/requerimientos");
	          }
	          if(cb) cb();
	        }, "empresas");	      
		});	    
  	}   
	hideFromMenu(section) {
	    var menuOptions = [...this.state.menuOptions];
	    menuOptions.forEach((option, i) => {
	      if(option.to.indexOf(section) > -1) {
	        option.hidden = true;
	      } else {
	        if(!option.sub) return;
	        option.sub.forEach((sub, j) => {
	          if(sub.to.indexOf(section) > -1)
	            sub.hidden = true;
	        });
	      }

	    });
	    this.setState({menuOptions});
	}
	unhideFromMenu(section) {
	    var menuOptions = [...this.state.menuOptions];
	    menuOptions.forEach((option, i) => {
	      if(option.to.indexOf(section) > -1) {
	        option.hidden = false;
	      } else {
	        if(!option.sub) return;
	        option.sub.forEach((sub, j) => {
	          if(sub.to.indexOf(section) > -1)
	            sub.hidden = false;
	        });
	      }

	    });
	    this.setState({menuOptions});
	} 
	render() {
		return (
          	<Frame
	            user={this.props.user}
	            location={this.props.location}
	            logout={this.props.doLogout}
	            header_class={this.props.header_class}
	            notifications={this.props.notifications}
	            redirectWithData={this.props.redirectWithData}	            	            	            	            
	            menuOptions={this.state.menuOptions}>
				<Switch>
					<Route exact path="/editar-perfil"
		                render={(data)=> {
		                  return <EditarPerfil
		                  updateUser={this.props.updateUser}
		                  createActionNotification={this.props.createActionNotification}
		                  changeHeaderClass={this.props.changeHeaderClass}
		                  location={this.props.location}
		                  user={this.props.user}
		                  isEmpresas={true} />
		                }
		              }/>
					<Route path="/mis-servicios">
		                <EmpresasMyServicesMain
						  updateUser={this.props.updateUser} 
						  user_role={this.props.user_role}
						  redirect_data={this.props.redirect_data}
		                  redirect={this.props.redirect}	                
		                  location={this.props.location}	                	                
		                  changeHeaderClass={this.props.changeHeaderClass}
		                  checkMenuOptions={this.checkMenuOptions.bind(this)}
		                  createActionNotification={this.props.createActionNotification}
		                  getUser={this.props.getUser}
		                  user={this.props.user}
		                  menuOptions={this.state.menuOptions} />
		              </Route>          
					
		            <Route exact path="/funcionarios">
						<EmpresasFuncionarios
		                  createActionNotification={this.props.createActionNotification}
		                  changeHeaderClass={this.props.changeHeaderClass}
		                  location={this.props.location}
		                  user={this.props.user} />
		            </Route>
		            <Route exact path="/funcionarios/nuevo">
						<EmpresasFuncionariosForm
	            			toggleMainLoading={this.props.toggleMainLoading}      	                  	            

		                  createActionNotification={this.props.createActionNotification}
		                  redirect={this.props.redirect}	                		                  
		                  changeHeaderClass={this.props.changeHeaderClass}
		                  location={this.props.location}
		                  user={this.props.user} />
		            </Route>
		            <Route exact path="/funcionarios/:id"
	            	render={(data)=>
						<EmpresasFuncionariosEditar
							funcionario_id={data.match.params.id}
		                  	createActionNotification={this.props.createActionNotification}
		                  	changeHeaderClass={this.props.changeHeaderClass}
		                  	location={this.props.location}
		                  	user={this.props.user} />}
                  	/>
		            <Route path="/requerimientos/">
	                <Solicitudes
						isEmpresas={true}
						createActionNotification={this.props.createActionNotification}
						user={this.props.user}
						checkMenuOptions={this.checkMenuOptions.bind(this)}
						changeHeaderClass={this.props.changeHeaderClass} />
	              </Route>
	              

	              <Route path="/informacion_interes">
	                <InformacionInteres
	                	isEmpresas={true}
	                  location={this.props.location}
	                  changeHeaderClass={this.props.changeHeaderClass}
	                />
	              </Route>		            
		            <Route path="*">
		                <Redirect to={"/mis-servicios"} />
		            </Route>
        		</Switch>
            </Frame>
		);
	}
}
