import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import Tooltip  from 'rc-tooltip';
import GuestFrame from './GuestFrame.js';
import OrangeArrow from '../img/arrow_orange.png';
import FacebookIcon from '../img/facebook_f.png';
import GoogleIcon from '../img/google-g.png';
import {Link} from 'react-router-dom';
import {formatRut, checkRut} from '../utils.js';
import {available, params} from '../config.js';

export default class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			last_name: "",
			email: "",
			email_confirmation: "",
			dni: "",
			password: "",
			password_confirmation: "",
			phone: "",
			terms: false,
			errors: [],
			displayTerms: false,
		}
	}
	componentDidMount() {
		if(this.props.requiredMessage) {
			this.props.createActionNotification("Cuenta no registrada", "Para continuar completa el registro", "error");
		}
	}

	sendForm() {
		let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
		if(!this.state.terms) return this.setState({errors: {terms: true}})
		if(this.state.dni && !checkRut(this.state.dni)) return this.setState({errors: {dni: "El rut ingresado no es válido"}});
		let errors = {
			name: this.state.name === '' ? 'Campo requerido' : false,
			last_name: this.state.last_name === '' ? 'Campo requerido' : false,
			dni: this.state.dni === '' ? 'Campo requerido' : false,
			email: this.state.email === '' ? 'Campo requerido' : false,
			email_confirmation: this.state.email_confirmation === '' ? 'Campo requerido' : false,
			phone: this.state.phone === '' ? 'Campo requerido' : false,
			password: this.state.password === '' ? 'Campo requerido' : false,
			password_confirmation: this.state.password_confirmation === '' ? 'Campo requerido' : false,
		};
		if(!errors.phone){
			if(this.state.phone.length !== 9){errors.phone = 'Teléfono debe contener 9 dígitos'}
		}
		if(!errors.password){
			if(this.state.password !== this.state.password_confirmation){errors.password = 'Contraseñas deben ser iguales'}
		}
		if(!errors.email){
			if(!emailRegex.test(this.state.email)){errors.email = "Email debe contener . y @"}
		}
		if (!errors.email_confirmation) {
			if(this.state.email_confirmation !== this.state.email) {errors.email_confirmation = "Emails deben ser iguales"}
		}
		this.setState({
			errors: errors
		}, () => {
			var validation = true;
			for (var k in errors) {
				if(errors[k] !== false){
					validation = false;
				}
			}
			if(validation){
				this.setState({loading: true});
				this.props.doRegister(this.state.name, this.state.last_name, this.state.email, this.state.email_confirmation, this.state.dni, this.state.password, this.state.password_confirmation, this.state.phone, (error, result) => {
					this.setState({loading: false});
					if(error) {
						for(let index in error.errors){
							if(Array.isArray(error.errors[index])){
								error.errors[index] = error.errors[index][0];
							}
						}
						this.setState({errors: error.errors});
						console.log("error", error.errors);
					}
				});
			}
		});

	}
	deleteFormError(field_name) {
		var formErrors = {...this.state.errors};
		delete formErrors[field_name];
		this.setState({errors: formErrors})
	}

	render() {
		return (
			<GuestFrame>
				<div className="login-container register-container">
					<div className="background-flame">
						<h2>Registrate en Abastible</h2>
						<div className="login-social-buttons">
							{!available.login_google?null:<GoogleLogin
							    clientId={params.google_app_id}
							    responseType="code"
							    scope="profile email"
							    accessType="offline"
							    uxMode="redirect"
							    onSuccess={this.props.googleLoginCallback}
							    onFailure={responseGoogle}
							    render={renderProps => (
							      <button className="login-social-button google" onClick={renderProps.onClick}>
										<img alt="" src={GoogleIcon} /> Google</button>
							    )}
							    cookiePolicy={'none'} />}
							{!available.login_facebook?null:<FacebookLogin
							    appId={params.facebook_app_id}
							    autoLoad={false}
							    isMobile={false}
							    fields="name,email,picture.type(large),first_name,last_name"
							    onClick={() => {}}
							    render={renderProps => (
								    <button
								    	className="login-social-button facebook"
								    	onClick={renderProps.onClick}>
										<img alt="" src={FacebookIcon} /> Facebook
							    	</button>

								  )}
							    callback={this.props.facebookLoginCallback} />}

						</div>
						<div className="register-container margin-top-30">
							<div className="grid-column-2">
								<div className="login-field-container">
									<p>Nombre *</p>
								 	<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.name ? true: false}
								 	overlay={<span>{this.state.errors ? this.state.errors.name : ""}</span>}>
								 	<input
								 		value={this.state.name}
								 		onChange={(e) => {this.setState({name: e.target.value}); this.deleteFormError("name")}} />
									</Tooltip>
						 		</div>
						 		<div className="login-field-container">
									<p>Apellido *</p>
								 	<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.last_name ? true: false}
								 	overlay={<span>{this.state.errors ? this.state.errors.last_name : ""}</span>}>
								 	<input
								 		value={this.state.last_name}
								 		onChange={(e) => {this.setState({last_name: e.target.value}); this.deleteFormError("last_name")}} />
									</Tooltip>
						 		</div>
								<div className="login-field-container">
									<p>Rut *</p>
									<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.dni ? true: false}
								 	overlay={<span>{this.state.errors ? this.state.errors.dni : ""}</span>}>
								 	<input
								 		value={this.state.dni}
								 		onChange={(e) => {this.setState({dni: formatRut(e.target.value)}); this.deleteFormError("dni")}} />
									</Tooltip>
						 		</div>
						 		<div className="login-field-container">
									<p>Teléfono *</p>
									<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.phone ? true: false}
								 	overlay={<span>{this.state.errors ? this.state.errors.phone : ""}</span>}>
								 	<input
								 		value={this.state.phone}
								 		onChange={(e) => {this.setState({phone: e.target.value}); this.deleteFormError("phone")}} maxlength="9" />
									</Tooltip>
						 		</div>
					 		</div>

						 		<div className="login-field-container margin-top-20 margin-bottom-20">
									<p>Email *</p>
									<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.email ? true: false}
								 	overlay={<span>{this.state.errors ? this.state.errors.email : ""}</span>}>
								 	<input
								 		value={this.state.email}
								 		onChange={(e) => {this.setState({email: e.target.value}); this.deleteFormError("email")}} />
									</Tooltip>
						 		</div>

								<div className="login-field-container margin-top-20 margin-bottom-20">
									<p>Confirmar Email *</p>
									<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.email_confirmation ? true: false}
									overlay={<span>{this.state.errors ? this.state.errors.email_confirmation : ""}</span>}>
									<input
										value={this.state.email_confirmation}
										onChange={(e) => {this.setState({email_confirmation: e.target.value}); this.deleteFormError("email_confirmation")}} />
									</Tooltip>
								</div>

								<div className="grid-column-2">
							 		<div className="login-field-container">
										<p>Contraseña *</p>
										<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.password ? true: false}
									 	overlay={<span>{this.state.errors ? this.state.errors.password : ""}</span>}>
									 	<input type="password"
									 		value={this.state.password}
									 		onChange={(e) => {this.setState({password: e.target.value}); this.deleteFormError("password")}} />
										</Tooltip>
						 			</div>
							 		<div className="login-field-container">
										<p>Confirmar Contraseña *</p>
										<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.password_confirmation ? true: false}
									 	overlay={<span>{this.state.errors ? this.state.errors.password_confirmation : ""}</span>}>
									 	<input type="password"
									 		value={this.state.password_confirmation}
									 		onChange={(e) => {this.setState({password_confirmation: e.target.value}); this.deleteFormError("password_confirmation")}} />
										</Tooltip>
									</div>

								</div>
								<div className="message margin-top-30">
									<p>La contraseña debe contener mínimo 12 caracteres, al menos 1 minúscula, al menos 1 mayúscula, al menos 1 número y al menos 1 carácter especial.</p>
								</div>

						 		<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.terms ? true: false}
								 	overlay={<span>Debes aceptar los términos y condiciones de uso</span>}>
						 		<div className="terms-and-conditions">
						 			<label className="checkbox-container">
						 				<input type="checkbox" checked={this.state.terms} onChange={(e) => {this.setState({terms: e.target.checked})}} />
										<span className="checkmark"></span>
										<span>Acepto los <span className="terms-link" onClick={()=>{this.setState({displayTerms: !this.state.displayTerms})}}>
						 				términos y condiciones de uso *</span></span>
					 				</label>
						 			<div className={this.state.displayTerms ? 'show' : 'collapsed'}>
						 				<h2>Términos y Condiciones de Uso</h2>
						 				<p>Autorizo el tratamiento de mis datos personales con la finalidad de prestación del servicio y entrega de información y/o beneficios. Los referidos datos podrán en casos concretos ser comunicados a terceros, para cumplir con las finalidades recién mencionadas.</p>

									</div>
						 		</div>
						 		</Tooltip>
					 	</div>
				 		<div className="login-submit-button-container left col-100">
							<button  className="login-submit-button" onClick={() => this.sendForm()}>REGISTRARME</button>
							<img alt="REGISTRARME" src={OrangeArrow} />
						</div>
						<div className="link-white"><Link to={"/login"}><span>Volver</span></Link></div>
					</div>
				</div>
			</GuestFrame>

		);
	}
}
function responseGoogle() {

}
