import React, { Component } from 'react';
import {Route, Switch} from "react-router-dom";

import Login from './Login';
import LoginExternal from './LoginExternal';
import Register from './Register';
import RegisterEmpresa from './RegisterEmpresa';
import RegisterPrevent from './RegisterPrevent';
import Recover from './Recover';
import ConfirmEmail from './ConfirmEmail';
import SendVerificationEmail from './SendVerificationEmail';
import ConfirmUserData from './ConfirmUserData';	
import Frame from './Frame';

import {
  postLogin, postLoginFacebook, postLoginGoogle, postLoginExternal, postCompanyLoginExternal, postLoginEmpresas,
  postRegister, postRegisterFacebook, postRegisterGoogle,
  postRecover,
  postVerifyEmail, postVerifyEmailAgain,
} from '../api.js';
import SubscriptionTogglePage from './SubscriptionTogglePage.js';
import DesuscripcionFallaPage from './DesuscripcionFalla.js';

export default class UserGuest extends Component {
	constructor(props) {
		super(props);
		this.state = {
  			rrss_login_data: null,
			login_type: "personas"
		};
	}	  
	doLogin(email, password, cb) {
	    //console.log("doLogin start");
	    this.props.toggleMainLoading(true);	    
	    postLogin(email, password, (error, result) => {
	      //console.log("end postLogin en doLogin", error, result, "----------------");
	      if(error) {
	      		this.props.toggleMainLoading(false);
		        //console.log("ERROR!", error);
		        if(cb) cb(error);
		        return;
		    } else {
		    	if(result.message === "Correo o contraseña inválidos.") {
	      			this.props.toggleMainLoading(false);		    		
		    		if(cb) cb(result);
		    	}
		    	else if(result.status === "error_cuenta_deshabilitada") {          
		    		this.props.createActionNotification("Cuenta deshabilitada", "Por favor, Contacta a servicio al cliente.", "error");
	      			this.props.toggleMainLoading(false);		    		
		    		if(cb) cb(result);
		    	}
		    	else if(result.status === "error_email_verificado") {
		          //console.log("llegó que el mail no estaba verificado");
		          this.props.redirect("/enviar_codigo_verificacion", () => this.props.toggleMainLoading(false));
		      }
		      else if(result.status === "error") {
		      	alert("error inesperado");
	      		this.props.toggleMainLoading(false);		    				      	
		      } else {
		          //console.log("entramos");
		          this.props.createSession(result.data.person, result.data.token);
		          this.props.startUser(result.data.person, result.data.token);
		      }
		  }
		});
	}
	doLoginExternal(code, cb) {
	    this.props.toggleMainLoading(true);	    	    
	    postLoginExternal(code, (error, result) => {
	      if(error) {
	   		this.props.toggleMainLoading(false);	  
	        if(cb) cb(error);
	        return;
	    } else {
	    	if(result.message === "Correo o contraseña inválidos.") {
	      		this.props.toggleMainLoading(false);	    		
	    		if(cb) cb(result);
	    	}
	    	else if(result.status === "error_cuenta_deshabilitada") {
	          	this.props.createActionNotification("Cuenta deshabilitada", "Por favor, Contacta a servicio al cliente.", "error");
	      		this.props.toggleMainLoading(false);
	          	if(cb) cb(result);
	      }
	      else if(result.status === "error_email_verificado") {
	          if(cb) cb(result);
	          this.props.redirect("/enviar_codigo_verificacion", () => this.props.toggleMainLoading(false));
	      }
	      else if(result.status === "error") {
	      	if(cb) cb(error);
	      	this.props.toggleMainLoading(false);
	      } else {
	      	this.props.createSession(result.data.person, result.data.token);
	      	this.props.startUser(result.data.person, result.data.token);
	      }
	  }
	});
	}
	doCompanyExternalLogin(code, cb) {
		this.props.toggleMainLoading(true);	    	    
	    postCompanyLoginExternal(code, (error, result) => {
	      if(error) {
	   		this.props.toggleMainLoading(false);	  
	        if(cb) cb(error);
	        return;
	    } else {
	    	if(result.message === "Correo o contraseña inválidos.") {
	      		this.props.toggleMainLoading(false);	    		
	    		if(cb) cb(result);
	    	}
	    	else if(result.status === "error_cuenta_deshabilitada") {
	          	this.props.createActionNotification("Cuenta deshabilitada", "Por favor, Contacta a servicio al cliente.", "error");
	      		this.props.toggleMainLoading(false);
	          	if(cb) cb(result);
	      }
	      else if(result.status === "error_email_verificado") {
	          if(cb) cb(result);
	          this.props.redirect("/enviar_codigo_verificacion", () => this.props.toggleMainLoading(false));
	      }
	      else if(result.status === "error") {
	      	if(cb) cb(error);
	      	this.props.toggleMainLoading(false);
	      } else {
	      	this.props.createSession(result.data.person, result.data.token, result.data.user_type);
	      	this.props.startUser(result.data.person, result.data.token, result.data.user_type);
	      }
	  }
	});
	}
	doLoginEmpresas(email, password, cb) {
	    this.props.toggleMainLoading(true);	    	    
	    postLoginEmpresas(email, password, (error, result) => {
	      	if(error) {
		      	this.props.toggleMainLoading(false);	        
		        if(cb) cb(error);
		        return;
		    } else {
		    	if(result.message === "Correo o contraseña inválidos.") {
		    		this.props.toggleMainLoading(false);
		    		if(cb) cb(result);
		    	}
		    	else if(result.status === "error_cuenta_deshabilitada") {          
		    		this.props.createActionNotification("Cuenta deshabilitada", "Por favor, Contacta a servicio al cliente.", "error");
		    		this.props.toggleMainLoading(false);
		    		if(cb) cb(result);
		    	}
		    	else if(result.status === "error_email_verificado") {
		          this.props.redirect("/enviar_codigo_verificacion", () => this.props.toggleMainLoading(false));
		      	}
		      	else if(result.status === "error") {
		      	this.props.toggleMainLoading(false);
		      	if(result.message === "Cuenta sin empresas asociadas")
		    		this.props.createActionNotification("Cuenta sin empresas asociadas", "Por favor, Contacta a servicio al cliente.", "error");
		      	else alert("error inesperado");

		      	} else {
		          this.props.createSession(result.data.company_user, result.data.token, result.data.user_type);
		          this.props.startUserEmpresas(result.data.company_user, result.data.token, result.data.user_type);
		      	}
		  	}
		});
	}
	facebookLoginCallback(resultFB) {
		if(resultFB.status && resultFB.status === "unknown") return;
		this.props.toggleMainLoading(true);		
		postLoginFacebook(resultFB.accessToken, (error, result) => {
			if(error) {
				this.props.toggleMainLoading(false);
				console.log(error);
				return alert(JSON.stringify(error));
			}
			if(result.status === "error_cuenta_deshabilitada") {          
				this.props.toggleMainLoading(false);
				
				this.props.createActionNotification("Cuenta deshabilitada", "Por favor, Contacta a servicio al cliente.", "error");
				this.setState({main_loading: false});
			}
			else if(result.status === "ok_falta_registro" || result.status === "error_falta_registro") {
				this.props.toggleMainLoading(false);
				
				this.setState({
					rrss_login_data:{
						name: resultFB.first_name,
						last_name: resultFB.last_name,
						email: resultFB.email,
						token: resultFB.accessToken,
						profile_picture: resultFB.picture.data.url,
						type: "facebook"}}, () => {this.props.redirect("/confirmar_datos"); });
			} else if(result.status === "error_email_verificado") {
				return this.props.redirect("/enviar_codigo_verificacion", () => this.props.toggleMainLoading(false));
			} else {
				this.props.createSession(result.data.person, result.data.token);
				this.props.startUser(result.data.person, result.data.token);
			}

		});
	}
	googleLoginCallback(resultG) {
		this.props.toggleMainLoading(true);
		postLoginGoogle(resultG.code, (error, result) => {
			if(error) {
				this.props.toggleMainLoading(false);

				console.log(error);
				return alert(JSON.stringify(error));
			}
			if(result.status === "error_cuenta_deshabilitada") {      
				this.props.createActionNotification("Cuenta deshabilitada", "Por favor, Contacta a servicio al cliente.", "error");
				this.props.toggleMainLoading(false);

				this.setState({main_loading: false});
			}
			else if(result.status === "ok_falta_registro" || result.status === "error_falta_registro") {
				this.props.toggleMainLoading(false);

				this.setState({
					rrss_login_data:{
						token: result.google_token,
						type: "google",
						name: result.person.givenName,
						last_name: result.person.familyName,
						email: result.person.email,
						profile_picture: result.person.picture
					}}, () => this.props.redirect("/confirmar_datos"));
					this.props.toggleMainLoading(false);

			} else if(result.status === "error_email_verificado") {
				this.props.toggleMainLoading(false);

				return this.props.redirect("/enviar_codigo_verificacion", () => this.props.toggleMainLoading(false));				
			} else {
				this.props.createSession(result.data.person, result.data.token);
				this.props.startUser(result.data.person, result.data.token);
			}

		});
	}
	doRegister(name, last_name, email, email_confirmation, dni, password, password_confirmation, phone, cb) {

		this.props.toggleMainLoading(true);
		if(this.state.rrss_login_data && this.state.rrss_login_data.type === "facebook") {
			postRegisterFacebook(name, last_name, email, email_confirmation, dni, password, phone, this.state.rrss_login_data.token, this.state.rrss_login_data.profile_picture, (error, result) => {
				this.props.toggleMainLoading(false);
				if(error) {
					cb(error);
					return alert("error al registrar");
				}
				if(result.errors) {
					this.props.toggleMainLoading(false);
					return cb({errors: result.errors});
				}
				if(result.status === "ok") {
					this.facebookLoginCallback({accessToken: this.state.rrss_login_data.token});
				}
				cb();
	      //this.setState({redirect: "/verificar_email"}, () => this.setState({redirect: null}));
	  });
		} else if(this.state.rrss_login_data && this.state.rrss_login_data.type === "google") {
			postRegisterGoogle(name, last_name, email, email_confirmation, dni, password, phone, this.state.rrss_login_data.token, this.state.rrss_login_data.profile_picture, (error, result) => {
				this.props.toggleMainLoading(false);
				if(error) {
					cb(error);
					return alert("error al registrar");
				}
				if(result.errors) {
					this.props.toggleMainLoading(false);					
					return cb({errors: result.errors});
				}
				if(result.status === "ok") {
					this.googleLoginCallback({code: this.state.rrss_login_data.token});
				}
				cb();
			});
		} else {
			postRegister(name, last_name, email, email_confirmation, dni, password, password_confirmation, phone, (error, result) => {
				this.props.toggleMainLoading(false);
				if(error) {
					cb(error);
					return alert("error al registrar");
				}
				if(result.errors) {
					return cb({errors: result.errors});
				}
				cb();
      			this.props.redirect("/verificar_email");
			});
		}
	}
	doRecover(email, cb, isEmpresas) {
		this.props.toggleMainLoading(true);		
		postRecover(email, (error, result) => {
			this.props.toggleMainLoading(false);			
			if(error) {
				return console.log(error);
			}
			if(result.status === 'ok') {				
      			this.props.redirect("/login");
				this.props.createActionNotification("Nueva contraseña enviada", "Se ha enviado la nueva contraseña a tu correo");
			}
			cb(error, result);
		}, isEmpresas);
	}	
	doVerifyEmailAgain(email, cb) {
		this.props.toggleMainLoading(true);
		postVerifyEmailAgain(email, (error, result) => {
			this.props.toggleMainLoading(true);			
			if(error) {
				if(cb) cb(error);
				return console.log(error);
			}
			if(result.status === "error") {
				this.props.toggleMainLoading(false);				
			}
			if(result.status === 'ok'){
				this.props.createActionNotification("Correo enviado", "");
      			this.props.redirect("/verificar_email");		
				this.props.toggleMainLoading(false);
			}
			if(cb) cb(null, result);
		});
	}
	doVerifyEmail(code, cb) {
		this.props.toggleMainLoading(true);
		postVerifyEmail(code, (error, result) => {
	      	//console.log("END app.js postVerifyEmail", error, result);
	      	this.props.toggleMainLoading(false);
	      	if(error) {
		        //console.log("entramos al error");
		        return cb(error);
		    } else if(result.status === "ok") {
		        //return this.setState({redirect: "login"}, () => this.setState({redirect: null}));
		        cb(null, result);
		    } else {
		        //console.log("ahora mandaremos cb con null y el result");
		        cb(null, result);

		    }
		});
	}
	isCompanyLogin(loginType) {
		this.setState({login_type: loginType})
	}
	render() {
		return (
			<Switch>
				<Route exact path="/login">
					<Login 
						doLogin={this.doLogin.bind(this)}
						doLoginEmpresas={this.doLoginEmpresas.bind(this)} 
						loginType={this.state.login_type}
						facebookLoginCallback={this.facebookLoginCallback.bind(this)} 
						googleLoginCallback={this.googleLoginCallback.bind(this)} />
				</Route>
				<Route exact path="/login/:code" render={(data)=> {
					return <LoginExternal
							redirect={this.props.redirect}
							code={data.match.params.code}
							isCompany={new URLSearchParams(data.location.search).get("company")}
							doExternalLogin={this.doLoginExternal.bind(this)}
							setLoginType={this.isCompanyLogin.bind(this)}
							doCompanyExternalLogin={this.doCompanyExternalLogin.bind(this)}
							createActionNotification={this.props.createActionNotification}

					/>
				}} />
				<Route exact path="/register">
					<Register doRegister={this.doRegister.bind(this)} facebookLoginCallback={this.facebookLoginCallback.bind(this)} googleLoginCallback={this.googleLoginCallback.bind(this)} />
					{/* <RegisterPrevent redirect={this.props.redirect} /> */}
				</Route>
				<Route exact path="/register/required">
					<Register 
						doRegister={this.doRegister.bind(this)} 
						facebookLoginCallback={this.facebookLoginCallback.bind(this)} googleLoginCallback={this.googleLoginCallback.bind(this)} 
						requiredMessage={true}
						createActionNotification={this.props.createActionNotification}/>
				</Route>
				<Route path="/register_empresa">
					<RegisterEmpresa redirect={this.props.redirect} />
				</Route>
				<Route exact path="/verificar_email">
					<ConfirmEmail
						redirect={this.props.redirect}
						doVerifyEmail={this.doVerifyEmail.bind(this)}
						createActionNotification={this.props.createActionNotification}
						code={null}/>
				</Route>
				<Route
				path="/verificar_email/:code"
				render={(data)=> {
					return <ConfirmEmail
						redirect={this.props.redirect}
						doVerifyEmail={this.doVerifyEmail.bind(this)}
						createActionNotification={this.props.createActionNotification}
						code={data.match.params.code}
						/>
				}}
				/>
				<Route path="/enviar_codigo_verificacion">
					<SendVerificationEmail doVerifyEmailAgain={this.doVerifyEmailAgain.bind(this)} />
				</Route>
				<Route exact path="/recover">
					<Recover
						doRecover={this.doRecover.bind(this)}
						createActionNotification={this.props.createActionNotification} />
				</Route>
				<Route exact path="/recover/empresas">
					<Recover
						isEmpresas={true}
						doRecover={this.doRecover.bind(this)}
						createActionNotification={this.props.createActionNotification} />
				</Route>
				<Route path="/confirmar_datos">
					<Frame user={{}} location={this.props.location} menuOptions={[	]}>
						<ConfirmUserData data={this.state.rrss_login_data} doRegister={this.doRegister.bind(this)} />
					</Frame>
				</Route>
				<Route path="/desuscribir/:token">
					<SubscriptionTogglePage />
				</Route>
				<Route path="/desuscripcionfail">
					<DesuscripcionFallaPage />
				</Route>
			</Switch>
			);
	}
}
