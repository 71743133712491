import React, { useEffect, useState, useRef } from 'react'
import ContentBox from './ContentBox.js';
import ServicioAutogasSelectorPatente from "./ServicioAutogasSelectorPatente"
import { autogasGetMaintenance } from "../api"
import { Link, useLocation } from "react-router-dom";
import moment from "moment"


export default function ServiciosAutogasMantencionesAsistencias({patents, setSelectedPatenteAutogas, selectedPatenteAutogas}) {
    const [loading, setLoading] = useState(true)
    const [selectedOption, setSelectedOption] = useState("0")
    const [filter, setFilter] = useState(false)
    const [tableData, setTableData] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [scroll, setScroll] = useState(true)
    const location = useLocation();
    const firstRender = useRef(false)


    useEffect(()=>{
        if (location.state && !firstRender.current && filter === false) {
            firstRender.current = true
            setSelectedOption(location.state.selectedOption)
            getTableData(location.state.selectedPatenteAutogas.conversion_contract_number, location.state.selectedPatenteAutogas.patent, location.state.selectedOption)
        } else if (selectedPatenteAutogas) {
            getTableData(selectedPatenteAutogas.conversion_contract_number, selectedPatenteAutogas.patent, selectedOption)
        }
    }, [selectedPatenteAutogas, filter, location.state])

    const getTableData = (id_contract, patent_id, id_option) => {
        setLoading(true)
        autogasGetMaintenance(id_contract, patent_id, id_option, (err, res) => {
            if (err) {
                console.log(err)
            }
            if (res) {
                if (res.status === "error") {
                    let msg
                    switch(selectedOption) {
                        case "0":
                            msg = "No se registran mantenciones o asistencias para la patente indicada"
                            break;
                        case "1":
                            msg = "No se registran mantenciones para la patente indicada"
                            break;
                        case "2":
                            msg = "No se registran asistencias para la patente indicada"
                            break;
                        default:
                            msg = "No se registran mantenciones o asistencias para la patente indicada"
                    }
                    setTableData(false)
                    setEmpty(msg)
                    setLoading(false)
                } else if (res.status === "ok") {
                    let sortedData = res.data.sort((a,b) => (a.registration_date > b.registration_date) ? -1 : ((b.registration_date > a.registration_date) ? 1 : 0)); 
                    setTableData(sortedData)
                    setEmpty(false)
                    setLoading(false)
                }                    
            }
        })
    }

    return (
        <ContentBox loading={loading}>
            <div className="title-client-container clearfix">
                <h1>Mantenciones y Asistencias</h1>
                <div className="client-number">
                    <ServicioAutogasSelectorPatente 
                        selectedPatenteAutogas={selectedPatenteAutogas} 
                        patents={patents}
                        setSelectedPatenteAutogas={setSelectedPatenteAutogas} />	
                </div>
            </div>
            <div className="col-100 left d-flex justify-between margin-bottom-40">
                <div className="filter-container grid-column-2">
                    <div className="position-relative">
                        <select value={selectedOption} onChange={(e) => {e.preventDefault(); setSelectedOption(e.target.value)}}>
                            <option value="0">Todo</option>
                            <option value="1">Mantenciones</option>
                            <option value="2">Asistencias</option>
                        </select>
                    </div>    
                    <button className="button orange" onClick={() => {setFilter(!filter)}}>Filtrar</button>                
                </div>
            </div>
            <div className="table-container-2" onScroll={()=>{setScroll(false)}}>
                <div className={scroll ? null : "icon-scroll-fade"}><div className="icon-scroll"></div></div>
                <table className="table1">
                    <thead>
                        <tr>
                            <td>Tipo</td>
                            <td>Fecha de Registro</td>
                            <td>Descripción</td>
                            <td>Fecha Agendada</td>
                            <td>Hora Agendada</td>
                            <td></td>
                        </tr>							
                    </thead>
                    <tbody>
                        { tableData && tableData.length > 0 ? 
                            tableData.map((e, i) => {
                                return(
                                    <tr key={i}>
                                        <td>{e.type === "GM" ? "Mantención" : "Asistencia"}</td>
                                        <td>{moment(e.registration_date, "YYYY-MM-DD").isValid() ? moment(e.registration_date, "YYYY-MM-DD").format("DD-MM-YYYY") : null}</td>
                                        <td>{e.description}</td>
                                        <td>{moment(e.scheduled_date, "YYYY-MM-DD").isValid() ? moment(e.scheduled_date, "YYYY-MM-DD").format("DD-MM-YYYY") : null}</td>
                                        <td>{e.scheduled_time}</td>
                                        <td>
                                            <Link to={{
                                                pathname: "/mis-servicios/autogas/mantenciones-asistencias/"+ e.notice_id, 
                                                state:{                                                
                                                    selectedOption: selectedOption,
                                                    selectedPatenteAutogas: selectedPatenteAutogas
                                                    }
                                                }
                                            }>Ver Detalle</Link>
                                        </td>
                                    </tr>
                                )
                            })
                        : null }
                    </tbody>
                </table>
                { empty ? <p>{empty}</p> : null }
            </div>
        </ContentBox>
    )
}
