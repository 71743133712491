import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import {Link, withRouter} from "react-router-dom";

import Button2 from './Button2';
import SolicitudEvaluacion from './SolicitudEvaluacion';
import {getRequest, refreshToken, downPayment} from '../api.js';
import BreadCrumb from './BreadCrumb';
import ContentBox from './ContentBox.js';
import {urls} from '../config.js';
import moment from 'moment';
import flechaIzquierdaNegra from '../img/arrow_left_black.svg';

class Requerimiento extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
		evaluation_opened: false,
		loading: false,
		patente: null
	  };
	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.getRequest();
	}
	getRequest() {
		this.setState({loading: true});
		getRequest(this.props.request_id, (error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			this.setState({solicitud: result.data, loading: false});
			this.setState({patente: result.data.patent});
		}, this.props.isEmpresas?"empresas":null);
	}
	renderFields() {
		if(!this.state.solicitud) return;
		var created_at = moment(this.state.solicitud.created_at);
		var range = created_at.isBetween('2020-04-14', '2020-06-23');
		var groups = [];
		var currentGroup = null;
		var headerFields = [{type:"text", label: "Id", value: this.state.solicitud.crm_id}];
		if(this.state.patente) headerFields.push({type:"text", label: "Patente", value: this.state.patente});
		if(this.state.solicitud.term) headerFields.push({type:"text", label: "Plazo de respuesta", value: moment(this.state.solicitud.term, "YYYY-MM-DD").format("DD-MM-YYYY")});		
		[...headerFields, ...this.state.solicitud.data].forEach((field, i) => {
			//console.log(field);
			if(field.element !== "textarea") {
				var value = null;
				if(field.type === "file") {
					if(range) return null;
					value = <PostFileDownload value={field.value} request_id={this.props.request_id} field_name={field.name} filename={field.file_name} />;
				}
				else value = <p>{field.value}</p>;
				var fieldHTML =
					<div key={field.name?field.name:"id"} className="solicitud-detalle-field">
						<div className="solicitud-detalle-label">
							<p>{field.label}</p>
						</div>
						<div className="solicitud-detalle-value">
							{value}
						</div>
					</div>;
				if(currentGroup) currentGroup.push(fieldHTML);
				else currentGroup = [fieldHTML];
			} else {
				if(currentGroup) {
					groups.push(
						<div className="solicitud-detalle-fields col-100 left clearfix margin-top-30">
							{currentGroup}
						</div>);
					currentGroup = null;
				}
				groups.push(
					<div className="solicitud-detalle-comment col-100 left clearfix">
						<div className="solicitud-detalle-value">
							<p>{field.label}</p>
						</div>
						<div className="solicitud-detalle-label">
							<p>{field.value}</p>
						</div>
					</div>);
			}
		});
		if(currentGroup) groups.push(
			<div className="solicitud-detalle-fields col-100 left clearfix margin-top-30" key={groups.length}>
				{currentGroup}
			</div>);
		return groups;
	}
	closeSolicitud(){
		this.getRequest();
		this.setState({evaluation_opened: false})
	}
	renderAgreement(){
		let agreement = this.state.solicitud.agreement;
		
		return(
			<div className="solicitud-detalle-fields col-100 left clearfix">				
				<h2 className="uppercase clearfix"><strong style={{marginBottom: '30px'}} className="color-black bold col-100 left">Convenio Solicitado</strong></h2>			
				<div className="solicitud-detalle-field">
					<div className="solicitud-detalle-label">
						<p>Deuda vigente</p>
					</div>
					<div className="solicitud-detalle-value">
						<p>${parseInt(agreement.current_debt).toLocaleString("de")}</p>
					</div>
				</div>
				<div className="solicitud-detalle-field">
					<div className="solicitud-detalle-label">
						<p>Pie</p>
					</div>
					<div className="solicitud-detalle-value">
						<p>${parseInt(agreement.down_payment).toLocaleString("de")}</p>
					</div>
				</div>
				<div className="solicitud-detalle-field">
					<div className="solicitud-detalle-label">
						<p>Saldo a pagar en cuotas</p>
					</div>
					<div className="solicitud-detalle-value">
						<p>${(parseInt(agreement.current_debt) - parseInt(agreement.down_payment)).toLocaleString("de")}</p>
					</div>
				</div>
				<div className="solicitud-detalle-field">
					<div className="solicitud-detalle-label">
						<p>Cuotas</p>
					</div>
					<div className="solicitud-detalle-value">
						<p>{agreement.payments}</p>
					</div>
				</div>
				{this.renderAgreementState(agreement)}				
			</div>
		);
	}
	downPayment(status){
		this.setState({loading: true});
		downPayment(this.props.request_id, (error, result) => {
			this.setState({loading: false});
			if(typeof(error) != 'undefined' && error != null) {
				console.log("ocurrio un error con el request");
			}
			if(typeof(result) != 'undefined' && result != null && result.status === 'error') {
				this.props.createActionNotification("", result.message, "error");
				return console.log("ocurrio un error con el request");
			}
			if(typeof(result) != 'undefined' && result != null && result.status === 'ok'){
				var url = urls.portal_pago + 'medidor?codCliente=' + Number(this.state.solicitud.agreement.abastible_contract_id).toString() + '&email=' + this.props.user.email + '&movil=' + this.props.user.phone + '&pie=true';
				window.open(url, '_blank');
			}
		});
		
	}
	renderAgreementState(agreement){
		
		if(this.state.solicitud.status === "Cerrado"){
			if(this.state.solicitud.clossing_justification === "Z0000001"){
				if(agreement.status === "Pagado"){
					return(
						<div className="subcontent-box solicitud-respuesta margin-top-30">
							<div className="solicitud-detalle-status-container">
								<div>
									<h3 className="solicitud-detalle-status-title">Se ha registrado el pago del pie. Puedes verificar el estado de tu convenio en la sección "Convenios"</h3>
								</div>
							</div>
						</div>
					);
				} else if(agreement.status === "Pendiente creación SAP" || agreement.status === "Error anulación" || agreement.status === "Creado SAP" || agreement.status === 'Anulado') {
					return(
						<div className="subcontent-box solicitud-respuesta margin-top-30">
							<div className="solicitud-detalle-status-container">
								<div>
									<h3 className="solicitud-detalle-status-title">Tu solicitud ha sido aprobada{this.state.solicitud.agreement_validity ? " y será válida hasta el " + moment(this.state.solicitud.agreement_validity, "YYYY-MM-DD").format("DD-MM-YYYY") : null}. Presiona el botón "crear convenio" para dar curso al pago del pie.</h3>
									<div className="margin-top-30">
										<Button2 label="Crear Convenio de Pago" className="orange" onClick={()=>{this.downPayment(agreement.status)}} />
									</div>
								</div>
							</div>
						</div>
					);
				} else {
					return null;
				}
			}
			if(this.state.solicitud.clossing_justification === "Z0000003"){
				return(
					<div className="subcontent-box solicitud-respuesta margin-top-30">
						<div className="solicitud-detalle-status-container">
							<div>
								<h3 className="solicitud-detalle-status-title">Su solicitud de convenio de pago ha sido rechazada. {this.state.solicitud.respuesta ? this.state.solicitud.respuesta : null}</h3>
							</div>
						</div>
					</div>
				);
			}
		}
	}
	render() {
		var breadCrumbOptions = [
			{title: "Mis Requerimientos", to: "/requerimientos"},
			{title: "Detalle de Requerimiento"},
		];
		return (
				<Fragment>
					<div className="section-title">
						<h1>Mis Requerimientos</h1>
						<BreadCrumb options={breadCrumbOptions} />
					</div>
					<ContentBox loading={this.state.loading} className="solicitud-distribuidor">
						<h2 className="color-gray uppercase clearfix">Requerimiento: <strong className="color-black bold col-100 left">{this.state.solicitud ? this.state.solicitud.request_type.name : ""}</strong></h2>
						<Link to={{pathname:"/requerimientos", state: {filterParams: this.props.location.state.filterParams}}}>
							<div>
								<img alt="Atrás" src={flechaIzquierdaNegra}/>
								<p>atrás</p>
							</div>
						</Link>
						{this.renderFields()}
						{this.state.solicitud && !this.state.solicitud.agreement?<div className="subcontent-box solicitud-respuesta margin-top-30">
							<div className="solicitud-detalle-status-container">
								<h3 className="solicitud-detalle-status-title">Estado:</h3>
								<h3 className="solicitud-detalle-status solved">{this.state.solicitud ? this.state.solicitud.status : ""}</h3>
							</div>							
							{this.state.solicitud && 
							this.state.solicitud.respuesta &&
							this.state.solicitud.term &&
							new Date(this.state.solicitud.term).getTime() >= new Date("2020-09-01").getTime() ?
							<div>
								<div className="solicitud-detalle-value">
									<p>Respuesta</p>
								</div>
								<div className="solicitud-detalle-label">
									<p>{this.state.solicitud.respuesta}</p>
								</div>
							</div> : null}							
							{this.state.solicitud && this.state.solicitud.status === "Cerrado" && !this.state.solicitud.calificacion ?
							<Button2 label="Calificar" className="orange" onClick={()=>{this.setState({evaluation_opened: true})}} /> : null}
						</div>:null}
						{this.state.solicitud && this.state.solicitud.agreement ? this.renderAgreement() : null}
					</ContentBox>
					{this.state.evaluation_opened ? <SolicitudEvaluacion request_id={this.props.request_id} close={() => this.closeSolicitud()} /> : null}
				</Fragment>
		);
	}
}

export class PostFileDownload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: ""
		};
	}
	download() {
		refreshToken((error, result) => {
			if(error) return alert("Error descargando el archivo");
			this.setState({token: result.data.token}, () => {
				this._form.submit();
			});
		});
	}
	attachNode(node) {
    	this._form = ReactDOM.findDOMNode(node);
  	}
	render() {
		return (
			<div className="linkA-container" onClick={() => this.download()}>
				<form ref={this.attachNode.bind(this)}
				method="POST" action={urls.backend + "/request/file"}>
					<input type="hidden" name="request_id" value={this.props.request_id} />
					<input type="hidden" name="token" value={this.state.token} />
					<input type="hidden" name="name" value={this.props.field_name} />
				</form>
				<a><strong>{this.props.filename}</strong></a>
			</div>
		);
	}
}

export default withRouter(Requerimiento);