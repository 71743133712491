import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import {pathIsInArray} from '../utils.js';
import {available} from '../config.js';

export default class CustomTabs extends Component {
	

	render() {
		return (<Fragment>
			<div className="clearfix relative">
				<div className="tabs-container-master">
					<div className="tabs-container">
						{!this.props.tabs?null:this.props.tabs.map((tab, i) => {
		          			if(!available.granel && tab.title === "Granel Habitacional") return null;
							return(
							<Link key={i} to={typeof tab.to === "string"?tab.to:tab.to[0]}>
								<div className={"tab-container" + (pathIsInArray(tab.to, this.props.location.pathname)  ? " selected" : "")}>
									<p>{tab.title}</p>
								</div>
							</Link>
							);
						})}
					</div>
				</div>
				<div className="tabs-container-shadow"></div>
			</div>
			</Fragment>
		);
	}
}
