import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import ContentBox from './ContentBox.js';
import {cilindroBussinesLineList} from '../api.js';
import ServiceListBox from './ServiceListBox.js';
import {Link} from 'react-router-dom';
import {urls} from '../config.js';
import {getServiceAddressString} from '../utils.js';
import WhatsAppButton from './WhatsAppButton.js';

export default class CilindroRealizarPedido extends Component {
	constructor(props) {
		super(props);
		this.state = {
            cylinderServices: null
		};
	}
	componentDidMount() {
		this.getList();
	}
	getList(cb) {
		this.setState({loading: true, cylinderServices: []});
		cilindroBussinesLineList((error, result) => {
			if(error) {
				return console.log("ocurrio un error con el request");
			}
			var cylinderServices = null;
			if(result.status !== "empty") {
				cylinderServices = result.data;
				this.setState({
					lecturasMessage: 'No existen clientes asociados'
				});
			} else {
				cylinderServices = [];
			}
			this.setState({
				cylinderServices: cylinderServices,
				loading: false,
			});
		});
	}
	filter() {
		this.getList();
	}
	renderServices(services) {
		return(
			<div className="list-boxes-container col-100 left clearfix">
                {!services?null:services.map((service) => {
					return(
					<FormTomaPedidos user={this.props.user} key={service.client_cod} service={service} type="pedido" onButtonClick={() => {}} />
					);
				})}
				{!this.state.cylinderServices?<p>{this.state.lecturasMessage}</p>:null}
			</div>
		);
	}
	render() {
		var services = this.state.cylinderServices;
		if(this.state.parentServices && this.props.services) services = this.props.services.filter((s)=>parseInt(s.business_line_id) === 6);
		
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Realizar Pedidos</h1>
				</div>
				<div className="message margin-bottom-40">
					<p>Una vez realizado tu pedido, podrás verlo en la sección <Link to={"/mis-servicios/cilindro/pedidos"}>Historial de Pedidos</Link></p>
				</div>
				{this.renderServices(services)}
				<WhatsAppButton text={"Pide Gas Cilindro Aquí"}/>
			</ContentBox>
		);
	}
}
export class FormTomaPedidos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: ""
		};
	}
	submit() {		
		this._form.submit();
	}
	attachNode(node) {
    	this._form = ReactDOM.findDOMNode(node);
  	}	  
	render() {
		return (
			<div>
				<form ref={this.attachNode.bind(this)} target="_blank"
				method="POST" action={urls.portal_pedidos + "/pedidos/cilindro/info-cliente-sap-sucursal"}>
					<input type="hidden" name="tipo_servicio" value="EN" />
					<input type="hidden" name="cl_codigo" value={this.props.service.client_cod} />
					<input type="hidden" name="direccion" value={getServiceAddressString(this.props.service) + ", " + this.props.service.client_city} />
    				<input type="hidden" name="telefono" value={this.props.user.phone} />
				</form>
				<ServiceListBox	displayNumberLabel={"client_cod"} service={this.props.service} type="pedido" onButtonClick={() => {this.submit()}} />		
			</div>
		);
	}
}