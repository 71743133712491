import React, { Component } from 'react';
import GuestFrame from './GuestFrame.js';
import LoadingFull from './LoadingFull';
import {Link } from "react-router-dom";
import OrangeArrow from '../img/arrow_orange.png';

export default class LoginExternal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			code_error: null
		}
	}
	componentDidMount() {
		this.sendCodeForm();
	}		
	sendCodeForm() {
		this.setState({loading: true});
		if (this.props.isCompany === "true") {
			this.props.setLoginType("empresas")
			this.props.doCompanyExternalLogin(this.props.code, (error, result) => {
				console.log("codigo", result)
				this.setState({loading: false});
				if(error) {
					this.props.createActionNotification("Error con inicio de sesión empresa", "Intentelo nuevamente", "error");
					return console.log("error en request", error);
				}	
				if(!result) {
					this.props.createActionNotification("Error con inicio de sesión empresa", "Intentelo nuevamente", "error");
					setTimeout(() => {
						this.props.redirect("/login");
					}, this.props.code?0:1500);          					
					return;
				}
				if(result.status === "error") {
					this.props.createActionNotification("Error con inicio de sesión empresa", result.message, "error");
					setTimeout(() => {
						this.props.redirect("/login");
					}, this.props.code?0:1500);          					
					return;
				}	
				this.props.redirect("/login");
			});
		} else {
			this.props.setLoginType("personas")
			this.props.doExternalLogin(this.props.code, (error, result) => {
				this.setState({loading: false});
				if(error) {
					this.props.createActionNotification("Error con inicio de sesión", "Intentelo nuevamente", "error");
					return console.log("error en request", error);
				}	
				if(!result) {
					this.props.createActionNotification("Error con inicio de sesión", "Intentelo nuevamente", "error");
					setTimeout(() => {
						this.props.redirect("/login");
					}, this.props.code?0:1500);          					
					return;
				}
				if(result.status === "error") {
					this.props.createActionNotification("Error con inicio de sesión", result.message, "error");
					setTimeout(() => {
						this.props.redirect("/login");
					}, this.props.code?0:1500);          					
					return;
				}	
				this.props.redirect("/login");
			});
		}
	}	

	render() {
		if(this.props.code) {
			return <LoadingFull />;
		}
		return (
			<GuestFrame>	
				<div className="login-container">
					<div className="background-flame">
						<h2>Se ha enviado correo de verificación</h2>
						<p className="color-white">Hemos enviado un correo electrónico a la dirección que indicaste con las instrucciones para completar tu registro.</p>						
				 		<div className="login-submit-button-container">
							<button  className="login-submit-button" onClick={() => this.props.redirect("/login")}>IR AL INICIO</button>
							<img alt="" src={OrangeArrow} />
						</div>	
						<div className="link-white"><Link to={"/enviar_codigo_verificacion"}><span>Reenviar correo</span></Link></div>
					</div>
				</div>
			</GuestFrame>
				
		);
	}
}