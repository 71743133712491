import React, { Component } from 'react';
import Button2 from './Button2';
import close from '../img/close_orange.svg';
import {Link} from "react-router-dom"

export default class ModalConfirmation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: ""
		};
	}

	render() {
		return (
			<div className={"full-screen-veil " + (this.props.show ? 'show-modal' : '')}>
			<div className="modal1" style={this.props.expand?{width:'50%', padding:'10px',}:{}}>

				<div className="content col-100 left clearfix" style={this.props.expand?{maxHeight:'75vh'}:{}}>
					{this.props.expand && this.props.cancel?<img  src={close} alt="cerrar modal" onClick={() => {this.props.cancel()}} style={{cursor:'pointer', position:'absolute', top:'10px', right:'10px'}} />:null}
					{this.props.title?<h1 className="fw700">{this.props.title}</h1>:null}
					{this.props.renderContent?
						this.props.link?<Link to={this.props.link}>{this.props.renderContent()}</Link>:this.props.renderContent()
					:null}
					{((!this.props.expand && this.props.cancel)||this.props.confirm)?
		          	<div className="col-100 left clearfix margin-top-20">
			            {!this.props.expand && this.props.cancel?<Button2 label="Cancelar" className="button button-border-orange left" onClick={() => {this.props.cancel()}}/>:null}
			            {this.props.confirm?<Button2 label={this.props.isAceptar ? "Aceptar" : "Confirmar"} className={"button green" + (this.props.cancel?" right":"")} onClick={() => {this.props.confirm()}}/>:null}
					</div>
					:null}
				</div>

			</div>
			</div>
		);
	}
}
