import React, { Component} from 'react';
import {refreshToken, documentCheck} from '../api.js';
import ReactDOM from 'react-dom';
import ApiUser from '../api_user_store';


import {urls} from '../config.js';
export default class PostFileDownloadDocument extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: "",
			show_button: false,
		};
	}
	componentDidMount() {
		documentCheck(this.props.pdf_number, this.props.class_description, (error, result) => {
			if(error || result.status !== "ok"){
				this.setState({show_button: false});
				if (this.props.doCheck) {
					if (this.props.docType) {
						this.props.isChecked(this.props.docType)
					} else {
						this.props.isChecked(this.props.pdf_number)
					}
				}
			}else{
				this.setState({show_button: true});
				if (this.props.doCheck) {
					if (this.props.docType) {
						this.props.isChecked(this.props.docType)
					} else {
						this.props.isChecked(this.props.pdf_number)
					}
				}
			}
		}, this.props.isEmpresa?"empresas":null);
	}

	download() {			
		refreshToken((error, result) => {
			if(error) return alert("Error descargando el archivo");
			this.setState({token: result.data.token}, () => {
				this._form.submit();
			});
		});
	}
	
	/* isSafari() {
		if (navigator.vendor.match(/[Aa]+pple/g) && navigator.vendor.match(/[Aa]+pple/g).length > 0 ) {
			
			return true; 
		} else {
			
			return false;
		}			
	   } */

	attachNode(node) {
    	this._form = ReactDOM.findDOMNode(node);
  	}	  
	render() {
		let api_user = new ApiUser().get();  
		var url = api_user.role !== "Administrador" && api_user.role !== "Funcionario" ? (urls.backend + "/document/get") : (urls.backend + "/company_user/document/documento_electronico");
		
		if(this.state.show_button){
			return (
				<div  onClick={() => this.download()}>
					<form ref={this.attachNode.bind(this)}
					method="POST" action={url} target="_blank">
						<input type="hidden" name="pdf_number" value={this.props.pdf_number} />
						<input type="hidden" name="class_description" value={this.props.class_description} />
						<input type="hidden" name="token" value={this.state.token} />					
					</form>
					{this.props.contentRender()}
				
				</div>
			);
		}else{
			return null;
		}
	}
}