import React, { Component } from 'react';
import GuestFrame from './GuestFrame.js';
import {Link} from 'react-router-dom';
import Tooltip  from 'rc-tooltip';

export default class Recover extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			errors: []
		};
	}
	sendForm() {
		this.setState({loading: true});
		this.props.doRecover(this.state.email, (error, result) => {
			this.setState({loading: false});
			if(error) {
				this.setState({errors: error.errors});
				console.log("error", error.errors);
				return;
			}
			if(result.status === 'error'){
				var errorForm = {
					'email': result.message
				}
				this.setState({errors: errorForm});
			}
		}, this.props.isEmpresas);

	}
	deleteFormError(field_name) {
		var formErrors = {...this.state.errors};
		delete formErrors[field_name];
		this.setState({errors: formErrors})
	}
	render() {
		return (
			<GuestFrame>
				<div className="login-container">
					<div className="background-flame">
						<h2 className="margin-bottom-40">Olvidé mi Contraseña</h2>
						<p className="color-white">Ingresa tu correo electrónico y te enviaremos las instrucciones para restaurar tu contraseña.</p>
						<div className="login-field-container recover-mail">
							<p>Email</p>
							<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.email ? true : false}
				 			overlay={<span>{this.state.errors ? this.state.errors.email : ""}</span>}>
						 		<input
									value={this.state.email}
									onChange={(e) => {this.setState({email: e.target.value}); this.deleteFormError("email")}} />
							</Tooltip>
				 		</div>
				 		<div className="login-submit-button-container recover-link">
							<button className="login-submit-button margin-top-10" onClick={() => this.sendForm()}>REESTABLECER CONTRASEÑA</button>
						</div>
						<div className="link-white"><Link to={"/login"}><span>Volver</span></Link></div>
					</div>
				</div>
			</GuestFrame>

		);
	}
}
