import React, { Component, Fragment } from 'react';
import CustomTabs from './CustomTabs';
import {Route, Switch, Redirect} from "react-router-dom";
import ServiciosGranelPedidos from './ServiciosGranelPedidos';
import ServiciosGranelRealizarPedido from './ServiciosGranelRealizarPedido';
import ServiciosGeneralRequerimientos from './ServiciosGeneralRequerimientos';
import ServiciosGranelTelemetria from './ServiciosGranelTelemetria';
import ServiciosGranelTelemetriaTanques from './ServiciosGranelTelemetriaTanques';
import ServiciosGranelTelemetriaTanque from './ServiciosGranelTelemetriaTanque';
import ServiciosGranelTelemetriaTanqueHistorial from './ServiciosGranelTelemetriaTanqueHistorial';
import ServiciosGranelCuentaCorriente from './ServiciosGranelCuentaCorriente';
import ServiciosGranelHistorialPagos from './ServiciosGranelHistorialPagos';
import ServiciosGranelDetalleDeuda from './ServiciosGranelDetalleDeuda';
import ServiciosGranelTarifas from './ServiciosGranelTarifas.js';

import {housingBussinesLineList} from '../api.js';
import {available} from '../config.js';

export default class ServiciosGranel extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.tabs = [			
            {
				title: "Realizar Pedidos",
				to: "/mis-servicios/granel/realizar-pedidos"
			},
			{
				title: "Historial de Pedidos",
				to: [
					"/mis-servicios/granel/pedidos",
					"/mis-servicios/granel/pedidos/*"
				]
            },
            {
				title: "Historial de Pagos",
				to: [
					"/mis-servicios/granel/historial-pagos",
				]
            },
            {
				title: "Detalle de Deuda",
				to: [
					"/mis-servicios/granel/detalle-deuda"
				]
            },
			{
				title: "Cuenta Corriente",
				to: [
					"/mis-servicios/granel/cuenta-corriente",
					"/mis-servicios/granel/cuenta-corriente/*"]
			},           
			{
				title: "Telemetría",
				to: [
					"/mis-servicios/granel/telemetria",
					"/mis-servicios/granel/telemetria/*/*",
					"/mis-servicios/granel/telemetria/*/tanques/*",
					"/mis-servicios/granel/telemetria/*/tanques/*/historial"


				]
            },
            {
				title: "Requerimientos",
				to: "/mis-servicios/granel/requerimientos"
			},
			{
				title: "Variación de Precios",
				to: "/mis-servicios/granel/variacion-precio"
            },
		];

	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadServices();		
	}
	loadServices() {
		housingBussinesLineList((error, result) => {
			if(error) {
				return console.log("ocurrio un error con el request");
			}
			var services = null;
			if(result.status !== "empty") {
				services = result.data;
			} else {
				services = [];
				this.props.changeHeaderClass("big");
			}
			this.setState({
				services,
			});
			if(!this.props.selectedHousing) this.props.setSelectedHousing(services[0]);

		});
	}	
	render() {
		return (
			<Fragment>
				<div className="section-title">
					<h1>Granel Habitacional</h1>
				</div>
				<CustomTabs tabs={this.tabs.filter((tab) => {
					if(!available.granel_pedidos && tab.title === "Historial de Pedidos") return false;
					if(!available.granel_detalle_deuda && tab.title === "Detalle de Deuda") return false;
					if(!available.granel_historial_pago && tab.title === "Historial de Pagos") return false;
					if(!available.granel_realizar_pedido && tab.title === "Realizar Pedidos") return false;
					if(!available.granel_telemetria && tab.title === "Telemetría") return false;
					if(!available.granel_requerimientos && tab.title === "Requerimientos") return false;
					if(!available.granel_cuenta_corriente && tab.title === "Cuenta Corriente") return false;
					if(!available.granel_tarifas && tab.title === "Variación de Precios") return false;

					return true;
				})} location={this.props.location} />	
        		<Switch>
        			<Route path="/mis-servicios/granel/pedidos">
	                	<ServiciosGranelPedidos 
	                		services={this.state.services} 
	                		selectedService={this.props.selectedHousing}
	                		setSelectedService={this.props.setSelectedHousing} />
		            </Route>
		            
		            <Route exact path="/mis-servicios/granel/detalle-deuda" render={()=>
						<ServiciosGranelDetalleDeuda 
						user={this.props.user}          	
						services={this.state.services}
						selectedService={this.props.selectedHousing}
						createActionNotification={this.props.createActionNotification}
						setSelectedService={this.props.setSelectedHousing}/>
						
					}/>
		            <Route exact path="/mis-servicios/granel/historial-pagos">
	                	<ServiciosGranelHistorialPagos
	                		createActionNotification={this.props.createActionNotification}
	                		services={this.state.services} 
	                		selectedService={this.props.selectedHousing}
	                		setSelectedService={this.props.setSelectedHousing} />
		            </Route>
					<Route path="/mis-servicios/granel/realizar-pedidos">
	                	<ServiciosGranelRealizarPedido user={this.props.user} />
		            </Route>
		            <Route exact path="/mis-servicios/granel/requerimientos">
	                	<ServiciosGeneralRequerimientos
	                		solicitud_requerimiento_preload={this.state.solicitud_requerimiento_preload}	                	
	                		business_line={"granel"}
                  			checkMenuOptions={this.props.checkMenuOptions} />	                		
		            </Route>
					<Route exact path="/mis-servicios/granel/telemetria">
	                	<ServiciosGranelTelemetria 
							redirect={this.props.redirect}
							user={this.props.user}
							getUser={this.props.getUser}
	                		createActionNotification={this.props.createActionNotification}							
							updateUser={this.props.updateUser}
						/>
		            </Route>
		            <Route path="/mis-servicios/granel/cuenta-corriente">
	                	<ServiciosGranelCuentaCorriente
	            			redirect_data={this.props.redirect_data}  
	                		createActionNotification={this.props.createActionNotification}
	            			      
	                		services={this.state.services} 
	                		selectedService={this.props.selectedHousing}
	                		setSelectedService={this.props.setSelectedHousing} />
		            </Route>
		            
		            <Route exact path="/mis-servicios/granel/variacion-precio">
	                	<ServiciosGranelTarifas 
	                		services={this.state.services} 
	                		selectedService={this.props.selectedHousing}
	                		setSelectedService={this.props.setSelectedHousing}
					 />
		            </Route>
		            <Route exact path="/mis-servicios/granel/telemetria/:client_cod/tanques"
              			render={(data)=> 
	                	<ServiciosGranelTelemetriaTanques
	                		client_cod={data.match.params.client_cod}
							redirect={this.props.redirect}
						/>}/>
					<Route exact path="/mis-servicios/granel/telemetria/:client_cod/tanques/:tank_id"
              			render={(data)=> 
							<ServiciosGranelTelemetriaTanque 
	                			client_cod={data.match.params.client_cod}
	                			tank_id={data.match.params.tank_id}	                			
							/>
						}
					></Route>
					<Route exact path="/mis-servicios/granel/telemetria/:client_cod/tanques/:tank_id/historial"
              			render={(data)=> 
							<ServiciosGranelTelemetriaTanqueHistorial 
	                			client_cod={data.match.params.client_cod}
	                			tank_id={data.match.params.tank_id}
	                			
							/>
						}
					></Route>
					<Route path="*">
		                <Redirect to={"/mis-servicios"} />
		          	</Route>
        		</Switch>
			</Fragment>
		);
	}
}
