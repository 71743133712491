import React, { Component } from 'react';
import Button2 from './Button2';
import close from '../img/close_orange.svg';
import {Link} from "react-router-dom"

export default class ModalCampain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: ""
		};
	}

	render() {
		let thisStyle = {};
		let modalStyle = {width:'auto', padding:'10px'};
		let styleCloseButton = {cursor:'pointer',position:'absolute', top:'10px', left:'10px', width:'20px', height:'auto'};
		if(this.props.closeRight){
			styleCloseButton['left']='';
			styleCloseButton['right']='10px';
		}
		
		if(this.props.expand){
			thisStyle['maxHeight']='75vh';
		}

		if(this.props.expandFull){
			thisStyle['maxHeight']='95vh';
			modalStyle['maxHeight']='95vh';
		}
		if(this.props.noPadding){
			thisStyle['padding']='initial';
		}
		return (
			<div className={"full-screen-veil " + (this.props.show ? 'show-modal' : '')}>
			<div className="modal1" style={modalStyle}>

				<div className="content col-100 left clearfix" style={thisStyle}>
					<img  src={close} alt="cerrar modal" onClick={() => {this.props.cancel()}} style={styleCloseButton} />
					{this.props.renderContent?
						this.props.link?
							this.props.externalLink?
								<a href={this.props.link} target={"_blank"} onClick={() => {this.props.cancel()}} >{this.props.renderContent()}</a>:
								<Link to={this.props.link} >{this.props.renderContent()}</Link>:
						this.props.renderContent()
					:null}
				</div>

			</div>
			</div>
		);
	}
}
