import React, { useEffect, useState } from 'react'
import ContentBox from './ContentBox.js';
import { autogasGetNotice } from "../api"
import { Link, useLocation, useParams } from "react-router-dom";
import flechaIzquierdaNegra from '../img/arrow_left_black.svg';
import moment from "moment"
import Button2 from './Button2';
import PopupPedidoAnularMantencion from './PopupPedidoAnularMantencion';


export default function ServiciosAutogasMantencionesAsistenciasDetalle() {
    const [loading, setLoading] = useState(true)
    const params = useParams();
    const location = useLocation();
    const [noticeId, setNoticeId] = useState(false)
    const [noticeData, setNoticeData] = useState(false)
    const [showAnular, setShowAnular] = useState(false)

    useEffect(()=>{
        setNoticeId(params.notice_id)
    }, [params])

    useEffect(()=>{
        if (noticeId && location) {
            autogasGetNotice(location.state.selectedPatenteAutogas.conversion_contract_number, location.state.selectedPatenteAutogas.patent, noticeId, (err, res) => {
                if (err) {
                    console.log(err)
                }
                if (res) {
                    setNoticeData(res.data)
                    setLoading(false)
                }
            })
        }
    }, [noticeId, location])

    return (
        <ContentBox loading={loading}>
            {!noticeData ? null : 
            <React.Fragment>
                <div className="title-client-container clearfix">
                    <h1>{noticeData.type === "GM" ? "Detalle Mantención" : "Detalle Asistencia"}</h1>
                </div>
                <div>
                    <Link to={{pathname: "/mis-servicios/autogas/mantenciones-asistencias", state: { selectedPatenteAutogas: location.state.selectedPatenteAutogas, selectedOption: location.state.selectedOption}}}>
                        <div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30">
                            <img alt="Atrás" src={flechaIzquierdaNegra}/>
                            <p>atrás</p>
                        </div>
                    </Link>
                </div>
                <div className="solicitud-detalle-fields col-100 left clearfix">
                    <div className="solicitud-detalle-field">
                        <div className="solicitud-detalle-label ">
                            <p>Tipo</p>
                        </div>
                        <div className="solicitud-detalle-value">
                            <p>{noticeData.type === "GM" ? "MANTENCIÓN" : "ASISTENCIA"}</p>
                        </div>
                    </div>
                    <div className="solicitud-detalle-field">
                        <div className="solicitud-detalle-label ">
                            <p>Fecha de Registro</p>
                        </div>
                        <div className="solicitud-detalle-value">
                            <p>{moment(noticeData.registration_date, "YYYY-MM-DD").isValid() ? moment(noticeData.registration_date, "YYYY-MM-DD").format("DD-MM-YYYY") : null}</p>
                        </div>
                    </div>
                    <div className="solicitud-detalle-field">
                        <div className="solicitud-detalle-label ">
                            <p>Descripción</p>
                        </div>
                        <div className="solicitud-detalle-value">
                            <p>{noticeData.description.toUpperCase()}</p>
                        </div>
                    </div>
                    <div className="solicitud-detalle-field">
                        <div className="solicitud-detalle-label ">
                            <p>Fecha Agendada</p>
                        </div>
                        <div className="solicitud-detalle-value">
                            <p>{moment(noticeData.scheduled_date, "YYYY-MM-DD").isValid() ? moment(noticeData.scheduled_date, "YYYY-MM-DD").format("DD-MM-YYYY") : null}</p>
                        </div>
                    </div>
                    <div className="solicitud-detalle-field">
                        <div className="solicitud-detalle-label ">
                            <p>Hora Agendada</p>
                        </div>
                        <div className="solicitud-detalle-value">
                            <p>{noticeData.scheduled_time}</p>
                        </div>
                    </div>
                    <div className="solicitud-detalle-field">
                        <div className="solicitud-detalle-label ">
                            <p>Nombre del Taller</p>
                        </div>
                        <div className="solicitud-detalle-value">
                            <p>{noticeData.workshop_name}</p>
                        </div>
                    </div>
                    <div className="solicitud-detalle-field">
                        <div className="solicitud-detalle-label ">
                            <p>Dirección del taller</p>
                        </div>
                        <div className="solicitud-detalle-value">
                            <p>{noticeData.workshop_address}</p>
                        </div>
                    </div>
                    <div className="solicitud-detalle-field">
                        <div className="solicitud-detalle-label ">
                            <p>Comuna del taller</p>
                        </div>
                        <div className="solicitud-detalle-value">
                            <p>{noticeData.workshop_city}</p>
                        </div>
                    </div>
                    <div className="solicitud-detalle-field">
                        <div className="solicitud-detalle-label ">
                            <p>Región del taller</p>
                        </div>
                        <div className="solicitud-detalle-value">
                            <p>{noticeData.workshop_region}</p>
                        </div>
                    </div>
                    <div className="solicitud-detalle-field">
                        <div className="solicitud-detalle-label ">
                            <p>Estado</p>
                        </div>
                        <div className="solicitud-detalle-value">
                            <p>{noticeData.status === "1" ? "PENDIENTE" : "REALIZADA"}</p>
                        </div>
                    </div>
                    {noticeData.type === "GM" && noticeData.status === "1" && (moment(noticeData.scheduled_date + noticeData.scheduled_time, "YYYY-MM-DD hh:mm") > moment()) ? 
                        <div className="col-100 text-center clearfix">
                            <Button2 label="Anular Mantención" className="button-border-orange" onClick={()=>{setShowAnular(true)}}/>
                        </div>
                        : null
                    }
                </div>
            </React.Fragment>
            }
            <PopupPedidoAnularMantencion 
					patent={location.state.selectedPatenteAutogas.patent} 
					noticeId={noticeId} 
					cancel={()=>setShowAnular(false)} 
                    show={showAnular} 
            />
        </ContentBox>
    )
}
