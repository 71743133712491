import React, { Component } from 'react';
import {Route, Switch, Redirect} from "react-router-dom";

import Frame from './Frame';
import SeleccionarServicioAgregar from './SeleccionarServicioAgregar';
import Solicitudes from './Solicitudes';
import InformacionInteres from './InformacionInteres';
import EditarPerfil from './EditarPerfil';
import MisCuentas from './MisCuentas';

import MyServicesMain from './MyServicesMain';

import {
  housingBussinesLineList, meterBussinesLineList, cilindroBussinesLineList, autogasBussinesLineList, 
  getRequestList, cilindroNotifications
} from '../api.js';
import {available} from '../config.js';
import menuServicios from '../img/menu-mis-servicios.svg';
import menuMedidor from '../img/menu-medidor.svg';
import menuGranel from '../img/menu-granel.svg';
import menuCilindro from '../img/icon_cilindro.png';
import menuAutogas from '../img/icon_autogas.png';

import menuAdministrar from '../img/menu-administrar.svg';
import menuInformaciones from '../img/menu_informacion.png';
import menuRequerimientos from '../img/menu-requerimientos.svg';
const initialMenuOptions = [
  {
    name: "Mis Servicios",
    to: ["/mis-servicios", "/servicios/seleccionar"],
    icon: menuServicios,
    sub: [
      {
        name: "Medidor",
        to: [
          "/mis-servicios/medidor/boletas",
          "/mis-servicios/medidor/*",
        ],
        icon: menuMedidor,
      },
      {
        name: "Granel",
        to: [
          "/mis-servicios/granel/realizar-pedidos",
          "/mis-servicios/granel/*"
        ],
        icon: menuGranel
      },
      {
        name: "Cilindro",
        to: ["/mis-servicios/cilindro/realizar-pedidos",
        	"/mis-servicios/cilindro/*"],
        icon: menuCilindro
      },
      {
        name: "Autogas",
        to: ["/mis-servicios/autogas/contrato",
        	"/mis-servicios/autogas/*"
        ],
        icon: menuAutogas
      }
    ]
  },
  {
    name: "Mis Requerimientos",
    to: [
      "/requerimientos",
      "/requerimientos/*"
    ],
    icon: menuRequerimientos,
  },
  {
    name: "Información de Interés",
    to: [
      "/informacion_interes",
      "/informacion_interes/*"

    ],
    icon: menuInformaciones
  },
  {
    name: "Administrar mis servicios/productos",
    to: [
      "/adminitracion_cuentas/",
      "/adminitracion_cuentas/*"],
    icon: menuAdministrar,
  }
];

export default class UserNatural extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOptions: [...initialMenuOptions]
		};
	}
	componentDidMount() {
		this.checkMenuOptions((error) => {});
	}	
	componentWillUnmount() {
		clearInterval(this.interval);
	  }
	
	checkMenuOptions(cb) {
        this.hideFromMenu("/mis-servicios/medidor/*");
        this.hideFromMenu("/mis-servicios/granel/*");
        this.hideFromMenu("/mis-servicios/cilindro/*");
        this.hideFromMenu("/mis-servicios/autogas/*");

        this.hideFromMenu("/adminitracion_cuentas/*");	          	
        this.hideFromMenu("/requerimientos");
        this.unhideFromMenu("/informacion_interes");
        


	    meterBussinesLineList((error, resultMeter) => {
	      //console.debug("1 meterBussinesLineList DONE", error, resultMeter);
	      if(!error && !resultMeter) {/*console.log("callback se mal ejecuto");*/return;}
	      if(error) {
	        if(cb) cb(error);
	        return console.log("error en meterBussinessLineList para menu", error);
	      }
	      housingBussinesLineList((error, resultHousing) => {
	        if(error) {
	          if(cb) cb(error);
	          return console.log("error en housingBussinessLineList para menu", error);
	        }
	        cilindroBussinesLineList((error, resultCilindro) => {
		        if(error) {
		          if(cb) cb(error);
		          return console.log("error en cilindroBussinessLineList para menu", error);
		        }
		        autogasBussinesLineList((error, resultAutogas) => {
			        if(error) {
			          if(cb) cb(error);
			          return console.log("error en autogasBussinessLineList para menu", error);
			        }
			        getRequestList(null, null, null, null, null, (error, resultRequests) => {
			          if(error) {
			             if(cb) cb(error);
			             return console.log("error en requestList para menu", error, resultRequests);
			          }
			          var hasMeter = false;
			          var hasHousing = false;
			          var hasCilindro = false;
			          var hasAutogas = false;

			          if(resultMeter.status !== "empty") {
			            this.unhideFromMenu("/mis-servicios/medidor/*");
			          	hasMeter = true;
			          }
			          if(available.granel && resultHousing.status !== "empty") {
			            this.unhideFromMenu("/mis-servicios/granel/*");
			          	hasHousing = true;
			          }
			          if(available.cilindro && resultCilindro.status !== "empty") {
			            this.unhideFromMenu("/mis-servicios/cilindro/*");
						  hasCilindro = true;
						  cilindroNotifications(1, (error) => {
							  if (error) {
								  console.log(error)
							  }
						  })
						  this.interval = setInterval(() => 
						  	cilindroNotifications(0, (error) => {
								if (error) {
									console.log(error)
								}
							}), 60000);
					  }
					  if(available.autogas && resultAutogas.status !== "empty") {
			            this.unhideFromMenu("/mis-servicios/autogas/*");
			          	hasAutogas = true;
			          }
			          if(hasMeter || hasHousing || hasCilindro || hasAutogas) {
			            this.unhideFromMenu("/adminitracion_cuentas/*");        	
					  }
			          if(!available.menu_mis_requerimientos || resultRequests.status === "empty" || resultRequests.data.length === 0) {
			            this.hideFromMenu("/requerimientos");
			          } else {	            
			            this.unhideFromMenu("/requerimientos");
			          }
			          if(cb) cb();
		          	});
		        });
	        });
	      });
	    });
  	}   
	hideFromMenu(section) {
	    var menuOptions = [...this.state.menuOptions];
	    menuOptions.forEach((option, i) => {
	      if(option.to.indexOf(section) > -1) {
	        option.hidden = true;
	      } else {
	        if(!option.sub) return;
	        option.sub.forEach((sub, j) => {
	          if(sub.to.indexOf(section) > -1)
	            sub.hidden = true;
	        });
	      }

	    });
	    this.setState({menuOptions});
	}
	unhideFromMenu(section) {
	    var menuOptions = [...this.state.menuOptions];
	    menuOptions.forEach((option, i) => {
	      if(option.to.indexOf(section) > -1) {
	        option.hidden = false;
	      } else {
	        if(!option.sub) return;
	        option.sub.forEach((sub, j) => {
	          if(sub.to.indexOf(section) > -1)
	            sub.hidden = false;
	        });
	      }

	    });
	    this.setState({menuOptions});
	} 
	render() {
		return (
	          <Frame
	            user={this.props.user}
	            location={this.props.location}
	            logout={this.props.doLogout}
	            header_class={this.props.header_class}
	            notifications={this.props.notifications}
	            redirectWithData={this.props.redirectWithData}	            	            
	            menuOptions={this.state.menuOptions}>
				<Switch>          
	              <Route exact path="/editar-perfil"
	                render={(data)=> {
	                  return <EditarPerfil
	                  updateUser={this.props.updateUser}
	                  createActionNotification={this.props.createActionNotification}
	                  changeHeaderClass={this.props.changeHeaderClass}
	                  location={this.props.location}
	                  user={this.props.user} />
	                }
	              }/>
	              <Route path="/servicios/seleccionar">
	                <SeleccionarServicioAgregar
	                  changeHeaderClass={this.props.changeHeaderClass}
	                 />
	              </Route>
	              <Route path="/mis-servicios">
	                <MyServicesMain
						updateUser={this.props.updateUser}   
	            		redirect_data={this.props.redirect_data}        
	                  	redirect={this.props.redirect}	                
	                  	location={this.props.location}	                	                
	                  	changeHeaderClass={this.props.changeHeaderClass}
	                  	checkMenuOptions={this.checkMenuOptions.bind(this)}
	                  	createActionNotification={this.props.createActionNotification}
	                  	getUser={this.props.getUser}
	                  	user={this.props.user} />
	              </Route>
	              <Route path="/requerimientos/">
	                <Solicitudes
						user={this.props.user}
						createActionNotification={this.props.createActionNotification}
						checkMenuOptions={this.checkMenuOptions.bind(this)}
						changeHeaderClass={this.props.changeHeaderClass} />
	              </Route>	              
	              <Route path="/informacion_interes">
	                <InformacionInteres
	                  location={this.props.location}
	                  changeHeaderClass={this.props.changeHeaderClass}
	                />
	              </Route>
	              <Route path="/adminitracion_cuentas/"
	              render={(data)=> {
	                return <MisCuentas
	                  redirect={this.props.redirect}
	                  location={this.props.location}
	                  changeHeaderClass={this.props.changeHeaderClass}
	                  createActionNotification={this.props.createActionNotification}
	                  checkMenuOptions={this.checkMenuOptions.bind(this)}
	                  user={this.props.user}
	                />
	              }} />
	              <Route path="*">
		                <Redirect to={"/mis-servicios"} />
		          </Route>
	             
        		</Switch>
	            </Frame>
		);
	}
}
