import React, { Component } from 'react';
import Close from '../img/close.svg';
import Button2 from './Button2';
import { autogasCancelMaintenance } from '../api.js';
import SolicitudAyudaImg from '../img/solicitud_ayuda_ok.png';
import ContentBox from './ContentBox';

export default class PopupPedidoAnular extends Component {
	constructor(props) {
		super(props);
		this.state = {
			step: props.initialStep?props.initialStep:1,
			loading: false,
		};
	}
	sendForm() {
		this.setState({loading: true})
		autogasCancelMaintenance(this.props.patent, this.props.noticeId, (err, res) => {
            if (err) {
				console.log(err)
				this.setState({loading: false})
                return this.setState({step: "retry"});
            }
            if (res) {
				this.setState({loading: false})
                this.setState({step: 2});
            }
        })
		
	}
	cancel() {
		this.setState({step: this.props.initialStep?this.props.initialStep:1})
		this.props.cancel();
	}
	renderStep1() {
		return(
			<div className="content col-100 left clearfix max-height-none">
				<h1 className="fw700">Anular Mantención</h1>
				<div className="col-100 left clearfix margin-bottom-20 font-20" style={{overflowY: "initial"}}>
					<p>¿Confirma anulación de hora agendada?</p>

				</div>
				<div className="col-100 left clearfix flex justify-center">
					<Button2 label="No" className="button button-border-orange" onClick={() => {this.cancel()}}/>
					<Button2 label="Sí" className="button green" onClick={() => {this.sendForm()}}/>
				</div>
			</div>);
	}

	renderStep2() {
		return(
			<div className="content col-100 left clearfix">
	      		<h1 className="fw700">Se ha enviado la solicitud de anulación de la mantención.</h1>
	      		<div className="information-need-help-ok-image">
					<img className="question-orange" src={SolicitudAyudaImg} alt="Ícono ayuda" />
				</div>
			</div>);
	}
	renderStepRetry() {
		return(
			<div className="content col-100 left clearfix">
	      		<h1 className="fw700">Error anulando mantención, inténtalo más tarde</h1>
	      		<div className="col-100 left clearfix">
		            <Button2 label="Reintentar" className="button green right" onClick={() => {this.sendForm()}}/>
				</div>
			</div>);
	}
	renderCantCancel() {
		return(
			<div className="content col-100 left clearfix">
	      		<h1 className="fw700">Ya solicitaste anular esta mantención</h1>
	      		<div className="information-need-help-ok-image">
					<img className="question-orange" src={SolicitudAyudaImg} alt="Ícono ayuda" />
				</div>
			</div>);
	}
	renderSteps() {
		if(this.state.step === 1) return this.renderStep1();
		if(this.state.step === 2) return this.renderStep2();
		if(this.state.step === "retry") return this.renderStepRetry();

	}
	render() {
		return (
			<div className={"full-screen-veil " + (this.props.show ? 'show-modal' : '')}>
				<div className="modal1">
					<ContentBox loading={this.state.loading}>
						{this.renderSteps()}
					</ContentBox>
					<div className="solicitud-evaluacion-close-container" onClick={() => this.cancel()}>
						<img alt="" src={Close} />
					</div>
				</div>
			</div>
		);
	}
}
