import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import calendar from '../img/calendar.svg';
import bannerModal from '../img/banner_convenio_c360.png';
import DatePicker from 'react-datepicker';
import 'chartjs-plugin-datalabels';
import ContentBox from './ContentBox.js';
import ClientSelectMedidor from './ClientSelectMedidor';
import moment from 'moment';
import es from 'date-fns/locale/es';
import { registerLocale } from  "react-datepicker";
import {getTickets, refreshToken, meterBussinesLineSubsSync, meterBussinesLineSubsList} from '../api.js';
import {available, urls} from '../config.js';
import {Link} from "react-router-dom"
import Button2 from "./Button2"
import ModalConfirmation from './ModalConfirmation';
registerLocale('es', es);

export default class ServiciosMedidorBoletas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphData: null,
			lecturas: null,
			lecturasMessage: null,
			times: 0,
			loading: false,	
			subs: [],
			hasSubs: false,		
			from: moment().subtract(2, "months").toDate(),
			to: moment().toDate()
		};
		if(window.location.hash ?? window.location.hash == 'showmodal'){
			this.state.modalC360 = bannerModal;
		}
	}	
	componentDidMount() {
		if(this.props.selectedService) {
			this.getList();
			this.loadSubs();
		}
	}	
	componentDidUpdate(prevProps) {
		if(prevProps.selectedService !== this.props.selectedService && this.props.selectedService) {
			this.getList();
			this.loadSubs();
		}	
		if(this.state.subs && this.state.subs.length > 0 && this.state.hasSubs === false) {
			if (this.state.subs.find(x => x.suscribed === true)) {
				this.setState({hasSubs: true})
			}
		}	
	}

	loadSubs() {
		this.setState({loading: true})
		meterBussinesLineSubsSync((err, res) => {
			if (err) {
				console.log(err)
				this.setState({loading: false})
			}
			if (res && res.status === "ok") {
				meterBussinesLineSubsList((err, res) => {
					let settingSubs = []
					if (err) {
						console.log(err)
						this.setState({loading: false})
					}
					if (res) {
						res.contacts.map((sub) => {
							settingSubs = [...settingSubs, 
								{sap_client_id: sub.id,
								suscribed: sub.suscribed}]
						})
						this.setState({subs: settingSubs, loading: false})
					}
				})
			}
		})
	}
	getList(cb) {
		this.setState({formErrors: null, loading: true, lecturas: null, lecturasMessage: null});
        var start = moment(this.state.from).startOf('month').format("YYYY-MM-DD");
        var end = moment(this.state.to).endOf('month').format("YYYY-MM-DD");
        this.getTickets(start, end, () => {
            this.setState({loading: false});
        });
	}
	filter() {
		if(this.state.from && this.state.to){
			this.getList();
		}else{
			this.setState({lecturas: null, lecturasMessage: 'No se encontraron boletas para el periodo seleccionado'});
		}
	}
	getTickets(from, to, cb) {
		if(!from || !to) {
			cb();
			return alert("Debes seleccionar Fecha de inicio y de Término");
		}
		getTickets(from, to, this.props.selectedService != null ? Number(this.props.selectedService.contract_cod).toString() : Number(this.props.selectedMeter.contract_cod).toString(), (error, result) => {
			if(error) {
				cb(error);
				return console.log(error);
			}
			if(result.status === "error") {
				cb();
				return this.setState({lecturasMessage: result.message});
			}
			if(result.status === "empty") {
				cb();
				return this.setState({lecturas: null, lecturasMessage: result.message});
			}
			//let tableDataOrdered = result.data.sort((a,b) => (a.broadcast_date < b.broadcast_date) ? 1 : ((b.broadcast_date < a.broadcast_date) ? -1 : 0))
			this.setState({lecturas: result.data}, cb?cb:null);
		});
	}
	render() {
		if(window.location.hash ?? window.location.hash == 'showmodal'){
			window.history.pushState("", document.title, window.location.pathname+ window.location.search);
		}
		return (
			<>
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
						<h1>Boletas</h1>
						<div className="client-number">
							<ClientSelectMedidor 
								selected={this.props.selectedService} 
								services={this.props.services}
								setSelectedService={this.props.setSelectedService} />	
						</div>
					</div>
					{available.medidor_suscripcion ? 
						<div className="message margin-bottom-40">
							{this.state.hasSubs ? 
							<p>Modifica envío de boleta/factura electrónica <Link to={"/mis-servicios/medidor/suscripcion"}>aquí</Link></p>
							: 
							<p>Puedes suscribir a tu correo electrónico las boletas o facturas electrónicas haciendo click <Link to={"/mis-servicios/medidor/suscripcion"}>aquí</Link></p>
							}
						</div>
					: null}
					<div className="col-100 left d-flex justify-between margin-bottom-40">
						<div className="filter-container grid-column-3">
							<div className="position-relative">
								<img className="calendar" src={calendar} alt="calendario" />
								<DatePicker showMonthYearPicker popperModifiers={{flip: {enabled: false}}} maxDate={this.state.to} dateFormat="MM/yyyy" placeholderText="Desde" selected={this.state.from} locale="es" onChange={(date) => {this.setState({from: date})}} />
							</div>
							<div className="position-relative">
								<img className="calendar" src={calendar} alt="calendario" />
								<DatePicker showMonthYearPicker popperModifiers={{flip: {enabled: false}}} minDate={this.state.from} maxDate={new Date()} dateFormat="MM/yyyy" placeholderText="Hasta" selected={this.state.to} locale="es" onChange={(date) => {this.setState({to: date})}} />
							</div>
							<button className="button orange" onClick={() => {this.filter()}}>Filtrar</button>
						</div>
					</div>
					<div className="table-container">
						<table className="table1">
							<thead>
								<tr>
									<td>Numero Boleta</td>
									<td>Fecha Emisión</td>
									<td>Monto Anterior</td>
									<td>Monto Actual</td>
									<td>Monto Total</td>
									<td>Fecha de vencimiento</td>
									<td>Consumo (m3)</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								{!this.state.lecturas?null:this.state.lecturas.map((lectura, i) => {
									return(
								<tr key={'lectura-' + i}>
									<td>{lectura.ticket_number}</td>
									<td>{moment(lectura.broadcast_date, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
									<td>{lectura.prev_amount!='NO DISPONIBLE'?'$'+parseInt(lectura.prev_amount).toLocaleString("de"):lectura.prev_amount}</td>
									<td>{lectura.current_amount!='NO DISPONIBLE'?'$'+parseInt(lectura.current_amount).toLocaleString("de"):lectura.current_amount}</td>
									<td>${parseInt(lectura.amount).toLocaleString("de")}</td>
									<td>{moment(lectura.expiration_date, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
                                    <td>{parseInt(lectura.consumption).toLocaleString("de")}</td>
                                    <td>
                                    	{lectura.pdfAvailable?
                                    		<PostFileDownloadBoleta ticket_number={lectura.ticket_number} doc_type={lectura.doc_type} />:
                                    		<p className="list-link-disabled"><strong>No disponible</strong></p>}
                                	</td>
								</tr>
                                    );

								})}
							</tbody>
						</table>
						{!this.state.lecturas?<p>{this.state.lecturasMessage}</p>:null}
					</div>
			</ContentBox>
			<ModalConfirmation link={"/mis-servicios/medidor/requerimientos"} renderContent={()=>this.renderContent360Modal()} cancel={()=>this.closeC360Modal()}  isAceptar={true} show={this.state.modalC360} expand={true}/>
			</>
		);
	}
	renderContent360Modal(){
		return <img src={this.state.modalC360} alt="" style={{width:'100%', height:'auto'}} />
	}

	closeC360Modal(){
		this.setState({
			modalC360: false
		});
	}
}
export class PostFileDownloadBoleta extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: ""
		};
	}
	download() {
		refreshToken((error, result) => {
			if(error) return alert("Error descargando el archivo");
			this.setState({token: result.data.token}, () => {
				this._form.submit();
			});
		});
	}
	attachNode(node) {
    	this._form = ReactDOM.findDOMNode(node);
  	}	  
	render() {
		return (
			<div className="linkA-container" onClick={() => this.download()}>
				<form ref={this.attachNode.bind(this)}
				method="POST" action={urls.backend + "/tickets/file"} target="_blank">
					<input type="hidden" name="ticket_number" value={this.props.ticket_number} />
					<input type="hidden" name="doc_type" value={this.props.doc_type ? this.props.doc_type : null} />
					<input type="hidden" name="token" value={this.state.token} />
				</form>
				<a><strong>Ver Boleta</strong></a>
			</div>
		);
	}
}