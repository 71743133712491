import React, { useEffect, useState } from 'react'
import ContentBox from './ContentBox.js';
import ServicioAutogasSelectorPatente from "./ServicioAutogasSelectorPatente"
import { autogasGetContract } from "../api"
import PostFileDownloadDocument2 from "./PostFileDownloadDocument2"
import moment from "moment"
import {urls} from "../config.js";

export default function ServiciosAutogasContrato({patents, setSelectedPatenteAutogas, selectedPatenteAutogas}) {
    const [loading, setLoading] = useState(true)
    const [contrato, setContrato] = useState(false)

    useEffect(() => {
        setLoading(true);
        if(selectedPatenteAutogas) {
            autogasGetContract(selectedPatenteAutogas.conversion_contract_number, selectedPatenteAutogas.patent, (err, res) => {
                if (err) {
                    console.log(err)
                    setLoading(false)
                }
                if (res) {
                    setContrato(res.data)
                    setLoading(false)
                }
            })
        }
    }, [selectedPatenteAutogas])    

    return (
           <ContentBox loading={loading}>                
                <div className="title-client-container clearfix">
                    <h1>Contrato</h1>
                    <div className="client-number">
                        <ServicioAutogasSelectorPatente 
                            selectedPatenteAutogas={selectedPatenteAutogas} 
                            patents={patents}
                            setSelectedPatenteAutogas={setSelectedPatenteAutogas} />	
                    </div>
                </div>
                {contrato ? 
                    <React.Fragment>
                        <div className="subcontent-box col-100 left clearfix margin-bottom-30 z-index-0">
                            <div className="granel-pedido-details-title grid-column-1">
                                <h2 className="uppercase clearfix col-100 sub-section-title">Datos del Contrato</h2>
                            </div>                            
                            <div className="granel-pedido-details grid-column-3 margin-bottom-30">
                                <div className="detail">
                                    <span>N° de Contrato</span>
                                    <p>{Number(contrato.contract_number)}</p>
                                </div>
                                <div className="detail">
                                    <span>Tipo de Contrato</span>
                                    <p>{contrato.description_type_contract.toUpperCase()}</p>
                                </div>
                                <div className="detail">
                                    <span>Estado del Contrato</span>
                                    <p>{contrato.description_state_contract}</p>
                                </div>
                                <div className="detail">
                                    <span>Fecha de Inicio</span>
                                    <p>{moment(contrato.start_date_contract).format("DD/MM/YYYY")}</p>
                                </div>
                                <div className="detail">
                                    <span>Plazo de Cumplimiento</span>
                                    <p>{moment(contrato.end_date_contract).format("DD/MM/YYYY")}</p>
                                </div>
                                <div className="detail">
                                    <span>Meses transcurridos</span>
                                    <p>{contrato.months_elapsed_start_contract}</p>
                                </div>
                                <div className="detail">
                                    <span>Meses restantes</span>
                                    <p>{contrato.months_left_end_contract}</p>
                                </div>
                                {/* <div className="detail">
                                    <span>Vendedor</span>
                                    <p>{contrato.seller_name}</p>
                                </div>
                                <div className="detail">
                                    <span>Ejecutivo</span>
                                    <p>{contrato.executive_name}</p>
                                </div> */}
                                {contrato.downloads && contrato.downloads.length > 0 && (contrato.downloads[0] === "CONTRACT" || contrato.downloads[1] === "CONTRACT")   ?
                                <div className="detail">
                                    <span>Contrato</span>
                                    <PostFileDownloadDocument2 	
                                        contract_number={contrato.contract_number}
                                        patent={contrato.vehicle_patent}
                                        type={"CONTRACT"}
                                        contentRender={() => <p className="uppercase contrato-link">Descargar</p>}
                                    />
                                </div>:null}
                                {contrato.downloads && contrato.downloads.length > 0 && (contrato.downloads[0] === "CIA" || contrato.downloads[1] === "CIA")   ?
                                <div className="detail">
                                    <span>Certificado Adaptación</span>
                                    <PostFileDownloadDocument2 	
                                        contract_number={contrato.contract_number}
                                        patent={contrato.vehicle_patent}
                                        type={"CIA"}
                                        contentRender={() => <p className="uppercase contrato-link">Descargar</p>}
                                    />
                                </div>:null}
                            </div>
                            <div className="granel-pedido-details-title grid-column-1">
                                <h2 className="uppercase clearfix col-100 sub-section-title">Datos del Cliente</h2>
                            </div>  
                            <div className="granel-pedido-details grid-column-3 margin-bottom-30">
                                <div className="detail">
                                    <span>CÓDIGO CLIENTE</span>
                                    <p>{Number(contrato.client_code)}</p>
                                </div>
                                <div className="detail">
                                    <span>RUT</span>
                                    <p>{contrato.rut}</p>
                                </div>
                                <div className="detail">
                                    <span>TITULAR</span>
                                    <p>{contrato.titular_name}</p>
                                </div>
                                <div className="detail">
                                    <span>DIRECCIÓN</span>
                                    <p className="uppercase">{contrato.address}</p>
                                </div>
                                <div className="detail">
                                    <span>COMUNA</span>
                                    <p className="uppercase">{contrato.comune}</p>
                                </div>
                                <div className="detail">
                                    <span>REGIÓN</span>
                                    <p className="uppercase">{contrato.region}</p>
                                </div>
                            </div>
                            <div className="granel-pedido-details-title grid-column-1">
                                <h2 className="uppercase clearfix col-100 sub-section-title">Datos del Vehículo</h2>
                            </div>  
                            <div className="granel-pedido-details grid-column-3 margin-bottom-30">
                                <div className="detail">
                                    <span>marca</span>
                                    <p>{contrato.vehicle_brand}</p>
                                </div>
                                <div className="detail">
                                    <span>modelo</span>
                                    <p>{contrato.vehicle_model}</p>
                                </div>
                                <div className="detail">
                                    <span>patente</span>
                                    <p>{contrato.vehicle_patent}</p>
                                </div>
                                <div className="detail">
                                    <span>chasis</span>
                                    <p>{contrato.vehicle_chassis_number}</p>
                                </div>
                            </div>
                            <div className="granel-pedido-details-title grid-column-1">
                                <h2 className="uppercase clearfix col-100 sub-section-title">Datos de Consumo</h2>
                            </div>  
                            <div className="granel-pedido-details grid-column-3 margin-bottom-30">
                                <div className="detail">
                                    <span>meses totales</span>
                                    <p>{contrato.months_contract_duration}</p>
                                </div>
                                <div className="detail">
                                    <span>meta mensual</span>
                                    <p>{Math.round(contrato.monthly_consumption_goal) + " Lts."}</p>
                                </div>
                                <div className="detail">
                                    <span>Total contrato</span>
                                    <p>{Math.round(contrato.total_expected_consumption) + " Lts."}</p>
                                </div>
                                <div className="detail">
                                    <span>total consumidos</span>
                                    <p>{Math.round(contrato.total_consumption) + " Lts."}</p>
                                </div>
                                <div className="detail">
                                    <span>promedio consumo mensual</span>
                                    <p>{Math.round(contrato.average_monthly_consumption) + " Lts."}</p>
                                </div>
                                <div className="detail">
                                    <span>PORCENTAJE CUMPLIMIENTO CONTRATO</span>
                                    <p>{Math.round(contrato.consumed_percentage_expected_total) + "%"}</p>
                                </div>
                            </div>
                            {contrato.kits && contrato.kits.length > 0 ? 
                                <React.Fragment>
                                    <div className="granel-pedido-details-title grid-column-1">
                                        <h2 className="uppercase clearfix col-100 sub-section-title">Datos del KIT</h2>
                                    </div>  
                                    {contrato.kits.map((kit, i) => {
                                        return (
                                            <div key={i} className="granel-pedido-details grid-column-3">
                                                <div className="detail">
                                                    <span>Código de material</span>
                                                    <p>{kit.material_code}</p>
                                                </div>
                                                <div className="detail">
                                                    <span>Descripción</span>
                                                    <p>{kit.description}</p>
                                                </div>
                                                {kit.autogas_manual_id ? 
                                                <div className="detail">
                                                    <span>Manual</span>
                                                    <p style={{color: "black"}}><a className="uppercase contrato-link" target="_blank" href={urls.backend + "/autogas/manual/" + kit.autogas_manual_id}>Descargar</a></p>
                                                </div>
                                                : null}                                                
                                            </div>
                                        )
                                    })}
                                </React.Fragment>
                                :
                                null
                            }
                            <div className="granel-pedido-details-title grid-column-1 margin-top-30">
                                <h2 className="uppercase clearfix col-100 sub-section-title">Datos Giftcard</h2>
                            </div>  
                            <div className="granel-pedido-details grid-column-3">
                                <div className="detail">
                                    <span>Litros disponibles</span>
                                    <p>{contrato.giftcard_liters}</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                : null}
            </ContentBox>
    )
}
