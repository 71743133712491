import React, { Fragment, useState, useEffect } from 'react'
import { getEnvasadoInstitucionalCuentaCorrienteDocumento } from '../api.js';
import {Link, useLocation} from "react-router-dom"
import ContentBox from './ContentBox.js';
import flechaIzquierdaNegra from '../img/arrow_left_black.svg';
import PostFileDownloadDocument from './PostFileDownloadDocument';
import Button2 from './Button2';

export default function EmpresasEnvasadoIndustrialDetalleCuentaCorriente({isEmpresa, createActionNotification}) {
    const props = useLocation();
    const docClientCod = props.state.docClientCod
    const docNumber = props.state.docNumber
    const docYear = props.state.docYear
    const filterParams = props.state.filterParams

    const [doc, setDoc] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        if (props) {
            getEnvasadoInstitucionalCuentaCorrienteDocumento(docClientCod, docNumber, docYear, (error,result) => {
                if (error) {
                    console.log(error)
                } if (result) {
					setDoc(result.data)
                    setLoading(false);
                }
            })
        }
    }, [])

    return (
        <Fragment>
            <ContentBox loading={loading}>
                <div className="title-client-container clearfix">
					<h1 className="light">N° del Documento:<strong>{doc?doc.folio_number:""}</strong></h1>
				</div>
                <div>
                    <Link to={{pathname: "/mis-servicios/cilindro/cuenta-corriente", state: {filterParams}}}>
                        <div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30">
                            <img alt="Atrás" src={flechaIzquierdaNegra}/>
                            <p>atrás</p>
                        </div>
                    </Link>
                </div>
                <div className="subcontent-box col-100 left clearfix margin-bottom-30">
						<div className="granel-pedido-details grid-column-3">						
						<div className="detail">
							<span>Fecha de emisión</span>
							<p>{doc?doc.issue_date:""}</p>
						</div>
						<div className="detail">
							<span>Valor</span>
							<p>${doc?parseInt(doc.value).toLocaleString("de"):""}</p>
						</div>
						<div className="detail">
							<span>Guía de despacho</span>
							<p>{doc?doc.office_guide:""}</p>
						</div>
						<div className="detail">
							<span>Fecha de entrega</span>
							<p>{doc?doc['delivery date']:""}</p>
						</div>
						<div className="detail">
							<span>Cantidad</span>
							<p>{doc?doc.quantity:""}</p>
						</div>
						<div className="detail">
							<span>Producto</span>
							<p>{doc?doc.product:""}</p>
						</div>
						{doc && doc.address && doc.address.trim() !== ""?<div className="detail">
							<span>Dirección</span>
							<p>{doc.address}</p>
						</div>:null}
                        {loading?null:
						<div className="grid-item-full text-center">							
							{doc && doc.pdf_guide ?
							<PostFileDownloadDocument 
								class_description={doc.pdf_guide.class_description} 
								pdf_number={doc.pdf_guide.pdf_number} 
								createActionNotification={createActionNotification}
								isEmpresa={isEmpresa?"empresas":null}								
								contentRender={() => 
								<div className="linkA-container">
									<Button2 label={"Descargar guía de despacho"} className="button button-border-orange" />
								</div>} />:null}
							{doc && doc.pdf_document ?
							<PostFileDownloadDocument 
								class_description={doc.pdf_document.class_description} 
								pdf_number={doc.pdf_document.pdf_number} 
								createActionNotification={createActionNotification}
								isEmpresa={isEmpresa?"empresas":null}
								contentRender={() => 
								<div className="linkA-container">
									<Button2 label={"Descargar documento"} className="button button-border-orange" />
								</div>} />:null}
						</div>}						
					</div>
			   </div>		
            </ContentBox>
        </Fragment>
    )
}
