import React, { Component, Fragment, } from 'react';
import {updateProfile, getProfile} from '../api.js';
import BreadCrumb from './BreadCrumb';
import defaultAvatar2 from '../img/default_avatar2.png';
import ContentBox from './ContentBox.js';
import {formatRut, checkRut} from '../utils.js';
import Tooltip  from 'rc-tooltip';

export default class EditarPerfil extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notification_check: null,
			password_duplicate: null,
			name: '',
			last_name: '',
			dni: '',
			phone: '',
			password: '',
			password_confirmation: '',
			user: [],
			errors: [],
		};
	}

	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadProfile();
	}

	loadProfile(){
		getProfile(this.props.user.id, (error, result) => {			
			if(result){
				if(result.status === "ok") {
					var user = result.data[this.props.isEmpresas?"company_user":"person"];
					this.props.updateUser(user);
					this.setState({
						user,
						notification_check: parseInt(user.notification_check) === 1 ? true : false,
						name: user.name,
						last_name: user.last_name,
						dni: user.dni,
						phone: user.phone,
						profile_picture: user.profile_picture
					}, () => {});
				}
			}
		}, this.props.isEmpresas?"empresas":null);
	}

	submitUpdateProfile = (event) => {
		//event.preventDefault();
		updateProfile(this.props.user.id, this.state.dni, this.state.name, this.state.last_name, this.state.phone, this.state.password, this.state.password_confirmation, this.state.notification_check, this.state.password_duplicate, (error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			if(result.status === "error" && result.errors) {
				console.log(result.errors)
				this.setState({
					errors: result.errors
				});
			}
			if(result.status === "ok") {
				this.loadProfile();
				this.props.createActionNotification("Edición completada", "Datos actualizados correctamente");
			}
		}, this.props.isEmpresas?"empresas":null);
	}

	comparePasswords = (e, type) =>{
		var element = document.getElementById('input-password');
		this.deleteFormError(type)
		if(type === 'password'){
			this.setState({password: e.target.value}, (event) => {
				if(this.state.password !== this.state.password_confirmation){
					element.setCustomValidity("Las contraseñas no coinciden");
				}else{
					element.setCustomValidity("");
				}
			});
		}else{
			this.setState({password_confirmation: e.target.value}, (event) => {
				if(this.state.password !== this.state.password_confirmation){
					element.setCustomValidity("Las contraseñas no coinciden");
				}else{
					element.setCustomValidity("");
				}
			});
		}
	}

	changeRut = (e) =>{
		var target = e.target;
		this.setState({dni: formatRut(e.target.value)}, () => {
			if(!checkRut(this.state.dni)){
				target.setCustomValidity("El rut ingresado no es válido");
			}else{
				target.setCustomValidity("");
			}
		});
	}

	deleteFormError(field_name) {
		var formErrors = {...this.state.errors};
		delete formErrors[field_name];
		this.setState({errors: formErrors})
	}

	render() {
		var breadCrumbOptions = [
			{title: "Mis Servicios"},
			{title: "Editar Perfil"},
		];
		var profilePicture = this.state.profile_picture?this.state.profile_picture:defaultAvatar2;
		return (
			<Fragment>
				<div className="section-title">
					<h1>Editar Perfil</h1>
					<BreadCrumb options={breadCrumbOptions} />
				</div>
				<ContentBox>
						<div className="edit-profile-container d-flex">
							<div className="name d-flex flex-column justify-content-center col-40">
								<div className="d-flex justify-content-center margin-bottom-10">
									<img className="col-30" src={profilePicture} alt="Imagen Perfil"/>
								</div>
								<div className="d-flex align-items-center flex-column">
									<h3 className="font-20">{this.props.user.name} {this.props.user.last_name}</h3>
									<p className="margin-bottom-40">{this.props.user.email}</p>
									<button type="button" className="button green col-inherit" onClick={()=>this.submitUpdateProfile()}>Guardar</button>
								</div>
							</div>
							<div className="col-60">
								<div className="margin-bottom-40">
									<h2 className="margin-bottom-10 font-25">Editar Perfil</h2>
									<div className="grid-column-2">
										<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.name ? true: false}
									 	overlay={<span>{this.state.errors ? this.state.errors.name : ""}</span>}>
											<input placeholder="Nombre" value={this.state.name} onChange={e => {this.setState({name: e.target.value}); this.deleteFormError("name");}} required/>
										</Tooltip>
										<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.last_name ? true: false}
									 	overlay={<span>{this.state.errors ? this.state.errors.last_name : ""}</span>}>
											<input placeholder="Apellido" value={this.state.last_name} onChange={e => {this.setState({last_name: e.target.value}); this.deleteFormError("last_name");}} required/>
										</Tooltip>
										<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.dni ? true: false}
									 	overlay={<span>{this.state.errors ? this.state.errors.dni : ""}</span>}>
											<input placeholder="Rut" value={formatRut(this.state.dni)} onChange={e => {this.changeRut(e); this.deleteFormError("dni");}} required/>
										</Tooltip>
										<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.phone ? true: false}
									 	overlay={<span>{this.state.errors ? this.state.errors.phone : ""}</span>}>
											<input placeholder="Teléfono" value={this.state.phone} onChange={e => {this.setState({phone: e.target.value}); this.deleteFormError("phone");}} required/>
										</Tooltip>
									</div>
								</div>
								<div className="margin-bottom-40">
									<h2 className="margin-bottom-10 font-25">Restablecer Contraseña</h2>
									<div className="grid-column-2">
										<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.password ? true: false}
											overlay={<span>{this.state.errors ? this.state.errors.password : ""}</span>}>
											<input id="input-password" 
												type="password" 
												autoComplete="new-password"
												placeholder="Contraseña" 
												value={this.state.password} 
												onChange={e => this.comparePasswords(e, 'password')}/>
										</Tooltip>
										<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.password_confirmation ? true: false}
											overlay={<span>{this.state.errors ? this.state.errors.password_confirmation : ""}</span>}>
											<input 
												type="password" 
												autoComplete="new-password"
												placeholder="Repetir Contraseña" 
												value={this.state.password_confirmation} 
												onChange={e => this.comparePasswords(e, 'password_confirmation')}/>
										</Tooltip>
									</div>
								</div>
								{typeof this.props.user.second_account !== 'undefined' && this.props.user.second_account ? <div className="col-100 left clearfix">
									<label htmlFor="password_duplicate" className="checkbox-container">
										<input id="password_duplicate" type="checkbox" checked={this.state.password_duplicate ? true : false} onChange={(e) => {this.setState({password_duplicate: !this.state.password_duplicate})}}/>
										<span className="checkmark checkbox-with-border"></span>
										<span>Sincroniza contraseña con cuenta Persona natural (Email: <strong>{this.props.user.seccond_email}</strong>)</span>
									</label>
								</div>:null}
								<div className="col-100 left clearfix">
									<label htmlFor="notification_check" className="checkbox-container">
										<input id="notification_check" type="checkbox" checked={this.state.notification_check ? true : false} onChange={(e) => {this.setState({notification_check: !this.state.notification_check})}}/>
										<span className="checkmark checkbox-with-border"></span>
										<span>Deseo activar el envío de notificaciones</span>
									</label>
								</div>
							</div>
						</div>
				</ContentBox>
			</Fragment>
		);
	}
}
