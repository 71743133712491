import React, { Component, Fragment } from 'react';
import CustomTabs from './CustomTabs';
import {Route, Switch, Redirect} from "react-router-dom";
import BreadCrumb from './BreadCrumb';

import ServiciosMedidorHistorialLectura from './ServiciosMedidorHistorialLectura.js';
import ServiciosMedidorHistorialPagos from './ServiciosMedidorHistorialPagos.js';
import ServiciosMedidorDetalleDeuda from './ServiciosMedidorDetalleDeuda.js';
import ServiciosGeneralRequerimientos from './ServiciosGeneralRequerimientos.js';
import ServiciosMedidorBoletas from './ServiciosMedidorBoletas.js';
import ServiciosMedidorConvenioPago from './ServiciosMedidorConvenioPago.js';
import ServiciosMedidorConvenioPagoDetalle from './ServiciosMedidorConvenioPagoDetalle.js';
import ServiciosMedidorSuscripcion from './ServiciosMedidorSuscripcion.js'
import ServiciosMedidorSimulador from "./ServiciosMedidorSimulador.js"

import {meterBussinesLineList} from '../api.js';
import {available} from '../config.js';

export default class ServiciosMedidor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			solicitud_requerimiento_preload: null
		};
		this.tabs = [
			{
				title: "Boletas",
				to: "/mis-servicios/medidor/boletas"
			},
			{
				title: "Historial de Lectura",
				to: "/mis-servicios/medidor/historial-lectura"
            },
            {
				title: "Historial de Pagos",
				to: "/mis-servicios/medidor/historial-pagos"
            },
            {
				title: "Detalle de Deuda",
				to: "/mis-servicios/medidor/detalle-deuda"
            },
            {
				title: "Convenios de Pago",
				to: "/mis-servicios/medidor/convenio-pago"
            }, 
            {
				title: "Tarifas",
				to: "/mis-servicios/medidor/tarifas"
            },
            {
				title: "Requerimientos",
				to: "/mis-servicios/medidor/requerimientos"
			},
            {
				title: "Simulador convenio ley 21.340",
				to: "/mis-servicios/medidor/simulador"
			}
		];

	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadServices();		
	}
	loadServices() {
		meterBussinesLineList((error, result) => {
			if(error) {
				return console.log("ocurrio un error con el request");
			}
			var services = null;
			if(result.status !== "empty") {
				services = result.data;				
			} else {
				services = [];
				this.props.changeHeaderClass("big");
			}
			this.setState({
				services
			});
			if(!this.props.selectedMeter) this.props.setSelectedMeter(services[0]);

		});
	}
	setSolicitudRequerimientoPreloadData(data, cb) {
		this.setState({solicitud_requerimiento_preload: data}, cb);
	}
	render() {
		var breadCrumbOptions = [
			{title: "Mis Servicios", to: "/mis-servicios"},
			{title: "Medidor"},
		];
		return (
			<Fragment>
				<div className="section-title">
					<h1>Medidor</h1>
					<BreadCrumb options={breadCrumbOptions} />					
				</div>
				<CustomTabs tabs={this.tabs.filter((tab) => {
					if(!available.medidor_requerimientos && tab.title === "Requerimientos") return false;
					if(!available.medidor_historial_lectura && tab.title === "Historial de Lectura") return false;
					if(!available.medidor_historial_pagos && tab.title === "Historial de Pagos") return false;
					if(!available.medidor_boletas && tab.title === "Boletas") return false;
					if(!available.medidor_tarifas && tab.title === "Tarifas") return false;
					if(!available.medidor_convenio_pago && tab.title === "Convenios de Pago") return false;
					if(!available.medidor_detalle_deuda && tab.title === "Detalle de Deuda") return false;
					if(!available.medidor_simulador && tab.title === "Simulador convenio ley 21.340") return false;


					return true;
				})} location={this.props.location} />		
        		<Switch>
					<Route exact path="/mis-servicios/medidor/boletas">
	                	<ServiciosMedidorBoletas
	                		services={this.state.services} 
	                		selectedService={this.props.selectedMeter}
	                		setSelectedService={this.props.setSelectedMeter} />
		            </Route>
        			<Route exact path="/mis-servicios/medidor/historial-lectura">
	                	<ServiciosMedidorHistorialLectura 
	                		services={this.state.services} 
	                		selectedService={this.props.selectedMeter}
	                		redirect={this.props.redirect}
	                		setSolicitudRequerimientoPreloadData={this.setSolicitudRequerimientoPreloadData.bind(this)}	                		
	                		setSelectedService={this.props.setSelectedMeter} />
		            </Route>
		            <Route exact path="/mis-servicios/medidor/historial-pagos">
	                	<ServiciosMedidorHistorialPagos 
	                		services={this.state.services} 
	                		selectedService={this.props.selectedMeter}
	                		setSelectedService={this.props.setSelectedMeter} />
		            </Route>
		            <Route exact path="/mis-servicios/medidor/detalle-deuda">
	                	<ServiciosMedidorDetalleDeuda 
	                		services={this.state.services} 
	                		selectedService={this.props.selectedMeter}
	                		setSelectedService={this.props.setSelectedMeter}
	                		getLastBill={this.props.getLastBill}
                  			user={this.props.user} />
		            </Route>
		            <Route exact path="/mis-servicios/medidor/requerimientos">
	                	<ServiciosGeneralRequerimientos
	                		solicitud_requerimiento_preload={this.state.solicitud_requerimiento_preload}
	                		setSolicitudRequerimientoPreloadData={this.setSolicitudRequerimientoPreloadData.bind(this)}
	                		business_line={"medidor"}
                  			user={this.props.user}
                  			checkMenuOptions={this.props.checkMenuOptions} />
		            </Route>
					<Route exact path="/mis-servicios/medidor/requerimientos/:code" render={(data)=>{
						return <ServiciosGeneralRequerimientos
								solicitud_requerimiento_preload={this.state.solicitud_requerimiento_preload}
							setSolicitudRequerimientoPreloadData={this.setSolicitudRequerimientoPreloadData.bind(this)}
							business_line={"medidor"}
							user={this.props.user}
							code={data.match.params.code}
							checkMenuOptions={this.props.checkMenuOptions}
						/>
					}}/>

					<Route exact path="/mis-servicios/medidor/convenio-pago">
	                	<ServiciosMedidorConvenioPago
							services={this.state.services}
							selectedService={this.props.selectedMeter}
	                		setSelectedService={this.props.setSelectedMeter}
	                		user={this.props.user}/>
		            </Route>
					<Route path="/mis-servicios/medidor/convenio-pago/detalle/:id"
              				render={(data)=> 
      					<ServiciosMedidorConvenioPagoDetalle agreementId={data.match.params.id} />}>
		            </Route>
					{available.medidor_suscripcion ? 
					<Route exact path="/mis-servicios/medidor/suscripcion">
						<ServiciosMedidorSuscripcion
								services={this.state.services}
								user={this.props.user}
								solicitud_requerimiento_preload={this.state.solicitud_requerimiento_preload}
								setSolicitudRequerimientoPreloadData={this.setSolicitudRequerimientoPreloadData.bind(this)}
								/>
					</Route>
					: null}
					{available.medidor_simulador ? 
					<Route exact path="/mis-servicios/medidor/simulador">
						<ServiciosMedidorSimulador
								services={this.state.services}
								user={this.props.user}
								solicitud_requerimiento_preload={this.state.solicitud_requerimiento_preload}
	                			setSolicitudRequerimientoPreloadData={this.setSolicitudRequerimientoPreloadData.bind(this)}
								selectedService={this.props.selectedMeter}
								setSelectedService={this.props.setSelectedMeter}/>
					</Route>
					: null}
		            <Route path="*">
		                <Redirect to={"/mis-servicios"} />
		          	</Route>
        		</Switch>
			</Fragment>
		);
	}
}