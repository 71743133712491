import React, { Component } from 'react';
import {getServiceAddressString} from '../utils.js';
export default class ServiceListBox extends Component {
	renderPatentes(patentes) {
		return patentes.map((patente, i) => {
			return (<p>{patente.patent}</p>);
		});
	}
	render() {
		var type = "";
		var buttonText = "";
		if(this.props.type === "asociar") {
			type = " asociar";
			buttonText = "ASOCIAR"
		} else if(this.props.type === "desvincular") {
			type = " desvincular";
			buttonText = "DESVINCULAR";
		} else if(this.props.type === "pedido") {
			type = " desvincular";
			buttonText = "HACER PEDIDO";
		} else if(this.props.type === "activar"){
			type = " asociar";
			buttonText = "ACTIVAR";
		} else if(this.props.type === "detalle"){
			type = " desvincular";
			buttonText = "VER DETALLE";
		}
		var service = this.props.service;
		var client_address = getServiceAddressString(service);		
		var displayNumber = null;
		if(this.props.displayNumberLabel) displayNumber = service[this.props.displayNumberLabel] !== ''  ? parseInt(service[this.props.displayNumberLabel]) : parseInt(service.client_cod);;
		if(displayNumber === null) {
			displayNumber = typeof service.contract_cod !== 'undefined' && service.contract_cod !== "" ? parseInt(service.contract_cod) : parseInt(service.client_cod);
		}
		return (
			<div className={"servicio-list-box text-align-left" + type} onClick={this.props.onButtonClick}>
				{this.props.hideClientCode?null:<h1>N° de cliente {displayNumber}</h1>}
				{service.pivot && service.pivot.company_name?<p className="uppercase font-14 fw700 lh-100pct margin-top-10">{service.pivot.company_name}</p>:null}
				{service.pivot && service.pivot.company_dni?<p className="uppercase font-14 fw700 lh-130pct">RUT: {service.pivot.company_dni}</p>:null}


				{this.props.onlyHeader ? null : <p className="servicio-list-box-address">
					{client_address}
				</p>}
				{this.props.onlyHeader ? null : <p className="servicio-list-box-city">
					{service.client_city}, {service.client_region_cod}
				</p>}
				{!buttonText ? null : <div className="servicio-list-box-button">
					<p>{buttonText}</p>
				</div>}
				{/*service.patents?this.renderPatentes(service.patents):null*/}
			</div>
		);
	}
}
