import React, { Component } from 'react';

export default class CustomSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: false
		};
	}
	selectOption(option) {
		this.props.onChange(option);
		this.closeOptions();
	}
	componentDidUpdate() {
		if(this.props.force_close) this.forceClose();
	}
	forceClose() {
		this.setState({opened: false}, () => {
			if(this.props.onForceClosed) this.props.onForceClosed();
		});				
	}
	toggleOptions() {
		if(this.state.opened) this.closeOptions();
		else this.openOptions();
	}
	openOptions() {
		this.setState({opened: true}, () => {
			if(this.props.onOptionsOpened) this.props.onOptionsOpened();
		});
	}
	closeOptions() {
		this.setState({opened: false}, () => {
			if(this.props.onOptionsClosed) this.props.onOptionsClosed();			
		});
	}
	renderPlaceHolder() {
		if(this.props.placeholder)
			return(<p>{this.props.placeholder}</p>);
		return(<p>Selecciona</p>);
	}
	render() {
		return (
			<div 
				className={"CustomSelect" + (this.state.opened ? " opened" : "")} 
				onClick={() => {this.toggleOptions()}}>
				{this.props.selected ? this.props.renderSelected(this.props.selected, this.state.opened) : this.renderPlaceHolder()}
				<div className="CustomSelect-icon"></div>
				<div className="CustomSelect-options" onClick={(event) => {event.stopPropagation()}}>
					{this.props.options.map((option, i) => {
					return(<div key={JSON.stringify(option)} className="CustomSelect-option" onClick={() => {this.selectOption(option)}}>
						{this.props.selected === option?null:this.props.renderOption(option, i)}
					</div>)
					})}
				</div>
			</div>
		);
	}
}
