import React, { useEffect, useState } from 'react'
import ContentBox from './ContentBox.js';
import ServicioAutogasSelectorPatente from "./ServicioAutogasSelectorPatente"
import ServiciosAutogasFormularioMantenciones from "./ServiciosAutogasFormularioMantenciones"
import ServiciosAutogasProximaMantencion from "./ServiciosAutogasProximaMantencion"
import { autogasNextMaintenance, autogasMaintenancePrice } from "../api"

export default function ServiciosAutogasAgendaMantenciones({patents, setSelectedPatenteAutogas, selectedPatenteAutogas}) {
    const [loading, setLoading] = useState(true)
    const [mensajeError, setMensajeError] = useState(false)
    const [mantencionAgendada, setMantencionAgendada] = useState(false)
    const [validFormulario, setValidFormulario] = useState(false)
    const [reload, setReload] = useState(false)
    const [theMainFunc, setTheMainFunc] = useState(false)

    useEffect(()=>{
        setLoading(true)
        if (selectedPatenteAutogas) {
            mainFunc(selectedPatenteAutogas.patent)
        }
    }, [selectedPatenteAutogas])

    useEffect(()=>{
        if (reload) {
            setLoading(true)
            setMantencionAgendada(false)
            setValidFormulario(false)
            setMensajeError(false)
            setTheMainFunc(true)
        }
        return () => {
            setReload(false)
        }
    }, [reload])

    useEffect(()=>{
        if (theMainFunc) {
            mainFunc(selectedPatenteAutogas.patent)
        }
    }, [theMainFunc])

    const mainFunc = (patente) => {
        setTheMainFunc(false)
        autogasNextMaintenance(patente, (err, res) => {
            if (err) {
                console.log(err)
            }
            if (res) {
                if (res.status === "error") {
                    setMensajeError("No fue posible obtener la información, por favor intente nuevamente más tarde")
                    setMantencionAgendada(false)
                    setValidFormulario(false)
                } else if (res.status === "empty") {
                    setMensajeError(false)
                    setMantencionAgendada(false)
                    setValidFormulario(true)
                } else if (res.status === "ok") {
                    setValidFormulario(false)
                    setMantencionAgendada(res.data)
                }
                
            }
        })
    }

    return (
        <ContentBox loading={loading}>
            <div className="title-client-container clearfix">
                <h1>Agenda Mantenciones</h1>
                <div className="client-number">
                    <ServicioAutogasSelectorPatente 
                        selectedPatenteAutogas={selectedPatenteAutogas} 
                        patents={patents}
                        setSelectedPatenteAutogas={setSelectedPatenteAutogas} />	
                </div>
            </div>
            {mensajeError ? 
                <div className="col-100" style={{margin: "0 auto"}}>
                    <p className="message">
                        {mensajeError}
                    </p>
                </div>
            :
                mantencionAgendada ? 
                    <ServiciosAutogasProximaMantencion setLoading={(x) => setLoading(x)} mantencionAgendada={mantencionAgendada}/>   
                :
                validFormulario ? <ServiciosAutogasFormularioMantenciones selectedPatenteAutogas={selectedPatenteAutogas} setLoading={(x) => setLoading(x)} setReload={(x) => setReload(x)}/> : null
            }            
        </ContentBox>
    )
}
