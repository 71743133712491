import React, { Component } from 'react';
import ContentBox from './ContentBox.js';
import ClientSelectCilindro from './ClientSelectCilindro';
import {cilindroGetDiscounts} from '../api.js';
import {formatRut} from "../utils"
import moment from 'moment';

export default class ServiciosCilindroDescuentos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ciudad: "",
			region: ""
		};
	}	
	componentDidMount() {
		if(this.props.selectedService) {
			if (this.props.selectedService) this.getList();
		}
	}	
	componentDidUpdate(prevProps) {
		if(prevProps.selectedService !== this.props.selectedService && this.props.selectedService) {
			this.getList();
		}
	}
	getList(cb) {
		this.setState({errorMsg: null, loading: true, descuentos_data: null});
		cilindroGetDiscounts((error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
			}
			if(result.status === "error") {
				if(cb) cb();
				this.setState({loading: false, errorMsg: result.message});
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({loading: false, errorMsg: result.message});
			}
			if (result.data) {
				this.setState({descuentos_data: result.data.rut_discounts, loading: false});
			}
		});
	}

	
	
	render() {
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Mis Descuentos</h1>
					
					{/* <div className="client-number">
						<ClientSelectCilindro 
							selected={this.props.selectedService} 
							services={this.props.services}
							setSelectedService={this.props.setSelectedService} />	
					</div> */}
				</div>	
				<h2 className="uppercase clearfix col-100 sub-section-title fw-400">Descuentos por RUT</h2>
				<div className="pedido-status-container margin-bottom-20 col-100 left clearfix">
					<div className="message">
						{this.state.descuentos_data && this.state.descuentos_data.discounts > 0 ? 
							<span>
								<p>Descuentos disponibles para el RUT {this.state.descuentos_data && this.state.descuentos_data.rut ? this.state.descuentos_data.rut : null}</p>
								<p style={{fontSize: "1.6em", marginTop: "5px"}}><strong>{this.state.descuentos_data && this.state.descuentos_data.discounts ? this.state.descuentos_data.discounts : null}</strong></p>
							</span>
						:
							<p>No existen descuentos disponibles para el RUT {this.state.descuentos_data && this.state.descuentos_data.rut ? this.state.descuentos_data.rut : null}</p>
						}
						
					</div>
				</div>			
				<div className="table-container-2">
					<table className="table1">
						<thead>
							<tr>
								<td colSpan="2">
									<div>	
										<p>Monto del Descuento</p>	
									</div>	
								</td>								
							</tr>
						</thead>
						<tbody>
							{this.state.descuentos_data && this.state.descuentos_data.cylinders.map(dcto => {
								return(
									<tr key={dcto.description}>
										<td>{dcto.description}</td>
										<td>${parseInt(dcto.amount).toLocaleString("de")}</td>
									</tr>
								);
							}) }							
						</tbody>
					</table>
					{this.state.errorMsg ? this.state.errorMsg : null}
				</div>
			</ContentBox>
		);
	}
}