import React, { Component, Fragment, } from 'react';
import ContentBox from './ContentBox.js';
import Tooltip  from 'rc-tooltip';
import {formatRut} from '../utils';
import StepSwitch from './StepSwitch';
import {checkFuncionarioExists, adminEmpresasClientesList, getPrivileges, postFuncionario, requestFuncionario} from '../api';
import PopupPrivilegiosFuncionario from './PopupPrivilegiosFuncionario';
import Button1 from './Button1';
import ArrowRightWhite from '../img/arrow_right_white.png';
import flechaIzquierdaNegra from '../img/arrow_left_black.svg';
import {Link} from 'react-router-dom';
import ModalConfirmation from './ModalConfirmation';

export default class EmpresasFuncionariosForm extends Component {
	constructor(props) {
	  	super(props);
	  	this.state = {
			name: "",
			last_name: "",
			dni: "",
			email: "",
			phone: "",
			step: 1,
			loading: true,
			exist_person: false,
			options: [
		  		{step: 1, label: "Crear funcionario"},
		  		{step: 2, label: "Datos del funcionario"},
		  		{step: 3, label: "Clientes habilitados"}
		  	],
		  	empresa_selected: null,
		  	client_codes: {}

	  	};
	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");				
		this.setState({loading: false});
	}
	openPrivilegesPopup(cliente) {
		this.getPrivileges(""+cliente.abastible_client_id, (error) => {
			if(error) {
				console.log("error this.getPrivileges", error);
				return;			
			}
			this.setState({selected_client: cliente, no_client_codes_error: false});
		});
	}	
	validateStep2Form(cb) {
		this.setState({loading: true});
		postFuncionario({validation_only: 1, name: this.state.name, last_name: this.state.last_name, phone: this.state.phone, dni: this.state.dni, email: this.state.email, client_codes: this.state.client_codes}, (error, result) => {
			if(error) {
				console.log("error", error);
				
				return this.setState({loading: false},()=> {if(cb) cb(false)});
			}
			if(result.errors) {
				if(!(Object.keys(result.errors).length === 1 && result.errors["client_codes"]))
					return this.setState({loading: false, errors: result.errors},()=> {if(cb) cb(false)});
			}
			this.setState({loading: false},()=> {if(cb) cb(true)});
			
		});
	}
	getPrivileges(client_id, cb) {
		getPrivileges(client_id, (error, result) => {
			if(error) {
				console.log("error getPrivileges", error);
				if(cb) cb(error);
				return;
			}			
			this.setState({privileges: result.data}, () => {if(cb) cb()});			
		});
	}
	privilegesPopupConfirm(privileges) {
		this.updateClientCodes(this.state.selected_client.id,privileges);
		this.setState({selected_client:null});
	}
	updateClientCodes(client_id, codes) {
		var client_codes = {...this.state.client_codes};
		if(!codes || codes.length === 0) delete client_codes[client_id];
		else client_codes[client_id] = [...codes];
		this.setState({client_codes, no_client_codes_error: false});
	}
	toggleEmpresaSelected(selected) {
		if(selected === this.state.empresa_selected) this.setState({empresa_selected: null, no_client_codes_error: false});
		else this.setState({empresa_selected: selected, no_client_codes_error: false});
	}
	deleteFormError(field_name) {
		var formErrors = {...this.state.errors};
		delete formErrors[field_name];
		this.setState({errors: formErrors})
	}	
	renderStep1() {
		return(
			<Fragment>
				<Link to={"/funcionarios"}>
				<div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30">
					<img alt="Atrás" src={flechaIzquierdaNegra}/>
					<p>atrás</p>
				</div>
				</Link>
				<div className="agregar-solicitud-form">
					<div className="agregar-solicitud-form-group">
						<div className="form-field-label"><p>Verificar disponinilidad de RUT</p></div>
						<Tooltip placement="bottom" visible={this.state.error_rut}
					 	overlay={<span>{this.state.error_rut}</span>}>
							<input 
								className={"text-align-left"}
								placeholder="Ingresa RUT" 
								value={this.state.dni/*formatRut(this.state.dni)*/} 
								onChange={e => {this.setState({dni: formatRut(e.target.value)}); 
									this.deleteFormError("dni");
									if(this.state.error_rut)this.setState({error_rut: null});}} />
						
						</Tooltip>
					</div>					
					<Button1
						label="SIGUIENTE"
						className="margin-top-50" icon={ArrowRightWhite}
						onClick={() => {this.goToStep2()}} />
				</div>
			</Fragment>	
		);
	}
	goToStep2() {
		this.setState({loading: true, error_rut: null}, () => {
			checkFuncionarioExists(this.state.dni, (error, result) => {
				this.setState({loading: false});
				if(error) {
					console.log("error", error);
				}
				if(result.status === "ok") {
					if(result.data){
						this.setState({
							step: 2,
							exist_person: true,
							name: result.data.name,
							last_name: result.data.last_name,
							email: result.data.email,
							phone: result.data.phone,
							origin: result.origin
						});
					}else{
						this.setState({step: 2, exist_person: false});
					}
				} else if(result.status === "error") {
					if(result.show_modal) {
						this.setState({checkRutModal: result});
					}
					else this.setState({error_rut: result.errors["dni"]});

				} 
			});

		});
	}
	renderStep2() {
		return(
		<div className="agregar-solicitud-form">
			{this.state.origin !== "company_users" && this.state.exist_person ? 
				<div className="message margin-bottom-40">
					<p>Atención: El RUT ingresado está registrado en una cuenta de Persona Natural. Se recomienda verificar la información.</p>
				</div>
			:null}
			{this.state.origin === "company_users" ? 
				<div className="message margin-bottom-40">
					<p>Atención: Existe un registro deshabilitado asociado al rut ingresado. Por favor verificar que la información es correcta. Para habilitarlo presione "Siguiente"</p>
				</div>
			:null}
			<div className="grid-column-2 text-align-left">
				<strong>RUT: {this.state.dni}</strong>
				<div></div>
				<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.name ? true: false}
			 	overlay={<span>{this.state.errors ? this.state.errors.name : ""}</span>}>
					<input placeholder="Nombre" value={this.state.name} onChange={e => {this.setState({name: e.target.value}); this.deleteFormError("name");}} required disabled={this.state.exist_person || this.state.origin === "company_users"} />
				</Tooltip>
				<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.last_name ? true: false}
			 	overlay={<span>{this.state.errors ? this.state.errors.last_name : ""}</span>}>
					<input placeholder="Apellido" value={this.state.last_name} onChange={e => {this.setState({last_name: e.target.value}); this.deleteFormError("last_name");}} required disabled={this.state.exist_person || this.state.origin === "company_users"} />
				</Tooltip>
				<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.email ? true: false}
			 	overlay={<span>{this.state.errors ? this.state.errors.email : ""}</span>}>
					<input placeholder="Email" value={this.state.email} onChange={e => {this.setState({email: e.target.value}); this.deleteFormError("email");}} required disabled={this.state.origin === "company_users"} />
				</Tooltip>
				<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.phone ? true: false}
			 	overlay={<span>{this.state.errors ? this.state.errors.phone : ""}</span>}>
					<input placeholder="Teléfono" value={this.state.phone} onChange={e => {this.setState({phone: e.target.value}); this.deleteFormError("phone");}} required disabled={this.state.origin === "company_users"} />
				</Tooltip>			
			</div>
			<Button1
				label="SIGUIENTE"
				className="margin-top-50 text-align-center" icon={ArrowRightWhite}
				onClick={() => {this.goToStep3()}} />
		</div>
		);
	}
	goToStep3() {
		this.validateStep2Form((validated) => {
			if(validated)
			adminEmpresasClientesList((error, result) => {
				if(error) {
					console.log("error", error);
				}
				//console.log(result);
				this.setState({step: 3, empresas: result.data});
			});
		}); 
	}
	renderStep3() {
		var empresas = this.state.empresas.map((empresa, i) => {
			return( 
			<div key={empresa.id} className={"clientes-habilitados-empresa-container margin-top-30" + (this.state.empresa_selected === empresa.id? " selected":"")}>
				<div className="clientes-habilitados-empresa-title" onClick={() => this.toggleEmpresaSelected(empresa.id)}>
					<p className="text-align-center color-white uppercase"><strong>{empresa.name}</strong> {empresa.dni}</p>
					<div className="clientes-habilitados-empresa-container-arrow"></div>					
				</div>
					<div className={"table-container" + (this.state.empresa_selected === empresa.id? " selected":"")}>
						<table className="table1">
							<thead className="table-header-gray">
								<tr>
									<td>N° de Cliente</td>
									<td>Servicios</td>
									<td>Dirección</td>
									<td></td>	
									<td></td>								
								</tr>
							</thead>
							<tbody>
							{empresa.sap_clients.map((cliente, j) => {
									var servicio = "";
									if(cliente.business_line_id === "3") servicio = "Granel Habitacional";
									if(cliente.business_line_id === "1") servicio = "Medidor";
									if(cliente.business_line_id === "6") servicio = "Granel Industrial";



									return(
								<tr key={cliente.id}>
									<td>{cliente.abastible_client_id}</td>
									<td>{servicio}</td>
									<td>{cliente.address}, {cliente.comune}</td>
									<td>
										<div className={"inline-block " + (cliente.merchandise_recipients && cliente.merchandise_recipients.length > 0 ? "icon-tanque": "")}></div>
										<div className={"inline-block " + (cliente.payment_responsibles && cliente.payment_responsibles.length > 0 ? "icon-dinero": "")}></div>
									</td>  
									<td>
										<div className="linkA-container" onClick={() => {this.openPrivilegesPopup(cliente)}}>
											<p className={"cliente-funcionario-habilitar-administrar" + (this.state.client_codes[cliente.id]?" administrar":"")}>{this.state.client_codes[cliente.id]?"Administrar":"Habilitar"}</p>
										</div>
									</td>									
								</tr>
									);

								})}
							</tbody>
						</table>
					</div>					
		</div>)
		});		
		return(
			<div className="text-align-center">
				<div className="margin-top-80">{empresas}</div>
				<Tooltip 
					placement="bottom" visible={this.state.no_client_codes_error}
			 		overlay={<span>Debes activar al menos un privilegio</span>}>
					<Button1
						label="GUARDAR"
						className="margin-top-50" icon={ArrowRightWhite}
						onClick={() => {this.goToStep4()}} />
				</Tooltip>
			</div>)
	}
	goToStep4() {
			if(JSON.stringify(this.state.client_codes) === JSON.stringify({})) {
				return this.setState({no_client_codes_error: true});
			}		
			this.setState({step:4});
	}
	renderStep4() {
		var empresas = this.state.empresas.filter(e=>e.sap_clients.filter(sp=>this.state.client_codes[""+sp.id]).length>0).map((empresa, i) => {
			return( 
			<div key={empresa.id} className={"clientes-habilitados-empresa-container margin-top-30" + (this.state.empresa_selected === empresa.id? " selected":"")}>
				<div className="clientes-habilitados-empresa-title" onClick={() => this.toggleEmpresaSelected(empresa.id)}>
					<p className="text-align-center color-white uppercase"><strong>{empresa.name}</strong> {empresa.dni}</p>
					<div className="clientes-habilitados-empresa-container-arrow"></div>					
				</div>
					<div className={"table-container" + (this.state.empresa_selected === empresa.id? " selected":"")}>
						<table className="table1">
							<thead className="table-header-gray">
								<tr>
									<td></td>
									<td>N° de Cliente</td>
									<td>Servicios</td>
									<td>Dirección</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
							{empresa.sap_clients.filter(sp=>this.state.client_codes[""+sp.id]).map((cliente, j) => {
									var servicio = "";									
									if(cliente.business_line_id === "3") servicio = "Granel Habitacional";
									if(cliente.business_line_id === "1") servicio = "Medidor";
									if(cliente.business_line_id === "6") servicio = "Granel Industrial";


									return(
								<tr key={cliente.id}>
									<td><div className="funcionario_cliente_habilitado-icon" /></td>
									<td>{cliente.abastible_client_id}</td>
									<td>{servicio}</td>
									<td>{cliente.address}, {cliente.comune}</td>	
									<td>
										<div className={"inline-block " + (cliente.merchandise_recipients && cliente.merchandise_recipients.length > 0 ? "icon-tanque": "")}></div>
										<div className={"inline-block " + (cliente.payment_responsibles && cliente.payment_responsibles.length > 0 ? "icon-dinero": "")}></div>
									</td>  																
								</tr>
									);

								})}
							</tbody>
						</table>
					</div>					
		</div>)
		});		
		return(
		<div className="text-align-center">
			<div className="subcontent-box col-100 clearfix margin-bottom-30">
				<div className="granel-pedido-details grid-column-3">
					<div className="detail">
						<span>Nombre</span>
						<p>{this.state.name} {this.state.last_name}</p>
					</div>
					<div className="detail">
						<span>Rut</span>
						<p>{this.state.dni}</p>
					</div>
					<div className="detail">
						<span>Teléfono</span>
						<p>{this.state.phone}</p>
					</div>									
					<div className="detail">
						<span>Email</span>
						<p>{this.state.email}</p>
					</div>					
				</div>
		   </div>
			{empresas}
			<Button1
				label="GUARDAR"
				className="margin-top-50 center" icon={ArrowRightWhite}
				onClick={() => {this.sendForm()}} />
		</div>)
	}
	sendForm() {
		this.setState({loading: true});		
		postFuncionario({validation_only: 0, name: this.state.name, last_name: this.state.last_name, phone: this.state.phone, dni: this.state.dni, email: this.state.email, client_codes: this.state.client_codes}, (error, result) => {
			if(error) {
				this.props.createActionNotification("", "Hubo un error creando funcionario", "error");
				this.setState({loading: false});
				return console.log("error guardando funcionario", error);
			}
			this.props.createActionNotification("", "Se ha creado el funcionario correctamente");
			this.props.redirect("/funcionarios");
		});
	}
	renderStep() {
		if(this.state.step === 1) return this.renderStep1();
		if(this.state.step === 2) return this.renderStep2();
		if(this.state.step === 3) return this.renderStep3();
		if(this.state.step === 4) return this.renderStep4();
	}
	cancelCheckRutModal() {
		this.setState({checkRutModal: null});
	}
	confirmCheckRutModal() {	
	   	this.props.toggleMainLoading(true);
		requestFuncionario(this.state.dni, (error, result) => {
			this.setState({checkRutModal: null});
	      	this.props.toggleMainLoading(false);

			if(error) {
				return this.props.createActionNotification("Ocurrió un error al enviar la solicitud", "Intenta nuevamente", "error");
			}
			if(result.status === "ok") {
				this.props.createActionNotification("", "La solicitud se ha enviado correctamente");
			} else {
				this.props.createActionNotification("Ocurrió un error al enviar la solicitud", result.message, "error");
			}

		});
	}
	render() {
		var tabTitle = "Crear Funcionario";
		if(this.state.step === 2) tabTitle = "Datos del Funcionario";
		if(this.state.step === 3) tabTitle = "Clientes Habilitados";
		if(this.state.step === 4) tabTitle = "Resumen";
		return (
			<Fragment>
				<div className="section-title">
					<h1>Mis Funcionarios</h1>
				</div>
				<ContentBox loading={this.state.loading}>
					<div className={"title-client-container clearfix"}>
						<h1>{tabTitle}</h1>
						{this.state.step === 4?null:<div className="agregar-solicitud-steps">
							<StepSwitch options={this.state.options} selected={this.state.step} onChange={(step) => {this.setState({step})}} />
						</div>}
					</div>
					{this.renderStep()}
					<PopupPrivilegiosFuncionario 
						cancel={()=>this.setState({selected_client: null})} 
						confirm={this.privilegesPopupConfirm.bind(this)} 
						show={this.state.selected_client}
						client_privileges={this.state.selected_client?this.state.client_codes[this.state.selected_client.id]:null}
						privileges={this.state.privileges && this.state.selected_client?this.state.privileges.filter((p)=> p.business_lines_id === this.state.selected_client.business_line_id):null} />
						
				</ContentBox>
				<ModalConfirmation 
					cancel={this.cancelCheckRutModal.bind(this)} 
					confirm={this.confirmCheckRutModal.bind(this)} 
					title={"Funcionario reservado"} 
					renderContent={()=>this.state.checkRutModal?this.state.checkRutModal.show_modal_message_1 + " " + this.state.checkRutModal.show_modal_message_2: null}
					show={this.state.checkRutModal}/>

			</Fragment>
		);
	}
}