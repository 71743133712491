import React, { Component } from 'react';
import Close from '../img/close.svg';
import {Line, defaults} from 'react-chartjs-2';
import moment from 'moment';
defaults.global.plugins.datalabels.display = false;
export default class PopupTanqueGrafico extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}	
	cancel() {
		this.props.cancel();
	}
	componentDidUpdate(prevProps) {
		if(prevProps.lecturas !== this.props.lecturas || prevProps.lecturas_old !== this.props.lecturas_old) {
			var labels = [];
			var values = [];
			var values2 = [];
			if(!this.props.lecturas) return;
			this.props.lecturas.forEach((lectura, i) => {
				labels.push(moment(lectura.date, "YYYY-MM-DD").format("DD-MM"));
				values.push(parseInt(lectura.percentage_level));
				var found = false;
				this.props.lecturas_old.forEach((lectura2, i) => {
					if(!found && lectura.date !== lectura2.date && moment(lectura.date, "YYYY-MM-DD").format("DD-MM") === moment(lectura2.date, "YYYY-MM-DD").format("DD-MM")) {
						values2.push(lectura2.percentage_level);
						found = true;
					}
				});
			});		
			const data = {
			  labels,
			  datasets: [
			    {
			      label: 'Porcentaje de llenado',
			      fill: false,
			      lineTension: 0.1,
			      backgroundColor: '#FE5000',
			      borderColor: '#FE5000',
			      borderCapStyle: 'butt',
			      borderDash: [],
			      borderDashOffset: 0.0,
			      borderJoinStyle: 'miter',
			      pointBorderColor: '#FE5000',
			      pointBackgroundColor: '#FE5000',
			      pointBorderWidth: 0,
			      pointHoverRadius: 3,
			      pointHoverBackgroundColor: '#FE5000',
			      pointHoverBorderColor: '#FE5000',
			      pointHoverBorderWidth: 2,
			      pointRadius: 1,
			      pointHitRadius: 10,
			      borderWidth: 1.5,
			      data: values,
			      radius: 0, // radius is 0 for only this dataset

			    },
			    {
			      label: 'Porcentaje de llenado año anterior',
			      fill: false,
			      lineTension: 0.1,
			      backgroundColor: 'rgba(75,10,10,1)',
			      borderColor: 'rgba(75,10,10,1)',
			      borderCapStyle: 'butt',
			      borderDash: [],
			      borderDashOffset: 0.0,
			      borderJoinStyle: 'miter',
			      pointBorderColor: 'rgba(75,10,10,1)',
			      pointBackgroundColor: 'rgba(75,10,10,1)',
			      pointBorderWidth: 0,
			      pointHoverRadius: 3,
			      pointHoverBackgroundColor: 'rgba(75,10,10,1)',
			      pointHoverBorderColor: 'rgba(75,10,10,1)',
			      pointHoverBorderWidth: 2,
			      pointRadius: 1,
			      borderWidth: 1.5,			      
			      pointHitRadius: 10,
			      data: values2
			    }
			  ]
			};
			this.setState({data});
		}
	}
	renderGraph() {
		if(!this.state.data) return null;
		return(			
			<Line
				data={this.state.data}
				width={520}
				height={300}
				options={{					
					maintainAspectRatio: true,					
					legend: {
								display: true
							},
					tooltips: {
			            callbacks: {
			                label: function(tooltipItem, data) {

			                    let label = data.labels[tooltipItem.index];
			                    let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
			                    return 'Porcentaje de llenado: ' + value + '%';

			                }
			            }
			        },
					scales: {
					      yAxes: [{
					          ticks: {
					              beginAtZero:true,
					              min: 0,
					              max: 100   ,					             
								    callback: function(label, index, labels) {
								        return label+'%';
								    
									}

					          }
					        }]
					     }					
				}}
			/>
		);
	}
	
	render() {
		return (
			<div className={"full-screen-veil " + (this.props.show ? 'show-modal' : '')}>
				<div className="modal1">
					<div className="modalContainer">
						<div className="content col-100 left clearfix">
							<h1 className="fw700">Gráfico de lecturas para el periodo entre {moment(this.props.startDate, "YYYY-MM-DD").format("DD-MM-YYYY")} y {moment(this.props.endDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</h1>
						</div>
						{this.renderGraph()}
					</div>					
					<div className="solicitud-evaluacion-close-container" onClick={() => this.cancel()}>
						<img alt="" src={Close} />
					</div>

				</div>
			</div>
		);
	}
}