import React, { useEffect, useState } from 'react'
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import mapDestination from '../img/map-destination.png';
import mapTruck from '../img/map-truck.png';

const clientId = "gme-abastible"
const mapContainerStyle = {
  width: "calc(100% + 100px)",
  margin: "-65px 0 -50px -50px",
  borderRadius: "0 0 10px 10px",
  float: "left",
  height: "400px"
}
const center = {
  lat: 0,
  lng: 0
}
const options = {
  disableDefaultUI: true,
  zoomControl: true
}

export default function ServiciosCilindroMap({truck_position, direccion, setDireccion}) {
  
  const {isLoaded, loadError} = useLoadScript({
    googleMapsClientId: clientId
  })
  const mapRef = React.useRef()
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    createCoords(direccion)
  }, [])
  const [truck, setTruck] = useState(false)
  const [direction, setDirection] = useState(false)

  useEffect(()=>{
    if (truck_position) {
      setTruck({lat: truck_position.latitude, lng: truck_position.longitude})
    }
  }, [truck_position])

  useEffect(()=>{
    if (direction || truck) {
      createBounds(direction, truck)
    }
  }, [direction, truck])


  const createCoords = React.useCallback((dir) => {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({address: dir}, function(results, status) {
      if (status === "OK") {
        setDirection({lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()})
        //mapRef.current.panTo({lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()})
      } else {
        setDireccion(false)
      }
    })
  }, []);

  const createBounds = React.useCallback((dir, trk) => {
    let bounds = new window.google.maps.LatLngBounds();
    if (dir) bounds.extend(dir);
    if (trk) bounds.extend(trk);
    if (dir || trk) {
      mapRef.current.fitBounds(bounds)
    }  
  })

  if(loadError) return "Error al cargar el mapa, por favor intente más tarde"
  if (!isLoaded) return " - " 
  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        options={options}
        center={center}
        onLoad={onMapLoad}>
          {truck ? <Marker position={truck} icon={mapTruck}/> : null}
          {direction ? <Marker position={direction} icon={mapDestination}/> : null}          
      </GoogleMap>
    </div>
  )
}
