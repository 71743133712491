import React, { Component } from 'react';
import GuestFrame from './GuestFrame.js';
import OrangeArrow from '../img/arrow_orange.png';
import Tooltip  from 'rc-tooltip';

export default class SendVerificationEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			email: "",
			errors: []
		}
	}

	sendEmailForm() {
		this.setState({loading: true});
		this.props.doVerifyEmailAgain(this.state.email, (error, result) => {
			this.setState({loading: false});
			if(error) {
				return alert("error al loguear");
			}
			if(result.status === 'error'){
				var errorForm = {
					'email': result.message
				}
				this.setState({errors: errorForm});
			}
		});

	}
	deleteFormError(field_name) {
		var formErrors = {...this.state.errors};
		delete formErrors[field_name];
		this.setState({errors: formErrors})
	}

	render() {
		return (
			<GuestFrame>
				<div className="login-container">
					<div className="background-flame">
						<h2>Verificar Email</h2>
						<p className="color-white">¿No recibiste el correo de confirmación? Ingresa tu email nuevamente para que te enviemos otro.</p>
						<div className="login-field-container">
							<p>Email</p>
							<Tooltip placement="bottom" visible={this.state.errors && this.state.errors.email ? true : false}
				 			overlay={<span>{this.state.errors ? this.state.errors.email : ""}</span>}>
								<input
									value={this.state.email}
									onChange={(e) => {this.setState({email: e.target.value}); this.deleteFormError("email")}} />
							</Tooltip>
						 </div>
				 		<div className="login-submit-button-container margin-top-30">
							<button  className="login-submit-button" onClick={() => this.sendEmailForm()}>ENVIAR CORREO</button>
							<img alt="Verificar email" src={OrangeArrow} />
						</div>

					</div>
				</div>
			</GuestFrame>
		);
	}
}
