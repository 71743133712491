import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import logo from '../img/logo_blue.png';
import menuClose from '../img/menu-close.png';

import telephone from '../img/telephone_24_7.svg';
import calendar from '../img/calendar.svg';
import whatsapp from '../img/whatsapp-color.svg';
import {sectionHasPath} from '../utils.js';
import {contact} from '../config.js';

export default class Menu extends Component {
	renderMenuOptions() {
		var barTop = -8;
		var barHeight = 40;
		var barFound = false;
		var rows = [];
		let user = JSON.parse(localStorage.getItem('user'));
		this.props.menuOptions.forEach((option, i) => {
			if(option.hidden) return;
			var selected = sectionHasPath(option, this.props.location.pathname) ? " selected" : "";
			if(!selected) {
				if(!barFound) {
					barTop += 40;
				}
				if(option.sub) {
					option.sub.forEach((subOption, j) => {
						if(subOption.hidden) return;
						if(!selected) {
							//selected = subOption.to.indexOf(this.props.location.pathname) > -1 ? " selected" : "";
							selected = sectionHasPath(subOption, this.props.location.pathname) ? " selected" : "";

							if(selected) {
								barHeight = 35;
								barFound = true;
							} else {
								if(!barFound) barTop += 35;
							}
						}
					});
				}
			} else {
				barFound = true;
				barHeight = 40;
			}
			if(!selected && !barFound) {
				barTop += 20;
			}
			rows.push(
				<Link to={option.to[0]} key={option.to[0]} onClick={this.props.onClick}>
				<div className={"menu-option" + selected}>
					<img alt="" className="menu-option-icon" src={option.icon} />
					<p className="menu-option-text">{option.name}</p>
				</div>
				</Link>
			);
			if(option.sub) {
				option.sub.forEach((subOption) => {
					if(subOption.hidden) return;

					//var selected = subOption.to.indexOf(this.props.location.pathname) > -1 ? " selected" : "";
					var selected = sectionHasPath(subOption, this.props.location.pathname) ? " selected" : "";

					rows.push(
						<Link to={subOption.to[0]+((subOption.name =='Medidor' && user && user.hasdebt)?'/#showmodal':'')} key={subOption.to[0]} onClick={this.props.onClick}>
						<div className={"menu-option sub" + selected}>
							<img alt="" className="menu-option-icon" src={subOption.icon} />
							<p className="menu-option-text">{subOption.name}</p>
						</div>
						</Link>
					);
				});
			}
		});
		if(barFound) rows.push(<div className="menu-selected-option-bar" style={{top: barTop + "px", height: barHeight+"px"}} key="bar"></div>)
		return rows;
	}
	render() {
		return (
			<div className={"menu-container" + (this.props.opened ? " opened" : "")}>
				<div className="menu-header">
					<img alt="" src={logo} className="menu-logo" />
					<img alt="" src={menuClose} className="menu-close" onClick={() => this.props.toggleMenu()} />
				</div>
				<div className="menu-options">
					{this.renderMenuOptions()}
				</div>
				<div className="help-container">
					<div className="help Button2 orange">
						<span>Ayuda</span>
					</div>
					<div className="help-data">
						<div className="emergency"><img alt="telephone" src={telephone} className="icon-phone" /><p><strong>{contact.phone}</strong></p></div>
						<div className="schedule"><img alt="calendar" src={calendar} className="icon-calendar" /><p><strong>{contact.calendar[0]}{contact.calendar[1]?<span>{contact.calendar[1]}</span>:null}</strong></p></div>
						<div className="instant-messaging"><img alt="whatsapp" src={whatsapp} className="icon-whatsapp" /><a href={'https://wa.me/'+(contact.whatsapp.replace(/\s/g,''))} target='_blank' ><p><strong>{contact.whatsapp}</strong></p></a></div>
					</div>
				</div>
			</div>
		);
	}
}