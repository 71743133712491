import React, { Component } from 'react';
import Close from '../img/close.svg';
import Button2 from './Button2';
import {postHousingClaim, postCylinderClaim} from '../api.js';
import SolicitudAyudaImg from '../img/solicitud_ayuda_ok.png';
import ContentBox from './ContentBox.js';
import moment from "moment"

export default class PopupPedidoReclamo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			step: props.initialStep?props.initialStep:1,
			comment: "",
			loading: false,
			hasClaim: this.props.hasClaim
		};
	}
	sendForm() {
		var comment = undefined;
		this.setState({loading: true});
		if(this.state.comment.trim() !== "") comment = this.state.comment.trim();
		if(this.props.business_line === 'cylinder'){
			postCylinderClaim(this.props.order_number, this.props.client_cod, comment, (error, result) => {
				this.setState({loading: false});
				if(error) {
					return this.setState({error: "Error enviando reclamo, inténtalo más tarde"});
				}				
				if(result.status === "error") return this.setState({error: result.message});
				this.setState({hasClaim: true});
				this.setState({step: 3});
			},this.props.isEmpresa?"empresas":null);
		} else {
			postHousingClaim(this.props.order_number, this.props.client_cod, comment, (error, result) => {
				this.setState({loading: false});
				if(error) {
					return this.setState({error: "Error enviando reclamo, inténtalo más tarde"});
				}
				if(result.status === "error") return this.setState({error: result.message});
				this.setState({hasClaim: true});
				this.setState({step: 3});
			},this.props.isEmpresa?"empresas":null);
		}
	}
	cancel() {
		this.setState({step: this.props.initialStep?this.props.initialStep:1, comment: ""})
		this.props.cancel();
	}
	renderStep1() {
		if (this.state.hasClaim) {
			return(
				<div className="content col-100 left clearfix">
					<h1 className="fw700">Ya hemos recibido un reclamo asociado a este pedido. ¿Deseas enviar uno nuevamente?</h1>
					<div className="col-100 left clearfix flex justify-center">
						<Button2 label="No" className="button button-border-orange" onClick={() => {this.cancel()}}/>
						<Button2 label="Sí" className="button green" onClick={() => {this.setState({step: 2})}}/>
					</div>
				</div>
			);
		} else {
			if ((moment().format(this.props.show.scheduledDate, "YYYY-MM-DD") === moment().format("YYYY-MM-DD")) && (moment().format(this.props.show.scheduledTime, "H:mm:ss") <= moment().format("H:mm:ss")) ) {
				return(
					<div className="content col-100 left clearfix">
						<h1 className="fw700">Tu pedido está en camino. ¿Estás seguro que deseas registrar un reclamo?</h1>
						<div className="col-100 left clearfix flex justify-center">
							<Button2 label="No" className="button button-border-orange" onClick={() => {this.cancel()}}/>
							<Button2 label="Sí" className="button green" onClick={() => {this.setState({step: 2})}}/>
						</div>
					</div>
				);
			} else {
				this.setState({step: 2})
			}
		}
	}
	renderStep2() {
		return(
			<div className="content col-100 left clearfix">
	      		<h1 className="fw700">Observación</h1>
	      		<textarea value={this.state.comment} onChange={(e) => this.setState({comment:e.target.value})} />
	      		<div className="col-100 left clearfix">
		        	<Button2 label="Enviar" className="button green margin-top-20" onClick={() => this.sendForm()} />
						</div>
			</div>);
	}
	renderStep3() {
		return(
			<div className="content col-100 left clearfix">
				<h1 className="fw700">Reclamar retraso</h1>
	      		<div className="col-100 left clearfix font-20" style={{overflowY: "initial"}}>
	      			<p>Informaremos al área de despacho para que tu pedido llegue a la brevedad</p>
      			</div>
	      		<div className="information-need-help-ok-image">
					<img className="question-orange" src={SolicitudAyudaImg} alt="Ícono ayuda" />
				</div>
			</div>);
	}
	renderStepError() {
		return(
			<div className="content col-100 left clearfix">
				<h1 className="fw700">Reclamar retraso</h1>
	      		<div className="col-100 left clearfix margin-bottom-20 font-20" style={{overflowY: "initial"}}>
	      			<p>{this.state.error}</p>
      			</div>	      		
			</div>);
	}
	renderSteps() {
		if(this.state.error) return this.renderStepError();
		if(this.state.step === 1) return this.renderStep1();
		if(this.state.step === 2) return this.renderStep2();
		if(this.state.step === 3) return this.renderStep3();
	}
	render() {
		return (
			<div className={"full-screen-veil " + (this.props.show ? 'show-modal' : '')}>
				<div className="modal1">
					<div className="solicitud-evaluacion-close-container" onClick={() => this.cancel()}>
						<img alt="" src={Close} />
					</div>
					<ContentBox className={'box-shadow-none padding-0'} loading={this.state.loading}>
						{this.renderSteps()}
					</ContentBox>
				</div>
			</div>
		);
	}
}
