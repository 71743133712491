import React, { Component } from 'react';
import GridBox from './GridBox';
import FaqIcon from '../img/icon_faq.png';
import InfoIcon from '../img/icon_info.png';

var newServices = [
	{
		name: "Preguntas Frecuentes",
		desc: "Revisa las respuestas a las preguntas más recurrentes que recibimos de nuestros clientes.",
		icon: FaqIcon,
		a: "https://www.abastible.cl/medidor/atencion-al-cliente/#home-faq"
	},
	{
		name: "Información",
		desc: "Revisa en detalle cada uno de nuestros productos y servicios, los beneficios del uso del GLP y accede a prácticos consejos que te ayudarán a disfrutar de forma segura de la energia de Abastible.",
		icon: InfoIcon,
		a: "https://www.abastible.cl/medidor/"
	},
	/*{
		name: "Valores por Metro Cúbico",
		desc: "Conoce  las tarifas de gas para cada comuna y región.",
		icon: ValoresIcon,
		link: "/informacion_interes/valores_metro_cubico"
	}*/
];
export default class InformacionInteresMedidor extends Component {
	render() {
		return (
        	<div className="informacion-choose-container margin-top-40">
				{newServices.map((service, i) => {
					return(<GridBox key={i} service={service} buttonLabel="SABER MÁS" />);
				})}
			</div>
		);
	}
};
