import React, { Component, Fragment } from 'react';

import MedidorIcon from '../img/icon_medidor.png';
import TanqueIcon from '../img/icon_tanque.png';
import CilindroIcon from '../img/icon_cilindro.png';
import AutogasIcon from '../img/icon_autogas.png';

import GridBox from './GridBox';
import BreadCrumb from './BreadCrumb';
import {available} from '../config.js';


var newServices = [
	{
		name: "Cilindro",
		desc: "Usas cilindros de gas en alguno de sus distintos formatos: 05, 11, 15 o 45 kilos.",
		icon: CilindroIcon,
		link: "/adminitracion_cuentas/cilindro/asociar"
	},
	{
		name: "Granel Habitacional",
		desc: "Tienes un tanque de gas en tu domicilio y lo abastecemos de acuerdo a tu necesidad",
		icon: TanqueIcon,
		link: "/adminitracion_cuentas/granel/asociar"

	},
	{
		name: "Autogas",
		desc: "Tienes un Kit y tanque de GLP instalado en tu vehículo",
		icon: AutogasIcon,
		link: "/adminitracion_cuentas/autogas/asociar"

	},
	{
		name: "Medidor",
		desc: "Si mensualmente recibes una boleta en tu hogar por el consumo de gas",
		icon: MedidorIcon,
		link: "/adminitracion_cuentas/medidor/asociar"

	}
];
export default class SeleccionarServicioAgregar extends Component {
	componentDidMount() {
		this.props.changeHeaderClass("medium");    	
    }
	render() {
		var breadCrumbOptions = [
			{title: "Agregar Servicios"},
		];
		return (
          	<Fragment>
				 
			<div className="section-title">
					<h1>Seleccione Servicio que desea Agregar</h1>
					<BreadCrumb options={breadCrumbOptions} />					
				</div>
          	<div className="service-choose-container margin-sides-40 margin-top-80">
	          	{newServices.map((service, i) => {
		          	if(!available.granel && service.name === "Granel Habitacional") return null;
					  if(!available.cilindro && service.name === "Cilindro") return null;
					  if(!available.autogas && service.name === "Autogas") return null;

	          		return(<GridBox service={service} key={i} buttonLabel="AGREGAR" />);
	          	})}		
          	</div>
          	</Fragment>
		);
	}
};