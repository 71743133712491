import React, {useState, useEffect} from 'react';
import ContentBox from "./ContentBox";
import {getRequestList, reqTree} from '../api.js';
import {Link, useLocation} from "react-router-dom";
import DatePicker from 'react-datepicker';
import calendar from '../img/calendar.svg';
import moment from 'moment';

export default function SolicitudesMisRequerimientos({isEmpresa}) {
    const props = useLocation();
    const [totalSolicitudes, setTotalSolicitudes] = useState([])
    const [fecha, setFecha] = useState(null)
    const [servicio, setServicio] = useState(null)
    const [servicioOpciones, setServicioOpciones] = useState([])
    const [categoria, setCategoria] = useState(null)
    const [categoriaOpciones, setCategoriaOpciones] = useState([])
    const [tipoRequerimiento, setTipoRequerimiento] = useState(null)
    const [tipoRequerimientoOpciones, setTipoRequerimientoOpciones] = useState([])
    const [filtro, setFiltro] = useState(false)
    const [tree, setTree] = useState([])
    const [solicitudesFiltradas, setSolicitudesFiltradas] = useState([])
    const [messageSol, setMessageSol] = useState(null)
    const [cleanUp, setCleanUp] = useState(false)

    useEffect(()=>{
        getRequestList(fecha, null, tipoRequerimiento, servicio, categoria, (err, res) => {
            if (err) {
                console.log(err)
            }
            if (res && res.status === "ok") {
                setTotalSolicitudes(res.data)
                filtrar(res.data)
            }

        }, isEmpresa?"empresas":null)
    }, [])


    useEffect(()=>{
        if (props.state && props.state.filterParams && totalSolicitudes && totalSolicitudes.length > 0) {
            let loc = props.state.filterParams;
            if (loc.fecha) {
                setFecha(loc.fecha)
            }
            if (loc.servicio) {
                setServicio(loc.servicio)
            }
            if (loc.categoria) {
                setCategoria(loc.categoria)
            }
            if (loc.tipoRequerimiento) {
                setTipoRequerimiento(loc.tipoRequerimiento)
            }
            filtrar(totalSolicitudes, loc.fecha, loc.servicio, loc.categoria, loc.tipoRequerimiento)
            setTimeout(() => {
                setCleanUp(true)
            }, 1500);
        }
    }, [props.state, totalSolicitudes])

    useEffect(()=>{
        reqTree((err,res) => {
            if (err) {
                console.log(err)
            }
            if (res) {
                setTree(res.data)
            }
        }, isEmpresa?"empresas":null)
    }, [])

    useEffect(()=>{
        if (tree && tree.length > 0) {
            let servicios = []
            tree.map((servicio, i) => {
                servicios = [...servicios, {id: servicio.id, name: servicio.servicio}]
            })
            setServicioOpciones(servicios)
        }
    }, [tree])

    useEffect(()=>{
        if (cleanUp) {
            setCategoria(null)
        }
        if (tree && tree.length > 0 && servicio) {
            tree.map((s) => {
                if (s.id === parseInt(servicio)) {
                    let categ = []
                    s.categorias.map((c) => {
                        categ = [...categ, {id: c.id, categoria: c.categoria}]
                    })
                    setCategoriaOpciones(categ)
                }
            })
        } else if (tree && tree.length > 0 && !servicio) {
            let categ = []
            tree.map((s) => {
                s.categorias.map((c) => {
                    categ = [...categ, {id: c.id, categoria: c.categoria}]
                })
            })
            setCategoriaOpciones(categ)
        }
    }, [servicio, tree])

    useEffect(()=>{
        if (cleanUp) {
            setTipoRequerimiento(null) 
        }
        if (tree && tree.length > 0 && categoria) {
            tree.map(s => {
               s.categorias.map(c => {
                    if (c.id === parseInt(categoria)) {
                        let tipReq = []
                        c.tipos_solicitudes.map(sol => {
                            tipReq = [...tipReq, {id: sol.id, tipo_solicitud: sol.tipo_solicitud}]
                        })
                        setTipoRequerimientoOpciones(tipReq)
                    }
                })
            })
        } else if (tree && tree.length > 0 && !categoria) {
            if (servicio) {
                let tipReq = []
                tree.map(s => {
                    if (s.id === parseInt(servicio)) {
                        s.categorias.map(c => {
                            c.tipos_solicitudes.map(sol => {
                                tipReq = [...tipReq, {id: sol.id, tipo_solicitud: sol.tipo_solicitud}]
                            })
                         })
                    }
                 })
                 setTipoRequerimientoOpciones(tipReq)
            } else {
                let tipReq = []
                tree.map(s => {
                    s.categorias.map(c => {
                        c.tipos_solicitudes.map(sol => {
                            tipReq = [...tipReq, {id: sol.id, tipo_solicitud: sol.tipo_solicitud}]
                        })
                     })
                 })
                 setTipoRequerimientoOpciones(tipReq)
            }
        }
    }, [categoria, tree, servicio])

    useEffect(()=>{
        if (totalSolicitudes) {
            filtrar(totalSolicitudes, fecha, servicio, categoria, tipoRequerimiento)
        }
    }, [filtro])

    const dateSelect = (date) => {
        if (moment(date).isValid()) {
            setFecha(date)
		} else {
			setFecha('')
		}
    }

    const filtrar = (solicitudesOriginales, fecha, servicio, categoria, tipoRequerimiento) => {
        if (!fecha && !servicio && !categoria && !tipoRequerimiento) {
            let tableDataOrdered = solicitudesOriginales.sort((a,b) => (a.created_at_date < b.created_at_date) ? 1 : ((b.created_at_date < a.created_at_date) ? -1 : 0))
			setSolicitudesFiltradas(tableDataOrdered) 
		} else {
			let filteredRes = solicitudesOriginales.filter(item => 
				(!fecha || moment(fecha).format("YYYY-MM-DD") === item.created_at_date)
				  &&
				(!servicio || item.bussines_lines_id === servicio)
				 &&
				(!categoria || categoria && item.request_categories_id === categoria)
				&&
				(!tipoRequerimiento || item.request_types_id === tipoRequerimiento) 
            )
            let tableDataOrdered = filteredRes.sort((a,b) => (a.created_at_date < b.created_at_date) ? 1 : ((b.created_at_date < a.created_at_date) ? -1 : 0))
			setSolicitudesFiltradas(tableDataOrdered)
        }
    }

    useEffect(()=>{
        if (solicitudesFiltradas && solicitudesFiltradas.length === 0) {
            setMessageSol("No existen resultados para esta búsqueda")
        } else {
            setMessageSol(null)
        }
    }, [solicitudesFiltradas])

    return (
        <React.Fragment>
            <div className="section-title">
                <h1>Mis Requerimientos</h1>
            </div>
            <ContentBox>
                <div className="col-100 left d-flex justify-between margin-bottom-40">
                    <div className="filter-container grid-column-4">
                        <div className="position-relative">
                            <img className="calendar" src={calendar} alt="calendario" />
                            <DatePicker popperModifiers={{flip: {enabled: false}}} maxDate={new Date()} dateFormat="dd/MM/yyyy" placeholderText="Fecha" selected={fecha ? fecha : ''} locale="es" onChange={(date) => {dateSelect(date)}} />
                        </div>
                        <div className="position-relative">
                            <select value={servicio ? servicio : ''} onChange={(e) => {setServicio(e.target.value)}}>
                                <option value="">Servicio</option>
                                {servicioOpciones ?
                                    servicioOpciones.map((service, i) =>
                                        <option key={i} value={service.id}>{service.name}</option>
                                    )
                                : null}
                            </select>
                        </div>
                        <div className="position-relative">
                            <select value={categoria ? categoria : ''} onChange={(e) => {setCategoria(e.target.value)}}>
                                <option value="">Categoria</option>
                                {categoriaOpciones ?
                                    categoriaOpciones.map((category, i) =>
                                        <option key={i} value={category.id}>{category.categoria}</option>
                                    )
                                : null}
                            </select>
                        </div>
                        <div className="position-relative">
                            <select value={tipoRequerimiento ? tipoRequerimiento : ''} onChange={(e) => {setTipoRequerimiento(e.target.value)}}>
                                <option value="">Tipo de solicitud</option>
                                {tipoRequerimientoOpciones ?
                                    tipoRequerimientoOpciones.map((tipoReq, i) =>
                                        <option key={i} value={tipoReq.id}>{tipoReq.tipo_solicitud}</option>
                                    )
                                : null}
                            </select>
                        </div>

                        <button className="button orange" onClick={() => {setFiltro(!filtro)}}>Filtrar</button>
                    </div>
                </div>
                <div className="table-container">
                    <table className="table1">
                        <thead>
                            <tr>
                                <td>Id Requerimiento</td>
                                <td>Fecha</td>
                                <td>Servicios</td>
                                <td>Tipo Requerimiento</td>
                                <td>Estado</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {!solicitudesFiltradas ? null : solicitudesFiltradas.map((solicitud, i) => {
                                return(
                                    <tr key={i}>
                                        <td>{solicitud.crm_id}</td>
                                        <td>{moment(solicitud.created_at_date, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
                                        <td>{solicitud.bussines_lines_name}</td>
                                        <td>{solicitud.request_type_name}</td>
                                        <td>{solicitud.status}</td>
                                        <td>
                                            <div className="linkA-container">
                                                <Link to={{pathname: "requerimientos/" + solicitud.id, state: {filterParams: {fecha: fecha, servicio: servicio, categoria: categoria, tipoRequerimiento: tipoRequerimiento}}}}>Detalle</Link>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    {messageSol && totalSolicitudes && totalSolicitudes.length > 0 ? messageSol : null}
                </div>
            </ContentBox>
        </React.Fragment>
    )
}
