import React, { Component } from 'react';
import UserNatural from './UserNatural.js';
import UserEmpresas from './UserEmpresas.js';

export default class UserLogged extends Component {
	constructor(props) {
		super(props);
		this.state = {
			header_class: null,
			redirect_data: null
		};
	}
	changeHeaderClass(header_class) {
	    this.setState({header_class});
	}
	redirectWithData(data) {
		var url = null;
		if(data.section === "granel_cuenta_corriente") url = "/mis-servicios/granel/cuenta-corriente";
		this.props.redirect(url, () => {
			this.setState({redirect_data: data}, () => {
				this.setState({redirect_data: null}, () => {
				});			
			});			
		});
	}
	render() {
		if(this.props.user_role === "Administrador" || this.props.user_role === "Funcionario") {
	        return (
			<UserEmpresas
				createActionNotification={this.props.createActionNotification}     
				user_role={this.props.user_role} 
				updateUser={this.props.updateUser}   				
	            redirect={this.props.redirect}             
	            user={this.props.user}
	            doLogout={this.props.doLogout}
	            location={this.props.location}
	            getUser={this.props.getUser}
	            notifications={this.props.notifications}
	            header_class={this.state.header_class}
	            redirectWithData={this.redirectWithData.bind(this)}    
	            redirect_data={this.state.redirect_data}  
	            toggleMainLoading={this.props.toggleMainLoading}      	                  	            
	            changeHeaderClass={this.changeHeaderClass.bind(this)}
	        />);			
		}		
		return (
			<UserNatural
				createActionNotification={this.props.createActionNotification}   
				updateUser={this.props.updateUser}   
	            redirect={this.props.redirect}             
	            user={this.props.user}
	            doLogout={this.props.doLogout}
	            location={this.props.location}
	            getUser={this.props.getUser}
	            notifications={this.props.notifications}	            
	            header_class={this.state.header_class}	  
	            redirectWithData={this.redirectWithData.bind(this)}  
	            redirect_data={this.state.redirect_data}        
	            changeHeaderClass={this.changeHeaderClass.bind(this)}	            
	        />);
	}
}