import React, { Component } from 'react';
import {Bar, defaults} from 'react-chartjs-2';
import calendar from '../img/calendar.svg';
import DatePicker from 'react-datepicker';
import 'chartjs-plugin-datalabels';
import ContentBox from './ContentBox.js';
import ClientSelectMedidor from './ClientSelectMedidor';
import moment from 'moment';
import es from 'date-fns/locale/es';
import { registerLocale } from  "react-datepicker";
import {getReadings, getReadingsGraph, requestForm, meterBussinesLineFormIds} from '../api.js';
import {monthNumberToShortName} from '../utils.js';
import { useMediaQuery } from 'react-responsive';
import {Link} from 'react-router-dom'

registerLocale('es', es);
defaults.global.defaultFontFamily = 'Titillium Web';
var pattern = require('../libs/patternomaly');
const graphBase = {
  labels: [],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: [
      	pattern.draw('diagonal-right-left', 'rgba(255,255,255,1)','rgba(120,190,32,1)', 7),
      	'rgba(255,108,0,1)','rgba(255,108,0,1)',
      	'rgba(255,108,0,1)','rgba(255,108,0,1)',
      	'rgba(255,108,0,1)','rgba(255,108,0,1)',
      	'rgba(255,108,0,1)','rgba(255,108,0,1)',
      	'rgba(255,108,0,1)','rgba(255,108,0,1)',
      	pattern.draw('diagonal-double', 'rgba(255,255,255,1)','rgba(255,108,0,1)', 7),
      	'rgba(120,190,32,1)'],
      //borderColor: 'rgba(255,99,132,1)',
      borderWidth: 0,
      //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      //hoverBorderColor: 'rgba(255,99,132,1)',
      data: []
    }
  ]
};
function App(props) {	
	var size = 25;
	size = useMediaQuery({query: '(max-width: 1100px)'})?40:size;
	size = useMediaQuery({query: '(max-width: 800px)'})?60:size;	
	size = useMediaQuery({query: '(max-width: 300px)'})?80:size;
	return <ServiciosMedidorHistorialLectura {...props} graphHeight={size} />;
}
export default App;
class ServiciosMedidorHistorialLectura extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphData: null,
			lecturas: null,
			graphMessage: null,
			lecturasMessage: null,
			times: 0,
			loading: false,
			dataObj: false,
			from: moment().subtract(12, "months").toDate(),
			to: moment().toDate()			
		};
	}	
	componentDidMount() {
		if(this.props.selectedService) {
			this.getList();
			this.getFormPreloadData();
		}
	}	
	componentDidUpdate(prevProps) {
		if(prevProps.selectedService !== this.props.selectedService && this.props.selectedService) {
			this.getList();
			this.getFormPreloadData();
		}
		if(prevProps.graphHeight !== this.props.graphHeight) {
			this.setState({reloadingGraph: true},() => this.setState({reloadingGraph: false}));
		}
	}
	solicitarLectura(lectura) {
		/* var month = moment(lectura.reading_date + "-01", "YYYY-MM-DD").format("MMMM");
		month = month.charAt(0).toUpperCase() + month.slice(1); */
		/* var data = {
			selectedService: this.props.selectedService,
		  	selectedCategory: 3,
		  	selectedType: 118,
		  	formValues: {n1: month} 
		};*/
		
		this.props.setSolicitudRequerimientoPreloadData(this.state.dataObj, () => {
			this.props.redirect("/mis-servicios/medidor/requerimientos");
		});
	}
	getFormPreloadData() {
		let crmId = "C321";
		meterBussinesLineFormIds(crmId, (err,res) => {
			if (err) {
				console.log(err)
			}
			if (res && res.status === "ok") {
				let info = res.data;
				let formDataObj = {
					selectedService: this.props.selectedService, 
					selectedCategory: parseInt(info.request_category_id),
					selectedType:  parseInt(info.id)};
				this.setState({dataObj: formDataObj})
			}
		})
	}
	getList(cb) {
		this.setState({formErrors: null, loading: true});
		
		getReadingsGraph(this.props.selectedService != null ? Number(this.props.selectedService.contract_cod).toString() : Number(this.props.selectedMeter.contract_cod).toString(), (error, result) => {
			
			if(error) {
				console.log("error")
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
				//return console.log("ocurrio un error con el request");
			}
			if(result.status === "error") {
				console.log("error status")
				if(cb) cb();
				this.setState({loading: false, formErrors: result.errors});
			}
			if(result.status === "empty") {
				console.log("empty")
				if(cb) cb();
				return this.setState({loading: false, graphMessage: result.data});
			}
			requestForm(118, this.props.selectedService.client_id, null, (error, resultForm) => {
				
				if(error) {
					this.setState({loading: false});
					if(cb) cb(error);
					return console.log("ocurrio un error con el request", error);
				}
				var showSolicitar = true;
				if(resultForm.status === 'error' && resultForm.message === 'Ya cuentas con una solicitud de este tipo que está en curso. Puedes revisarla en la sección "Mis requerimientos"') {
					showSolicitar = false;
				}
				this.setState({
					showSolicitar,
					graphData: {...graphBase,
						labels: result.data.map((month) => month.reading_month.split("-").map((date_part, i) =>i===1?monthNumberToShortName(date_part):date_part).reverse()),
						datasets: [{...graphBase.datasets[0], data: result.data.map((month) => month.reading_consumption)}]
					}
				}, () => {
					
					var start = moment(this.state.from).format("YYYY-MM-DD");
					var end = moment(this.state.to).format("YYYY-MM-DD");

					this.getReadings(start, end, () => {
						this.setState({loading: false});


					});
				});
			});			
		});
	}
	filter() {
		if(this.state.from && this.state.to){
			this.getList();
		}else{
			this.setState({lecturas: null, lecturasMessage: 'No existen registros de lectura'});
		}
	}
	getReadings(from, to, cb) {
		
		if(!from || !to) {
			cb();
			return alert("Debes seleccionar Fecha de inicio y de Término");
		}
		getReadings(moment(from).startOf('month').format("YYYY-MM-DD"), moment(to).endOf('month').format("YYYY-MM-DD"), this.props.selectedService != null ? Number(this.props.selectedService.contract_cod).toString() : Number(this.props.selectedMeter.contract_cod).toString(), (error, result) => {
			if(error) {
				cb(error);
				return console.log(error);
				//return console.log("ocurrio un error con el request");
			}
			if(result.status === "error") {
				cb();
				this.setState({formErrors: result.errors});
			}
			if(result.status === "empty") {
				cb();
				this.setState({lecturas: null});
				return this.setState({times: 1, lecturasMessage: this.state.times > 0 ? result.data : "No existen registros de lectura"});
			}
			this.setState({lecturas: result.data}, cb?cb:null);
		});
	}
	render() {
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
						<h1>Historial de Lectura</h1>
						<div className="client-number">
							<ClientSelectMedidor 
								selected={this.props.selectedService} 
								services={this.props.services}
								setSelectedService={this.props.setSelectedService} />	
						</div>
					</div>
					<div className="col-100 left d-flex justify-between margin-bottom-40">
						<div className="filter-container grid-column-3">
							<div className="position-relative">
								<img className="calendar" src={calendar} alt="calendario" />
								<DatePicker showMonthYearPicker popperModifiers={{flip: {enabled: false}}} maxDate={this.state.to} dateFormat="MM/yyyy" placeholderText="Desde" selected={this.state.from} locale="es" onChange={(date) => {this.setState({from: date})}} />
							</div>
							<div className="position-relative">
								<img className="calendar" src={calendar} alt="calendario" />
								<DatePicker showMonthYearPicker popperModifiers={{flip: {enabled: false}}} minDate={this.state.from} maxDate={new Date()} dateFormat="MM/yyyy" placeholderText="Hasta" selected={this.state.to} locale="es" onChange={(date) => {this.setState({to: date})}} />
							</div>
							<button className="button orange" onClick={() => {this.filter()}}>Filtrar</button>
						</div>
					</div>
					<div className="table-container">
						<table className="table1">
							<thead>
								<tr>
									<td>Fecha Lectura</td>
									<td>Lectura Obtenida</td>
									<td>Consumo m3</td>
									<td>Estado</td>
									{false && this.state.showSolicitar?<td>Registrar Lectura</td>:null}
								</tr>
							</thead>
							<tbody>
								{!this.state.lecturas?null:this.state.lecturas.sort((a,b) => Date.parse(b.reading_date) - Date.parse(a.reading_date)).map((lectura, i) => {
									return(
								<tr key={'lectura-' + i}>
									<td>{lectura.reading_date.length === 10? moment(lectura.reading_date, "YYYY-MM-DD").format("DD-MM-YYYY") : moment(lectura.reading_date, "YYYY-MM").format("MM-YYYY")}</td>
									<td>{!Number.isNaN(parseInt(lectura.reading_value)) ? lectura.reading_value : ''}</td>
									<td>{!Number.isNaN(parseInt(lectura.reading_consumption)) ? parseInt(lectura.reading_consumption).toLocaleString("de") : ''}</td>
									{<td>{lectura.reading_status}</td>}
									{false && this.state.showSolicitar?<td>
										{!lectura.reading_can_update?null:<div className="linkA-container">
											<Link to={"#"} onClick={() => this.solicitarLectura(lectura)}>Solicitar</Link>
										</div>}
									</td>:null}
								</tr>
										);

								})}
							</tbody>
						</table>
						{!this.state.lecturas?<p>{this.state.lecturasMessage}</p>:null}
					</div>
					<div className="d-flex margin-top-40 margin-bottom-20">
						<div className="col-60">
							<h2 className="asociar-servicios-sugerencias">GRÁFICO DE CONSUMO ÚLTIMOS 13 MESES</h2>
						</div>

						<div className="graph-labels col-40">
							<div className="graph-label">
								<div className="graph-label-square graph-lines-green">
								</div>
								<div className="graph-label-text">
									<p>Mismo Mes Año Anterior</p>
								</div>

							</div>
							<div className="graph-label">
								<div className="graph-label-square graph-lines-orange">
								</div>
								<div className="graph-label-text">
									<p>Mes anterior</p>
								</div>
							</div>
							<div className="graph-label">
								<div className="graph-label-square green">
								</div>
								<div className="graph-label-text">
									<p>Mes actual</p>
								</div>
							</div>
							<div className="graph-label">
								<div className="graph-label-square orange">
								</div>
								<div className="graph-label-text">
									<p>Otros meses</p>
								</div>
							</div>
						</div>
					</div>
					{!this.state.graphData?<p>{this.state.graphMessage}</p>:
					this.state.reloadingGraph?null:<Bar
						data={this.state.graphData}
						width={100}
						height={this.props.graphHeight}
						options={{
							plugins: {
								datalabels: {
									clamp: true,
									anchor: "end",
									align: "top",
									offset: -4,
									display: true,
									color: 'black',

								}

							},
							maintainAspectRatio: true,
							legend: {
								display: false
							},
							tooltips: {
								enabled: false
							},
							scales: {
								xAxes: [{
									ticks: {
										/*callback: function(value, index, values) {
											return value;
										}*/
									},
									barPercentage: 0.5,
									gridLines: {
										color: "rgba(0,0,0,0)"
									},
									scaleLabel: {
										display: true,
										labelString: 'Meses'
									}
								}],
								yAxes: [{
									ticks: {
										maxTicksLimit: 5
									},
									scaleLabel: {
										display: true,
										labelString: 'M3'
									}
								}]
							}
						}}
					/>}
			</ContentBox>
		);
	}
}