import React, { Component } from 'react';
import {getResumenTanques} from '../api.js';
import ContentBox from './ContentBox.js';
import ClientSelectGranel from './ClientSelectGranel';


export default class ServiciosIndustrialResumenTanques extends Component {
	constructor(props) {
		super(props);
		this.state = {
			centrales: null,
			loading: true
		};
	}
	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();		
	}
	componentDidUpdate(prevProps) {
		if(prevProps.selectedService !== this.props.selectedService) {
			this.getList();
			this.setState({loading: true});
		}
	}
	getList(cb) {
		this.setState({centrales: null, pedidosMessage: ""});
		var orderNumber = null;
		var filterDays = null;
		if(this.state.searchType === "order_number") {
			orderNumber = this.state.searchPedido;
		}
		if(this.state.searchType === "days") {
			filterDays = this.state.filterDays;
		}
		getResumenTanques(this.props.selectedService.client_cod, (error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
			}
			if(result.status === "error") {
				if(cb) cb();
				this.setState({loading: false});
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({loading: false, pedidosMessage: result.message});
			}
			this.setState({centrales: result.data, loading: false});
		}, this.props.isEmpresa?"empresas":null);
	}
	filter() {
		this.getList();
	}
	toggleCentralSelected(selected) {
		if(selected === this.state.central_selected) this.setState({central_selected: null});
		else this.setState({central_selected: selected});
	}
	toggleSubCentralSelected(selected) {
		if(selected === this.state.subCentral_selected) this.setState({subCentral_selected: null});
		else this.setState({subCentral_selected: selected});
	}
	renderCentrales() {
		var existWithoutUt = false;
		var centrales = !this.state.centrales?null:this.state.centrales.map((central, i) => {
			if(central.type === '4'){
				return(
					<div key={central.central_id} className={"clientes-habilitados-empresa-container resumen-tanques" + (this.state.central_selected === central.central_id? " selected":"")}>
						<div className="clientes-habilitados-empresa-title resumen-tanques" onClick={() => this.toggleCentralSelected(central.central_id)}>
							<p className="text-align-center color-white uppercase margin-right-40"><strong>UBICACIÓN TÉCNICA: {central.central_id}</strong></p>
							<p className="text-align-center color-white uppercase margin-right-40">{central.address}, {central.city}, {central.region_name} (Capacidad total: {central.total_capacity} litros)</p>
							
							<div className="clientes-habilitados-empresa-container-arrow"></div>

						</div>
						<div className={"table-container table-resumen-tanque" + (this.state.central_selected ===central.central_id? " selected":"")}>
							{!central.centrals?null:central.centrals.map((subCentral, x) => {
								return(
									<div key={subCentral.central_id} className={"clientes-habilitados-empresa-container inner-table resumen-tanques" + (this.state.subCentral_selected === subCentral.central_id? " selected":"")}>
										<div className="clientes-habilitados-empresa-title-2 resumen-tanques" onClick={() => this.toggleSubCentralSelected(subCentral.central_id)}>
											<p className="text-align-center uppercase margin-right-40"><strong>CENTRAL {subCentral.central_id}</strong></p>
											<p className="text-align-center uppercase margin-right-40">{subCentral.address}, {subCentral.city}, {subCentral.region_name} (Capacidad total: {subCentral.total_capacity} litros)</p>
										<div className="clientes-habilitados-empresa-container-arrow-2"></div>

										</div>
										<div className={"table-container-2" + (this.state.subCentral_selected === subCentral.central_id? " selected":"")}>
											<table className="table2">
												<thead>
													<tr>
														<td>ID de Tanque</td>
														<td>Nro. Serie</td>
														<td>Capacidad (litros)</td>
													</tr>
												</thead>
												<tbody>
												{subCentral.tanks.map((tank, j) => {									
													return(
														<tr key={tank.tank_id}>
															<td>{parseInt(tank.tank_id)}</td>
															<td>{parseInt(tank.serial_number)}</td>
															<td>{tank.capacity}</td>									
														</tr>
													);
												})}
												</tbody>
											</table>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				);
			}else{
				existWithoutUt = true;
			}
		});
		var centralesSinUt = !existWithoutUt ? null:<div key="without-ut" className={"clientes-habilitados-empresa-container resumen-tanques" + (this.state.central_selected === 'without'? " selected":"")}>
			<div className="clientes-habilitados-empresa-title resumen-tanques" onClick={() => this.toggleCentralSelected('without')}>
				<p className="text-align-center color-white uppercase margin-right-40"><strong>Centrales sin UT asignada</strong></p>
				<div className="clientes-habilitados-empresa-container-arrow"></div>

			</div>
			<div className={"table-container table-resumen-tanque" + (this.state.central_selected === 'without'? " selected":"")}>
				{!this.state.centrales?null:this.state.centrales.map((central, i) => {
					if(central.type === '3'){
						return(
							<div key={central.central_id} className={"clientes-habilitados-empresa-container resumen-tanques" + (this.state.subCentral_selected === central.central_id? " selected":"")}>
								<div className="clientes-habilitados-empresa-title resumen-tanques" onClick={() => this.toggleSubCentralSelected(central.central_id)}>
									<p className="text-align-center color-white uppercase margin-right-40"><strong>{central.central_id}</strong> {central.address}, {central.city}, {central.region_name} (Capacidad total: {central.total_capacity} litros)</p>
									<div className="clientes-habilitados-empresa-container-arrow-2"></div>
								</div>
								<div className={"table-container" + (this.state.subCentral_selected === central.central_id? " selected":"")}>
									<table className="table1">
										<thead className="table-header-gray">
											<tr>
												<td>ID de Tanque</td>
												<td>Nro. Serie</td>
												<td>Capacidad (litros)</td>
											</tr>
										</thead>
										<tbody>
										{central.tanks.map((tank, j) => {									
											return(
												<tr key={tank.tank_id}>
													<td>{parseInt(tank.tank_id)}</td>
													<td>{parseInt(tank.serial_number)}</td>
													<td>{tank.capacity}</td>									
												</tr>
											);
										})}
										</tbody>
									</table>
								</div>
							</div>
						);
					}
				})}
			</div>
		</div>;
		return(
			<div className="margin-top-30">{centrales}{centralesSinUt}</div>
		)
	}
	render() {
		return (
			<ContentBox loading={this.state.loading === null ? true : this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Resumen de Tanques</h1>
					<div className="client-number">
						<ClientSelectGranel
							selected={this.props.selectedService}
							services={this.props.services}
							setSelectedService={this.props.setSelectedService}
							number_field={"client_cod"} />
					</div>
				</div>
				<div className="table-container table-resumen-tanque">
					{this.renderCentrales()}
					{!this.state.pedidos?<p>{this.state.pedidosMessage}</p>:null}
				</div>
			</ContentBox>
		);
	}
}
