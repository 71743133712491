import React, { Component } from 'react';
import Close from '../img/close.svg';

export default class PopupDireccionesCotizacion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: []
		};
	}	
	componentDidUpdate(prevProps) {
		return;
		if(prevProps.show !== this.props.show) {
			this.setState({selected: this.props.client_privileges?[...this.props.client_privileges]:[]});	
		}
	}
	cancel() {
		this.props.cancel();
	}
	toggleOption(option) {
		var selected = [...this.state.selected];
		var i = selected.indexOf(option);
		if(i === -1) {
			selected.push(option);
		} else {
			selected.splice(i, 1);
		}
		this.setState({selected});
	}
	
	render() {
		return (
			<div className={"full-screen-veil " + (this.props.show ? 'show-modal' : '')}>
				<div className="modal1 modal-privilegios">
					<div className="content col-100 left clearfix">
	      				<h1 className="fw700">Direcciones</h1>
	      			</div>
	      			{!this.props.addresses?null:this.props.addresses.map((a)=> {
	      				return (<p onClick={() => {this.props.onSelect(a)}}>{a}</p>);
	      			})}
					


									
					<div className="solicitud-evaluacion-close-container" onClick={() => this.cancel()}>
						<img alt="" src={Close} />
					</div>

				</div>
			</div>
		);
	}
}