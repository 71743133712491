import React, { useEffect, useState, useRef } from 'react'
import ContentBox from './ContentBox.js';
import DatePicker from 'react-datepicker';
import calendar from '../img/calendar.svg';
import ServicioAutogasSelectorPatente from "./ServicioAutogasSelectorPatente"
import { autogasGetConsumoMensual, autogasGetConsumoRango } from "../api"
import Paginator from "./Paginator"
import moment from "moment"

export default function ServiciosAutogasConsumo({patents, setSelectedPatenteAutogas, selectedPatenteAutogas}) {
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [fechaDesde, setFechaDesde] = useState(moment().startOf('month')._d)
    const [fechaDesdeString, setFechaDesdeString] = useState(moment().startOf('month').format("YYYY-MM-DD"))
    const [fechaHasta, setFechaHasta] = useState(new Date())
    const [fechaHastaString, setFechaHastaString] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [acumulado, setAcumulado] = useState(false)
    const [tableData, setTableData] = useState(false)
    const [dataPage, setDataPage] = useState(1);
    const firstRender = useRef(false);
    const itemsPerPage = 10;

    useEffect(()=>{
        setLoading(true);
        if (selectedPatenteAutogas) {
            autogasGetConsumoMensual(selectedPatenteAutogas.patent, (err, res) => {
                if (err) {
                    console.log(err)
                    setLoading(false);
                } else if (res) {
                    if (res.status === "ok") {
                        setAcumulado(res.data.total_liters)
                    } else if (res.status === "empty") {
                        setAcumulado(false)
                    }
                    setLoading(false);
                }
            })
            filterDate(fechaDesdeString, fechaHastaString, selectedPatenteAutogas.patent)
        }
        
    }, [selectedPatenteAutogas])

    useEffect(() => {
        if (selectedPatenteAutogas) {
            if (!firstRender.current) {
                firstRender.current = true;
            } else {
                filterDate(fechaDesdeString, fechaHastaString, selectedPatenteAutogas.patent)                
            }
        }
    }, [filter])

    const handleFechaHasta = (date) => {
        setFechaHasta(date)
        setFechaHastaString(moment(date).format("YYYY-MM-DD"))
    }
    const handleFechaDesde = (date) => {
        setFechaDesde(date)
        setFechaDesdeString(moment(date).format("YYYY-MM-DD"))
    }

    const filterDate = (desde, hasta, patente) => {
        setLoading(true)
        autogasGetConsumoRango(desde, hasta, patente, (err, res) => {
            if (err) {
                console.log(err)
            } else if (res) {
                if (res.status === "empty") {
                    setEmpty(res.message)
                    setTableData([])
                    setLoading(false)
                } else if (res.status === "ok") {
                    setEmpty("")
                    let tableDataOrdered = res.data.sort((a,b) => (a.day < b.day) ? 1 : ((b.day < a.day) ? -1 : 0))
                    let doubleDataOrdered = tableDataOrdered.sort((a,b) => a.day === b.day && a.hour > b.hour ? -1 : 1)
                    setTableData(doubleDataOrdered)
                    setLoading(false)
                } else {
                    setEmpty(res.message)
                    setLoading(false)
                }
            }
        })
    }

    return (
        <ContentBox loading={loading}>
            <div className="title-client-container clearfix">
                <h1>Consumo</h1>
                <div className="client-number">
                    <ServicioAutogasSelectorPatente 
                        selectedPatenteAutogas={selectedPatenteAutogas} 
                        patents={patents}
                        setSelectedPatenteAutogas={setSelectedPatenteAutogas} />	
                </div>
            </div>
            <div className="pedido-status-container margin-bottom-40 col-100 left clearfix">
                <div className="message">
                    { acumulado ? 
                    <p>Consumo acumulado del mes en curso:&nbsp;&nbsp;&nbsp;<strong>{acumulado} Lts.</strong></p>
                    : 
                    <p>No se registran consumos en el mes en curso</p>}
                </div>
            </div>
            <div className="col-100 left d-flex justify-between margin-bottom-40">
                <div className="filter-container grid-column-3">
                    <div className="position-relative">
                        <img className="calendar" src={calendar} alt="calendario" />
                        <DatePicker popperModifiers={{flip: {enabled: false}}} maxDate={new Date()} dateFormat="dd/MM/yyyy" placeholderText="Desde" selected={fechaDesde} locale="es" onChange={(date) => {handleFechaDesde(date)}} />
                    </div>    
                    <div className="position-relative">
                        <img className="calendar" src={calendar} alt="calendario" />
                        <DatePicker popperModifiers={{flip: {enabled: false}}} maxDate={new Date()} dateFormat="dd/MM/yyyy" placeholderText="Hasta" selected={fechaHasta} locale="es" onChange={(date) => {handleFechaHasta(date)}} />
                    </div>
                    <button className="button orange" onClick={() => {setFilter(!filter)}}>Filtrar</button>                
                </div>
            </div>
            {tableData ? 
                <div className="table-container-2">
                    <table className="table1">
                        <thead>
                            <tr>
                                <td>Fecha de la venta</td>
                                <td>Hora de la venta</td>
                                <td>Litros consumidos</td>
                            </tr>							
                        </thead>
                        <tbody>
                            { tableData && tableData.length > 0 ? 
                                tableData.slice((dataPage - 1)*itemsPerPage, (dataPage - 1)*itemsPerPage + itemsPerPage).map((e, i) => {
                                    return(
                                        <tr key={i}>
                                            <td>{moment(e.day, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
                                            <td>{e.hour}</td>
                                            <td>{e.liters}</td>
                                        </tr>
                                    )
                                })
                            : null }
                        </tbody>
                    </table>
                    { empty ? <p style={{marginBottom: "107px"}}>{empty}</p> : null }
                </div>
            : <div style={{height: "360px", display: "block"}}></div>}
            {tableData && tableData.length > itemsPerPage?<Paginator page={dataPage} items={tableData?tableData.length:0} itemsPerPage={itemsPerPage} onPageChange={(page) => setDataPage(page)} />:null}
        </ContentBox>
    )
}
