import React, { Component } from 'react';
import Tooltip  from 'rc-tooltip';
import Close from '../img/close.svg';
import Star from '../img/star.svg';
import Button2 from './Button2';
import {evaluateRequest} from '../api.js';
export default class SolicitudEvaluacion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: [],
			evaluacion: null,
			comentario: ""
		}
	}
	sendForm() {
		evaluateRequest(this.props.request_id, this.state.evaluacion, this.state.comentario, (error, result) => {
			if(error) {
				console.log("error", error);
				return alert("Error en request");
			}
			if(result.status === "error") {
				console.log(result.errors);
				return this.setState({formErrors: result.errors});
			} else {
				this.props.close();
			}
			
		});
		
	}
	renderStars() {
		return [1,2,3,4,5].map((i) => {
			var selected = i <= this.state.evaluacion ? " selected" : "";
			return (<div 
				key={i} 
				className={"solicitud-evaluacion-star" + selected}
				onClick={()=> {this.setState({evaluacion: i, formErrors: null})}}>
				<img alt="" src={Star} />
			</div>);
		});		
	}
	deleteFormError(field_name) {
		var formErrors = {...this.state.formErrors};
		delete formErrors[field_name];
		this.setState({formErrors: formErrors})
	}
	render() {
		return (
			<div className="full-screen-veil show-modal">
				<div className="modal1">
					<h1 className="fw700">Califica tu experiencia</h1>
					<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors["calificacion"] ? true : false} destroyTooltipOnHide={true}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors["calificacion"] ? this.state.formErrors["calificacion"][0] : ""}</span>}>
					<div className="solicitud-evaluacion-stars-container">					
						{this.renderStars()}
					</div>
					</Tooltip>
					<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors["comentario"] ? true : false} destroyTooltipOnHide={true}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors["comentario"] ? this.state.formErrors["comentario"][0] : ""}</span>}>
					<div className="solicitud-evaluacion-textarea-container">
						<textarea value={this.state.comentario} onChange={(e) => {this.deleteFormError("comentario"); this.setState({comentario: e.target.value})}} placeholder="Describe tu experiencia"></textarea>
					</div>
					</Tooltip>
			 		<div className="solicitud-evaluacion-button-container">
						<Button2 label="Confirmar" className="orange" onClick={() => this.sendForm()} />
					</div>	
					<div className="solicitud-evaluacion-close-container" onClick={() => this.props.close()}>
						<img alt="" src={Close} />
					</div>
				</div>
			</div>
		);
	}
}
