import React, { Component, Fragment } from 'react';
import ContentBox from './ContentBox.js';
import ServiceListBox from './ServiceListBox.js';
import ModalConfirmation from './ModalConfirmation';
import Button2 from './Button2';
import {housingTelemetryList, postHousingNotificationConfig, getProfile} from '../api.js';

export default class ServiciosGranelTelemetria extends Component {
	constructor(props) {
		super(props);
		this.state = {
            housingServices: null,
            activationService: null,
            notification_status: this.props.user.telemetry_notification ? this.props.user.telemetry_notification_level : null,
            loading_notifications: false
		};
	}
	componentDidUpdate() {

	}
	componentDidMount() {
		this.loadProfile();
	}
	loadProfile(cb){
		getProfile(this.props.user.id, (error, result) => {			
			if(result){
				if(result.status === "ok") {
					var user = result.data[this.props.isEmpresas?"company_user":"person"];
					this.props.updateUser(user, () => {
						var notification_status = this.props.user.telemetry_notification ? this.props.user.telemetry_notification_level : null;
						this.setState({notification_status});
					});
					this.getList();
				}
			} 
		}, this.props.isEmpresas?"empresas":null);
	}
	getList(cb) {
		if(this.props.reloadServices) this.props.reloadServices();		
		this.setState({loading: true, housingServices: []});
		if(!this.props.isEmpresas) {
	        housingTelemetryList((error, result) => {
				if(error) {
					return console.log("ocurrio un error con el request");
				}
				var housingServices = null;
				if(result.status !== "empty") {
					housingServices = result.data;
	                this.setState({
	                    lecturasMessage: 'No existen clientes asociados'
	                });
				} else {
					housingServices = [];
				}
				this.setState({
					housingServices: housingServices,
	                loading: false,
				});
			});
		} else {
			this.setState({
				parentServices: true,
				loading: false
			});
		}
	}    
    goToTanques(service) {
    	this.props.redirect('/mis-servicios/granel/telemetria/' + service.client_cod + "/tanques");    	
    }
    serviceClick(service) {
    	if(!service.telemetry_activated || service.telemetry_activated === "0") this.setState({activationService: service});
    	else this.goToTanques(service);
    }
    cancelModal() {
    	this.setState({activationService: null});
    }    
    confirmModal() {
    	this.goToTanques(this.state.activationService);
    }
    notificationChecked(level) {
    	if(level === this.state.notification_status) this.setState({notification_status: null});
    	else this.setState({notification_status: level});

    }
    saveNotificationStatus() {
    	this.setState({loading_notifications: true});
    	var active = 0;
    	var level = 0;
    	if(this.state.notification_status !== null) {
    		active = 1;
    		level = parseInt(this.state.notification_status);
    	}
    	postHousingNotificationConfig(active, level, (error, result) => {
    		getProfile(this.props.user.id, (error, result) => {			
				if(result){
					if(result.status === "ok") {
						this.props.updateUser(result.data[this.props.isEmpresas?"company_user":"person"], () => {
	    					this.setState({loading_notifications: false});
							this.props.createActionNotification("", "Se ha guardado la configuración correctamente");
						});
					}
				}
			}, this.props.isEmpresas?"empresas":null);    	

    	}, this.props.isEmpresas?"empresas":null);	

    }
    renderServices(services) {
		return(
			<div className="list-boxes-container col-100 left clearfix">
                {!services?null:services.map((service) => {
					return(
					<ServiceListBox 
						displayNumberLabel={"client_cod"} 
						key={service.client_cod} 
						service={service} 
						type={!service.telemetry_activated || service.telemetry_activated === "0" ? "activar" : "detalle"} 
						onButtonClick={() => {this.serviceClick(service)}} />
					);
				})}					
			</div>
		); 
	}
	renderServicesEmpresas(services) {
		if(!services)return null;
		var groupedServices = [];
		services.forEach((s) => {
			var found = false;

			groupedServices.forEach((gs) => {
				if(gs.company_id === s.pivot.company_id) {
					gs.services.push(s);
					found = true;
				}
			});
			if(!found) {
				groupedServices.push({company_id: s.pivot.company_id, company_name: s.pivot.company_name, company_dni: s.pivot.company_dni, services: [s]});
			}
		});
		
		return groupedServices.map((gs) => {
			return(
				<Fragment key={gs.company_id}>
					<p className="fw700 font-18">{gs.company_name} (RUT: {gs.company_dni})</p>
					<div className="list-boxes-container col-100 left clearfix">
		                {gs.services.map((service) => {
							return(
							<ServiceListBox 
								displayNumberLabel={"client_cod"} 
								key={service.client_cod} 
								service={{...service, pivot: undefined}} 
								type={!service.telemetry_activated || service.telemetry_activated === "0" ? "activar" : "detalle"} 
								onButtonClick={() => {this.serviceClick(service)}} />
							);
						})}					
					</div>
				</Fragment>
			);
		});
	}
	render() {
		var show_notifications_config = false;
		show_notifications_config = true;

		if(this.state.housingServices) {
			this.state.housingServices.forEach((service) => {
				if(service.telemetry_activated && service.telemetry_activated !== "0") show_notifications_config = true;
			});
		}
		if(parseInt(this.props.user.notification_check) !== 1) show_notifications_config = false;
		var services = this.state.housingServices;
		if(this.state.parentServices && this.props.services) services = this.props.services.filter((s)=>parseInt(s.business_line_id) === 6);
		return (
			<Fragment>
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Telemetría</h1>
				</div>
				{this.state.loading?<div className="margin-bottom-100"></div>:null}
				{this.props.isEmpresas?this.renderServicesEmpresas(services):this.renderServices(services)}						
				<ModalConfirmation 
					cancel={this.cancelModal.bind(this)} 
					confirm={this.confirmModal.bind(this)} 
					title="¿ Seguro que desea activar la telemetría de este cliente ?" 
					show={this.state.activationService}/>

			</ContentBox>
			{show_notifications_config?<ContentBox className="margin-top-40" loading={this.state.loading_notifications}>
				<div className="title-client-container clearfix">
					<h1>Configuraciones de Telemetría</h1>					
				</div>
				<div className="col-100 left d-flex justify-between margin-bottom-40">
					<div className="filter-container grid-column-2">
						<label htmlFor="notification_check" className="checkbox-container" onClick={(e) => {this.notificationChecked(0)}}>
							<input type="checkbox" checked={parseInt(this.state.notification_status) === 0 ? true : false} readOnly />
							<span className="checkmark checkbox-with-border"></span>
							<span>Notificarme cuando mi tanque  esté bajo el 5%</span>
						</label>
						<label htmlFor="notification_check" className="checkbox-container" onClick={(e) => {this.notificationChecked(1)}}>
							<input type="checkbox" checked={parseInt(this.state.notification_status) === 1 ? true : false} readOnly />
							<span className="checkmark checkbox-with-border"></span>
							<span>Notificarme cuando mi tanque  esté bajo el 10%</span>
						</label>
						<label htmlFor="notification_check" className="checkbox-container" onClick={(e) => {this.notificationChecked(2)}}>
							<input type="checkbox" checked={parseInt(this.state.notification_status) === 2 ? true : false} readOnly />
							<span className="checkmark checkbox-with-border"></span>
							<span>Notificarme cuando mi tanque  esté bajo el 20%</span>
						</label>
						<label htmlFor="notification_check" className="checkbox-container" onClick={(e) => {this.notificationChecked(3)}}>
							<input type="checkbox" checked={parseInt(this.state.notification_status) === 3 ? true : false} readOnly />
							<span className="checkmark checkbox-with-border"></span>
							<span>Notificarme cuando mi tanque  esté bajo el 30%</span>
						</label>
						<label htmlFor="notification_check" className="checkbox-container" onClick={(e) => {this.notificationChecked(4)}}>
							<input type="checkbox" checked={parseInt(this.state.notification_status) === 4 ? true : false} readOnly />
							<span className="checkmark checkbox-with-border"></span>
							<span>Notificarme cuando mi tanque  esté bajo el 40%</span>
						</label>
						<label htmlFor="notification_check" className="checkbox-container" onClick={(e) => {this.notificationChecked(5)}}>
							<input type="checkbox" checked={parseInt(this.state.notification_status) === 5 ? true : false} readOnly />
							<span className="checkmark checkbox-with-border"></span>
							<span>Notificarme cuando mi tanque  esté bajo el 50%</span>
						</label>
					</div>
				</div>
        		<Button2 label="Guardar" className={"button orange text-align-center" +(parseInt(this.props.user.telemetry_notification_level) !== parseInt(this.state.notification_status)?"": " disabled")} onClick={() => this.props.user.telemetry_notification_level !== this.state.notification_status?this.saveNotificationStatus():null}/>
			</ContentBox>:null}
			</Fragment>
		);
	}
}
