import React, { useState, useEffect, useRef } from 'react'
import ContentBox from './ContentBox.js';
import ClientSelectCilindro from './ClientSelectCilindro';
import { getEnvasadoInstitucionalCuentaCorriente } from '../api.js';
import { Link, useLocation } from "react-router-dom";
import Paginator from "./Paginator"

export default function EmpresasEnvasadoIndustrialCuentaCorriente({services, selectedService, setSelectedService, reloadServices, isEmpresa}) {
    const [period, setPeriod] = useState('3');
    const [clientCod, setClientCod] = useState(false);
    const [documentos, setDocumentos] = useState(false);
    const [sinRegistros, setSinRegistros] = useState("");
    const verDocumentosInicial = useRef(true)
    const linkProps = useLocation();

    const [dataPage, setDataPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        if (clientCod && verDocumentosInicial.current) {            
            verDocumentosInicial.current = false;
            verDocumentos(clientCod, period) 
        }        
    }, [clientCod])

    useEffect(() => {
        if (linkProps.state) {
            setClientCod(linkProps.state.filterParams.clientCod)
            setPeriod(linkProps.state.filterParams.period)
            setDataPage(linkProps.state.filterParams.dataPage)
        } else if (selectedService != null) {
            setClientCod(selectedService.client_cod)
        }           
    }, [selectedService])

    const verDocumentos = (codigoCliente, periodo) => {
        getEnvasadoInstitucionalCuentaCorriente(codigoCliente, periodo, (error,result) => {
            if (error) {
                console.log(error)
            } if (result) {
                if (result.status == "empty" || result.state == "exception") {
                    setDocumentos(false)
                    setSinRegistros("No se han encontrado documentos en el período seleccionado.")
                } else if (result.status == "ok") {
                    setDocumentos(result.data)
                    setSinRegistros("")
                }
            }
        })
    }
    
    return (
        <ContentBox>
            <div className="title-client-container clearfix">
                <h1>Cuenta Corriente</h1>
                <div className="client-number">
                    <ClientSelectCilindro
                        selected={selectedService}
                        services={services}
                        setSelectedService={setSelectedService}
                        number_field={"client_cod"} />
                </div>                
            </div>
            <div className="col-100 left d-flex justify-between margin-bottom-40">
                <div className="filter-container grid-column-2">						
                    <div className="position-relative">
                        <select value={period} onChange={(e) => {setPeriod(e.target.value)}}>
                            <option value="3">3 meses</option>
                            <option value="6">6 meses</option>
                            <option value="9">9 meses</option>
                            <option value="12">12 meses</option>
                        </select>
                    </div>
                    <button className="button orange" onClick={() => {verDocumentos(clientCod, period)}}>Ver documentos</button>
                </div>
            </div>
            <div className="table-container">
                <table className="table1">
                    <thead>
                        <tr>
                            <td>Clase de Documento</td>
                            <td>N° de Documento</td>
                            <td>Fecha de Emisión</td>
                            <td>Clase de Vencimiento</td>
                            <td>Valor</td>
                            <td>Morosidad</td>
                            <td>Condición de Pago</td>
                            <td>Dirección Despacho</td>
                            <td>Ver Detalle</td>
                        </tr>
                    </thead>
                    <tbody>
                        {documentos?documentos.slice((dataPage - 1)*itemsPerPage, (dataPage - 1)*itemsPerPage + itemsPerPage).map((doc, index) => {
                            return (
                                <tr key={index}>
                                    <td>{doc.class_description}</td>
                                    <td>{doc.folio_number}</td>
                                    <td>{doc.issue_date}</td>
                                    <td>{doc.expiration_date}</td>
                                    <td>${parseInt(doc.value).toLocaleString("de")}</td>
                                    <td>{doc.late_payment > 0 ? doc.late_payment + " días": null}</td>
                                    <td>{doc.payment_condition_desc}</td>
                                    <td>{doc.address}</td>
                                    <td><Link to={{
                                            pathname: "/mis-servicios/cilindro/detalle-documento/"+doc.doc_number+"/"+doc.doc_year+"/"+clientCod, 
                                            state:{                                                
                                                docClientCod: clientCod,
                                                docNumber: doc.doc_number,
                                                docYear: doc.doc_year,
                                                filterParams: {clientCod, period, dataPage}
                                                    }
                                                }
                                        }>Ver Detalle</Link></td>
                                </tr>
                            )
                        }):null}                        
                    </tbody>
                </table>
                <p>{sinRegistros}</p>
            </div>
            {documentos && documentos.length > itemsPerPage?<Paginator page={dataPage} items={documentos?documentos.length:0} itemsPerPage={itemsPerPage} onPageChange={(page) => setDataPage(page)} />:null}
        </ContentBox>
    )
}
