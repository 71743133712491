import React, { Component } from 'react';
import ContentBox from './ContentBox.js';
import ClientSelectMedidor from './ClientSelectMedidor';
import Button2 from './Button2';
import {getDebtMeasurer} from '../api.js';
import {available, urls} from '../config.js';
import moment from 'moment';

export default class ServiciosMedidorDetalleDeuda extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bills: [],
			requestReposition: false,
			meterInstalled: false
		};
	}	
	componentDidMount() {
		if(this.props.selectedService) {		
			this.loadServices();
		}
	}	
	componentDidUpdate(prevProps) {
		if(prevProps.selectedService !== this.props.selectedService && this.props.selectedService) {
			this.loadServices();
				
		}
	}
	loadServices() {
		this.setState({loading: true});		
		this.handleDebt(() => {
			this.setState({loading: false});
		});
	}
	getDebt(contract_cod, cb){
		this.setState({bills: null, medidorDebtMessage: null})
		getDebtMeasurer(contract_cod, (error, result) => {
			if(cb) cb();
			if(result){
				if(result.status === "ok") {
					this.setState({
						bills: result.data,
					});
				}
				if(result.status === "exception") {
					this.setState({
						bills: null,
						medidorDebtMessage: result.message

					});
				}
			}else{
				this.setState({
					bills: null,
				});
			}
		});
	}
	handleDebt(cb){
		if(this.props.selectedService != null){			
			this.getDebt(this.props.selectedService.contract_cod, cb);
		}
	}
	renderWantToPay(){
		if(this.state.bills){
			let previous_amount = this.state.bills.previous_amount !== '' ? this.state.bills.previous_amount : 0;
			let amount = this.state.bills.amount !== '' ? this.state.bills.amount : 0;
			return(
				<div className="que-deseas-pagar">
					<h3 className="margin-bottom-10">Detalle de la deuda</h3>
					<p className="clearfix margin-bottom-30">Saldo Anterior: <strong>{previous_amount || previous_amount === 0?"$"+parseInt(previous_amount).toLocaleString("de"):""}</strong></p>
					<p className="clearfix margin-bottom-30">Deuda Vigente: <strong>{amount || amount === 0?"$"+parseInt(amount).toLocaleString("de"):""}</strong></p>
				</div>
			);
		}
	}
	paymentPortal(){
		let url = urls.portal_pago;
		url += 'medidor?codCliente=' + Number(this.props.selectedService.contract_cod).toString() + '&email=' + this.props.user.email + '&movil=' + this.props.user.phone + '&reposicion=' + this.state.requestReposition;
		window.open(url, '_blank');
  		window.focus();
	}
	renderPriceBill(){
		let price = this.state.bills.last_bill !== '' ? this.state.bills.last_bill : 0;
		let lastBill = this.props.getLastBill(this.state.bills.bills_documents);
		let bill_number = lastBill ? lastBill.bill_number : '';
		if(price === 0 || price === '0'){
			return(
				<div className="servicios-servicio-box-details-right">
						<div className="servicios-servicio-box-details-boleta-title margin-bottom-20">
							<p className="color-gray font-13 uppercase">Tu Última Boleta es por:</p>
						</div>
						<p className="text-green fw700">No tienes boletas pendientes de pago</p>
					</div>
			);
		}else{
			return(
				<div className="col-100 details-boleta-container">
					<div className="servicios-servicio-box-details-boleta-title">
						<p className="color-gray font-13 uppercase">Tu Última Boleta es por:</p>
					</div>
					<div className="servicios-servicio-box-details-price">
						<p>{price || price === 0?"$"+parseInt(price).toLocaleString("de"): ""}</p>
					</div>	
					<div className="servicios-servicio-box-details-boleta-num">
						<p className="uppercase bold">
							<span className="light font-14">N° de Boleta: </span>
							{bill_number}
						</p>
					</div>
					{/* <div className="servicios-servicio-box-details-boleta-num">
						{!available.medidor_detalle_deuda_solicitar_reposicion?null:<div>
							<input id="requestReposition" type="checkbox" checked={this.state.requestReposition ? true : false} onChange={(e) => {this.setState({requestReposition: !this.state.requestReposition, meterInstalled: false})}}/>
							<label htmlFor="requestReposition">Solicitar reposición de servicio	</label>
						</div>}
						{!this.state.requestReposition ? null :
							<div>
								<input id="meterInstalled" type="checkbox" checked={this.state.meterInstalled ? true : false} onChange={(e) => {this.setState({meterInstalled: !this.state.meterInstalled})}}/>
								<label htmlFor="meterInstalled">No tengo medidor instalado</label>
							</div>
						}
					</div> */}

					<Button2 label="Pagar Cuenta" className="" onClick={() => {this.paymentPortal()}}/>
				</div>
			);
		}
	}
	renderDetailBill(){
		let lastBill = this.props.getLastBill(this.state.bills.bills_documents);
		let bill_expire_date = lastBill ? lastBill.bill_expire_date : '';
		let bill_payment_day = lastBill ? lastBill.bill_payment_day : '';
		if(this.state.bills !== null){
			return(
				<div className="d-flex">
					<div className="col-40">
						<div className="margin-bottom-30">
							<p className="color-gray font-13">{bill_expire_date?"ÚLTIMA BOLETA VENCE":null}</p>
							<p className="fw700 color-black">{bill_expire_date}</p>
						</div>
						<div className="margin-bottom-30">
							<p className="color-gray font-13">{this.state.bills && this.state.bills.last_payment?"ÚLTIMO PAGO":null}</p>
							<p className="fw700 color-black">{this.state.bills&&this.state.bills.last_payment?moment(this.state.bills.last_payment, "DD-MM-YYYY").format("DD-MM-YYYY"):null}</p>
						</div>
						{bill_payment_day !== ''? 
							<div>
								<p className="color-gray font-13">ÚLTIMO PAGO</p>
								<p className="fw700 color-black">{bill_payment_day}</p>
							</div>
						:null}
						
					</div>
					{this.renderPriceBill()}
				</div>
			);
		}
	}
	renderContentBox(){
		if(this.state.bills !== null){
			return(
				<div className="d-flex col-100">
					<div className="col-40 previous-detail">
						{this.renderWantToPay()}
					</div>
					<div className="servicios-servicio-box-details">
						{this.renderDetailBill()}
					</div>
				</div>
			);
		}else{
			return(
				<div className="d-flex box-error-historial-lectura">
					<div className="box-white-last-bill col-100 text-center">
						<p className="text-green fw700 margin-bottom-10">{this.state.medidorDebtMessage?this.state.medidorDebtMessage:"En este momento no es posible obtener la información."}</p>
						<p className="text-green fw500 font-13 uppercase">{this.state.medidorDebtMessage?"":"Por favor intenta más tarde"}</p>
					</div>
				</div>
			);
		}
	}	
	render() {		
		return (
			<ContentBox className={"solicitudes-container container-deuda"} loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Detalle de Deuda</h1>
					<div className="client-number">
						<ClientSelectMedidor 
							selected={this.props.selectedService} 
							services={this.props.services}
							setSelectedService={this.props.setSelectedService} />	
					</div>
				</div>
				{this.renderContentBox()}
			</ContentBox>
		);
	}
}