import React, { useEffect } from 'react'
import ContentBox from "./ContentBox"
import { Link } from "react-router-dom"
import { autogasGetDashboard } from "../api"
import ServiceCardDetailAutogas from './ServiceCardDetailAutogas'
import ServiceCardDetailCilindro from "./ServiceCardDetailCilindro"

const Selector = (data, value, handler, title) => {
    if(data){
        if(data.length > 1){
            return(
                <div className="options-number-customer">
                    {title === "Autogas" ? <span>N° de Patente</span> : null}    
                    <select value={value && title === "Autogas" ? value.patent : value ? value.client_cod : '' } onChange={(e) => handler(e)}>
                        {data.map((service, i) =>
                            {
                                return(
                                    <option key={i} value={title === "Autogas" ? service.patent : service.client_cod}>
                                        {title === "Autogas" ? service.patent : "N° de Cliente " + Number(service.client_cod) }
                                    </option>
                                )
                            })}
                    </select>
                </div>
            );
        }else{
            return(
                <div className="box-border margin-top-40">
                    {title === "Autogas" ? <span>N° de Patente</span> : null} 
                    {title === "Autogas" ? data[0].patent : "N° de Cliente " + Number(data[0].client_cod)}
                </div>
            );
        }
    }
}

export default function ServiceCard({title, icon, loading, selectorData, selectorValue, handleSelect, links, setLoading }) {
   /*  useEffect(()=>{
        if (selectorValue && selectorValue.patent) {
            console.log(selectorValue)
            autogasGetDashboard(selectorValue.patent, (err, res) => {
                if (err) {
                    console.log("err ---->",err)
                } else if (res) {
                    console.log("-> Autogas", res)
                }
            })
        }
    }, [selectorValue]) */

    return (
        <ContentBox className="margin-top-40 margin-bottom-40 overflow-hidden" loading={loading}>
            <div className="servicios-servicio-box-leftside-2">
      			<img className="servicios-servicio-box-type-img" src={icon} alt="medidor" />
      			<div className="servicios-servicio-box-type">
      				<p>{title}</p>
  				</div>    
                          
				{Selector(selectorData, selectorValue, handleSelect, title)}                
			</div>
            <div className="servicios-servicio-box-links">
                {links && links.length > 0 ? 
                    links.map((link, i) => {
                        if (link.available) {
                            return(
                                <div key={i} className="linkA-container">
                                    <Link to={link.url}>{link.link}</Link>
                                </div>
                            )
                        }
                    })
                : null }
            </div>
            <div className="servicios-servicio-box-details">
                {!selectorValue ? null :
                    title === "Autogas" ? <ServiceCardDetailAutogas selectorValue={selectorValue} setLoading={(x) => setLoading(x)}/> : 
                    title === "Cilindro" ? <ServiceCardDetailCilindro selectorValue={selectorValue}/> : null
                }

            </div>
        </ContentBox>
    )
}
