import React, { useEffect, useState, useRef } from 'react'
import { getCampaigns } from "../api"
import {urls} from '../config.js';
import useDeviceDetect from "../libs/useDeviceDetect"
import ContentBoxSlider from "./ContentBoxSlider"
import "../libs/slick/slick.css"
import "../libs/slick/slick-theme.css"
import Slider from "react-slick";

export default function Campaigns({custom}) {
    let apiUrl = urls.backend;
    const { isMobile } = useDeviceDetect();
    const [device, setDevice] = useState("")
    const [totalCampaigns, setTotalCampaigns] = useState([])
    const [images, setImages] = useState([])
    const firstRender = useRef(false);

    useEffect(()=>{
        if (firstRender.current) {
            if (isMobile) {
                setDevice("mobile")
            } else {
                setDevice("desktop")
            }
        }            
        else {
            firstRender.current = true;
        }
    }, [isMobile])

    useEffect(()=>{        
        getCampaigns((err, res) => {
            if (err) {
                console.log(err)
            } 
            if (res) {
                if (res.data && res.data.campains) {
                    setTotalCampaigns(res.data.campains)
                }          
            }
        }, custom)
    }, [device])    

    useEffect(()=>{
        let imgArr = []
        if (totalCampaigns.length > 0) {
            totalCampaigns.map((c) => {
                imgArr = [...imgArr, {src: apiUrl+"/image/campain/"+device+ "/"+c.id, url: c.data.url_campain}]
            })
            setImages(imgArr)
        }
    }, [totalCampaigns])

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    return (
            images.length > 0 ? 
            <Slider {...settings} style={{marginBottom: "60px"}}>
                {images.map((img, i) => {
                    return (
                        <ContentBoxSlider key={i} className="overflow-hidden">
                            <a href={img.url} target="_blank"><img src={img.src} style={{width: "100%", fontSize: "0"}}/></a>
                        </ContentBoxSlider>
                    )
                }) }
            </Slider>
            :
            <React.Fragment></React.Fragment>
            
        
    )
}
