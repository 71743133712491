import React, { useState, useEffect } from 'react'
import { autogasGetDashboard, autogasGetNextMaintenance, autogasGetFutureMaintenance } from "../api"
import { Doughnut } from 'react-chartjs-2';
import Button2 from './Button2';
import moment from "moment"
import {Link} from "react-router-dom"

export default function ServiceCardDetailAutogas({selectorValue, setLoading}) {
    const [ liters, setLiters ] = useState([])
    const [ dashboardData, setDashboardData ] = useState(false)
    const [ futureMaintenance, setFutureMaintenance ] = useState(false)
    const [ nextMaintenance, setNextMaintenance ] = useState(false)
    const [ errorMessage, setErrorMessage] = useState(false)

    useEffect(()=>{
        if (selectorValue && selectorValue.patent) {
            setLoading(true)
            autogasGetDashboard(selectorValue.patent, (err, res) => {
                if (err) {
                    setLoading(false)
                } else if (res) {
                    let monthlyGoal = res.data.monthly_consumption_goal
                    let monthlyConsumption = res.data.monthly_consumption_current
                    setLiters([Number(monthlyConsumption), Math.max(0, Number(monthlyGoal - monthlyConsumption))])
                    setDashboardData(res.data)
                    setLoading(false)
                }
            })
            autogasGetNextMaintenance(selectorValue.patent, (err, res) => {
                if (err) {
                    setLoading(false)
                } else if (res) {
                    if (res.status === "empty") {
                        setNextMaintenance(false)
                        autogasGetFutureMaintenance(selectorValue.patent, (err, res) => {
                            if (err) {
                                console.log(err)
                            }
                            if (res) {
                                if (res.status === "empty") {
                                    setErrorMessage("No se cuenta con más información del plan de mantenimiento para este contrato")
                                    setFutureMaintenance(false)
                                } else {
                                    setFutureMaintenance(res.data[0])
                                    setErrorMessage(false)
                                }
                            }
                        })
                    } else if (res.status === "ok") {
                        setFutureMaintenance(false)
                        setNextMaintenance(res.data)
                        setErrorMessage(false)
                    } else if (res.status === "error") {
                        setFutureMaintenance(false)
                        setNextMaintenance(false)
                        setErrorMessage("No fue posible obtener la información, por favor intente nuevamente más tarde")
                    }
                    setLoading(false)
                }
            })
        }
    }, [selectorValue])

    let data = {
        labels: [
            'Litros Consumidos',
            'Litros Por Consumir'
        ],
        datasets: [{
            data: liters,
            backgroundColor: [
            '#fe5000',
            '#fff'            
            ],
            hoverBackgroundColor: [
            '#fe5000',
            '#fff' 
            ]
        }],
        options: {
            legend: {
                display: false
            }
          }
    };

    return (
        <React.Fragment>
            <div className="col-100 ">
                <div className="servicios-servicio-box-details-address">
                    <p>{dashboardData.vehicle_brand}</p>
                </div>
                {/* <div className="servicios-servicio-box-details-city-2">
                    <p>{dashboardData.vehicle_model}</p>
                </div> */}
            </div>     
            <div className="grid-column-2" style={{marginTop: "5px"}}>  
                {dashboardData && liters ?           
                <div className="grid-column-2 dashboard-hidden-desktop" style={{rowGap: "0px"}}>
                    <div className="box-doughnut">
                        <Doughnut data={data} options={data.options} width="100%" height="100%"/>
                    </div>
                    <div className="servicios-servicio-box-details-price" style={{alignSelf: "center"}}>
                        <div className="doughnut-text">
                            <p>{Math.round(dashboardData.monthly_consumption_percentage) + "%"}</p>
                        </div>
                        <div className="doughnut-text">
                            <div><span>{Math.round(dashboardData.monthly_consumption_current) + " Lts."}</span></div>
                        </div>                        
                    </div>
                    <div className="dashboard-detail">
                        <span>Meta Mensual: </span>
                    </div>   
                    <div className="dashboard-detail">
                        <h3>{Math.round(dashboardData.monthly_consumption_goal) + " lts."}</h3>
                    </div>   
                    <div style={{alignSelf: "end"}} className="dashboard-detail">
                        <span>Litros Disponibles Giftcard: </span>
                    </div>   
                    <div style={{alignSelf: "end"}} className="dashboard-detail">
                        <h3>{dashboardData.giftcard_liters + " lts."}</h3>
                    </div>   
                </div>
                : null} 
                <div className="grid-column-1">
                    { futureMaintenance ? 
                        <div className="servicios-servicio-box-details-city">  
                            <div className="dashboard-detail">
                                <span>Mantención Sugerida</span>
                                <h3>{futureMaintenance.text}</h3>
                            </div>    
                            <div className="dashboard-detail">
                                <span>Km Esperado Próxima Mantención</span>
                                <h3>{futureMaintenance.expected_km + " Km"}</h3>
                            </div>   
                            
                        </div>
                    : null }
                    { futureMaintenance ? 
                        <div style={{alignSelf: "end"}}>
                            <Link to={{pathname: "mis-servicios/autogas/agenda-mantenciones"}}><Button2 label="Agendar Mantención" className="button-border-orange"/></Link>
                        </div>
                    : null }         
                    { nextMaintenance ? 
                        <div className="servicios-servicio-box-details-city">
                            <div className="dashboard-detail">
                                <span>Mantención Agendada</span>
                                <h3>{nextMaintenance.description}</h3>
                            </div>    
                            <div className="dashboard-detail">
                                <span>Fecha</span>
                                <h3>{moment(nextMaintenance.scheduled_date, "YYYY-MM-DD").format("DD-MM-YYYY")}</h3>
                            </div>   
                            <div className="dashboard-detail">
                                <span>Hora</span>
                                <h3>{nextMaintenance.scheduled_time}</h3>
                            </div>  
                        </div>
                    : null }
                    { nextMaintenance ? 
                        <div style={{alignSelf: "end"}}>
                            <Link to={
                                 {pathname: "mis-servicios/autogas/mantenciones-asistencias/" + nextMaintenance.notice_id,
                                    state:{                                 
                                        selectedPatenteAutogas: selectorValue
                                    }
                                 }}><Button2 label="Anular Mantención" className="button-border-orange"/></Link>
                        </div>
                    : null }     
                    { errorMessage ? 
                    <p>
                        {errorMessage}
                    </p> 
                    : null }        
                </div>               
                {dashboardData && liters ?           
                <div className="grid-column-2 dashboard-hidden-mobile" style={{rowGap: "0px"}}>
                    <div className="box-doughnut">
                        <Doughnut data={data} options={data.options} width="100%" height="100%"/>
                    </div>
                    <div className="servicios-servicio-box-details-price" style={{alignSelf: "center"}}>
                        <div className="doughnut-text">
                            <p>{Math.round(dashboardData.monthly_consumption_percentage) + "%"}</p>
                        </div>
                        <div className="doughnut-text">
                            <span>{Math.round(dashboardData.monthly_consumption_current) + " Lts."}</span>
                        </div>                        
                    </div>
                    <div className="dashboard-detail">
                        <span>Meta Mensual: </span>
                    </div>   
                    <div className="dashboard-detail">
                        <h3>{Math.round(dashboardData.monthly_consumption_goal) + " lts."}</h3>
                    </div>   
                    <div style={{alignSelf: "end"}} className="dashboard-detail">
                        <span>Litros Disponibles Giftcard: </span>
                    </div>   
                    <div style={{alignSelf: "end"}} className="dashboard-detail">
                        <h4>{dashboardData.giftcard_liters + " lts."}</h4>
                    </div>   
                </div>
                : null} 
            </div>
        </React.Fragment>
    )
}
