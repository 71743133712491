import React, { Component, Fragment, } from 'react';
import {Link} from 'react-router-dom';
import BreadCrumb from './BreadCrumb';
import ContentBox from './ContentBox.js';
import Tooltip  from 'rc-tooltip';
import {adminEmpresasClientesList} from '../api.js';
import {formatRut} from '../utils.js';
import {funcionariosList, funcionarioDelete, funcionarioCanDelete} from '../api.js';
import ModalConfirmation from './ModalConfirmation';
export default class EmpresasFuncionarios extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
		searchType: "1",
		searchTerm: ""
	  };
	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		adminEmpresasClientesList((error, result)=> {
			if(error) {				
				this.setState({loading: false});
				return console.log(error);
			}
			this.setState({empresas: result.data, empresa_dni: result.data[0].dni});
			this.getList(0, undefined);
		});
	}

	getList(type, value, cb) {
		this.setState({loading: true, users: null, message: null});
		funcionariosList(type, type !== "4"?value:this.state.empresa_dni, (error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({loading: false, message: result.message});
			}
			this.setState({users: result.data, loading: false});
		});
	}
	filter() {
		if(this.state.searchType !== "4" && this.state.searchTerm.trim() === "") {
			return this.setState({formErrors: {value: ["Debe ingresar un término de búsqueda"]}});
		}
		var term = this.state.searchType !== "4"?this.state.searchTerm:this.state.empresa_dni;
		this.getList(this.state.searchType, term);
	}
	deleteFormError(field_name) {
		var formErrors = {...this.state.formErrors};
		delete formErrors[field_name];
		this.setState({formErrors: formErrors})
	}
	deleteFuncionario() {
		var id = this.state.deleteUser.id;
		this.setState({loading: true, deleteUser: null, canDeleteUser: null}, () => {
			funcionarioDelete(id, (error, result) => {
				this.getList(0, undefined);
			});

		});

	}
	openDeleteUser(user) {
		this.setState({deleteUser: user}, () => {
		funcionarioCanDelete(user.id, (error, result) => {
				this.setState({canDeleteUser: result});
			});

		});
	}
	render() {
		var breadCrumbOptions = [
			{title: "Mis Requerimientos"},
		];
		var searchTermField = this.state.searchType !=="4" ?
		<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors["value"] ? true : false} destroyTooltipOnHide={true}
		 	overlay={<span>{this.state.formErrors && this.state.formErrors["value"] ? this.state.formErrors["value"][0] : ""}</span>}>
			<input value={this.state.searchTerm} onChange={(e)=>{
				this.deleteFormError("value"); 
				this.setState({searchTerm: this.state.searchType!=="1"?e.target.value:formatRut(e.target.value)})}} 
				placeholder={null/*this.state.searchType*/} 
				style={{flex: 1, marginRight: "20px"}} />
		</Tooltip>:
		<select value={this.state.empresa_dni} onChange={(e) => {this.setState({empresa_dni: e.target.value})}}>
			{!this.state.empresas?null:this.state.empresas.map((empresa, i) => {
				return <option value={empresa.dni}>{empresa.name} ({empresa.dni})</option>
			})}
		</select>;

		return (
			<Fragment>
				<div className="section-title">
					<h1>Mis Funcionarios</h1>
					<BreadCrumb options={breadCrumbOptions} />
				</div>
				<ContentBox loading={this.state.loading}>
					<Link to={"funcionarios/nuevo"}>
						<button className="button green right margin-bottom-40">Crear Funcionario</button>
					</Link>
					<div className="col-100 left d-flex justify-between margin-bottom-40">
						<div className="filter-container grid-column-3">
							<div className="position-relative">
								<select value={this.state.searchType} onChange={(e) => {this.deleteFormError("value"); this.setState({searchType: e.target.value, searchTerm: ""})}}>
									<option value={1}>Rut</option>								
									<option value={2}>Email</option>
									<option value={3}>Nombre</option>
									<option value={4}>Empresa</option>
								</select>
							</div>
							{searchTermField}
							<button className="button orange" onClick={() => {this.filter()}}>BUSCAR</button>
						</div>
					</div>
					<div className="table-container">
						<table className="table1">
							<thead>
								<tr>
									<td>Nombre</td>
									<td>Rut</td>
									<td>Email</td>
									<td>Estado</td>
									<td></td>
									<td></td>									
								</tr>
							</thead>
							<tbody>
								{!this.state.users ? null : this.state.users.map((user, i) => {
									return(
								<tr key={user.id}>
									<td>{user.name} {user.last_name}</td>
									<td>{user.dni}</td>
									<td>{user.email}</td>
									<td><div className={"inline-block " + (user.status === "1"? "icon-habilitado": "icon-deshabilitado")}></div></td>
									<td>
										<div className="linkA-container">
											<Link to={"funcionarios/" + user.id}>Editar</Link>
										</div>
									</td>
									<td>
										{user.can_delete?
										<div className="linkA-container" onClick={() => {this.openDeleteUser(user)}}>
											<Link to="#">Eliminar</Link>
										</div>
										:null}
									</td>
								</tr>
									);

								})}
							</tbody>
						</table>
						{this.state.message?<p className="margin-top-30">{this.state.message}</p>:null}
						<ModalConfirmation 
							cancel={() => this.setState({deleteUser: null, canDeleteUser: null})} 
							confirm={this.state.canDeleteUser && this.state.canDeleteUser.status==="ok"?() => this.deleteFuncionario():null} 
							title={!this.state.canDeleteUser?"Cargando":this.state.canDeleteUser.message}
							show={this.state.deleteUser }/>
					</div>
				</ContentBox>
			</Fragment>
		);
	}
}