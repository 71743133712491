import React, { Component, Fragment } from 'react';
import CustomTabs from './CustomTabs';
import {Route, Switch, Redirect} from "react-router-dom";
import ServiciosGeneralRequerimientos from './ServiciosGeneralRequerimientos.js';
import ServiciosAutogasAgendaMantenciones from './ServiciosAutogasAgendaMantenciones.js';
import ServiciosAutogasMantencionesAsistencias from './ServiciosAutogasMantencionesAsistencias.js';
import ServiciosAutogasMantencionesAsistenciasDetalle from './ServiciosAutogasMantencionesAsistenciasDetalle.js';
import ServiciosAutogasContrato from './ServiciosAutogasContrato.js';
import ServiciosAutogasConsumo from './ServiciosAutogasConsumo.js';
import ServiciosAutogasMetas from './ServiciosAutogasMetas';
import {autogasBussinesLineList} from '../api.js';


export default class ServiciosAutogas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			solicitud_requerimiento_preload: null
		};
		this.tabs = [		
			{
				title: "Información Contrato",
				to: "/mis-servicios/autogas/contrato"
			},
			{
				title: "Consumo",
				to: "/mis-servicios/autogas/consumo"
			},
			{
				title: "Metas",
				to: "/mis-servicios/autogas/metas"
			},
			{
				title: "Mantenciones y Asistencias",
				to: "/mis-servicios/autogas/mantenciones-asistencias"
			},			
			/* {
				title: "Agenda Mantenciones",
				to: "/mis-servicios/autogas/agenda-mantenciones"
			}, */
			{
				title: "Requerimientos",
				to: "/mis-servicios/autogas/requerimientos"
			}
		];

	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadServices();		
	}
	loadServices() {
		autogasBussinesLineList((err, res) => {
			let autogasServices = []
			if(err) {
				console.log(err)
			} else {
				autogasServices = res.data
			}			
			this.setState({autogasServices}, () => {
				if(!this.props.selectedAutogas) this.props.setSelectedAutogas(autogasServices[0]);
				let autogasPatentes = []
				autogasServices.map(s => {
					return s.patents.map(p => {
						return autogasPatentes = [...autogasPatentes, 
							{patent: p.patent,
							 conversion_contract_number: p.conversion_contract_number,
							 client_cod: p.client_cod}]
					})
				})
				this.setState({autogasPatentes}, () => {
					if(this.props.selectedPatenteAutogas === null) this.props.setSelectedPatenteAutogas(autogasPatentes[0]);
					this.setState({loadingAutogas: false});
				})
			})
		})
	}
	setSolicitudRequerimientoPreloadData(data, cb) {
		this.setState({solicitud_requerimiento_preload: data}, cb);
	}
	render() {		
		return (
			<Fragment>
				<div className="section-title">
					<h1>Autogas</h1>
				</div>
				<CustomTabs tabs={this.tabs.filter((tab) => {
					//if(!available.medidor_requerimientos && tab.title === "Requerimientos") return false;
					return true;
				})} location={this.props.location} />		
        		<Switch>				
					<Route exact path="/mis-servicios/autogas/contrato">
						<ServiciosAutogasContrato
	                		business_line={"autogas"}
                  			checkMenuOptions={this.props.checkMenuOptions} 
							patents={this.state.autogasPatentes} 
							setSelectedPatenteAutogas={this.props.setSelectedPatenteAutogas.bind(this)}
	                  	  	selectedPatenteAutogas={this.props.selectedPatenteAutogas} />
					</Route>
					<Route exact path="/mis-servicios/autogas/requerimientos">
	                	<ServiciosGeneralRequerimientos
	                		solicitud_requerimiento_preload={this.state.solicitud_requerimiento_preload}
	                		setSolicitudRequerimientoPreloadData={this.setSolicitudRequerimientoPreloadData.bind(this)}
							business_line={"autogas"}
							selectedPatenteAutogas={this.props.selectedPatenteAutogas}
							setSelectedPatenteAutogas={this.props.setSelectedPatenteAutogas.bind(this)}
                  			checkMenuOptions={this.props.checkMenuOptions} />	                		
		            </Route>
					<Route exact path="/mis-servicios/autogas/agenda-mantenciones">
						<ServiciosAutogasAgendaMantenciones
	                		business_line={"autogas"}
							checkMenuOptions={this.props.checkMenuOptions}
							patents={this.state.autogasPatentes} 
							setSelectedPatenteAutogas={this.props.setSelectedPatenteAutogas.bind(this)}
	                  	  	selectedPatenteAutogas={this.props.selectedPatenteAutogas} />
					</Route>
					<Route exact path="/mis-servicios/autogas/mantenciones-asistencias">
						<ServiciosAutogasMantencionesAsistencias
	                		business_line={"autogas"}
							checkMenuOptions={this.props.checkMenuOptions}
							patents={this.state.autogasPatentes} 
							setSelectedPatenteAutogas={this.props.setSelectedPatenteAutogas.bind(this)}
	                  	  	selectedPatenteAutogas={this.props.selectedPatenteAutogas} />
					</Route>
					<Route exact path="/mis-servicios/autogas/mantenciones-asistencias/:notice_id">
						<ServiciosAutogasMantencionesAsistenciasDetalle
	                		business_line={"autogas"}
							checkMenuOptions={this.props.checkMenuOptions} />
					</Route>					
					<Route exact path="/mis-servicios/autogas/consumo">
						<ServiciosAutogasConsumo
	                		business_line={"autogas"}
                  			checkMenuOptions={this.props.checkMenuOptions} 
							patents={this.state.autogasPatentes} 
							setSelectedPatenteAutogas={this.props.setSelectedPatenteAutogas.bind(this)}
	                  	  	selectedPatenteAutogas={this.props.selectedPatenteAutogas} />
					</Route>
					<Route exact path="/mis-servicios/autogas/metas">
						<ServiciosAutogasMetas
	                		business_line={"autogas"}
                  			checkMenuOptions={this.props.checkMenuOptions} 
							patents={this.state.autogasPatentes} 
							setSelectedPatenteAutogas={this.props.setSelectedPatenteAutogas.bind(this)}
	                  	  	selectedPatenteAutogas={this.props.selectedPatenteAutogas} />
					</Route>
		            <Route path="*">
		                <Redirect to={"/mis-servicios"} />
		          	</Route>
        		</Switch>
			</Fragment>
		);
	}
}