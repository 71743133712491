import React, { Component } from 'react';
import Close from '../img/close.svg';
import AyudaBoletaNumeroClienteImg from '../img/img-modal-nro-cliente.jpeg';
import AyudaBoletaNumeroSerieImg from '../img/img-modal-nro-serie.png';
export default class PopupInfoClientCode extends Component {
	cancel() {
		this.props.cancel();
	}
	
	render() {
		return (
			<div className={"full-screen-veil " + (this.props.show ? 'show-modal' : '')}>
				<div className="modal1" style={{maxHeight: "95vh"}}>
	      				<h1 className="fw700" style={{marginBottom: 0}}>{this.props.show==1?'Número de Cliente':'Número de Serie'}</h1>
					<div className="col-100 left clearfix" style={{overflowY: "initial"}}>
						<img alt="" src={this.props.show==1?AyudaBoletaNumeroClienteImg:AyudaBoletaNumeroSerieImg} style={{maxWidth: "100%", maxHeight:'70vh'}} />
						{this.props.show==1 &&
							<>
							<p>INGRESE CÓDIGO DE CLIENTE (SIN DÍGITO VERIFICADOR)</p>
							<p><strong>Ejemplo: 12560138</strong></p>
							</>
						}

					</div>
					<div className="solicitud-evaluacion-close-container" onClick={() => this.cancel()}>
						<img alt="" src={Close} />
					</div>
				</div>
			</div>
		);
	}
}
