import React, { Component, Fragment } from 'react';
import ContentBox from './ContentBox.js';
import {housingTankHistory} from '../api.js';
import moment from 'moment';
import 'moment/locale/es';
import DatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';
import { registerLocale } from  "react-datepicker";

import Paginator from './Paginator';

import calendar from '../img/calendar.svg';
import PopupTanqueGrafico from './PopupTanqueGrafico';

registerLocale('es', es);

export default class ServiciosGranelTelemetriaTanqueHistorial extends Component {
	constructor(props) {
		super(props);
		this.state = {
			housingServices: null,
			loading: false,
			from: moment().subtract(12, "months").toDate(),
			to: moment().toDate(),
			page: 1,
			show_graph: false
		};
	}
	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();
		
		//this.getList();
	}	
	getList(from, to, cb) {
		var start = moment(this.state.from).format("YYYY-MM-DD");
		var end = moment(this.state.to).format("YYYY-MM-DD");
		var start_last = moment(this.state.from).subtract(1, "years").format("YYYY-MM-DD");
		var end_last = moment(this.state.to).subtract(1, "years").format("YYYY-MM-DD");
        this.setState({loading: true, lecturas: null, lecturas_old: null});
        housingTankHistory(this.props.client_cod, this.props.tank_id, start, end, (error, result) => {            	        
			if(error) {
		        this.setState({loading: false});				
				return console.log("ocurrio un error con el request");
            }
            if(result.status === "error") {
		        this.setState({loading: false});            	
				return console.log("ocurrio un error con el request", result.message);                
            }
            housingTankHistory(this.props.client_cod, this.props.tank_id, start_last, end_last, (error, result2) => {            
		        this.setState({loading: false});
				if(error) {
					return console.log("ocurrio un error con el request");
	            }
	            if(result2.status === "error"){
					return console.log("ocurrio un error con el request", result2.message);                
	            }
	            this.setState({
	            	page: 1,
	                lecturas: result.data,
	                lecturas_old: result2.data,

				});
			}, this.props.isEmpresas?"empresas":null);
		}, this.props.isEmpresas?"empresas":null);
	}
	filter() {
		if(this.state.from && this.state.to) {
			this.getList();
		}else{
			this.setState({lecturas: null, lecturasMessage: 'No existen registros de lectura'});
		}
	}
	changePage(page) {
		this.setState({page});
	}
	renderTable() {
		return(
			<Fragment>
			<div className="table-container">
				<table className="table1">
					<thead>
						<tr>
							<td>Fecha de Lectura</td>
							<td>Porcentaje de llenado</td>
							<td>Días para tanque vacío</td>
							<td>Consumo diario</td>
						</tr>
					</thead>
					<tbody>
						{!this.state.lecturas?null:this.state.lecturas.slice((this.state.page - 1)*15, (this.state.page - 1)*15 + 15).map((lectura, i) => {
							return(
								<tr key={'lectura-' + ((this.state.page-1)*15 + i)}>
									<td>{moment(lectura.date, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
									<td>{lectura.percentage_level ? lectura.percentage_level + "%" : "no disponible"}</td>
									<td>{lectura.stock_days || lectura.stock_days === 0 ? lectura.stock_days : "no disponible"}</td>									
									<td>{lectura.daily_consumption || lectura.daily_consumption === 0 ? lectura.daily_consumption + " litros" : "no disponible"}</td>									
								</tr>);
						})}
					</tbody>
				</table>
				{!this.state.lecturas?<p>{this.state.lecturasMessage}</p>:null}
			</div>
			{this.state.lecturas && this.state.lecturas.length > 10?<Paginator page={this.state.page} items={this.state.lecturas?this.state.lecturas.length:0} itemsPerPage={15} onPageChange={(page) => this.changePage(page)} />:null}
			</Fragment>
		);
	}
	
	render() {
		return (
			<ContentBox loading={this.state.loading}>
				<div className="historial-telemetria">
					<div className="title-client-container clearfix">
						<h1>Telemetría</h1>					
					</div>
					<div className="col-100 left d-flex justify-between margin-bottom-40">
						<div className="filter-container grid-column-4">
							<div className="position-relative">
								<img className="calendar" src={calendar} alt="calendario" />
								<DatePicker popperModifiers={{flip: {enabled: false}}} maxDate={this.state.to} minDate={moment().subtract(12, "months").toDate()} dateFormat="dd/MM/yyyy" placeholderText="Desde" selected={this.state.from} locale="es" onChange={(date) => {this.setState({from: date})}} />
							</div>
							<div className="position-relative">
								<img className="calendar" src={calendar} alt="calendario" />
								<DatePicker popperModifiers={{flip: {enabled: false}}} minDate={this.state.from} maxDate={new Date()} dateFormat="dd/MM/yyyy" placeholderText="Hasta" selected={this.state.to} locale="es" onChange={(date) => {this.setState({to: date})}} />
							</div>
							<button className="button orange" onClick={() => {this.filter()}}>Filtrar</button>
							<button className={"button orange" + (!this.state.lecturas?" disabled":"")} onClick={() => !this.state.lecturas?null:this.setState({show_graph: true})}>Ver Gráfico</button>
						</div>
					</div>
					{this.renderTable()}
				</div>
				<PopupTanqueGrafico cancel={()=>this.setState({show_graph: false})} confirm={null} show={this.state.show_graph} lecturas={this.state.lecturas} lecturas_old={this.state.lecturas_old} startDate={this.state.from} endDate={this.state.to} />								
			</ContentBox>			
		);
	}
}
