import React, { Fragment, useEffect } from 'react'
import moment from "moment"

export default function ServiciosAutogasProximaMantencion({mantencionAgendada, setLoading}) {
    useEffect(() => {
        if (mantencionAgendada) {
            setTimeout(() => {
                setLoading(false)
            }, 600);
        }
    }, [mantencionAgendada])

    if (mantencionAgendada) {
        return (
            <Fragment>
                <div className="col-100 clearfix margin-bottom-10">
                    <h2 className="uppercase" style={{fontSize: "20px"}}>Tu próxima mantención</h2>
                </div>            
                <div className="subcontent-box grid-column-2-mantenciones-asistencias clearfix margin-bottom-30 z-index-0">
                    <div className="grid-column-type1-mantenciones-asistencias">
                        <div className="maintenance-detail">
                            <span>Nombre de mantención:</span>
                        </div>
                        <div className="maintenance-detail">
                            <p>{mantencionAgendada.description}</p>
                        </div>
                        <div className="maintenance-detail">
                            <span>Fecha y hora:</span>
                        </div>
                        <div className="maintenance-detail">
                            <p>{moment(mantencionAgendada.scheduled_date, "YYYY-MM-DD").format("DD-MM-YYYY") + " - " + mantencionAgendada.scheduled_time}</p>
                        </div>
                        <div className="maintenance-detail">
                            <span>Nombre y dirección del taller:</span>
                        </div>
                        <div className="maintenance-detail">
                            <p>{mantencionAgendada.workshop_name}</p>
                            <p>{mantencionAgendada.workshop_address}</p>
                        </div>
                    </div>
                    <div className="grid-column-type2-mantenciones-asistencias">
                        <div className="maintenance-detail">
                            <span>Valor de la mantención:</span>
                        </div>
                        <div className="maintenance-detail">
                            <p>${parseInt(mantencionAgendada.valor_mantencion_taller).toLocaleString("de")}</p>
                        </div>
                        <div className="maintenance-detail">
                            <span>Porcentaje de descuento:</span>
                        </div>
                        <div className="maintenance-detail">
                            <p>{mantencionAgendada.porcentaje_descuento}%</p>
                        </div>
                        <div className="maintenance-detail">
                            <span>Total a pagar:</span>
                        </div>
                        <div className="maintenance-detail">
                            <p><strong>*${parseInt(mantencionAgendada.valor_aplicando_descuento).toLocaleString("de")}</strong></p>
                        </div>
                    </div>
                    <div className="grid-item-full text-center">
                        <p className="popup-referencia">*Precio referencial con IVA incluidos. Sujeto a verificación en taller.</p>
                    </div>
                </div>
            </Fragment>
        ) 
    } else return null;
    
}
