import React, { Component, Fragment } from 'react';
import CustomTabs from './CustomTabs';
import {Route, Switch, Redirect} from "react-router-dom";
import ServiciosGranelPedidos from './ServiciosGranelPedidos';
import ServiciosGranelRealizarPedido from './ServiciosGranelRealizarPedido';
import ServiciosGeneralRequerimientos from './ServiciosGeneralRequerimientos';
import ServiciosGranelTelemetria from './ServiciosGranelTelemetria';
import ServiciosGranelTelemetriaTanques from './ServiciosGranelTelemetriaTanques';
import ServiciosGranelTelemetriaTanque from './ServiciosGranelTelemetriaTanque';
import ServiciosGranelTelemetriaTanqueHistorial from './ServiciosGranelTelemetriaTanqueHistorial';
import ServiciosGranelCuentaCorriente from './ServiciosGranelCuentaCorriente';
import ServiciosGranelCuentaCorrienteDocumento from './ServiciosGranelCuentaCorrienteDocumento';
import ServiciosGranelHistorialPagos from './ServiciosGranelHistorialPagos';
import ServiciosGranelDetalleDeuda from './ServiciosGranelDetalleDeuda';
import ServiciosGranelTarifas from './ServiciosGranelTarifas.js';
import ServiciosIndustrialResumenTanques from './ServiciosIndustrialResumenTanques';

import privilegios from '../privilegios_empresa.js';
import {available} from '../config.js';

export default class ServiciosGranel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			priv: []
		};
		this.tabs = [			
			{
				title: "Resumen de Tanques",
				to: "/mis-servicios/granel/resumen-tanques"
			},
            {
				title: "Realizar Pedidos",
				to: "/mis-servicios/granel/realizar-pedidos"
			},
			{
				title: "Historial de Pedidos",
				to: [
					"/mis-servicios/granel/pedidos",
					"/mis-servicios/granel/pedidos/*"
				]
            },
            {
				title: "Historial de Pagos",
				to: [
					"/mis-servicios/granel/historial-pagos",
				]
            },
            {
				title: "Detalle de Deuda",
				to: [
					"/mis-servicios/granel/detalle-deuda"
				]
            },
			{
				title: "Cuenta Corriente",
				to: [
					"/mis-servicios/granel/cuenta-corriente",
					"/mis-servicios/granel/cuenta-corriente/*"]
			},           
			{
				title: "Telemetría",
				to: [
					"/mis-servicios/granel/telemetria",
					"/mis-servicios/granel/telemetria/*/*",
					"/mis-servicios/granel/telemetria/*/tanques/*",
					"/mis-servicios/granel/telemetria/*/tanques/*/historial"
				]
            },
            {
				title: "Requerimientos",
				to: "/mis-servicios/granel/requerimientos"
			},
			{
				title: "Variación de Precio",
				to: "/mis-servicios/granel/variacion-precio"
            },
		];

	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		//var priv = this.props.selectedIndustrial?this.props.selectedIndustrial.pivot.privileges:[];
		//this.loadServices();		
	}

	componentWillUnmount() {
		console.log(this.props.selectedIndustrial?this.props.selectedIndustrial.pivot.privileges:[])
	}

	componentWillReceiveProps(newProps) {
		var prvlgs = this.props.selectedIndustrial?newProps.selectedIndustrial.pivot.privileges:[];
		if (this.state.priv.length === 0) {
			if (prvlgs.length > 0) {
				this.setState({priv: prvlgs})
			}
		}
	}

	
	/*loadServices() {
		housingBussinesLineList((error, result) => {
			if(error) {
				return console.log("ocurrio un error con el request");
			}
			var services = null;
			if(result.status !== "empty") {
				services = result.data;
			} else {
				services = [];
				this.props.changeHeaderClass("big");
			}
			this.setState({
				services,
			});
			if(!this.props.selectedHousing) this.props.setSelectedHousing(services[0]);

		});
	}*/

	render() {
			return (
				<Fragment>
					<div className="section-title">
						<h1>Granel Industrial</h1>
					</div>
					<CustomTabs tabs={this.tabs.filter((tab) => {			
						if (tab.title === "Resumen de Tanques" && (this.state.priv.indexOf(privilegios.granel_industrial_resumen_tanques)<0 || available.granel_industrial_resumen_tanques === false)) return false;		
						if (tab.title === "Historial de Pedidos" && (this.state.priv.indexOf(privilegios.granel_industrial_historial_pedidos)<0 || available.granel_industrial_pedidos === false)) return false;
						if (tab.title === "Detalle de Deuda" && (this.state.priv.indexOf(privilegios.granel_industrial_detalle_deuda)<0 || available.granel_industrial_detalle_deuda === false)) return false;
						if (tab.title === "Historial de Pagos" && (this.state.priv.indexOf(privilegios.granel_industrial_historial_pagos)<0 || available.granel_industrial_historial_pagos === false)) return false;
						if (tab.title === "Realizar Pedidos" && (this.state.priv.indexOf(privilegios.granel_industrial_realizar_pedidos)<0 || available.granel_industrial_realizar_pedido === false)) return false;
						if (tab.title === "Telemetría" && (this.state.priv.indexOf(privilegios.granel_industrial_telemetria)<0 || available.granel_industrial_telemetria === false)) return false;
						if (tab.title === "Requerimientos" && (this.state.priv.indexOf(privilegios.granel_industrial_requerimientos)<0 || available.granel_industrial_requerimientos === false)) return false;
						if (tab.title === "Cuenta Corriente" && (this.state.priv.indexOf(privilegios.granel_industrial_cuenta_corriente)<0 || available.granel_industrial_cuenta_corriente === false)) return false;
						if (tab.title === "Variación de Precio" && (this.state.priv.indexOf(privilegios.granel_industrial_tarifas)<0 || available.granel_industrial_tarifas === false)) return false;	
						return true;
					})} location={this.props.location} />	
					{ this.state.priv && this.state.priv.length > 1 ? <Switch>
						{this.state.priv.indexOf(privilegios.granel_industrial_resumen_tanques)<0?null:<Route exact path="/mis-servicios/granel/resumen-tanques">
							<ServiciosIndustrialResumenTanques 
								services={this.props.services?this.props.services.filter(s=>s.pivot.privileges.indexOf(privilegios.granel_industrial_resumen_tanques)>=0):null} 
								selectedService={this.props.selectedIndustrial}
								setSelectedService={this.props.setSelectedIndustrial}
								reloadServices={this.props.reloadServices}	                    
								isEmpresa={true} />
						</Route>}
						{this.state.priv && this.state.priv.indexOf(privilegios.granel_industrial_historial_pedidos)<0?null:<Route path="/mis-servicios/granel/pedidos">
							<ServiciosGranelPedidos 
								services={this.props.services.filter(s=>s.pivot.privileges.indexOf(privilegios.granel_industrial_historial_pedidos)>=0)} 
								selectedService={this.props.selectedIndustrial}
								setSelectedService={this.props.setSelectedIndustrial}
								reloadServices={this.props.reloadServices}	                    	                		
								isEmpresa={true} />
						</Route>}
						{this.state.priv.indexOf(privilegios.granel_industrial_detalle_deuda)<0?null:<Route exact path="/mis-servicios/granel/detalle-deuda">
							<ServiciosGranelDetalleDeuda 
								user={this.props.user}          		                		
								createActionNotification={this.props.createActionNotification}
								services={this.props.services.filter(s=>s.pivot.privileges.indexOf(privilegios.granel_industrial_detalle_deuda)>=0)} 
								selectedService={this.props.selectedIndustrial}
								setSelectedService={this.props.setSelectedIndustrial}
								reloadServices={this.props.reloadServices}	                    	                		
								isEmpresa={true} />
						</Route>}
						{this.state.priv.indexOf(privilegios.granel_industrial_historial_pagos)<0?null:<Route exact path="/mis-servicios/granel/historial-pagos">
							<ServiciosGranelHistorialPagos
								services={this.props.services.filter(s=>s.pivot.privileges.indexOf(privilegios.granel_industrial_historial_pagos)>=0)} 
								selectedService={this.props.selectedIndustrial}
								createActionNotification={this.props.createActionNotification}
								
								setSelectedService={this.props.setSelectedIndustrial}
								reloadServices={this.props.reloadServices}	                    	                		
								isEmpresa={true}  />
						</Route>}		            
						{this.state.priv.indexOf(privilegios.granel_industrial_realizar_pedidos)<0?null:<Route path="/mis-servicios/granel/realizar-pedidos">
							<ServiciosGranelRealizarPedido 
								isEmpresas={true} 
								services={this.props.services.filter(s=>s.pivot.privileges.indexOf(privilegios.granel_industrial_realizar_pedidos)>=0)}
								reloadServices={this.props.reloadServices}	                    	                		
								user={this.props.user} />
						</Route>}
						{this.state.priv.indexOf(privilegios.granel_industrial_requerimientos)<0?null:<Route exact path="/mis-servicios/granel/requerimientos">
							<ServiciosGeneralRequerimientos
								isEmpresas={true} 
								services={this.props.services.filter(s=>s.pivot.privileges.indexOf(privilegios.granel_industrial_requerimientos)>=0)}
								business_line={"granel"}
								reloadServices={this.props.reloadServices}	                    	                		
								  checkMenuOptions={this.props.checkMenuOptions} />	                		
						</Route>}
						{this.state.priv.indexOf(privilegios.granel_industrial_telemetria)<0?null:<Route exact path="/mis-servicios/granel/telemetria">
							<ServiciosGranelTelemetria
								isEmpresas={true} 
								redirect={this.props.redirect}
								user={this.props.user}
								getUser={this.props.getUser}
								services={this.props.services.filter(s=>s.pivot.privileges.indexOf(privilegios.granel_industrial_telemetria)>=0)} 
								createActionNotification={this.props.createActionNotification}	
								reloadServices={this.props.reloadServices}	                    	                								
								updateUser={this.props.updateUser}
							/>
						</Route>}
						{this.state.priv.indexOf(privilegios.granel_industrial_cuenta_corriente)<0?null:<Route exact path="/mis-servicios/granel/cuenta-corriente">
							<ServiciosGranelCuentaCorriente
								services={this.props.services.filter(s=>s.pivot.privileges.indexOf(privilegios.granel_industrial_cuenta_corriente)>=0)} 
								selectedService={this.props.selectedIndustrial}
								setSelectedService={this.props.setSelectedIndustrial}
								reloadServices={this.props.reloadServices}
								redirect_data={this.props.redirect_data}  
								isEmpresa={true} />
						</Route>}
	
	
						<Route exact path="/mis-servicios/granel/cuenta-corriente/:id/:doc_year/:client_cod"
							render={(data)=> 
							<ServiciosGranelCuentaCorrienteDocumento
								createActionNotification={this.props.createActionNotification}      					 
								doc_number={data.match.params.id}
								client_cod={data.match.params.client_cod}
								selectedService={this.props.selectedIndustrial}      
								isEmpresa={true}
								doc_year={data.match.params.doc_year} />}>
						</Route>
	
	
						{this.state.priv.indexOf(privilegios.granel_industrial_tarifas)<0?null:<Route exact path="/mis-servicios/granel/variacion-precio">
							<ServiciosGranelTarifas 
								services={this.props.services.filter(s=>s.pivot.privileges.indexOf(privilegios.granel_industrial_tarifas)>=0)} 
								selectedService={this.props.selectedIndustrial}
								setSelectedService={this.props.setSelectedIndustrial}
								reloadServices={this.props.reloadServices}	                    	                		
								isEmpresa={true}
						 />
						</Route>}
						{this.state.priv.indexOf(privilegios.granel_industrial_telemetria)<0?null:<Route exact path="/mis-servicios/granel/telemetria/:client_cod/tanques"
							  render={(data)=> 
							<ServiciosGranelTelemetriaTanques
								client_cod={data.match.params.client_cod}
								redirect={this.props.redirect}
								reloadServices={this.props.reloadServices}	                    							
								isEmpresas={true} 
	
							/>}/>}
						{this.state.priv.indexOf(privilegios.granel_industrial_telemetria)<0?null:<Route exact path="/mis-servicios/granel/telemetria/:client_cod/tanques/:tank_id"
							  render={(data)=> 
								<ServiciosGranelTelemetriaTanque 
									client_cod={data.match.params.client_cod}
									tank_id={data.match.params.tank_id}	  
									reloadServices={this.props.reloadServices}	                    	                			
									isEmpresas={true}               			
								/>
							}
						></Route>}
						{this.state.priv.indexOf(privilegios.granel_industrial_telemetria)<0?null:<Route exact path="/mis-servicios/granel/telemetria/:client_cod/tanques/:tank_id/historial"
							  render={(data)=> 
								<ServiciosGranelTelemetriaTanqueHistorial 
									client_cod={data.match.params.client_cod}
									tank_id={data.match.params.tank_id}
									reloadServices={this.props.reloadServices}	                    
									isEmpresas={true} 
									
								/>
							}
						></Route>}
						<Route path="*">
							<Redirect to={"/mis-servicios"} />
					  </Route>
					</Switch> : null}
				</Fragment>
			);		
	}
}
