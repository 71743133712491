import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { addressBussinesLineSearch } from '../api.js'; 


export default function AutocompleteInput({city, regionCode, onFocus, locked, setSelected}) {
    const [suggestions, setSuggestions] = useState([])
    const [value, setValue] = useState("")

    useEffect(() => {
        if (city) {
            setValue("")
        }
    }, [city])

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
      };

    const onSuggestionsFetchRequested = ({ value }) => {
        getSuggestions(value)
      };

    const getSuggestions = async(value) => {
    let fetchRes = await fetchSuggestions(value)
    setSuggestions(fetchRes)
    };

    const fetchSuggestions = (value) => {
        return new Promise(resolve => {
            addressBussinesLineSearch(city, value, regionCode, (err, res) => {
                if(err) {
                    console.log(err)
                }
                if (res && res.status === "ok") {
                    if (res.data.length === 1 && value.toUpperCase() === res.data[0].toUpperCase()) {
                        resolve([])
                    } else {
                        resolve(res.data)
                    }                    
                } else if (res && res.status === "empty") {
                    resolve([])
                }
            });
        })
    }

    function shouldRenderSuggestions(value, reason) {
        return value.trim().length > 2;
      }

    const onChange = (event, { newValue }) => {
        if (!locked) {
            setValue(newValue.toUpperCase())
            setSelected(newValue.toUpperCase())
        }        
      };

    const getSuggestionValue = suggestion => suggestion;

    const renderSuggestion = suggestion => (
        <p>
          {suggestion}
        </p>
      );

    const inputProps = {
        value: locked ? "" : value,
        placeholder: "Calle",
        onChange: onChange,
        onFocus: onFocus
      };

    return (
        <div className="predictive-search">
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                shouldRenderSuggestions={shouldRenderSuggestions}
                inputProps={inputProps}
                theme={theme}
            />
        </div> 
    )
}

const theme = {
    suggestionsContainerOpen: {
        backgroundColor: "white",
        position: "absolute",
        top: "42px",
        left: "0", 
        right: "0",
        border: "1px solid #AAA",
        borderRadius: "0 0 5px 5px"
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    suggestion: { 
        padding: "0 18px",
        fontSize: "13px",
        cursor: "pointer",
        transition:" all 0.2s ease",
    },
    suggestionHighlighted: {
        backgroundColor: '#ddd'
    },
    input: {
        border: "1px solid #AAAAAA"
    }
}