var prod = {
	login_facebook: false,
	login_google: true,
	login_empresas: true,
	empresas_tutorial: true,
	granel: true, //GENERAL
	cilindro: true,
	autogas: true,
	autogas_requerimientos: true,
	autogas_agenda_mantenciones: false,
	autogas_contrato: true,
	menu_mis_requerimientos: true,
	medidor_boletas: true,
	medidor_historial_lectura: true,
	medidor_historial_pagos: true,	
	medidor_requerimientos: true,
	medidor_tarifas: false,
	medidor_convenio_pago: true,
	medidor_detalle_deuda: true,
	medidor_simulador: false,
	granel_pedidos: true,
	granel_detalle_deuda: true,
	granel_detalle_deuda_operation: true,
	granel_historial_pago: true,
	granel_realizar_pedido: true,
	granel_telemetria: true,
	granel_telemetria_historial: true,
	granel_requerimientos: true,
	granel_cuenta_corriente: true,
	granel_tarifas: false,
	granel_industrial_resumen_tanques: false,
	granel_industrial_pedidos: true,
	granel_industrial_detalle_deuda: true,
	granel_industrial_detalle_deuda_operation: true,
	granel_industrial_historial_pago: true,
	granel_industrial_realizar_pedido: true,
	granel_industrial_telemetria: true,
	granel_industrial_telemetria_historial: true,
	granel_industrial_requerimientos: true,
	granel_industrial_cuenta_corriente: true,
	granel_industrial_tarifas: false,
	envasado_institucional_menu: false,
	envasado_institucional_resumen: false,
	envasado_institucional_cotizar_pedido: false,
	envasado_institucional_cotizaciones: false,
	envasado_institucional_cuenta_corriente: false,	
	dashboard_solicitar_reposicion: true,
	medidor_detalle_deuda_solicitar_reposicion: true,
	medidor_suscripcion: true,
	header_notifications: true,
	cilindro_realizar_pedidos: true,
	cilindro_precios: true,
	cilindro_descuentos: true,
	cilindro_pedidos: true,
	cilindro_requerimientos: true,
	dashboard_campaign: true
}

var test = {
	login_facebook: true,
	login_google: true,
	login_empresas: true,
	empresas_tutorial: true,
	granel: true, //GENERAL
	cilindro: true,
	autogas: true,
	autogas_requerimientos: true,
	autogas_agenda_mantenciones: true,
	autogas_contrato: true,
	menu_mis_requerimientos: true,
	medidor_boletas: true,
	medidor_historial_lectura: true,
	medidor_historial_pagos: true,	
	medidor_requerimientos: true,
	medidor_tarifas: false,
	medidor_convenio_pago: true,
	medidor_detalle_deuda: true,
	medidor_simulador: true,
	granel_pedidos: true,
	granel_detalle_deuda: true,
	granel_detalle_deuda_operation: true,
	granel_historial_pago: true,
	granel_realizar_pedido: true,
	granel_telemetria: true,
	granel_telemetria_historial: true,
	granel_requerimientos: true,
	granel_cuenta_corriente: true,
	granel_tarifas: false,	
	granel_industrial_resumen_tanques: true,
	granel_industrial_pedidos: true,
	granel_industrial_detalle_deuda: true,
	granel_industrial_detalle_deuda_operation: true,
	granel_industrial_historial_pago: true,
	granel_industrial_realizar_pedido: true,
	granel_industrial_telemetria: true,
	granel_industrial_telemetria_historial: true,
	granel_industrial_requerimientos: true,
	granel_industrial_cuenta_corriente: true,
	granel_industrial_tarifas: false,
	envasado_institucional_menu: true,
	envasado_institucional_resumen: true,
	envasado_institucional_cotizar_pedido: true,
	envasado_institucional_cotizaciones: true,
	envasado_institucional_cuenta_corriente: true,		
	dashboard_solicitar_reposicion: true,
	medidor_detalle_deuda_solicitar_reposicion: true,
	medidor_suscripcion: true,
	header_notifications: true,
	cilindro_realizar_pedidos: true,
	cilindro_precios: true,
	cilindro_descuentos: true,
	cilindro_pedidos: true,
	cilindro_requerimientos: true,
	dashboard_campaign: false
}

var prod_urls = {
	backend: "https://backsucursal.abastible.cl/api",
	backend_public: "https://backsucursal.abastible.cl/files",
	portal_pago: 'https://pagodecuenta.abastible.cl/pago_abastible/',
	portal_pedidos: 'https://pedidos.abastible.cl',
	registro_empresas_url:'https://docs.google.com/forms/d/e/1FAIpQLScq0HAzK2PG1a_U9eTZco1_ucQg7x75bYTSezsdfcYJpL7JMQ/viewform',
	track_pedidos: 'https://delivery.driv.in/tracking-historic?token=eyJpc28iOiJjbCIsInBvc2l0aW9uIjpbeyJsYXQiOi0zMy40MDk3NzMsImxuZyI6LTcwLjU3MTQxMTd9XSwibG9jYWxlIjoiZXMiLCJ0aW1lX3pvbmUiOiJTYW50aWFnbyIsInVuc3VzY3JpYmVkX29yZ2FuaXphdGlvbl9pZCI6bnVsbCwid2lkZ2V0X3ZlciI6MiwiZGF0YV9kaXNwbGF5Ijp7ImdlbmVyYWxfaW5mbyI6eyJvcmRlcl9jb2RlIjp0cnVlLCJhZGRyZXNzIjp0cnVlLCJjbGllbnRfbmFtZSI6dHJ1ZSwiY2xpZW50X2NvZGUiOnRydWUsImNvbnRhY3RfZW1haWwiOnRydWUsImV0YV9kZWxpdmVyeV90aW1lIjp0cnVlLCJiaWxsaW5nX2RhdGUiOnRydWUsImNvbW1lbnQiOmZhbHNlfSwib3JkZXJfaW5mbyI6eyJpdGVtc190YWJsZSI6dHJ1ZSwiaW1hZ2VzIjp0cnVlLCJwZGYiOnRydWV9LCJzdGVwX2luZm8iOnsiZHJpdmVyX25hbWUiOmZhbHNlLCJ2ZWhpY2xlX2NvZGUiOnRydWUsInN0ZXBfbG9jYXRpb24iOnRydWUsInN0ZXBfZGF0ZXRpbWUiOnRydWV9fSwiZGF0YSI6eyJhcGlfa2V5IjoiMjVlNTFiNDgtYWE3Ni00MjdmLWFiZjgtNGZmYzAwOGRmZDc0In19&code=',
}

var preprod_urls = {
	backend: "https://backsucursal-qa.abastible.cl/api",
	portal_pago: 'https://pagodecuenta.abastible.cl/pago_abastible/',
	portal_pedidos: 'https://pedidos.abastible.cl',
	backend_public: "https://backsucursal-qa.abastible.cl/files",
	registro_empresas_url:'https://docs.google.com/forms/d/e/1FAIpQLScq0HAzK2PG1a_U9eTZco1_ucQg7x75bYTSezsdfcYJpL7JMQ/viewform',
	track_pedidos: 'https://delivery-qa.driv.in/tracking-historic?token=eyJpc28iOiJjbCIsInBvc2l0aW9uIjpbeyJsYXQiOi0zMy40MDk3NzMsImxuZyI6LTcwLjU3MTQxMTd9XSwibG9jYWxlIjoiZXMiLCJ0aW1lX3pvbmUiOiJTYW50aWFnbyIsInVuc3VzY3JpYmVkX29yZ2FuaXphdGlvbl9pZCI6bnVsbCwid2lkZ2V0X3ZlciI6MiwiZGF0YV9kaXNwbGF5Ijp7ImdlbmVyYWxfaW5mbyI6eyJvcmRlcl9jb2RlIjp0cnVlLCJhZGRyZXNzIjp0cnVlLCJjbGllbnRfbmFtZSI6dHJ1ZSwiY2xpZW50X2NvZGUiOnRydWUsImNvbnRhY3RfZW1haWwiOnRydWUsImV0YV9kZWxpdmVyeV90aW1lIjp0cnVlLCJiaWxsaW5nX2RhdGUiOnRydWUsImNvbW1lbnQiOnRydWV9LCJvcmRlcl9pbmZvIjp7Iml0ZW1zX3RhYmxlIjp0cnVlLCJpbWFnZXMiOnRydWUsInBkZiI6dHJ1ZX0sInN0ZXBfaW5mbyI6eyJkcml2ZXJfbmFtZSI6dHJ1ZSwidmVoaWNsZV9jb2RlIjp0cnVlLCJzdGVwX2xvY2F0aW9uIjp0cnVlLCJzdGVwX2RhdGV0aW1lIjp0cnVlfX0sImRhdGEiOnsiYXBpX2tleSI6IjY4ZTI0MGU5LTUyM2QtNDg5YS1iMTUzLWE3YmM0YmY5MzM1YiJ9fQ==&code=',
}

var test_urls = {
	backend: "https://backsucursaltest.abastible.cl/api",
	portal_pago: 'https://pagodecuentatest.abastible.cl/pago_abastible/',
	portal_pedidos: 'https://testpedidos.abastible.cl',
	backend_public: "https://backsucursaltest.abastible.cl/files",
	registro_empresas_url:'https://docs.google.com/forms/d/e/1FAIpQLScq0HAzK2PG1a_U9eTZco1_ucQg7x75bYTSezsdfcYJpL7JMQ/viewform',
	track_pedidos: 'https://delivery-qa.driv.in/tracking-historic?token=eyJpc28iOiJjbCIsInBvc2l0aW9uIjpbeyJsYXQiOi0zMy40MDk3NzMsImxuZyI6LTcwLjU3MTQxMTd9XSwibG9jYWxlIjoiZXMiLCJ0aW1lX3pvbmUiOiJTYW50aWFnbyIsInVuc3VzY3JpYmVkX29yZ2FuaXphdGlvbl9pZCI6bnVsbCwid2lkZ2V0X3ZlciI6MiwiZGF0YV9kaXNwbGF5Ijp7ImdlbmVyYWxfaW5mbyI6eyJvcmRlcl9jb2RlIjp0cnVlLCJhZGRyZXNzIjp0cnVlLCJjbGllbnRfbmFtZSI6dHJ1ZSwiY2xpZW50X2NvZGUiOnRydWUsImNvbnRhY3RfZW1haWwiOnRydWUsImV0YV9kZWxpdmVyeV90aW1lIjp0cnVlLCJiaWxsaW5nX2RhdGUiOnRydWUsImNvbW1lbnQiOnRydWV9LCJvcmRlcl9pbmZvIjp7Iml0ZW1zX3RhYmxlIjp0cnVlLCJpbWFnZXMiOnRydWUsInBkZiI6dHJ1ZX0sInN0ZXBfaW5mbyI6eyJkcml2ZXJfbmFtZSI6dHJ1ZSwidmVoaWNsZV9jb2RlIjp0cnVlLCJzdGVwX2xvY2F0aW9uIjp0cnVlLCJzdGVwX2RhdGV0aW1lIjp0cnVlfX0sImRhdGEiOnsiYXBpX2tleSI6IjY4ZTI0MGU5LTUyM2QtNDg5YS1iMTUzLWE3YmM0YmY5MzM1YiJ9fQ==&code=',
}

var local_urls = {
	backend: "http://localhost/sucursal-virtual/public/api",
	portal_pago: 'https://pagodecuentatest.abastible.cl/pago_abastible/',
	portal_pedidos: 'http://abastible.wantlabs.cl',
	backend_public: "http://localhost/sucursal-virtual/public/api/files",
	registro_empresas_url:'https://docs.google.com/forms/d/e/1FAIpQLScq0HAzK2PG1a_U9eTZco1_ucQg7x75bYTSezsdfcYJpL7JMQ/viewform',
	track_pedidos: 'https://delivery-qa.driv.in/tracking-historic?token=eyJpc28iOiJjbCIsInBvc2l0aW9uIjpbeyJsYXQiOi0zMy40MDk3NzMsImxuZyI6LTcwLjU3MTQxMTd9XSwibG9jYWxlIjoiZXMiLCJ0aW1lX3pvbmUiOiJTYW50aWFnbyIsInVuc3VzY3JpYmVkX29yZ2FuaXphdGlvbl9pZCI6bnVsbCwid2lkZ2V0X3ZlciI6MiwiZGF0YV9kaXNwbGF5Ijp7ImdlbmVyYWxfaW5mbyI6eyJvcmRlcl9jb2RlIjp0cnVlLCJhZGRyZXNzIjp0cnVlLCJjbGllbnRfbmFtZSI6dHJ1ZSwiY2xpZW50X2NvZGUiOnRydWUsImNvbnRhY3RfZW1haWwiOnRydWUsImV0YV9kZWxpdmVyeV90aW1lIjp0cnVlLCJiaWxsaW5nX2RhdGUiOnRydWUsImNvbW1lbnQiOnRydWV9LCJvcmRlcl9pbmZvIjp7Iml0ZW1zX3RhYmxlIjp0cnVlLCJpbWFnZXMiOnRydWUsInBkZiI6dHJ1ZX0sInN0ZXBfaW5mbyI6eyJkcml2ZXJfbmFtZSI6dHJ1ZSwidmVoaWNsZV9jb2RlIjp0cnVlLCJzdGVwX2xvY2F0aW9uIjp0cnVlLCJzdGVwX2RhdGV0aW1lIjp0cnVlfX0sImRhdGEiOnsiYXBpX2tleSI6IjY4ZTI0MGU5LTUyM2QtNDg5YS1iMTUzLWE3YmM0YmY5MzM1YiJ9fQ==&code=',
}

var test_params = {
	insert_google_tm: false,
	facebook_app_id: 686755251758459,
	google_app_id: "938499213352-gj3ni80o7n77npaheugcbd2bh16k8snd.apps.googleusercontent.com"
}
var prod_params = {
	insert_google_tm: true,
	facebook_app_id: 225150401840980,
	google_app_id: "576514833496-n6egn1h4lcku83f8i3rms5t5g13dmbqm.apps.googleusercontent.com"

}

//export var available = test; export var urls = local_urls; export var params = test_params; //sucursal local & test (dev)
//export var available = prod; export var urls = test_urls; export var params = test_params; //sucursal prod build con test data (dev)
//export var available = prod; export var urls = preprod_urls; export var params = prod_params; //sucursal prod build copia
export var available = prod; export var urls = prod_urls; export var params = prod_params; //sucursal prod


//console.log("STAGE", process.env.REACT_APP_STAGE);

var prod_contact = {
	phone: '800 20 9000',
	calendar:[
		'Lun a Dom entre las 08:00 y las 21:00 hrs', // se puede agregar 1 linea más
	],
	whatsapp: '+56 2 2583 1156',
}


export var contact = prod_contact;