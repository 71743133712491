import React, { Component, Fragment } from 'react';
import ServiceListBox from './ServiceListBox.js';
import PredictiveStreetSearch from './PredictiveStreetSearch';
import AutocompleteInput from './AutocompleteInput'
import QuestionOrange from '../img/question_orange.svg';
import SolicitudAyudaImg from '../img/solicitud_ayuda_ok.png';
import Button1 from './Button1';
import {postSendHelpMail, getRegions,
	meterBussinesLineRecommended, meterBussinesLineAdd, meterBussinesLineSearch,
	housingBussinesLineRecommended, housingBussinesLineAdd, housingBussinesLineSearch,
	cilindroBussinesLineRecommended, cilindroBussinesLineAdd, cilindroBussinesLineSearch} from '../api.js';
import ContentBox from './ContentBox.js';
import Isotipo from '../img/isotipo.svg';
import PopupInfoClientCode from './PopupInfoClientCode.js';
import {formatRut} from '../utils.js';
import Tooltip from "rc-tooltip";

export default class AsociarServicios extends Component {
	constructor(props) {
	  	super(props);
	  	this.state = {
	  		search_message: "",
			helpBoxCollapse: false,
			helpBoxDone: false,
			id_person: this.props.user.id,			
			street: "",
			number: "",
			second_numbering: "",
			region: '',
			regions: [],
			comune: '',
			code_region: '',
		  	services: null,
		  	searchServices: null,
		  	searchType: "N° Cliente",
		  	searchTerm: "",
		  	loadingSearch: false,
		  	tabs: [
				/*{
					title: "Cilindro",
					to: "/adminitracion_cuentas/cilindro"
				},*/
				{
					title: "Granel Habitacional",
					to: "/adminitracion_cuentas/granel/asociar"
				},
				/*{
					title: "Autogas",
					to: "/adminitracion_cuentas/autogas"
				},*/
				{
					title: "Medidor",
					to: "/adminitracion_cuentas/medidor/asociar"
				}
			]
		};
		this.myRef = React.createRef();
	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadRecommended();
		this.loadRegions();
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.service !== this.props.service) {
			this.loadRecommended();

		}
		if (prevState.region !== this.state.region) {
			let regionCode = this.state.regions.find(x => x.id === Number(this.state.region))
			this.setState({code_region: regionCode.code})
		}
	}
	scrollToMyRef = () => {
		var top  = window.pageYOffset || document.documentElement.scrollTop
		 var i = 20;
		  var int = setInterval(() => {
		    window.scrollTo(0, top + i);
		    i += 20;
		    if (top + i >= this.myRef.current.offsetTop) clearInterval(int);
		  }, 10);
	}
	loadRecommended() {
		var getFunction = null;
		if(this.props.service === "medidor") getFunction = meterBussinesLineRecommended;
		if(this.props.service === "cilindro") {
			this.setState({searchType: 'Código Cliente'});
			getFunction = cilindroBussinesLineRecommended;	
		}	
		if(this.props.service === "granel") {
			this.setState({searchType: 'Rut'});
			getFunction = housingBussinesLineRecommended;
		}
		
		getFunction((error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			this.setState({services: result.data});
		});
	}
	loadRegions() {
		getRegions((error, result) => {
			if(error) {
				return console.log("ocurrio un error con el request");
			}
			this.setState({
				regions: result.data
			});
		});
	}
	resetSearch() {
		this.setState({searchTerm: "", searchType: "N° Cliente", searchServices: null});
	}
	addService(service, from) {
		if(this.props.service === "medidor")
		meterBussinesLineAdd(service.client_cod, service.contract_cod,  (error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			if(from ===  "suggestions") this.loadRecommended();
			if(from ===  "search") this.resetSearch();
			if(result.status === 'error'){
				this.props.createActionNotification("", result.message, 'error');
			}
			if(result.status === 'ok'){
				this.props.createActionNotification("", "El servicio se ha vinculado a tu cuenta correctamente");
				this.props.checkMenuOptions();
				this.props.redirect("/");
			}
		});
		if(this.props.service === "granel")
		housingBussinesLineAdd(service.client_cod, service.contract_cod,  (error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			if(from ===  "suggestions") this.loadRecommended();
			if(from ===  "search") this.resetSearch();
			if(result.status === 'error'){
				this.props.createActionNotification("", result.message, 'error');
			}
			if(result.status === 'ok'){
				this.props.createActionNotification("", "El servicio se ha vinculado a tu cuenta correctamente");
				this.props.checkMenuOptions();
				this.props.redirect("/");
			}
		});
		if(this.props.service === "cilindro")
			cilindroBussinesLineAdd(service.client_cod,
				(error, result) => {
				if(error) {
					console.log(error);
					return console.log("ocurrio un error con el request");
				}
				if(from ===  "suggestions") this.loadRecommended();
				if(from ===  "search") this.resetSearch();
				if(result.status === 'error'){
					this.props.createActionNotification("", result.message, 'error');
				}
				if(result.status === 'ok'){
					this.props.createActionNotification("", "El servicio se ha vinculado a tu cuenta correctamente");
					this.props.checkMenuOptions();
					this.props.redirect("/");
				}
			});		
		}
	searchService() {
		this.setState({searchServices: null, search_message: null, loadingSearch: true, no_street: false, no_number: false});
		if(this.state.searchType === "Dirección") {
			if(!this.state.street || this.state.street === "") return this.setState({no_street: true, loadingSearch: false});
			if(!this.state.searchTermNumero || this.state.searchTermNumero === "") return this.setState({no_number: true, loadingSearch: false});
		}
		if(this.props.service === "medidor") {
			var contract_cod = undefined;
			var meter_cod = undefined;
			var terms = {};
			if(this.state.searchType === "Dirección") {
				terms.comune = this.state.city ? this.state.city.name : '';
				terms.street = this.state.street ? this.state.street : '';
				terms.number = this.state.searchTermNumero ? this.state.searchTermNumero : '';
				terms.client_dept = this.state.searchApartementNumber ? this.state.searchApartementNumber : '';
			}
			if(this.state.searchType === "N° Cliente") terms.contract_cod = this.state.searchTerm;
			if(this.state.searchType === "N° Serie Medidor") terms.meter_cod = this.state.searchTerm;
			meterBussinesLineSearch(terms, (error, result) => {
				if(error) {
					this.setState({loadingSearch: false});
					console.log(error);
					return console.log("ocurrio un error con el request");
				}
				if(result.message) this.setState({search_message: result.message, loadingSearch: false});
				else this.setState({searchServices: result.data, loadingSearch: false});
			});
			

		} else if(this.props.service === "granel") {
			var terms = {};
			if(this.state.searchType === "Dirección") {
				terms.comune = this.state.city ? this.state.city.name : '';
				terms.street = this.state.street ? this.state.street : '';
				terms.number = this.state.searchTermNumero ? this.state.searchTermNumero : '';
			}
			if(this.state.searchType === "Rut") {
           		terms.rut = this.state.searchTerm;
	        }
	        if(this.state.searchType === "Código Cliente") {
                terms.client_cod = this.state.searchTerm;
			}
			housingBussinesLineSearch(terms, (error, result) => {
				if(error) {
					this.setState({loadingSearch: false});
					return console.log("ocurrio un error con el request", error);
				}
				if(result.message) this.setState({search_message: result.message, loadingSearch: false});
				else this.setState({searchServices: result.data, loadingSearch: false});
			});

		}else if(this.props.service === "cilindro") {
			var terms = {};
			if(this.state.searchType === "Dirección") {
				terms.comune = this.state.city ? this.state.city.name : '';
				terms.street = this.state.street ? this.state.street : '';
				terms.number = this.state.searchTermNumero ? this.state.searchTermNumero : '';
			}
			if(this.state.searchType === "Código Cliente") {
                terms.client_cod = this.state.searchTerm;
			}
			cilindroBussinesLineSearch(terms, (error, result) => {
				if(error) {
					this.setState({loadingSearch: false});
					return console.log("ocurrio un error con el request", error);
				}
				if (result) {
					console.log(result)
				}
					if(result.message) this.setState({search_message: result.message, loadingSearch: false});
				else this.setState({searchServices: result.data, loadingSearch: false});
			});

		}
	}
	sendEmailHelp = (event) => {

		event.preventDefault();
		var business_line_id = 1;

		if(this.props.service === "medidor") business_line_id = 1;
		if(this.props.service === "granel") business_line_id = 3;
		if(this.props.service === "cilindro") business_line_id = 4;

		postSendHelpMail(this.state.id_person, business_line_id, this.state.street, this.state.number, this.state.second_numbering, this.state.region, this.state.comune, (error, result) => {
			if(error) {
			  return alert("error al registrar");
			}
			this.setState({helpBoxDone: true});
		});
	}
	renderBuscador(optionsRegions, optionsCommune, searchOptions) {
		/* if (optionsRegions && optionsRegions.length > 0) {
			optionsRegions.map(i => console.log(i.props))
		} */
		var fieldsContainerClass = "flex-row col-100 left clearfix";
		var searchTerms = <input value={this.state.searchTerm} onChange={(e)=>{this.setState({searchTerm: e.target.value})}} placeholder={this.state.searchType} style={{flex: 1, marginRight: "20px"}} />;
		if(["N° Cliente", "N° Serie Medidor"].includes(this.state.searchType)) {
			searchTerms =
			<Fragment>
				<input className={"input-with-info"} value={this.state.searchTerm} onChange={(e)=>{this.setState({searchTerm: e.target.value})}} placeholder={this.state.searchType} style={{flex: 1}} />
				<div className="field-help-popup-button" onClick={()=>this.setState({client_cod_info_popup:["N° Cliente", "N° Serie Medidor"].indexOf(this.state.searchType)+1})}></div>
			</Fragment>
		} else if(this.state.searchType === "Rut") {
			searchTerms =<input value={this.state.searchTerm} onChange={(e)=>{this.setState({searchTerm: formatRut(e.target.value)})}} placeholder={"(ej: 12345678-k)"} style={{flex: 1, marginRight: "20px"}} />;
		} else if(this.state.searchType === "Dirección") {
			fieldsContainerClass = "grid-column-3 col-100 left";
			searchTerms =
				<Fragment>
					<select value={this.state.region} onChange={(e) => {this.setState({region: e.target.value, city: null, street: "", no_city_error: null})}} required>
						<option value="" disabled>Región</option>
						{optionsRegions}
					</select>
					<select value={this.state.city?this.state.city.id:""} onChange={(e) => {
							this.state.regions.forEach(element => {
								if(element.id === parseInt(this.state.region)){
									element.communes.forEach(commune => {
										if(commune.id === parseInt(e.target.value)) this.setState({city: commune, street: "", no_city_error: null})
									});
								}
							});

							
						}} required>
						<option value="" disabled>Comuna</option>
						{optionsCommune}
					</select>
					<Tooltip placement="bottom" visible={this.state.no_city_error || this.state.no_street}
								 	overlay={<span>{this.state.no_street?"La calle es requerida":"Antes de ingresar la calle debe seleccionar una comuna"}</span>}>
					{/* <PredictiveStreetSearch 
						city={this.state.city?this.state.city.name:null} 
						region={this.state.region?this.state.region:null}
						codeRegion={this.state.code_region?this.state.code_region:null}
						selected={this.state.street}
						onFocus={(e) => {this.setState({no_street: false});if(!this.state.city) this.setState({ no_city_error: true})}}
						locked={this.state.no_city_error}
						service={this.props.service}
						onOptionSelected={(street) => {this.setState({street})}} 
						/> */}
						
						<AutocompleteInput 
							city={this.state.city?this.state.city.name:null} 
							regionCode={this.state.code_region?this.state.code_region:null}
							onFocus={(e) => {this.setState({no_street: false});if(!this.state.city) this.setState({ no_city_error: true})}}
							locked={this.state.no_city_error}
							setSelected={(street) => {this.setState({street})}}
						/>
					</Tooltip>
					{/*<input value={this.state.searchTermCalle} onChange={(e)=>{this.setState({searchTermCalle: e.target.value})}} placeholder={"Calle"}
						/>*/}

					<Tooltip placement="bottom" visible={this.state.no_number}
								 	overlay={<span>El número es requerido</span>}>
					 	<input value={this.state.searchTermNumero} onChange={(e)=>{this.setState({searchTermNumero: e.target.value, no_number: false})}} placeholder={"Número"}/>
				 	</Tooltip>
					{this.props.service === "medidor"?(<input value={this.state.searchApartementNumber} onChange={(e)=>{this.setState({searchApartementNumber: e.target.value,})}} placeholder={"N° Depto/ N° Casa (Condominio)"}/>):null}
				</Fragment>;
		}
		return(
		<div className="subcontent-box subcontent-box-asociar-servicios col-100 left clearfix margin-top-10 margin-bottom-30">
			<h1>BUSCAR POR</h1>
			<div className={fieldsContainerClass}>
				<select value={this.state.searchType} onChange={(e)=> {this.setState({searchType: e.target.value, searchTerm: ""});}}>
					{searchOptions.map((option) => <option key={option}>{option}</option>)}
				</select>
				{searchTerms}
				<div className="margin-top-20-under650">
				<div className="button orange" onClick={()=>this.searchService()}>
					<p>BUSCAR</p>
				</div>
				</div>
			</div>
		</div>
		);
	}
	render() {
		var title = "";
		var subTitle = "";
		var searchOptions = [];
		if(this.props.service === "cilindro") {
			title = "Cilindro";
			searchOptions = [
				"Código Cliente", "Dirección"
			];
		}
		if(this.props.service === "granel") {
			title = "Granel Habitacional";
			subTitle = "Asociar Dirección";
			searchOptions = [
				"Dirección", "Código Cliente", "Rut"
			];
		}
		if(this.props.service === "autogas") {
			title = "Autogas";
			subTitle = "Asociar Servicios";

		}
		if(this.props.service === "medidor") {
			title = "Medidor";
			subTitle = "Asociar Servicios";
			searchOptions = [
				"N° Cliente", "N° Serie Medidor" , "Dirección"
			];
		}
		/*var breadCrumbOptions = [
			{title: "Mis cuentas"},
			{title, to: "/adminitracion_cuentas/" + this.props.service},
			{title: "Asociar Servicio"}
		];*/
		var optionsRegions = [];
		this.state.regions.forEach(element => {
			optionsRegions.push(
				<option value={element.id} key={element.id}>{element.name}</option>
			);
		});
		var optionsCommune = [];
		this.state.regions.forEach(element => {
			if(element.id === parseInt(this.state.region)){
				element.communes.forEach(commune => {
					optionsCommune.push(
						<option key={commune.id} value={commune.id}>{commune.name}</option>
					);
				});
			}
		});
		var helpContent = null;
		if(!this.state.helpBoxDone) helpContent =
			<form onSubmit={this.sendEmailHelp}>
				<div className="form-need-help">
					<select>
						<option>{title}</option>
					</select> *
					<select value={this.state.region} onChange={(e) => {this.setState({region: e.target.value})}} required>
						<option value="" disabled>Región</option>
						{optionsRegions}
					</select> *
					<select value={this.state.comune} onChange={(e) => {this.setState({comune: e.target.value})}} required>
						<option value="" disabled>Comuna</option>
						{optionsCommune}
					</select> *
					<input type="text" value={this.state.street} onChange={(e) => {this.setState({street: e.target.value})}} placeholder="Calle" required/> *
					<input type="text" value={this.state.number} onChange={(e) => {this.setState({number: e.target.value})}} placeholder="Número" required/> *
					<input type="text" value={this.state.second_numbering} onChange={(e) => {this.setState({second_numbering: e.target.value})}} placeholder="Casa"/>
				</div>
				<p className="information-need-help">Un ejecutivo revisará tus datos y enviará  la informacion  requerida a tu correo {this.props.user.email}</p>
				<button type="submit" className="button orange button-need-help">Enviar solicitud</button>
			</form>;
		else helpContent =
						<div>
							<div className="information-need-help-ok-title">
								<p>Solicitud enviada con éxito</p>
							</div>
							<div className="information-need-help-ok-image">
								<img className="question-orange" src={SolicitudAyudaImg} alt="Ícono ayuda" />
							</div>
							<div className="information-need-help-ok-button-container">
								<Button1 className="button" label="Finalizar" onClick={() => this.setState({helpBoxCollapse: false, helpBoxDone: false})} />
							</div>
						</div>;
		return (
			<ContentBox key={this.props.service}>
				<h1>{subTitle}</h1>
				{!this.state.services ? null :
				<div>
					<h2 className="asociar-servicios-sugerencias margin-top-20 col-100 left clearfix">SUGERENCIAS</h2>
					<div className={"list-boxes-container col-100 left clearfix" + (this.props.service === "autogas"?" autogas":"")}>
					{this.state.services.map((service) => {
						
							return(<ServiceListBox key={service.contract_cod ? service.contract_cod : service.client_cod} service={service} type="asociar" onButtonClick={() => {this.addService(service, "suggestions")}} />);
											
					})}
					</div>
					<h3 className="col-100 left clearfix subtitle-asociar-servicios margin-top-20">¿Tu cuenta no está entre las sugeridas? Búscala a continuación.</h3>
				</div>}
				{this.renderBuscador(optionsRegions, optionsCommune, searchOptions)}
				{this.state.search_message && this.state.search_message.trim() !== ""?<div className="search-result-message italic col-100 left clearfix">
					<p className={"font-15"}>{this.state.search_message}</p>
				</div>:null}

				{!this.state.searchServices || this.state.searchServices.length === 0?null:<div className={"col-100 left list-boxes-container margin-top-20 asociar-servicios-container" + (this.props.service === "autogas"?" autogas":"")} style={{marginTop: "20px"}}>
				{!this.state.searchServices ? null : this.state.searchServices.map((service) => {
					
						return(<ServiceListBox key={service.contract_cod ? service.contract_cod : service.client_cod} service={service} type="asociar" onButtonClick={() => {this.addService(service, "search")}} />);
								
				})}
				</div>}
				{this.state.loadingSearch?
				<div className="margin-bottom-100" style={{position: "relative"}}>
					<div className={"content-box-loading"}>
						<div className="content-box-loading-content">
							<div className="loading-full-image">
								<img alt="Abastible" src={Isotipo} />
							</div>
							<div className="loading-full-text">
								<p>Cargando ...</p>
							</div>
						</div>
					</div>
				</div>:null}
				<div className="need-help margin-top-30 col-100 left clearfix">
					<button className="title-need-help" onClick={() => {
						if(!this.state.helpBoxCollapse) {
							setTimeout(() => this.scrollToMyRef(), 300);
						}
						this.setState({helpBoxCollapse: !this.state.helpBoxCollapse}) }}>
						<img className="question-orange" src={QuestionOrange} alt="help" />
						<span>Necesitas ayuda para asociar tus servicios</span>
					</button>
					<div ref={this.myRef} className={"slidedown " + (this.state.helpBoxCollapse ? 'show' : 'collapsed')}>
						{helpContent}
					</div>
				</div>
				<PopupInfoClientCode cancel={()=>this.setState({client_cod_info_popup: null})} confirm={null} show={this.state.client_cod_info_popup} />
			</ContentBox>
		);
	}
}
