import React, { Component } from 'react';
import {getGranelPaymentHistory} from '../api.js';
import ContentBox from './ContentBox.js';
import ClientSelectGranel from './ClientSelectGranel';
import Paginator from './Paginator';
import PostFileDownloadDocument from './PostFileDownloadDocument';
export default class ServiciosGranelHistorialPagos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchType: "days",
			searchPedido: "",
			filterDays: "30",
			checkers: [],
			page: 1,
			itemsPerPage: 10
		};
	}
	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();		
		if(this.props.selectedService) this.getList();

	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.selectedService !== this.props.selectedService) this.getList();
		if (prevState.checkers !== this.state.checkers && this.state.checkers.length > 0) {
			let allLoaded = true;
			this.state.checkers.map(el =>{
				if (el.status === false) {
					allLoaded = false
				}
			})
			if (allLoaded) {
				this.setState({loading: false})
			}
		}
	}
	getList(cb) {
		this.setState({formErrors: null, loading: true, pagos: null, page: 1, pedidosMessage: "", checkers: []});
		getGranelPaymentHistory(this.props.selectedService.client_cod, (error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
			}
			if(result.status === "error") {
				if(cb) cb();
				return this.setState({loading: false, formErrors: result.errors});
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({loading: false, pedidosMessage: result.message});
			}
			this.setState({pagos: result.data});
			this.loadRenderedItems(this.state.page)
		}, this.props.isEmpresa?"empresas":null);
	}
	filter() {
		this.getList();
	}
	changePage(page) {
		this.setState({page, loading: true});
		this.loadRenderedItems(page)
	}
	loadRenderedItems(page) {
		let itemsRendered = [];
		this.state.pagos.slice((page - 1)*this.state.itemsPerPage, (page - 1)*this.state.itemsPerPage + this.state.itemsPerPage).map(el=>{
			itemsRendered = [...itemsRendered, {pdf_id: el.pdf_document.pdf_number, status: false}]
		})
		this.setState({checkers: itemsRendered})
	}
	isChecked(id) {
		this.state.checkers.map((el, i)=>{
			if (el.pdf_id === id) {
				let array = [...this.state.checkers];
				array[i] = { ...array[i], status: true };
				this.setState({ checkers: array });
			}
		})
	}
	render() {
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Historial de Pagos</h1>
					<div className="client-number">
						<ClientSelectGranel
							selected={this.props.selectedService}
							services={this.props.services}
							setSelectedService={this.props.setSelectedService}
							number_field={"client_cod"} />
					</div>
				</div>				
				<div className="table-container">
					<table className="table1">
						<thead>
							<tr>
								<td>Clase de documento</td>
								<td>Nro. Documento</td>
								<td>Fecha Emisión</td>
								<td>Fecha Vencimiento</td>
								<td>Valor</td>
								<td style={{maxWidth: '80px', padding: '0px'}}>Morosidad</td>
								<td>Fecha Cancelación</td>
								<td style={{maxWidth: '70px', padding: '0px'}}></td>
							</tr>
						</thead>
						<tbody>
							{!this.state.pagos?null:this.state.pagos.slice((this.state.page - 1)*this.state.itemsPerPage, (this.state.page - 1)*this.state.itemsPerPage + this.state.itemsPerPage).map((pago, i) => {
								return(
							<tr key={pago.doc_number}>
								<td>{pago.class_description}</td>
								<td>{pago.folio_number}</td>								
								<td>{pago.issue_date}</td>
								<td>{pago.expiration_date}</td>
								<td>${parseInt(pago.value).toLocaleString("de")}</td>
								<td>{parseInt(pago.late_payment) > 0?pago.late_payment:""}</td>
								<td>{pago.payment_date}</td>
								<td style={{maxWidth: '70px', paddingLeft: '0px'}}>
								{pago.pdf_document?<PostFileDownloadDocument 
									createActionNotification={this.props.createActionNotification} 
									class_description={pago.pdf_document.class_description} 
									isEmpresa={this.props.isEmpresa?"empresas":null}
									doCheck={true}
									isChecked={this.isChecked.bind(this)}

									pdf_number={pago.pdf_document.pdf_number} contentRender={() => 
									<div className="linkA-container">
										<a>Descargar Documento</a>
									</div>
								} />:null}									
								</td>
							</tr>);

							})}
						</tbody>
					</table>
					{!this.state.pagos?<p>{this.state.pedidosMessage}</p>:null}
				</div>
				{this.state.pagos && this.state.pagos.length > this.state.itemsPerPage?<Paginator page={this.state.page} items={this.state.pagos?this.state.pagos.length:0} itemsPerPage={this.state.itemsPerPage} onPageChange={(page) => this.changePage(page)} />:null}					
			</ContentBox>
		);
	}
}
