import React, { useEffect } from 'react'

export default function Clara() {
    useEffect(()=>{
        var tc = document.createElement("script");
		tc.type = "text/javascript"; 
        tc.async = true;
		tc.src = "https://app.talkcenter.io/v2/talkcenter.min.js";
		var s = document.getElementsByTagName("script")[0];
		s.parentNode.insertBefore(tc, s);
		window.TalkCenter = {token: "dee19d5d070416cfdd7fe46adb4a79a3"}; 
        document.body.appendChild(tc);
        return () => {
            let el = document.getElementById("talkcenter")
            document.body.removeChild(el)
        }
    }, [])
    return (
        <div>
            
        </div>
    )
}
