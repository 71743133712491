import React, { useState, useEffect } from 'react'
import Button2 from "./Button2"
import Close from '../img/close.svg';
import { autogasCreateMaintenance } from "../api"
import SolicitudAyudaImg from '../img/solicitud_ayuda_ok.png';
import ContentBox from './ContentBox.js';

export default function ServiciosAutogasPopupMantencion({solicitud, show, setShowModal, solicitudData, priceData, setReload}) {
    const [step, setStep] = useState(1);
    const [isSent, setIsSent] = useState(false)
    const [errMessage, setErrMessage] = useState(false)

    const enviarSolicitud = () => {
        setIsSent(true)
        autogasCreateMaintenance(solicitud, (err, res) => {
            if (err) {
                console.log(err)
                setIsSent(false)
                setStep(3)
            }
            if (res) {
                setIsSent(false)
                if (res.status === "ok") {
                    setStep(2)
                } else if (res.status === "error") {
                    if (res.message) {
                       setErrMessage(res.message)
                    }
                    setStep(3)
                }
                
            }
        })
    }


    return (
        <div className={"full-screen-veil " + (show ? 'show-modal' : '')}>
            <div className="modal1" style={{maxHeight: "80vh", textAlign: "left", height: "100%"}}>
                <div className="this-modal-scroll" style={{overflowY: "auto", height: "100%"}}>
                    {step === 1 ? 
                    <ContentBox loading={isSent}>
                        <div className="col-100 text-center clearfix margin-bottom-20">
                            <h1 className="popup-titulo">Agendar Mantenimiento</h1>
                        </div>
                        <div className="col-100 left clearfix margin-bottom-20">
                            <p className="popup-subtitulo">Datos Mantenimiento</p>
                        </div>
                        <div className="col-100 left clearfix">
                            <div className="grid-column-2 margin-bottom-10">
                                <div className="popup-detail">
                                    <span>Región</span>
                                    <p>{solicitudData.region}</p>
                                </div>  
                                <div className="popup-detail">
                                    <span>Taller</span>
                                    <p>{solicitudData.workshop}</p>
                                </div>  
                                <div className="popup-detail">
                                    <span>Fecha</span>
                                    <p>{solicitudData.date}</p>
                                </div>  
                                <div className="popup-detail">
                                    <span>Hora</span>
                                    <p>{solicitudData.hour}</p>
                                </div>  
                                <div className="popup-detail">
                                    <span>Valor Mantención</span>
                                    <p>${parseInt(priceData.valor_mantencion_taller).toLocaleString("de")}</p>
                                </div>  
                                <div className="popup-detail">
                                <span>Porcentaje de Descuento</span>
                                    <p>{priceData.porcentaje_descuento}%</p>
                                </div>  
                                <div className="popup-detail">
                                    <span>Total a Pagar</span>
                                    <p><strong>${parseInt(priceData.valor_aplicando_descuento).toLocaleString("de")}</strong></p>
                                </div>  
                                <div className="grid-item-full left margin-bottom-40">
                                    <p className="popup-referencia">*Precio referencial con IVA incluidos. Sujeto a verificación en taller.</p>
                                </div>
                                <div className="grid-item-full text-center"style={{maxWidth: "90%", margin: "auto"}}>
                                    <Button2 label="Cancelar"  className="button button-border-orange uppercase"  onClick={() => {setReload(true); setShowModal(false)}}/>
                                    <Button2 label="Confirmar" className="button uppercase green" onClick={() => enviarSolicitud()}/>
                                </div>
                            </div>
                        </div>    
                    </ContentBox>
                    : null}
                    {step === 2 ? 
                    <div className="content col-100 text-center clearfix">
                        <h1 className="fw700">Se ha agendado la hora con éxito.</h1>
                        <div className="information-need-help-ok-image">
                            <img style={{margin: "25%"}} className="question-orange" src={SolicitudAyudaImg} alt="Ícono ayuda" />
                        </div>
                    </div>
                    : null}   
                    {step === 3 ? 
                    <div className="content col-100 text-center clearfix">
                        <h1 className="fw700">{errMessage ? errMessage : "Error agendando la mantención, inténtalo más tarde"}</h1>
                        <div className="col-100 text-center clearfix">
                            <Button2 label="Cerrar" className="button button-border-orange uppercase" onClick={() => {setErrMessage(false); setReload(true); setShowModal(false)}}/>
                        </div>
                    </div>
                    : null}   
                </div>
                <div className="solicitud-evaluacion-close-container" onClick={() => {setErrMessage(false); setReload(true); setShowModal(false)}}>
                    <img alt="" src={Close} />
                </div>   
            </div>     
        </div>
    )
}
