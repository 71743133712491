import React, { Component } from 'react';
import ContentBox from './ContentBox.js';
import {housingTankTelemetry} from '../api.js';
import GranelTanqueDinamico from './GranelTanqueDinamico';
import moment from 'moment';
import {Link} from 'react-router-dom';
import 'moment/locale/es';
import flechaIzquierdaNegra from '../img/arrow_left_black.svg';
import {available} from '../config.js';
moment.locale('es');


export default class ServiciosGranelTelemetriaTanque extends Component {
	constructor(props) {
		super(props);
		this.state = {
			housingServices: null,
			loading: true
		};
	}
	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();
		
		this.getList();
	}
	componentDidUpdate(prevProps) {
		if(prevProps.selectedService !== this.props.selectedService && !this.state.loading) this.props.redirect('/mis-servicios/granel/telemetria/' + this.props.selectedService.client_id);
	}
	getList(cb) {
        this.setState({loading: true, housingServices: []});
        housingTankTelemetry(this.props.client_cod, this.props.tank_id, (error, result) => {
            let telemetries = null;
			if(error) {
				return console.log("ocurrio un error con el request");
            }
            if(result.status === "error"){
                telemetries = null;
            }else if(result.status === "ok"){
				telemetries = result.data;
            }
//			console.log(telemetries);
            this.setState({
                telemetries: telemetries,
                loading: false,
			});
		}, this.props.isEmpresas?"empresas":null);
	}
	
    renderDetailTelemetry(){
		var last_telemetry = null;
        if(this.state.telemetries && !this.state.loading){
			last_telemetry = this.getLastTelemetry(this.state.telemetries);
			return(
					<div>
						<div className="col-100 flex-row justify-content-center telemetria-tanque-columns">
							<div className="d-flex flex-column align-items-center flex-1">
								<GranelTanqueDinamico level={last_telemetry.percentage} />
							</div>
							<div className="flex-1 margin-left-60">
								<p className="font-25 fw700">Estado Actual del Tanque</p>
								<p className="font-13 fw200"><span className="font-80 fw700 margin-right-20 color-green">{last_telemetry.percentage}%</span>DEL TOTAL DE GAS</p>							
								<div className="is-hidden-mobile">
									<div className={"telemetria-tanque-data"}>
										<p className="margin-top-10"><span className="fw300">Código del tanque:</span> {parseInt(last_telemetry.meter_code)}</p>
									</div>
									<div className={"telemetria-tanque-data"}>
										<p className="margin-top-10"><div className="reload-icon margin-right-15"></div><span className="fw300">Última actualización:</span> {/*moment(last_telemetry.reading_date + ' ' + last_telemetry.reading_time, "YYYY-MM-DD HH:mm:ss").fromNow()*/} {moment(last_telemetry.reading_date + ' ' + last_telemetry.reading_time, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY - HH:mm")}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-50-tanque-info flex-row justify-content-center telemetria-tanque-columns">
							<div className="telemetria-tanque-data">
								<div className="flex-1 padding-15 semi-border-dashed-orange">
									<p className="fw700 font-18 color-orange">{parseInt(parseInt(last_telemetry.capacity)*parseInt(last_telemetry.percentage)*0.01)} de {last_telemetry.capacity}</p>
									<p className="font-11">Litros restantes</p>
								</div>
								<div className="flex-1 padding-15 semi-border-dashed-orange">
									<p className="fw700 font-18 color-orange">{moment().add(last_telemetry.days_empty_meter, 'days').format('DD/MM/YYYY')}</p>
									<p className="font-11">Estimamos que te  quedarás sin gas en esta fecha</p>
								</div>
								<div className="flex-1 padding-15">
									<p className="fw700 font-18 color-orange">{last_telemetry.average_consumption} Lts</p>
									<p className="font-11">Consumo promedio mensual</p>
								</div>
							</div>
						</div>
						<div className="col-100 flex-row justify-content-center telemetria-tanque-columns is-visible-mobile">							
							<div className={"telemetria-tanque-data"}>
								<p className="margin-top-10"><span className="fw300">Código del tanque:</span> {parseInt(last_telemetry.meter_code)}</p>
							</div>
							<div className={"telemetria-tanque-data"}>
								<p className="margin-top-10"><div className="reload-icon margin-right-15"></div><span className="fw300">Última actualización:</span> {/*moment(last_telemetry.reading_date + ' ' + last_telemetry.reading_time, "YYYY-MM-DD HH:mm:ss").fromNow()*/} {moment(last_telemetry.reading_date + ' ' + last_telemetry.reading_time, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY - HH:mm")}</p>
							</div>							
						</div>
					</div>
					
			);
        }else if(!this.state.loading){
			return(
				<div className="d-flex box-error-historial-lectura">
					<div className="box-white-last-bill col-100 text-center">
						<p className="text-green fw700 margin-bottom-10">En este momento no es posible obtener la información.</p>
						<p className="text-green fw500 font-13 uppercase">Por favor intenta más tarde</p>
					</div>
				</div>
			);
        }
	}
	getLastTelemetry(telemetries){
		var last = null;
		var last_date = null;
		var date = null;
		telemetries.forEach(telemetry => {
			if(!last){
				last = telemetry;
			}else{
				last_date = moment(last.reading_date + ' ' + last.reading_time, "YYYY-MM-DD HH:mm:ss");
				date = moment(telemetry.reading_date + ' ' + telemetry.reading_time, "YYYY-MM-DD HH:mm:ss");
				if(date.isBefore(last_date)){
					last = telemetry;
				}
			}
		});
		return last;
	}
	render() {
		var show_historial = ((this.props.isEmpresas&&available.granel_industrial_telemetria_historial) || (!this.props.isEmpresas && available.granel_telemetria_historial));
		console.log(show_historial, this.props.isEmpresas, available.granel_industrial_telemetria_historial);
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Telemetría</h1>		
					{show_historial?<div className="client-number servicios-servicio-box-links">
						<div className="linkA-container">
							<Link to={"/mis-servicios/granel/telemetria/" + this.props.client_cod + "/tanques/" + this.props.tank_id +  "/historial"}>
							Ver historial</Link>
						</div>
					</div>:null}		
				</div>
				<Link to={"/mis-servicios/granel/telemetria/" + this.props.client_cod + "/tanques"}>
				<div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30">
					<img alt="Atrás" src={flechaIzquierdaNegra}/>
					<p>atrás</p>
				</div>
				</Link>
				<div className="telemetria-tanque col-100 left clearfix">
                    {this.renderDetailTelemetry()}
					{!this.state.housingServices?<p>{this.state.lecturasMessage}</p>:null}
				</div>
				
			</ContentBox>
		);
	}
}
