import React, { Component } from 'react';
import {getServiceAddressString} from '../utils.js';
export default class ServiceListBoxAutogas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedPatentes: [],
			allItemPatentes: [],
			linkedPatents: [],
			freePatents: []
		};
	}

	componentDidMount() {
		if (this.props.service.patents.length === 1 && this.props.type === "asociar") {
			this.setState({selectedPatentes: [this.props.service.patents[0].conversion_contract_number]})
		}
		if (this.props.service.patents.length > 0 && this.props.type === "asociar") {
			
			let patentesTomadas = []
			let patentesLibres = []
			this.props.service.patents.map((patente)=>{
				if (patente.linked === true) {
					patentesTomadas = [...patentesTomadas, patente]
				} else {
					patentesLibres = [...patentesLibres, patente]
				}
			})
			if (patentesLibres.length === 1) {
				this.setState({selectedPatentes: [patentesLibres[0].conversion_contract_number]})
			}
			this.setState({linkedPatents: patentesTomadas })
			this.setState({freePatents: patentesLibres })
		}
	}

	togglePatente(patente) {

		if(this.props.type === "desvincular") return;
		var selectedPatentes = [...this.state.selectedPatentes];
		var patenteIndex = selectedPatentes.indexOf(patente);
		if(patenteIndex > -1) selectedPatentes.splice(patenteIndex,1);
		else selectedPatentes.push(patente);
		this.setState({selectedPatentes});
	}

	buttonAction() {
		if (this.props.type === "asociar") {
			if (this.state.selectedPatentes.length > 0) {
				this.props.onButtonClick(this.state.selectedPatentes)
			} else {
				let selectAll = []
				this.props.service.patents.map((patente, i) => {
					if (patente.linked === true) {
						return;
					} else {
						return selectAll = [...selectAll, patente.conversion_contract_number]
					}
				})
				this.setState({selectedPatentes: selectAll});
			}
		} else {
			this.props.onButtonClick(this.state.selectedPatentes && this.state.selectedPatentes > 0 ? this.state.selectedPatentes : this.props.service.patents)
		}
		
	}

	
	render() {
		var type = "";
		var buttonText = "";
		if(this.props.type === "asociar") {
			if (this.state.selectedPatentes.length > 0) {
				buttonText = "ASOCIAR"
			} else {
				buttonText = "SELECCIONAR TODOS"
			}
			type = " asociar";
			
		} else if(this.props.type === "desvincular") {
			type = " desvincular";
			buttonText = "DESVINCULAR";
		}
		var service = this.props.service;
		var client_address = getServiceAddressString(service);		
		var displayNumber = null;
		if(this.props.displayNumberLabel) displayNumber = service[this.props.displayNumberLabel] !== ''  ? parseInt(service[this.props.displayNumberLabel]) : parseInt(service.client_cod);
		if(displayNumber === null) {
			displayNumber = typeof service.contract_cod !== 'undefined' ? parseInt(service.contract_cod) : parseInt(service.client_cod);
		}

		return (
			<div className={"servicio-list-box text-align-left" + type}>
				<div className={"servicio-list-box-autogas-columns desvincular"}>
					<div className="flex1" style={{display: "flex"}}>
						<div>
							{this.props.hideClientCode?null:<h1>N° de cliente {displayNumber}</h1>}
							{service.pivot && service.pivot.company_name?<p className="uppercase font-14 fw700 lh-100pct margin-top-10">{service.pivot.company_name}</p>:null}
							{service.pivot && service.pivot.company_dni?<p className="uppercase font-14 fw700 lh-130pct">RUT: {service.pivot.company_dni}</p>:null}


							{this.props.onlyHeader ? null : <p className="servicio-list-box-address">
								{client_address}
							</p>}
							{this.props.onlyHeader ? null : <p className="servicio-list-box-city">
								{service.client_city}, {service.client_region_cod}
							</p>}
							{this.props.onlyHeader ? null : <React.Fragment>
								<p className="servicio-list-box-address" style={{margin: "0px"}}>
									{service.client_rut}
								</p>
								<p className="servicio-list-box-address" style={{margin: "0px"}}>
									{service.client_name}
								</p>
								</React.Fragment>}
						</div>
						{!buttonText ? null : <div className="servicio-list-box-button" onClick={()=> this.buttonAction()}>
							<p>{buttonText}</p>
						</div>}
					</div>
					<div className={"margin-top-20"}>
						
						{
							service && service.patents.length > 0 && type === " desvincular" ?
								service.patents.map((patente, i) => {
									return(
										<div className={"button-checkbox inline-block margin-top-10" 
												+ (this.state.selectedPatentes.indexOf(patente.conversion_contract_number)>-1?" checked":"") 
												+ (this.props.type ==="desvincular"?" remove":"")
											} key={i} 					
										onClick={()=>{this.props.type==="desvincular"?this.props.onPatenteButtonClick(patente.conversion_contract_number):this.togglePatente(patente.conversion_contract_number)}}>
											<p className="fw700">{patente.patent}</p>
											<div className={"button-checkbox-icon"} />
										</div>
									)
								})
						: null }

						{
							this.state.freePatents && this.state.freePatents.length === 1 && type === " asociar" ?
								this.state.freePatents.map((patente, i) => {
									{
										return (<div className={"button-checkbox inline-block margin-top-10"
										+ (this.state.selectedPatentes.indexOf(patente.conversion_contract_number)>-1?" checked":"")} key={i}>
												<p className="fw700">{patente.patent}</p>
												<div className={"button-checkbox-icon"} />
											</div>);
									}
							}) 
						: null }

						{
							this.state.freePatents && this.state.freePatents.length > 1 ?
								this.state.freePatents.map((patente, i) => {
									return(
										<div className={"button-checkbox inline-block margin-top-10" 
												+ (this.state.selectedPatentes.indexOf(patente.conversion_contract_number)>-1?" checked":"") 
												+ (this.props.type ==="desvincular"?" remove":"")
											} key={i} 					
										onClick={()=>{this.props.type==="desvincular"?this.props.onPatenteButtonClick(patente.conversion_contract_number):this.togglePatente(patente.conversion_contract_number)}}>
											<p className="fw700">{patente.patent}</p>
											<div className={"button-checkbox-icon"} />
										</div>
									)
								})
						: null }
						{
							this.state.linkedPatents && this.state.linkedPatents.length > 0 ?
								<p className="servicio-list-box-address uppercase" style={{marginBottom: "10px"}}>Patentes ya asignadas a este cliente:</p>
						: null}

						{
							this.state.linkedPatents && this.state.linkedPatents.length > 0 ?
								this.state.linkedPatents.map((patente, i) => {
									return (
										<div className={"button-checkbox inline-block margin-top-10 linked" 
											} key={i}>
											<p className="fw700">{patente.patent}</p>
											<div className={"button-checkbox-icon"} />
										</div>
									);
								})
						: null}
					</div>
				</div>
			</div>
		);
	}
}
