import React, { Component } from 'react';
import MyServices from './MyServices';
import EmpresasServiciosGranel from './EmpresasServiciosGranel';
import EmpresasServiciosCilindro from './EmpresasServiciosCilindro';

import EmpresasMisServicios from './EmpresasMisServicios';
import EmpresasMisClientes from './EmpresasMisClientes'
import EmpresasCliente from './EmpresasCliente'

import {Route, Switch, Redirect} from "react-router-dom";
import {industrialBussinesLineList, userClientsPrivileges} from '../api.js';
export default class MyServicesMain extends Component {
	constructor(props) {
		super(props);
		this.state = {			
			selectedIndustrial: null,
			selectedCilindro: null
		};
	}	
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadServices();		
	}
	loadServices() {
		userClientsPrivileges((error, result) => {
			if(error) {
				return console.log("ocurrio un error con el request");
			}
			var services = null;
			if(result.status !== "empty") {
				services = result.data;
				services.forEach((service) => {
					service.client_cod = service.abastible_client_id;
				});
			} else {
				services = [];
				this.props.changeHeaderClass("big");
			}

			this.setState({
				services,
			});
			
			if(!this.state.selectedIndustrial) {
				let isSelected = false;
				services.forEach((service)=> {
					if(parseInt(service.business_line_id) === 6 && !isSelected) {
						this.setSelectedIndustrial(service)
						isSelected = true;
					}
				});

			}
			else {
				services.forEach((service)=> {
					if(service.abastible_client_id === this.state.selectedIndustrial.abastible_client_id) {
						let isSelected = false;
						if(parseInt(service.business_line_id) === 6 && !isSelected) {
							this.setSelectedIndustrial(service)
							isSelected = true;
						}
					}
				});
			}
			if(!this.state.selectedCilindro) {
				let isSelected = false;
				services.forEach((service)=> {
					if(parseInt(service.business_line_id) === 2 && !isSelected) {
						this.setSelectedCilindro(service)
						isSelected = true;
					}
				});
			}
			else {
				services.forEach((service)=> {
					if(service.abastible_client_id === this.state.selectedCilindro.abastible_client_id) {
						this.setSelectedCilindro(service);
					}
				});
			}

		});
	}	           
	
	setSelectedIndustrial(industrial) {
	    this.setState({selectedIndustrial: industrial});
	}
	setSelectedCilindro(cilindro) {
	    this.setState({selectedCilindro: cilindro});
	}	
	render() {
		return (
			<Switch>				
				<Route path="/mis-servicios/cliente">
					<EmpresasCliente/>	
	            </Route> 
	            <Route path="/mis-servicios/granel/*"
	                render={(data) => {
	                  return <EmpresasServiciosGranel
	                  	services={this.state.services?this.state.services.filter((s)=>parseInt(s.business_line_id) === 6):null}
                  		setSelectedIndustrial={this.setSelectedIndustrial.bind(this)}
	                  	selectedIndustrial={this.state.selectedIndustrial}
						updateUser={this.props.updateUser}	                  		
						location={this.props.location}
						redirect_data={this.props.redirect_data} 
	                    changeHeaderClass={this.props.changeHeaderClass}
	                    checkMenuOptions={this.props.checkMenuOptions}
	                    redirect={this.props.redirect}
	                    user={this.props.user}
	                    getUser={this.props.getUser}
		                reloadServices={this.loadServices.bind(this)}	                    
	                    createActionNotification={this.props.createActionNotification} />
                }}/>
                <Route path="/mis-servicios/cilindro"
	                render={(data) => {
	                  return <EmpresasServiciosCilindro
	                  	  services={this.state.services?this.state.services.filter((s)=>parseInt(s.business_line_id) === 2):null}
	                  	  redirect={this.props.redirect}
	                      setSelectedCilindro={this.setSelectedCilindro.bind(this)}
	                  	  selectedCilindro={this.state.selectedCilindro}
	                      location={this.props.location}
	                      changeHeaderClass={this.props.changeHeaderClass}
	                      checkMenuOptions={this.props.checkMenuOptions}
	                      getLastBill={this.getLastBill}
						  user={this.props.user}
						  createActionNotification={this.props.createActionNotification}
						  reloadServices={this.loadServices.bind(this)}
	                  />
	                }
	              }/>
				<Route exact path="/mis-servicios">
					<EmpresasMisServicios
						services={this.state.services}
						setSelectedIndustrial={this.setSelectedIndustrial.bind(this)}
						selectedIndustrial={this.state.selectedIndustrial}
						setSelectedCilindro={this.setSelectedCilindro.bind(this)}
	                  	selectedCilindro={this.state.selectedCilindro}
						createActionNotification={this.props.createActionNotification}
						changeHeaderClass={this.props.changeHeaderClass}
						location={this.props.location}
						reloadServices={this.loadServices.bind(this)}
						user={this.props.user}
						user_role={this.props.user_role}
						menuOptions={this.props.menuOptions} />
	            </Route> 
	            <Route path="*">
		              <Redirect to={"/mis-servicios"} />
		        </Route>
			</Switch>
		);
	}
}
