import React, { Component } from 'react';
import Close from '../img/close.svg';
import Button2 from './Button2';
import { meterBussinesLineSimulate } from '../api.js';

export default class PopupReintentarSimulador extends Component {
	constructor(props) {
		super(props);
		this.state = {
			step: props.initialStep?props.initialStep:1,
		};
	}
	sendForm() {
		this.props.toggle()
	}
	cancel() {
		this.setState({step: this.props.initialStep?this.props.initialStep:1})
		this.props.cancel();
	}
	renderStep1() {
		return(
			<div className="content col-100 center clearfix">
	      		<h1 className="fw700">En este momento no podemos realizar la acción solicitada.</h1>
	      		<div className="col-100 left clearfix">
		            <Button2 label="Reintentar" className="button green right" onClick={() => {this.sendForm()}}/>
				</div>
			</div>);
	}
	renderSteps() {
		if(this.state.step === 1) return this.renderStep1();
	}
	render() {
		return (
			<div className={"full-screen-veil " + (this.props.show ? 'show-modal' : '')}>
				<div className="modal1">
					{this.renderSteps()}
					<div className="solicitud-evaluacion-close-container" onClick={() => this.cancel()}>
						<img alt="" src={Close} />
					</div>
				</div>
			</div>
		);
	}
}
