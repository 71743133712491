import React, { Component } from 'react';
import MyServices from './MyServices';
import ServiciosMedidor from './ServiciosMedidor';
import ServiciosGranel from './ServiciosGranel';
import ServiciosCilindro from './ServiciosCilindro';
import ServiciosAutogas from './ServiciosAutogas';


import {Route, Switch, Redirect} from "react-router-dom";
import moment from 'moment';

export default class MyServicesMain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedMeter: null,
			selectedHousing: null,
			selectedCilindro: null,
			selectedAutogas: null,
			selectedPatenteAutogas: null			
		};
	}
	           
	setSelectedMeter(meter) {
    	this.setState({selectedMeter: meter});
	}
	setSelectedHousing(housing) {
	    this.setState({selectedHousing: housing});
	}
	setSelectedCilindro(cilindro) {
	    this.setState({selectedCilindro: cilindro});
	}
	setSelectedAutogas(autogas) {
	    this.setState({selectedAutogas: autogas});
	}
	setSelectedPatenteAutogas(patenteAutogas) {
	    this.setState({selectedPatenteAutogas: patenteAutogas});
	}
	getLastBill = (bills) => {
    	let bill = null;
		if(bills){
			bill = bills.length > 0 ? bills[0] : null;
		}
		if(bills){
			bills.forEach(element => {
				if(moment(element.bill_expire_date, "DD-MM-YYYY").isAfter(moment(bill.bill_expire_date, "DD-MM-YYYY"))){
					bill = element;
				}
			});
		}
		return bill;
	}
	render() {
		
		return (
			<Switch>
				<Route exact path="/mis-servicios">
	                <MyServices	                  
	                  getLastBill={this.getLastBill}
	                  setSelectedMeter={this.setSelectedMeter.bind(this)}
					  selectedMeter={this.state.selectedMeter}
					  setSelectedAutogas={this.setSelectedAutogas.bind(this)}
					  selectedAutogas={this.state.selectedAutogas}					  
					  setSelectedPatenteAutogas={this.setSelectedPatenteAutogas.bind(this)}
					  selectedPatenteAutogas={this.state.selectedPatenteAutogas}
	                  setSelectedHousing={this.setSelectedHousing.bind(this)}
					  selectedHousing={this.state.selectedHousing}
					  setSelectedCilindro={this.setSelectedCilindro.bind(this)}
					  selectedCilindro={this.state.selectedCilindro}
	                  changeHeaderClass={this.props.changeHeaderClass}
	                  user={this.props.user} />
	              </Route>
	              <Route path="/mis-servicios/medidor"
	                render={(data) => {
	                  return <ServiciosMedidor
	                  		redirect={this.props.redirect}
	                      setSelectedMeter={this.setSelectedMeter.bind(this)}
	                  	  selectedMeter={this.state.selectedMeter}
	                      location={this.props.location}
	                      changeHeaderClass={this.props.changeHeaderClass}
	                      checkMenuOptions={this.props.checkMenuOptions}
	                      getLastBill={this.getLastBill}
	                      user={this.props.user}
	                  />
	                }
	              }/>
	              <Route path="/mis-servicios/granel"
	                render={(data) => {
	                  return <ServiciosGranel
	                  		setSelectedHousing={this.setSelectedHousing.bind(this)}
	                  		selectedHousing={this.state.selectedHousing}
							updateUser={this.props.updateUser}   
	            			redirect_data={this.props.redirect_data}        	                  		
	                      location={this.props.location}
	                      changeHeaderClass={this.props.changeHeaderClass}
	                      checkMenuOptions={this.props.checkMenuOptions}
	                      redirect={this.props.redirect}
	                      user={this.props.user}
	                      getUser={this.props.getUser}
	                      createActionNotification={this.props.createActionNotification}
	                  />
	                }
	              }/>
	              <Route path="/mis-servicios/cilindro"
	                render={(data) => {
	                  return <ServiciosCilindro
	                  		redirect={this.props.redirect}
	                      setSelectedCilindro={this.setSelectedCilindro.bind(this)}
	                  	  selectedCilindro={this.state.selectedCilindro}
	                      location={this.props.location}
	                      changeHeaderClass={this.props.changeHeaderClass}
	                      checkMenuOptions={this.props.checkMenuOptions}
	                      getLastBill={this.getLastBill}
	                      user={this.props.user}
	                  />
	                }
	              }/>
	              <Route path="/mis-servicios/autogas"
	                render={(data) => {
	                  return <ServiciosAutogas
	                  		redirect={this.props.redirect}
	                      setSelectedAutogas={this.setSelectedAutogas.bind(this)}
						  selectedAutogas={this.state.selectedAutogas}
						  setSelectedPatenteAutogas={this.setSelectedPatenteAutogas.bind(this)}
	                  	  selectedPatenteAutogas={this.state.selectedPatenteAutogas}
	                      location={this.props.location}
	                      changeHeaderClass={this.props.changeHeaderClass}
	                      checkMenuOptions={this.props.checkMenuOptions}
	                      user={this.props.user}
	                  />
	                }
	              }/>
	              <Route path="*">
		                <Redirect to={"/mis-servicios"} />
		          </Route>
			</Switch>
		);
	}
}
