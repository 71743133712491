import React, { Component } from 'react';
import Isotipo from '../img/isotipo.svg';

export default class ContentBox extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	afterMount: false,
	  	loadingFade: false,

	  };
	}
	componentDidMount() {
		setTimeout(() => this.setState({afterMount: true}));
	}
	render() {
		return (
			<div className={"clearfix content-box-slider" + (this.props.className?" " + this.props.className: "") + (!this.state.afterMount?" content-box-hidden": "")}>
				{this.props.children}
				{this.props.loading?
					<div className={"content-box-loading" + (this.state.loadingFade?" content-box-loading-fade": "")} style={{position: "absolute"}}>
					<div className="content-box-loading-content">

						<div className="loading-full-image">
							<img alt="Abastible" src={Isotipo} />
						</div>
						<div className="loading-full-text">
							<p>Cargando ...</p>
						</div>
					</div>
					</div>:null}
			</div>
		);
	}
}
