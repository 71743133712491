import React, { Component } from 'react';
import GuestFrame from './GuestFrame.js';
import LoadingFull from './LoadingFull';
import OrangeArrow from '../img/arrow_orange.png';

export default class RegisterEmpresa extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			code: this.props.code,
			code_error: null
		}
	}

	render() {
		if(this.props.code) {
			return <LoadingFull />;
		}
		return (
			<GuestFrame>
				<div className="login-container">
					<div className="background-flame">
						<h2 className="margin-bottom-30">¿ No estás registrado ?</h2>
						<ul className="color-white">
							<li>Para registrar una empresa debes contactarte con el <strong>call center al 22 693 9111.</strong></li>
							<li>Un Ejecutivo gestionará la creación de una cuenta para la empresa.</li>
							<li>Un ejecutivo entregará los datos para el ingreso de sucursal virtual como empresa.</li>

						</ul>
				 		<div className="login-submit-button-container">
							<button  className="login-submit-button" onClick={() => this.props.redirect("/login")}>ENTENDIDO</button>
							<img alt="" src={OrangeArrow} />
						</div>
					</div>
				</div>
			</GuestFrame>

		);
	}
}
