import React, { Component, useState } from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import Menu from './Menu.js';

import { useMediaQuery } from 'react-responsive';

import logoWhite from '../img/logo_white.png';
import menuOpen from '../img/menu-open.png';
import PhoneIcon from '../img/phone.png';
import arrowDownWhite from '../img/arrow_down_white.png';
import defaultAvatar from '../img/default_avatar.png';
import defaultAvatar2 from '../img/default_avatar2.png';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import {available} from '../config.js';

import iconMedidor from '../img/menu-medidor.svg';
import iconGranel from '../img/menu-granel.svg';
import iconCilynder from '../img/icon_cilindro.png';
import ReactDOM from 'react-dom';

import {readNotifications} from '../api.js';
function App(props) {
	const [hideOnScroll, setHideOnScroll] = useState("scrolling-top");
	const isSmall = useMediaQuery({ query: '(max-width: 1100px)' });
	useScrollPosition(({ prevPos, currPos }) => {
	  var scroll = null;
	  if(currPos.y > -115) {
	  	scroll = "scrolling-top"
	  }
	  else if(currPos.y > prevPos.y) {
	  	scroll = "scrolling-up"
	  }
	  else if(currPos.y < prevPos.y) {
	  	scroll = "scrolling-down"
	  }
	  if(scroll !== hideOnScroll) setHideOnScroll(scroll)
	}, [hideOnScroll])
	  return <Frame  {...props} scroll={hideOnScroll} isSmall={isSmall} />;
}
export default App;
class Frame extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  	user_expanded: false,
		  	notifications_expanded: false,
		  	menu_opened: !props.isSmall
		};

	/*const mq = window.matchMedia( "(max-width: 1100px)" );
		if (mq.matches) {
			this.state = {
				menu_opened: false
			};
		}
		else {
			this.state = {
				menu_opened: true
			};
		}*/
	}

	toggleMenu() {
		this.setState({menu_opened: !this.state.menu_opened});
	}
	toggleUserExpanded() {
		if(!this.state.user_expanded) {
			this.setState({notifications_expanded: false, user_expanded: true});
		} else {
			this.setState({user_expanded: false});
		}
	}
	toggleNotificationsExpanded() {
		if(!this.state.notifications_expanded) {
			this.setState({notifications_expanded: true, user_expanded: false});
			readNotifications((error) => {if(error) return console.log("error leyendo notificaciones")});
		} else {
			this.setState({notifications_expanded: false});
		}
	}
	render() {
		var profilePicture = this.props.user.profile_picture?this.props.user.profile_picture:defaultAvatar; 
		return (
			<div className={this.props.scroll}>
				<Menu opened={this.state.menu_opened} toggleMenu={this.toggleMenu.bind(this)} location={this.props.location} menuOptions={this.props.menuOptions} onClick={() => {if(this.props.isSmall)this.toggleMenu()}} />
				<div className={"header-container" + (this.state.menu_opened ? " menu-opened" : "")}>
					<div style={{position: "relative"}}>
					<div style={{position: "absolute", top: 0, left: 0, right: 0}}>
						<div className={"header-gradient" + (this.props.header_class?" "+this.props.header_class:"")}>
							<div className="header-columns">
								<div className="flex-row align-items-center">
									<img alt="" src={menuOpen} className="menu-open" onClick={() => this.toggleMenu()} />
									<img alt="Abastible - Sucursal Virtual" src={logoWhite} className="header-logo" />
								<div className="header-phone-info">
									<img alt="" src={PhoneIcon} className="guest-header-icon margin-right" />
									<p className="color-white light">Emergencias: <strong>600 200 9000</strong></p>
								</div>
								</div>
								<div className="header-user-bar flex-row align-items-center not-selectable">
									{!available.header_notifications?null:
									<div className="header-circle icon bell-icon" onClick={() => {this.toggleNotificationsExpanded()}}>
										{this.props.notifications&&this.props.notifications.filter((n)=>n.status==="0").length?<div className="header-notification-bell-circle">
											<p className="color-white fw600">{this.props.notifications?this.props.notifications.filter((n)=>n.status==="0").length:"-"}</p>
										</div>:null}
										{!this.state.notifications_expanded?null:
											<ExpandedNotifications toggleNotificationsExpanded={this.toggleNotificationsExpanded.bind(this)} notifications={this.props.notifications}
									            redirectWithData={this.props.redirectWithData}
											 />}
									</div>}
									<div className="user-header" onClick={() => {this.toggleUserExpanded()}}>
										<div className="header-circle header-avatar" style={{backgroundImage: "url(" + profilePicture + ")"}}>
										</div>
										<p className="header-username color-white fw600">
											{this.props.user ? this.props.user.name + " " + this.props.user.last_name : ""}
										</p>
										<img src={arrowDownWhite} className="header-user-arrow" alt="arrow" />
										{!this.state.user_expanded?null:
											<ExpandedUser user={this.props.user} logout={this.props.logout} user_expanded={this.state.user_expanded} />}
									</div>
								</div>
							</div>
							{/*this.props.header ? this.props.header() : null*/}
						</div>
						<div className="header-gradient-image">
						</div>
					</div>
					</div>
					<div className="content">
						<div className="content-header-margin">
							{this.props.children}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export class ExpandedUser extends Component {
	render() {
		var profilePicture = this.props.user.profile_picture?this.props.user.profile_picture:defaultAvatar2;		
		return (
			<div className={"header-user-bar-expanded" + (this.props.user_expanded ? " opened" : "")}>
				<div className="col-30 left">
					<div className="header-circle header-avatar" style={{backgroundImage: "url(" + profilePicture + ")"}}>
					</div>
				</div>
				<div className="left header-userbar-avatar">
					<p className="header-username fw600">{this.props.user.name} {this.props.user.last_name}</p>
					<p className="header-mail">{this.props.user.email}</p>
					<Link to={'/editar-perfil'}><p className="edit-profile button orange transition">Editar Perfil</p></Link>
					<div className="logout transition" onClick={() => {this.props.logout()}}>Cerrar Sesión</div>
				</div>
			</div>
		);
	}
}
export class ExpandedNotifications extends Component {
	
	render() {
		return (
			<div onClick={(e)=> e.stopPropagation()} className={"header-notifications header-user-bar-expanded opened"}>
				<p className="header-notifications-title uppercase fw700 font-12">Notificaciones</p>
				<div className="header-notifications-content">
				{!this.props.notifications?null:this.props.notifications.map((notification, i)=> 
					<Notification key={notification.id} 
	            		redirectWithData={this.props.redirectWithData}
	            		toggleNotificationsExpanded={this.props.toggleNotificationsExpanded}
						notification={notification} />)}
				</div>
			</div>
		);
	}
}
export class Notification extends Component {	
	attachNode(node) {
    	this._form = ReactDOM.findDOMNode(node);
  	}
	notificationClick(notification) {
		var data = null;
		if(notification.data) data = JSON.parse(notification.data);
		if(data.type === "external") this._form.submit();
		else if(data.type === "internal") {
			this.props.redirectWithData(data.data);
			this.props.toggleNotificationsExpanded();
		}
	}
	render() {
		var notification = this.props.notification;
		var icon = null;
		var title = "";
		if(parseInt(notification.business_line_id) === 3) {
			icon = iconGranel;
			title = "Granel Habitacional";
		}
		if(parseInt(notification.business_line_id) === 1) {
			icon = iconMedidor;
			title = "Medidor";
		}
		if(parseInt(notification.business_line_id) === 4) {
			icon = iconCilynder;
			title = "Cilindro";
		}
		if(parseInt(notification.business_line_id) === 6) {
			icon = iconGranel;
			title = "Granel Industrial";
		}
		var data = null;
		if(notification.data) data = JSON.parse(notification.data);
		var formValues = [];
		if(data && data.type === "external" && data.data) {
			for (const prop in data.data) {    				
				formValues.push(<input type="hidden" name={prop} value={data.data[prop]} />);
			}
		}
		return (
			<div className={"header-notification flex flex-row" + (data? " pointer":"")} onClick={()=> data?this.notificationClick(notification):null}>
				<div>
					<img className={"header-notification-icon"} src={icon} />
				</div>
				<div>	
					<div className="flex flex-row justify-between">
						<div className="fw700 font-13">{title}</div>
						<div className="font-8 color-gray capitalize">{moment(notification.created_at).format("D MMM")}</div>
					</div>
					<div className="font-10">{notification.description}</div>
				</div>	
				{data && data.type === "external"?
				<form ref={this.attachNode.bind(this)} target="_blank"
				method="POST" action={data.url}>
				{formValues}
				</form>:null}
			</div>
		);
	}
}
