import React, { Component } from 'react';
import GridBox from './GridBox';
import FaqIcon from '../img/icon_faq.png';
import InfoIcon from '../img/icon_info.png';
import BuscaIcon from '../img/icon_busca.png';


var newServices = [
	{
		name: "Preguntas Frecuentes",
		desc: "Revisa las respuestas a las preguntas más recurrentes que recibimos de nuestros clientes.",
		icon: FaqIcon,
		a: "https://www.abastible.cl/gas-cilindro/atencion-al-cliente/#home-faq"
	},
	{
		name: "Información",
		desc: "Revisa en detalle los distintos tipos de cilindros que tenemos disponibles, infórmate de las promociones y concursos vigentes, y accede a prácticos consejos que te ayudarán a disfrutar de forma segura de la energía de Abastible.",
		icon: InfoIcon,
		a: "https://www.abastible.cl/"
	},
	{
		name: "Busca tu distribuidor",
		desc: "Encuentra a tu distribuidor de cilindros más cercano",
		icon: BuscaIcon,
		a: "https://www.abastible.cl/gas-cilindro/distribuidores/"
	}
];
export default class InformacionInteresCilindro extends Component {
	render() {
		return (
        	<div className="informacion-choose-container margin-top-40">
				{newServices.map((service, i) => {
					return(<GridBox key={i} service={service} buttonLabel="SABER MÁS" />);
				})}
			</div>
		);
	}
};
