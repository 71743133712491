import React, { Component } from 'react';

export default class CotizacionTable extends Component {
	render() {
		var total = 0;
		return (
			<div className="cotizacion-table">
				<table>
					<thead>
						<tr>
							<td>Tipo</td>
							<td>Valor unitario</td>
							<td>Cantidad</td>
							<td>Subtotal</td>

						</tr>						
					</thead>
					<tbody>
						{this.props.items?this.props.items.map((item)=> {
							var subtotal = item.value*item.count;
							total += subtotal;
							return(
							<tr>
								<td>{item.name}</td>
								<td>${item.value.toLocaleString("de")}</td>
								<td><input value={item.count} onChange={(e) => this.props.itemCountChange(item, e.target.value)} /></td>
								<td>${(subtotal).toLocaleString("de")}</td>

							</tr>)
						}):null}
						<tr>
							<td></td>
							<td></td>
							<td>Total: </td>
							<td>${(total).toLocaleString("de")}</td>

						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}
