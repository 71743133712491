import React, { Component } from 'react';

export default class StepSwitch extends Component {
	render() {
		return (
			<div className="StepSwitch flex-row">
				{this.props.options.map((option, i) => {
					return( 
						<div key={option.step} /*onClick={() => {this.props.onChange(option.step)}}*/ className={"StepSwitch-step flex-row" + (i > 0  ? " flex1" : "") + (option.step <= this.props.selected  ? " active" : "")}>
							{i > 0 ? <div className="StepSwitch-line"></div> : null}
							<div className="StepSwitch-circle">
								<p>{option.step}</p>
								<div className="StepSwitch-label">
									<p>{option.label}</p>
								</div>
							</div>
						</div>						
					);

				})}
			</div>
		);
	}
}
