import React, { Component } from 'react';
import Tooltip  from 'rc-tooltip';
import Button1  from './Button1';
import ArrowRightWhite from '../img/arrow_right_white.png';
import {formatRut, checkRut} from '../utils.js';

export default class ConfirmUserData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.data && this.props.data.name ? this.props.data.name : "",
			last_name: this.props.data && this.props.data.last_name ? this.props.data.last_name : "",
			email: this.props.data && this.props.data.email ? this.props.data.email : "",
			dni: "",
			phone: "",
			errors: []
		}
	}
	sendForm() {
		this.setState({loading: true});
		if(this.state.dni && !checkRut(this.state.dni)) return this.setState({errors: {dni: "El rut ingresado no es válido"}});
		this.props.doRegister(this.state.name, this.state.last_name, this.state.email, this.state.dni, undefined, undefined, this.state.phone, (error, result) => {
			this.setState({loading: false});
			if(error) {
				this.setState({errors: error.errors});
				console.log("error", error.errors);
			}
		});

	}
	deleteFormError(field_name) {
		var formErrors = {...this.state.errors};
		delete formErrors[field_name];
		this.setState({errors: formErrors})
	}
	render() {
		return (
			<div className="full-screen-veil show-modal">
				<div className="modal1">
					<div className="content" id="modal-content">
						<h1 className="fw700">Confirmar Información</h1>
						<div className="modal1-field-column" style={{display: "block"}}>
							<div className="modal1-field-container">
								<p>Nombre *</p>
							 	<Tooltip align={{overflow: {adjustY: false}}} getTooltipContainer={()=>document.getElementById("modal-content")} placement="bottom" visible={this.state.errors && this.state.errors.name ? true : false}
							 	overlay={<span>{this.state.errors ? this.state.errors.name : ""}</span>}>
							 	<input
							 		value={this.state.name}
							 		onChange={(e) => {this.setState({name: e.target.value}); this.deleteFormError("name")}} />
								</Tooltip>

					 		</div>
					 		<div className="modal1-field-container">
								<p>Apellido *</p>
							 	<Tooltip align={{overflow: {adjustY: false}}} getTooltipContainer={()=>document.getElementById("modal-content")} placement="bottom" visible={this.state.errors && this.state.errors.last_name ? true : false}
							 	overlay={<span>{this.state.errors ? this.state.errors.last_name : ""}</span>}>
							 	<input
							 		value={this.state.last_name}
							 		onChange={(e) => {this.setState({last_name: e.target.value}); this.deleteFormError("last_name")}} />
								</Tooltip>

					 		</div>
					 		<div className="modal1-field-container">
								<p>Rut *</p>
								<Tooltip align={{overflow: {adjustY: false}}} getTooltipContainer={()=>document.getElementById("modal-content")} placement="bottom" visible={this.state.errors && this.state.errors.dni ? true : false}
							 	overlay={<span>{this.state.errors ? this.state.errors.dni : ""}</span>}>
							 	<input
							 		value={this.state.dni}
							 		onChange={(e) => {this.setState({dni: formatRut(e.target.value)}); this.deleteFormError("dni")}} />
								</Tooltip>

					 		</div>
							<div className="modal1-field-container">
								<p>Email *</p>
							 	<Tooltip align={{overflow: {adjustY: false}}} getTooltipContainer={()=>document.getElementById("modal-content")} placement="bottom" visible={this.state.errors && this.state.errors.email ? true : false}
							 	overlay={<span>{this.state.errors ? this.state.errors.email : ""}</span>}>
							 	<input
							 		value={this.state.email}
							 		onChange={(e) => {this.setState({email: e.target.value}); this.deleteFormError("email")}} />
								</Tooltip>

					 		</div>
					 		<div className="modal1-field-container padding-bottom-30">
								<p>Teléfono * <span className="text-align-left fw200 inline" style={{fontWeight: 200, fontSize: "14px"}}>(ej. 912345678)</span></p>
								<Tooltip align={{overflow: {adjustY: false}}} getTooltipContainer={()=>document.getElementById("modal-content")} placement="bottom" visible={this.state.errors && this.state.errors.phone ? true : false}
							 	overlay={<span>{this.state.errors ? this.state.errors.phone : ""}</span>}>
							 	<input
							 		value={this.state.phone}
							 		onChange={(e) => {this.setState({phone: e.target.value}); this.deleteFormError("phone")}} />
								</Tooltip>				 		 		
					 		</div>
					 		</div>
						</div>
						<Button1
							label="CONFIRMAR"
							className="margin-top-30 margin-bottom-30" icon={ArrowRightWhite}
							onClick={() => this.sendForm()} />
						<p>Para poder continuar tienes que confirmar esta información</p>
				</div>
			</div>
		);
	}
}
