import React, { Component } from 'react';
import {Route, Switch} from "react-router-dom";
import Solicitud from './Solicitud';
import SolicitudesMisRequerimientos from './SolicitudesMisRequerimientos.js';


export default class Requerimientos extends Component {
	constructor(props) {
	  super(props);
	}

	componentDidMount() {
		this.props.changeHeaderClass("medium");

	}
	render() {
		return(
			<Switch>
					<Route exact path="/requerimientos/">
	                	<SolicitudesMisRequerimientos isEmpresa={this.props.isEmpresas?true:false}/>
	              </Route>
	              <Route path="/requerimientos/:id" render={(data)=> {
	                //if(data.match.params.id === "nueva") return;
	                return <Solicitud
						isEmpresas={this.props.isEmpresas?true:false}
						createActionNotification={this.props.createActionNotification}
						changeHeaderClass={this.props.changeHeaderClass}
						user={this.props.user}
						request_id={parseInt(data.match.params.id)}/>
	              }}/>
			</Switch>
		);
	}
}
