import React, { useState, useEffect } from 'react'
import ContentBox from './ContentBox.js';
import ClientSelectMedidor from './ClientSelectMedidor';
import { meterBussinesLineSimulate, meterBussinesLineFormIds } from '../api';
import PopupReintentarSimulador from "./PopupReintentarSimulador"
import { useHistory } from "react-router-dom"
import moment from 'moment';

export default function ServiciosMedidorSimulador({selectedService, services, setSelectedService, setSolicitudRequerimientoPreloadData, solicitud_requerimiento_preload}) {
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState(false)
    const [cantidadCuotas] = useState(48)
    const [cuotasACalcular, setCuotasACalcular] = useState(1)
    const [totalPorCuota, setTotalPorCuota] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [showReintentar, setShowReintentar] = useState(false)
    const [functionToggle, setFunctionToggle] = useState(false)
    const [solicitudPreload, setSolicitudPreload] = useState(false)
    const [formData, setFormData] = useState(false)
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        if(selectedService) {
           meterBussinesLineSimulate(moment().format("YYYY-MM-DD"), selectedService.contract_cod, (err, res) => {
                if (err) {
                    console.log(err)
                    setLoading(false)
                }
                if (res) {
                    if (res.status === "ok") {
                        setAmount(res.amount);
                        setLoading(false)
                        setErrorMsg(false)
                        setShowReintentar(false)
                    } else if (res.status === "empty") {
                        setAmount(false)
                        setErrorMsg("El cliente seleccionado no registra deuda.")
                        setLoading(false)
                        setShowReintentar(false)
                    } else if (res.status === "error") {
                        setAmount(false)
                        setErrorMsg("En este momento no podemos realizar la acción solicitada.")
                        setLoading(false)
                        setShowReintentar(true)
                    }
                }
            })
        }
    }, [selectedService])

    useEffect(() => {
            let crmId = "C361";
            meterBussinesLineFormIds(crmId, (err,res) => {
                if (err) {
                    console.log(err)
                }
                if (res && res.status === "ok") {
                    setFormData(res.data)
                }
            })
    }, [])

    useEffect(() => {
        if (solicitud_requerimiento_preload && solicitudPreload) {
            history.push({pathname: "/mis-servicios/medidor/requerimientos"})
        }
    }, [solicitud_requerimiento_preload])

    useEffect(()=>{
        if (functionToggle) {
            setShowReintentar(false)
            setFunctionToggle(false)
            meterBussinesLineSimulate(moment().format("YYYY-MM-DD"), selectedService.contract_cod, (err, res) => {
                if (err) {
                    console.log(err)
                    setLoading(false)
                }
                if (res) {
                    console.log(res)
                    if (res.status === "ok") {
                        setAmount(res.amount);
                        setErrorMsg(false)
                        setLoading(false)
                        setShowReintentar(false)
                    } else if (res.status === "empty") {
                        setAmount(false)
                        setErrorMsg("El cliente seleccionado no registra deuda.")
                        setLoading(false)
                        setShowReintentar(false)
                    } else if (res.status === "error") {
                        setAmount(false)
                        setErrorMsg("En este momento no podemos realizar la acción solicitada.")
                        setLoading(false)
                        setShowReintentar(true)
                    }
                }
            })
        }
    }, [functionToggle])

    useEffect(() => {
        if (cuotasACalcular && amount) {
            let total = "$" + parseInt(Math.round(amount/cuotasACalcular)).toLocaleString("de")
            setTotalPorCuota(total)
        }
    }, [cuotasACalcular, amount])

    const handleCuotasSelector = (e) => {
        setCuotasACalcular(e.target.value)
    }

    return (
        <ContentBox loading={loading}>
            <div className="title-client-container clearfix">
                <h1>Simulador convenio ley 21.340</h1>
                <div className="client-number">
                    <ClientSelectMedidor
                        selected={selectedService} 
                        services={services}
                        setSelectedService={setSelectedService} />		
                </div>
                <span className="featured-section-text">
                    En esta sección podrás simular tu convenio en caso de que
                    acojas a La ley n° 21.340, y cumplas alguno de los criterios 
                    de vulnerabilidad establecidos en esta ley y que puedes <a target="_blank" href="https://www.abastible.cl/medidor/">revisar aquí.</a>
                </span>
            </div>
            {!amount ? null :
            <React.Fragment>
                <div className="simulador-monto grid-column-2-simulador clearfix margin-bottom-30 z-index-0">
                    <div className="simulador-monto">
                        <span>Monto total del convenio*</span>
                        <div className="servicios-servicio-box-details-price">
                            <p>${parseInt(amount).toLocaleString("de")}</p>
                        </div>
                    </div>
                    <div className="subcontent-box">
                        <div className="simulador-cuotas">
                            <p>Número de cuotas</p>
                            <div className="grid-item-full">
                                <select onChange={handleCuotasSelector} value={cuotasACalcular} style={{width: "50%", marginBottom: "20px"}}>
                                    {[...Array(cantidadCuotas)].map((e, i) => {
                                        return <option key={i} value={i+1}>{i+1}</option>
                                    })}
                                </select>
                            </div>
                            <div className="grid-item-full">
                                <p style={{fontSize: "20px"}}>Mensualmente Pagarías: <strong>{totalPorCuota}**</strong></p>
                                {formData ? 
                                    <button className="button orange" style={{marginTop: "20px"}} onClick={()=>{setSolicitudRequerimientoPreloadData(
                                        {selectedService: selectedService,
                                        selectedCategory: parseInt(formData.request_category_id),
                                        selectedType: parseInt(formData.id) }, setSolicitudPreload(true)
                                        )}} >Solicitar Convenio</button>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="detailed-section-text">
                    <div>
                        <span>
                        *Monto corresponde a los consumos acumulados a la fecha sin pagar (en el caso de existir) y no contempla intereses de mora 
                        ni gastos asociados desde Marzo del año 2020 a la fecha, según lo estipulado por la ley 21.340.
                        </span>
                    </div>
                    <div>
                        <span>**Este valor sólo considera tu cuota de convenio y no incluye el valor de consumo mensual.</span>
                    </div>
                    <div>
                        <span>
                        ***Convenio será ejecutado al término de la vigencia de la ley, fecha que se encuentra sujeta a posibles cambios de normativa.
                        </span>
                    </div>
                </div>
            </React.Fragment>
            }
            {!errorMsg ? null :
            <div className="message margin-bottom-40">
                <p>{errorMsg}</p>
            </div>}
            <PopupReintentarSimulador 
					cancel={()=>setShowReintentar(false)} 
                    show={showReintentar}
                    toggle={()=>setFunctionToggle(true)}
            />
        </ContentBox>
    )
}
