import React, { Component } from 'react';
import ServiceListBoxAutogas from './ServiceListBoxAutogas.js';

import Button2 from './Button2';
import {Link} from 'react-router-dom';
import {
	autogasBussinesLineList, autogasBussinesLineDelete} from '../api.js';
import ContentBox from './ContentBox.js';
import ModalConfirmation from './ModalConfirmation';
import Masonry from 'react-masonry-css';


export default class ServiciosAsociadosAutogas extends Component {
	constructor(props) {
	  super(props);
	  this.state = {	  	
	  	services: null,
		modal: false,
		serviceSelected: null
	  };
	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadServices();
	}	
	loadServices(cb) {
		this.setState({loading: true});
		var getFunction = null;
		getFunction = autogasBussinesLineList;


		getFunction((error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: true});
				return console.log("ocurrio un error con el request", error);
			}
			var response = result.status === "empty" ? [] : result.data;
			if(cb) cb(null, response);
			this.setState({services: response, loading: false});
		});
	}
	deleteService(service) {
		
		autogasBussinesLineDelete(service.client_cod, null,(error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			this.props.createActionNotification("", "El servicio se ha desvinculado de tu cuenta correctamente");
			this.loadServices((error, new_services) => {
				if(error) {
					console.log("error cargando nuevos servicios");
				}
				if(!new_services.length)
				this.props.redirect("/");

			});
			this.props.checkMenuOptions();

		});
		
	}
	deletePatente(service, patente) {		
		autogasBussinesLineDelete(service.client_cod, [patente], (error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			this.props.createActionNotification("", "La patente se ha desvinculado de tu cuenta correctamente");
			this.loadServices((error, new_services) => {
				if(error) {
					console.log("error cargando nuevos servicios");
				}
				if(!new_services.length)
				this.props.redirect("/");

			});
			this.props.checkMenuOptions();

		});
		
	}
	cancelModal(){
		this.setState({
			modal: false,
			modalPatente: null,
			serviceSelected: null,
		});
	}
	confirmModal(){
		this.setState({
			modal: false,
			modalPatente: null,
			serviceSelected: null,
		});
		this.deleteService(this.state.serviceSelected);
	}
	confirmModalPatente(){
		this.deletePatente(this.state.serviceSelected, this.state.modalPatente);
		this.setState({
			modal: false,
			modalPatente: null,
			serviceSelected: null,
		});
	}
	renderServices(nameLabel) {
		return !this.state.services ? null : this.state.services.map((service) => {
			return(<ServiceListBoxAutogas key={service.client_cod} displayNumberLabel={nameLabel} 
					service={service} type="desvincular" 
					onButtonClick={() => {this.setState({serviceSelected: service, modal: true})}}
					onPatenteButtonClick={(patente) => {this.setState({serviceSelected: service, modalPatente: patente})}} />);			
			
			});
	}
	render() {
		var subTitle = "";
		var buttonText = "";
		var nameLabel = "";
	
		subTitle = "Mis Cuentas";
		buttonText = "Asociar Cuenta";
		nameLabel = 'client_cod';

		return (
		
				<ContentBox key={this.props.service} className="asociar-servicios-container" loading={this.state.loading}>
					<div className="title-button-container margin-bottom-40 col-100 left clearfix">
						<h1>{subTitle}</h1>
						<Link to={"/adminitracion_cuentas/autogas/asociar"}><Button2 label={buttonText} className="orange right clearfix" /></Link>
					</div>
					<Masonry
					  breakpointCols={{default:2, 800:1}}
					  className="my-masonry-grid"
					  columnClassName="my-masonry-grid_column">
											{this.renderServices(nameLabel)}
					  
					</Masonry>
					<ModalConfirmation cancel={this.cancelModal.bind(this)} confirm={this.confirmModal.bind(this)} title="¿Seguro que deseas desvincular este cliente?" show={this.state.modal}/>
					<ModalConfirmation cancel={this.cancelModal.bind(this)} confirm={this.confirmModalPatente.bind(this)} title="¿Seguro que deseas desvincular esta patente?" show={this.state.modalPatente}/>

				</ContentBox>
			
		);
	}
}
