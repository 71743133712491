import React, { useEffect } from 'react'
import { cilindroGetLastPedido } from "../api"

export default function ServiceCardDetailCilindro({selectorValue}) {
/*    useEffect(()=>{
        cilindroGetLastPedido(selectorValue.client_cod, (err, res) => {
            if (err) {
                console.log(err)
            } else if (res) {
                console.log("-> Cilindro", res)
            }
        })
    }, []) */

    return (
        <React.Fragment>
            <div className="servicios-servicio-box-details-left">
                <div className="servicios-servicio-box-details-address">
                    <p>{selectorValue.client_address}</p>
                </div>
                <div className="servicios-servicio-box-details-city">
                    <p>{selectorValue.client_city + ", " + selectorValue.client_region_cod}</p>                    
                </div>
            </div>
            <div className="servicios-servicio-box-details-right">
                <div className="servicios-servicio-box-details-price">
                    <div className="servicios-servicio-box-details-price">
                        <p></p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
