import React, { useEffect, useState, useRef } from 'react'
import ContentBox from './ContentBox.js';
import ServicioAutogasSelectorPatente from "./ServicioAutogasSelectorPatente"
import { autogasGetMetas } from "../api"
import Paginator from "./Paginator"

export default function ServiciosAutogasMetas({patents, setSelectedPatenteAutogas, selectedPatenteAutogas}) {
    const [loading, setLoading] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [tableData, setTableData] = useState(false)
    const [dataPage, setDataPage] = useState(1);
    const [scroll, setScroll] = useState(true)
    const itemsPerPage = 10;

    useEffect(()=>{
        setLoading(true)
        if (selectedPatenteAutogas) {
            autogasGetMetas(selectedPatenteAutogas.patent, (err, res) => {
                if (err) {
                    console.log(err)
                }
                if (res) {
                    if (res.status === "ok") {
                        setTableData(res.data)
                        setEmpty(false)
                    } else if (res.status === "empty") {
                        setEmpty("No se han encontrado registros")
                        //setEmpty(res.message)
                    }
                }        
                setLoading(false)
            })
        }

    }, [selectedPatenteAutogas])

    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    return (
        <ContentBox loading={loading}>
            <div className="title-client-container clearfix">
                <h1>Metas</h1>                
                <div className="client-number">
                    <ServicioAutogasSelectorPatente 
                        selectedPatenteAutogas={selectedPatenteAutogas} 
                        patents={patents}
                        setSelectedPatenteAutogas={setSelectedPatenteAutogas} />	
                </div>                
            </div>
                    
            <div className="table-container-2" onScroll={()=>{setScroll(false)}}>
                <div className={scroll ? null : "icon-scroll-fade"}><div className="icon-scroll"></div></div>
                <table className="table1">
                    <thead>
                        <tr>
                            <td>Año</td>
                            <td>Mes</td>
                            <td>Meta</td>
                            <td>Acumulado</td>
                            <td>Cumplimiento</td>
                        </tr>							
                    </thead>
                    <tbody>
                        { tableData && tableData.length > 0 ? 
                            tableData.slice((dataPage - 1)*itemsPerPage, (dataPage - 1)*itemsPerPage + itemsPerPage).map((e, i) => {
                                return(
                                    <tr key={i}>
                                        <td>{e.year}</td>
                                        <td>{meses[Number(e.month) - 1]}</td>
                                        <td>{Math.round(parseFloat(e.goal.replace(/,/g, '.')))}</td>
                                        <td>{Math.round(parseFloat(e.liters.replace(/,/g, '.')))}</td>
                                        <td>{e.fulfillment}%</td>
                                    </tr>
                                )
                            })
                        : null }
                    </tbody>
                </table>
                { empty ? <p>{empty}</p> : null }
            </div>
            {tableData && tableData.length > itemsPerPage?<Paginator page={dataPage} items={tableData?tableData.length:0} itemsPerPage={itemsPerPage} onPageChange={(page) => setDataPage(page)} />:null}
            <div className="message margin-top-30">
                <p>La información mostrada corresponde al período de vigencia del contrato</p>
            </div>
        </ContentBox>
    )
}
