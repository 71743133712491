import React, { useEffect, useState } from 'react';
import LogoWhite from '../img/logo_white.png';
import PhoneIcon from '../img/phone.png';
import ContentBox from './ContentBox';
import MedidorIcon from '../img/icon_medidor.gif';
import Button2 from './Button2';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  meterBussinesCheckSubStatus,
  meterBussinesDecryptToken,
  meterBussinesUnsuscribe,
} from '../api';

export default function SubscriptionTogglePage() {
  const history = useHistory();
  const { token } = useParams();

  const [open, setOpen] = useState();
  const [modalType, setModalType] = useState(null);
  const [decryptedToken, setDecryptedToken] = useState(null);
  const [encryptedToken, setEncryptedToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [remainingContacts, setRemainingContacts] = useState(null);
  const [subStatus, setSubStatus] = useState(null);

  useEffect(() => {
    if (token) {
      setEncryptedToken(token);
      meterBussinesDecryptToken(token, (err, res) => {
        if (err) {
          return;
        }
        if (res) {
          setDecryptedToken(res);
          const currentUrl = window.location.href;
          const newUrl = currentUrl.replace(`/${token}`, '');
          window.history.replaceState(null, '', newUrl);
        }
      });
    }
  }, [token]);

  function handleClick() {
    setOpen(false);
    setIsLoading(true);

    const handleResponse = (err, res) => {
      setIsLoading(false);

      if (err) {
        setOpen(true);
        setModalType('error');
        console.error(err);
        return;
      }

      if (res?.status !== 'ok') {
        setOpen(true);
        setModalType('error');
        return;
      }

      setOpen(true);
      setModalType('success');
      setRemainingContacts(res.remaining_contacts);
    };

    meterBussinesUnsuscribe(encryptedToken, handleResponse);
  }

  useEffect(() => {
    if (!subStatus) {
      setIsLoading(true);
      meterBussinesCheckSubStatus(encryptedToken, (err, res) => {
        if (err) {
          setIsLoading(false);
          console.log(err);
        }
        if (res) {
          setIsLoading(false);
          if (res.status !== 'ok') return;
          setSubStatus(res.subscriptionStatus);
        }
      });
    }
  }, [encryptedToken, subStatus]);

  return (
    <>
      <div>
        {/* Header */}
        <div className='header-background h-44'>
          <div className='flex gap-4 justify-center items-center h-full flex-col md:px-20 md:flex-row md:justify-start md:gap-8 flex-mobile'>
            <img
              alt='Abastible - Sucursal Virtual'
              src={LogoWhite}
              className='w-36'
            />
            <div className='flex items-center gap-2 flex-mobile'>
              <img
                alt='ícono teléfono'
                src={PhoneIcon}
                className='guest-header-icon'
              />
              <p className='color-white light'>
                Emergencias: <strong>600 200 9000</strong>
              </p>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className='content mt--28 md:mt--36'>
          <ContentBox loading={decryptedToken === null || isLoading}>
            {subStatus === '0' ? (
              <div className='flex gap-8 w-fit mx-auto flex-wrap-reverse flex-mobile justify-center'>
                <div className='text-center space-y-4'>
                  <p className='max-md:text-center'>
                    Ya se ha procesado tu solicitud de desuscripción.
                  </p>
                  <p className='max-md:text-center'>
                    No es necesario realizar esta acción nuevamente.
                  </p>
                  <p className='max-md:text-center'>
                    Puedes suscribirte nuevamente a través de nuestros medios
                    disponibles.
                  </p>
                </div>
              </div>
            ) : (
              <div className='flex gap-8 w-fit mx-auto flex-wrap-reverse flex-mobile justify-center'>
                <img
                  alt='Medidor'
                  src={MedidorIcon}
                  width={150}
                  className='max-md:hidden'
                />
                <div className='space-y-4 text-center'>
                  <p>¡Hola!</p>
                  <p>
                    Estás a punto de cancelar la suscripción de tu boleta
                    electrónica asociada a:
                  </p>
                  <p>
                    N° de Cliente:{' '}
                    <b>{decryptedToken?.client_id ?? 'Sin información'}</b>
                  </p>
                  <p>
                    Correo: <b>{decryptedToken?.email ?? 'Sin información'}</b>
                  </p>
                  <p className='text-sm opacity-80'>
                    Te recordamos que, si tu boleta no se encuentra suscrita a
                    un correo electrónico, la recibiras de forma física en el
                    domicilio.
                  </p>
                  <div className='w-full mx-auto max-w-64 pt-8'>
                    <button
                      className='Button2 orange w-full'
                      onClick={() => {
                        setOpen(true);
                        setModalType('confirmation');
                      }}
                    >
                      <p>Aceptar</p>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </ContentBox>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen}>
        {modalType === 'confirmation' ? (
          <>
            <p>Cancelar la suscripción de boleta electrónica asociada a:</p>
            <br />
            <p>
              N° de Cliente:{' '}
              <b>{decryptedToken?.client_id ?? 'Sin información'}</b>
            </p>
            <p>
              Correo: <b>{decryptedToken?.email ?? 'Sin información'}</b>
            </p>
            <br />
            <p className='text-sm opacity-80'>
              Te recordamos que, si tu boleta no se encuentra suscrita a un
              correo electrónico, la recibiras de forma física en el domicilio.
            </p>
            <div className='col-100 left clearfix margin-top-20'>
              <Button2
                label='Cancelar'
                className='button button-border-orange left'
                onClick={() => setOpen(false)}
              />
              <Button2
                label='Aceptar'
                className='green right w-full md:w-fit'
                onClick={handleClick}
              />
            </div>
          </>
        ) : modalType === 'success' ? (
          <>
            <p>
              Tu <b>suscripción</b> a la boleta electrónica fue{' '}
              <b>cancelada con éxito</b>.
            </p>
            {remainingContacts ? (
              <p>
                La <b>boleta</b> electrónica <b>seguirá siendo enviada</b> a los{' '}
                <b>otros correos</b> electrónicos <b>suscritos</b> para este
                número de cliente.
              </p>
            ) : (
              <p>
                Recibirás la <b>boleta</b> en{' '}
                <b>formato físico en el domicilio</b> a contar del siguiente o
                subsiguiente mes de facturación.
              </p>
            )}
            <p>
              Si quieres <b>volver a suscribirte</b> puedes hacerlo a través de
              nuestros <b>canales digitales</b>.
            </p>
            <Button2
              label='Aceptar'
              className='orange'
              onClick={() => history.push('/login')}
            />
          </>
        ) : modalType === ' error' ? (
          <>
            <p>
              Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.
            </p>
            <Button2
              label='Aceptar'
              className='orange'
              onClick={() => history.push('/login')}
            />
          </>
        ) : null}
      </Modal>
    </>
  );
}

function Modal({ children, open }) {
  return (
    <div className={`full-screen-veil ${open ? 'show-modal' : ''}`}>
      <div className='modal1 space-y-4'>{children}</div>
    </div>
  );
}
