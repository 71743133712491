import React, { Component } from 'react';
import CloseOK from '../img/close_green.svg';
//import CloseError from '../img/close_orange.svg';
import NotificationOk from '../img/notification_ok.svg';
import NotificationError from '../img/notification_error_2.svg';


export default class ActionNotification extends Component {
	render() {
		return (
			<div className={"action-notification-container " + (this.props.notification.type ? this.props.notification.type : 'ok') + "  " + (this.props.show_notification ? 'fadeIn' : 'fadeOut')}>
				<div className="left" style={this.props.notification.type === "error" ? {border: "2px solid #fe5000"} : null}>
					<img alt="Notificación correcta" src={this.props.notification.type === "error" ? NotificationError : NotificationOk} />
				</div>
				<div className="right">
					<div className="action-notification-title">
						<p>{this.props.notification.title}</p>
					</div>
					<div className="action-notification-desc">
						<p>{this.props.notification.desc}</p>
					</div>
					<div className="solicitud-evaluacion-close-container-popup" onClick={() => this.props.close(this.props.notification)}>
						<img alt="Cerrar" src={CloseOK} />
					</div>
				</div>
			</div>
		);
	}
}
