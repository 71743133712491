import React from 'react';
import LogoWhite from '../img/logo_white.png';
import PhoneIcon from '../img/phone.png';
import ContentBox from './ContentBox';
import MedidorIcon from '../img/icon_medidor.gif';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function DesuscripcionFallaPage() {
  const history = useHistory();

  return (
    <>
      <div>
        {/* Header */}
        <div className='header-background h-44'>
          <div className='flex gap-4 justify-center items-center h-full flex-col md:px-20 md:flex-row md:justify-start md:gap-8 flex-mobile'>
            <img
              alt='Abastible - Sucursal Virtual'
              src={LogoWhite}
              className='w-36'
            />
            <div className='flex items-center gap-2 flex-mobile'>
              <img
                alt='ícono teléfono'
                src={PhoneIcon}
                className='guest-header-icon'
              />
              <p className='color-white light'>
                Emergencias: <strong>600 200 9000</strong>
              </p>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className='content mt--28 md:mt--36'>
          <ContentBox>
            <div className='flex gap-8 w-fit mx-auto flex-wrap-reverse flex-mobile justify-center'>
              <img
                alt='Medidor'
                src={MedidorIcon}
                width={100}
                className='max-md:hidden'
              />
              <div className='space-y-4 text-center'>
                <p>Estimado cliente</p>
                <p>
                  La <b>suscripción</b> de tu boleta electrónica tiene{' '}
                  <b>más de un correo asociado</b>.
                </p>
                <p>
                  Si quieres cancelar tu suscripción debes hacerlo en nuestra{' '}
                  <b>Sucursal Virtual</b>.
                </p>
                <div className='w-full mx-auto max-w-64 pt-8'>
                  <button
                    className='Button2 orange w-full'
                    onClick={() => {
                      history.push('/login');
                    }}
                  >
                    <p>Ir a Sucursal Virtual</p>
                  </button>
                </div>
              </div>
            </div>
          </ContentBox>
        </div>
      </div>
    </>
  );
}
