import {urls} from './config.js';
import ApiUser from './api_user_store';
var apiUrl = urls.backend;
//var token = null;
//var role = null;
var logoutCB = null;
var refreshCallbacks = null;

export function registerLogoutCallback(_logoutCB) {
  logoutCB = _logoutCB;
}
export function registerApiUser(_user, _token, _role, cb) {
  //user = _user;
  //if(!_role) _role = null;
  //token = _token;
  //role = _role;
  var user = new ApiUser({token: _token, role: _role});
  if(cb) cb();
} 
export function refreshToken(cb) {
    if(refreshCallbacks) {
      return refreshCallbacks.push(cb);    
    }
    let api_user = new ApiUser().get(); 
    refreshCallbacks = [cb];
    var url = api_user.role === "Administrador" || api_user.role === "Funcionario" ? "/company_user/refresh_token" : "/refresh_token";
    var body = {};    
    var options = {
      method: "POST",
    };   
    options.headers = {
      'Content-Type': 'application/json',
      //'Authorization': 'Bearer ' + token,
      'X-Requested-With': 'XMLHttpRequest'
    };
    if(api_user.token) body.token = api_user.token;
    options.body = JSON.stringify(body);
    fetch(apiUrl + url /*+ "?access_token=" + token*/, options)
    .then((response) => {
        if(response.status && response.status === 500) {
          refreshCallbacks.forEach((single_cb) => single_cb(response.json()));
          refreshCallbacks = null;
        } else if(response.status && response.status === 401) {
          return logoutCB();
        }else {
          return response.json();
        }
    })
    .then((responseJson) => {
      var token = responseJson.data.token;
      registerApiUser(api_user.user, token, api_user.role);
      localStorage.setItem('token', token);
      refreshCallbacks.forEach((single_cb) => single_cb(null, responseJson));
      refreshCallbacks = null;
    })
    .catch((error) => {
      refreshCallbacks.forEach((single_cb) => single_cb(error));
      refreshCallbacks = null;      
    });    
};
export function downloadFile(request_id, name, cb) {
    defaultPost("/request/file", {request_id, name}, cb);
};
export function postMe(cb) {
    defaultPost("/me", {}, cb);
} 
export function postLogin(email, password, cb) {
	defaultPost("/login/email", {email, password}, cb);
}
export function postLoginEmpresas(email, password, cb) {
  defaultPost("/company_user/login", {email, password}, cb);
}
export function postLoginExternal(code, cb) {
  defaultPost("/login/validate", {validate: code}, cb);
}
export function postCompanyLoginExternal(code, cb) {
  defaultPost("/company_user/login/validate", {validate: code}, cb);
}
export function postLoginFacebook(facebook_token, cb) {
  defaultPost("/login/facebook", {facebook_token}, cb);
}
export function postLoginGoogle(google_token, cb) {
  defaultPost("/login/google", {google_token}, cb);
}
export function postRegister(name, last_name, email, email_confirmation, dni, password, password_confirmation, phone, cb) {
  var body = {name, last_name, email, email_confirmation, dni, password, password_confirmation, phone};
	defaultPost("/register", body, cb);
}
export function postRegisterFacebook(name, last_name, email, email_confirmation, dni, password, phone, facebook_token, profile_picture, cb) {
  var body = {name, last_name, email, email_confirmation, dni, password, password_confirmation: password, phone, facebook_token, profile_picture};
  defaultPost("/register", body, cb);
}
export function postRegisterGoogle(name, last_name, email, email_confirmation, dni, password, phone, google_token, profile_picture, cb) {
  var body = {name, last_name, email, email_confirmation, dni, password, password_confirmation: password, phone,  google_token, profile_picture};
  defaultPost("/register", body, cb);
}
export function postVerifyEmail(code, cb) {
  defaultPost("/verify", {verify_email_token: code}, cb);
}
export function postVerifyEmailAgain(email, cb) {
  defaultPost("/verify_again", {email}, cb);
}
export function postRecover(email, cb, isEmpresas) {
  var url = "/recover";
  if(isEmpresas) url = "/company_user" + url;
  defaultPost(url, {email}, cb);
}
export function getNotifications(cb) {
    let api_user = new ApiUser().get();  
    var url = api_user.role !== "Administrador" && api_user.role !== "Funcionario" ? "/housing/notification/list" : "/company_user/notification/list";
    defaultPost(url, {}, cb);
} 
export function readNotifications(cb) {
    let api_user = new ApiUser().get();
    var url = api_user.role !== "Administrador" && api_user.role !== "Funcionario" ? "/housing/notification/check" : "/company_user/notification/check";
    defaultPost(url, {}, cb);
} 

export function postSendHelpMail(id_person, id_business_line, street, number, second_numbering, region, comune, cb, patent) {
  defaultPost("/person/email_request_help", {id_person, id_business_line, street, number, second_numbering, region, comune, patent}, cb);
}
export function getCategories(cb, custom) {
  var url = "/request_category/list";
  if(custom && custom === "empresas") {
    url = "/company_user/request_category/list";
  }
  defaultGet(url, cb);
}
export function getServices(cb, custom) {
  var url = "/request_category/business_line/list";
  if(custom && custom === "empresas") {
    url = "/company_user/request_category/business_line/list";
  }
  defaultGet(url, cb);
}
export function getRequestTypes(cb, custom) {
  var url = "/request_type/list";
  if(custom && custom === "empresas") {
    url = "/company_user/request_type/list";
  }
  defaultGet(url, cb);
}
export function addressBussinesLineSearch(city, street, region, cb) {
  defaultPost("/bussines_line/address/search", {comune: city, street, region}, cb);
}
//MEDIDOR
export function meterBussinesLineFormIds(crm_type_id, cb) {
  defaultPost("/request/type/ids", {crm_type_id}, cb);
}
export function meterBussinesLineSimulate(date, contract_cod, cb) {
  defaultPost("/simulation/amount", {date, contract_cod}, cb);
}
export function meterBussinesLineSubsSync(cb) {
  defaultPost("/tickets/sync_suscriptions", {}, cb);
}
export function meterBussinesLineSubsList(cb) {
  defaultPost("/tickets/suscription_list", {}, cb);
}
export function meterBussinesSaveSubs(suscriptions, cb) {
  defaultPost("/tickets/register", {suscriptions: suscriptions}, cb);
}
export function meterBussinesUnsuscribe(token, cb) {
  defaultGet(`/tickets/unsuscribe/${token}`, cb);
}
export function meterBussinesDecryptToken(token, cb) {
  defaultPost("/tickets/decrypt_token", { text: token}, cb);
}
export function meterBussinesCheckSubStatus(token, cb) {
  defaultGet(`/tickets/check_status/${token}`, cb);
}
export function meterBussinesLineSearch(terms, cb) {
  defaultPost("/bussines_line/meter/search", {...terms}, cb);
}
export function meterBussinesLineRecommended(cb) {
  defaultPost("/bussines_line/meter/recomended", {}, cb);
}
export function meterBussinesLineList(cb) {
  defaultPost("/bussines_line/meter/list", {}, cb);
}
export function meterBussinesLineAdd(client_cod, contract_cod, cb) {
  defaultPost("/bussines_line/meter/store", {client_cod, contract_cod}, cb);
}
export function meterBussinesLineDelete(client_cod, contract_cod, cb) {
  defaultPost("/bussines_line/meter/delete", {client_cod, contract_cod}, cb);
}


//GRANEL HABITACIONAL
export function housingBussinesLineSearch(terms, cb) {
  defaultPost("/bussines_line/housing/search", {...terms}, cb);
}
export function housingBussinesLineRecommended(cb) {
  defaultPost("/bussines_line/housing/recomended", {}, cb);
}
export function housingBussinesLineList(cb) {
  defaultPost("/bussines_line/housing/list", {}, cb);
}
export function industrialBussinesLineList(cb) {
  //defaultPost("/company_user/bussines_line/industrial/list", {}, cb);
  defaultPost("/privileges/self_get", {/*client_code: "0011044787"*/}, cb);

}
//CILINDRO
export function cilindroBussinesLineList(cb) {
  defaultPost("/bussines_line/cylinder/list", {}, cb);
}
export function cilindroBussinesLineRecommended(cb) {
  defaultPost("/bussines_line/cylinder/recomended", {}, cb);
}
export function cilindroBussinesLineSearch(terms, cb) {
  defaultPost("/bussines_line/cylinder/search", {...terms}, cb);
}
export function cilindroBussinesLineAdd(client_cod, cb) {
  defaultPost("/bussines_line/cylinder/store", {client_cod}, cb);
}
export function cilindroBussinesLineDelete(client_cod, cb) {
  defaultPost("/bussines_line/cylinder/delete", {client_cod}, cb);
}
export function cilindroGetPrices(client_cod, cb) {
  defaultPost("/cylinder/get_prices", { client_cod }, cb);
}
export function cilindroNotifications(first_time, cb) {
  defaultPost("/cylinder/generate_status_change_notifications", { first_time }, cb);
}
export function cilindroGetDiscounts(cb) {
  defaultPost("/cylinder/discounts_available", { }, cb);
}
export function cilindroGetPedidos(client_cod, days, order_number, cb, custom) {
  var url = "/cylinder/order_search";
  /*if(custom && custom === "empresas") {
    url = "/company_user/industrial/order_search";
  }*/
  defaultPost(url, {client_cod, days, order_number}, cb);
}
export function cilindroGetPedido(order_number, cb, custom) {
  var url = "/cylinder/order_detail";
  /* if(custom && custom === "empresas") {
    url = "/company_user/industrial/order_detail";
  } */
  defaultPost(url, {client_cod: null, order_number}, cb);
}
export function cilindroGetLastPedido(client_code, cb) {
  defaultPost("/cylinder/last_order", { client_code }, cb)
}
export function getCilindroOrderCoords(truck_code, cb) {
  defaultPost("/cylinder/order_coordinates", {truck_code}, cb)
}

//CILINDRO INDUSTRIAL

export function cilindroIndustrialGetCuotas(client_cod, cb) {
  defaultPost("/company_user/institutional/quotes_type", { client_cod }, cb);
}
export function cilindroIndustrialCotizacionMateriales(client_cod, quote_type, payment_code, cb) {
  defaultPost("/company_user/institutional/material_list", {client_cod, quote_type, payment_code}, cb);
}
export function cilindroIndustrialCotizacionCreate(data, cb) {
  defaultPost("/company_user/institutional/quote/create", data, cb);
}
export function cilindroIndustrialCotizaciones(data, cb) {
  defaultPost("/company_user/institutional/quote/list", data, cb);
}

//AUTOGAS
export function autogasBussinesLineSearch(searchType, searchTerm, cb) {
  let search = {}
  if (searchType === "Patente") {
    search = {patent: searchTerm.toUpperCase()}
  }
  if (searchType === "Rut") {
    search = {rut: searchTerm.toUpperCase()}
  }
  defaultPost("/bussines_line/autogas/search", search, cb);
}
export function autogasBussinesLineRecommended(cb) {
  defaultPost("/bussines_line/autogas/recomended", {}, cb);
}
export function autogasBussinesLineList(cb) {
  defaultPost("/bussines_line/autogas/list", {}, cb);
}
export function autogasBussinesLineAdd(client_cod, patentes, cb) {
  defaultPost("/bussines_line/autogas/store", {client_cod, patents:patentes}, cb);
}
export function autogasBussinesLineDelete(client_cod, patentes, cb) {
  defaultPost("/bussines_line/autogas/delete", {client_cod, patents: patentes}, cb);
}
export function autogasGetPatentes(client_cod, cb) {
  defaultPost("/autogas/patent/list", { client_cod }, cb);
}
export function autogasGetContract(contract_number, patent, cb) {
  defaultPost("/autogas/contract", { contract_number, patent }, cb);
}
export function autogasGetMaintenance(contract_number, patent, option, cb) {
  defaultPost("/autogas/maintenance_assistance_history", { contract_number, patent, option }, cb);
}
export function autogasGetNotice(contract_number, patent, notice_id, cb) {
  defaultPost("/autogas/maintenance_assistance_detail", { contract_number, patent, notice_id }, cb);
}
export function autogasGetConsumoMensual(patent, cb) {
  defaultPost("/autogas/record/current", { patent }, cb);
}
export function autogasGetConsumoRango(start_day, end_day, patent, cb) {
  defaultPost("/autogas/record/list", { start_day, end_day, patent }, cb);
}
export function autogasGetMetas(patent, cb) {
  defaultPost("/autogas/goals", { patent }, cb);
}
export function autogasGetDashboard(patent, cb) {
  defaultPost("/autogas/dashboard", { patent }, cb);
}
export function autogasGetNextMaintenance(patent, cb) {
  defaultPost("/autogas/maintenance/next", { patent }, cb);
}
export function autogasGetFutureMaintenance(patent, cb) {
  defaultPost("/autogas/maintenance/future/list", { patent }, cb);
}
export function autogasGetMaintenanceRegion(patent, cb) {
  defaultPost("/autogas/maintenance/regions", { patent }, cb);
}
export function autogasGetMaintenanceWorkshops(region_code, cb) {
  defaultPost("/autogas/maintenance/workshops", { region_code }, cb);
}
export function autogasGetMaintenanceDatetimes(workshop_code, id_service, cb) {
  defaultPost("/autogas/maintenance/datetimes", { workshop_code, id_service }, cb);
}
export function autogasCreateMaintenance(solicitud, cb) {
  defaultPost("/autogas/maintenance/schedule", solicitud, cb);
}
export function autogasNextMaintenance(patent, cb) {
  defaultPost("/autogas/maintenance/next", { patent }, cb);
}
export function autogasCancelMaintenance(patent, notice_id, cb) {
  defaultPost("/autogas/maintenance/cancel", { patent, notice_id}, cb);
}
export function autogasMaintenancePrice(patent, num_mant, workshop_code, cb) {
  defaultPost("/autogas/maintenance/prices", { patent, num_mant, workshop_code}, cb);
}


export function userClientsPrivileges(cb) {
  //defaultPost("/company_user/bussines_line/industrial/list", {}, cb);
  defaultPost("/privileges/self_get", {/*client_code: "0011044787"*/}, cb);

}
export function housingTelemetryList(cb) {
  defaultPost("/housing/telemetry/list", {}, cb);
}
export function housingCuentaCorriente(client_cod, period, cb, custom) {
  var url = "/housing/current_account";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/current_account";
  }
  defaultPost(url, {client_cod, period}, cb);
}
export function housingCuentaCorrienteDetalle(client_cod, doc_number, doc_year, cb, custom) {
  var url = "/housing/document_details";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/document_details";
  }
  defaultPost(url, {client_cod, doc_number, doc_year}, cb);
}
export function housingTelemetryTanks(client_cod, cb, custom) {
  var url = "/housing/telemetry/tanques_list";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/telemetry/tanques_list";
  }
 defaultPost(url, {client_cod}, cb); 
}
export function housingTankTelemetry(client_cod, meter_code, cb, custom) {
  var url = "/housing/telemetry/tanque_details";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/telemetry/tanque_details";
  }
 defaultPost(url, {client_cod, meter_code}, cb); 
}
export function housingTankHistory(client_cod, tank_cod, start_date, end_date, cb, custom) {
  var url = "/housing/telemetry/record";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/telemetry/record";
  }
 defaultPost(url, {client_cod, tank_cod, start_date, end_date}, cb); 
}
export function housingBussinesLineAdd(client_cod, contract_cod, cb) {
  defaultPost("/bussines_line/housing/store", {client_cod, contract_cod}, cb);
}
export function housingBussinesLineDelete(client_cod, contract_cod, cb) {
  defaultPost("/bussines_line/housing/delete", {client_cod, contract_cod}, cb);
}
export function requestCategoryList(business_line_id, cb, custom) {
  var url = "/request/category/list";
  if(custom && custom === "empresas") {
    url = "/company_user/request/category/list";
  }
  defaultPost(url, {business_line_id}, cb);
}
export function requestForm(request_type_id, client_id, patent, cb, custom) {
  var url = "/request/type/get";
  if(custom && custom === "empresas") {
    url = "/company_user/request/type/get";
  }
  defaultPost(url, {request_type_id, client_id, patent}, cb);
}
export function sendRequestForm(client_id, patent, request_type_id, values, cb, custom) {
  var url = "/request/create";
  if(custom && custom === "empresas") {
    url = "/company_user/request/create";
  }
  defaultPost(url, {client_id, patent, request_type_id, ...values}, cb);
}
export function validateRequestForm(client_id, patent, request_type_id, values, cb, custom) {
  var url = "/request/validate";
  defaultPost(url, {client_id, patent, request_type_id, ...values}, cb);
}
export function getRequestList(start_date, end_date, request_type_id, business_line_id, request_category_id, cb, custom) {
  var url = "/request/list";
  if(custom && custom === "empresas") {
    url = "/company_user/request/list";
  }
  defaultPost(url, {start_date, end_date, request_type_id, business_line_id, request_category_id}, cb);
}
export function reqTree(cb, custom) {
  var url = "/request/type/tree";
  if(custom && custom === "empresas") {
    url = "/company_user/request/type/tree";
  }
  defaultPost(url, {}, cb)
}
export function updateProfile(id, dni, name, last_name, phone, password, password_confirmation, notification_check, password_duplicate, cb, custom) {
  var url = "/person/profile/update";
  if(custom && custom === "empresas") {
    url = "/company_user/profile/update";
  }
  defaultPost(url, {id, dni, name, last_name, phone, password, password_confirmation, notification_check, password_duplicate}, cb);
}
export function getProfile(id, cb, custom) {
  var url = "/person/profile/get";
  if(custom && custom === "empresas") {
    url = "/company_user/profile/get";
  }
  defaultPost(url, {id}, cb);
}
export function getRequest(request_id, cb, custom) {
  var url = "/request/get";
  if(custom && custom === "empresas") {
    url = "/company_user/request/get";
  }
  defaultPost(url, {request_id}, cb);
}
export function downPayment(request_id, cb) {
  defaultPost("/request/create/down_payment", {request_id}, cb);
}
export function getAgreementsList(contract_code, cb) {
  defaultPost("/request/agreements/list", {contract_code: contract_code}, cb);
}
export function getAgreement(agreements_id, cb) {
  defaultPost("/request/agreements/detail", {agreements_id: agreements_id}, cb);
}
export function evaluateRequest(request_id, calificacion, comentario, cb) {
  defaultPost("/request/rate", {request_id, calificacion, comentario}, cb);
}
export function getReadings(start_month, end_month, contract_cod, cb) {
  defaultPost("/reading/list", {star_month: start_month, end_month, contract_cod}, cb);
}
export function getReadingsGraph(contract_cod, cb) {
  defaultPost("/reading/graph", {contract_cod: ""+contract_cod}, cb);
}
export function getTickets(start_month, end_month, contract_cod, cb) {
  defaultPost("/tickets/list", {star_month: start_month, end_month, contract_cod}, cb);
}
export function getDebtMeasurer(contract_cod, cb) {
  defaultPost("/bill/get", {contract_cod: ""+contract_cod}, cb);
}
export function getDebtHousing(client_cod, cb, custom) {
  var url = "/housing/dashboard";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/available_credit";
  }
  defaultPost(url, {client_cod: ""+client_cod}, cb);
}
export function getPaymentHistory(start_date, end_date, contract_cod, cb) {
  defaultPost("/payment/history", {start_date, end_date, contract_cod}, cb);
}
export function getGranelPaymentHistory(client_cod, cb, custom) {
  var url = "/housing/payment/record";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/payment/record";
  }
  defaultPost(url, {client_cod}, cb);
}
export function getGranelPedidos(client_cod, days, order_number, cb, custom) {
  var url = "/housing/order_search";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/order_search";
  }
  defaultPost(url, {client_cod: ""+client_cod, days, order_number}, cb);
}
export function getGranelPedido(order_number, cb, custom) {
  var url = "/housing/order_detail";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/order_detail";
  }
  defaultPost(url, {client_cod: null, order_number}, cb);
}
export function getGranelFreqUpdateTruck(cb, custom) {
  var url = "/housing/freq_update_truck";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/freq_update_truck";
  }
  defaultPost(url, {}, cb);
}
export function getGranelOrderCoords(patent, cb, custom) {
  var url = "/housing/order_coordinates";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/order_coordinates";
  }
  defaultPost(url, {patent}, cb);
}
export function postHousingClaim(order_number, client_cod, comment, cb, custom) {
  var url = "/housing/claim";
  if(custom && custom === "empresas") {
    url = "/company_user/bussines_line/industrial/claim";
  }
  defaultPost(url, {order_number, client_cod, commentary: comment}, cb);
}
export function postCylinderClaim(order_number, client_cod, comment, cb, custom) {
  var url = "/cylinder/claim";
  if(custom && custom === "empresas") {
    //url = "/company_user/bussines_line/industrial/claim"; /// FALTA 
  }
  defaultPost(url, {order_number, client_cod, commentary: comment}, cb);
}
export function postHousingCancel(order_number, comment,  cb, custom) {
  var url = "/housing/order_cancel";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/order_cancel";
  }
  defaultPost(url, {order_number, reason: comment}, cb);
}
export function postCyinderCancel(order_number, cb, custom) {
  var url = "/cylinder/order_cancel";
  /* if(custom && custom === "empresas") {
    url = "/company_user/industrial/order_cancel";
  } */
  defaultPost(url, {order_number}, cb);
}
export function postHousingNotificationConfig(enabled, level, cb, custom) {
  var url = "/housing/telemetry/set_notifications";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/telemetry/set_notifications";
  }
  defaultPost(url, {enabled, level}, cb);
}
export function granelDetalleDeuda(client_cod, cb, custom) {
  var url = "/housing/deb_details";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/deb_details";
  }
  defaultPost(url, {client_cod}, cb);
}
export function granelCanPay(cb, custom) {
  var url = "/housing/can_pay";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/can_pay";
  }
  defaultPost(url, {}, cb);
}
export function granelGetPriceChanges(client_cod, cb, custom) {
  var url = "/housing/get_price_changes";
  if(custom && custom === "empresas") {
    url = "/company_user/industrial/get_price_changes";
  }
  
  defaultPost(url, {client_cod}, cb);
}
export function funcionariosList(type, value, cb) {
  defaultPost("/company_user/list", {type, value}, cb);
}
export function funcionarioCanDelete(id, cb) {
  defaultPost("/company_user/can_delete", {id}, cb);
}
export function funcionarioDelete(id, cb) {
  defaultPost("/company_user/delete", {id}, cb);
}
export function checkFuncionarioExists(dni, cb) {
  defaultPost("/company_user/exists", {dni}, cb);
}
export function requestFuncionario(dni, cb) {
  defaultPost("/company_user/request_user", {dni}, cb);
}
export function adminEmpresasClientesList(cb) {
  defaultPost("/company/list", {}, cb);
}
export function getPrivileges(client_code, cb) {
  defaultPost("/privileges/list", {client_code}, cb);  
}
export function postFuncionario(funcionario, cb) {
  defaultPost("/company_user/store", funcionario, cb);
}
export function editFuncionario(funcionario, cb) {
  defaultPost("/company_user/update", funcionario, cb);
}
export function getFuncionario(id, cb) {
  defaultPost("/company_user/get", {id}, cb);
}
export function getResumenTanques(client_code, cb) {
  defaultPost("/company_user/bussines_line/industrial/resume", {client_code}, cb);
}
export function getCompanySelector(cb) {
  defaultPost("/company_user/business_line/company/selector", {}, cb);
}
export function getServiciosSelector(cb) {
  defaultPost("/company_user/business_line/selector", {}, cb);
}
export function getCompanyClients(client_cod, company_id, business_line_id, cb) {
  defaultPost("/company_user/business_line/client/list", {client_cod, company_id, business_line_id}, cb)
}
export function getCompanyClientDetail(client_id, company_id, cb) {
  defaultPost("/company_user/business_line/client/get", {client_id, company_id}, cb)
}
export function getRegions(cb) {
  defaultPost("/regions", {}, cb);
}
export function getCampaignLogin(cb) {
  let url = "/campains/login"
  defaultGet(url, cb);
}

export function getCampaigns(cb, custom) {
  let url = "/person/campains"
  if (custom && custom === "Empresas") {
    url = "/company_user/campains"
  }
  defaultPost(url, {}, cb);
}

export function getEnvasadoInstitucionalCuentaCorriente(client_cod, period, cb) {
  defaultPost("/company_user/institutional/current_account", {client_cod, period}, cb)
}
export function getEnvasadoInstitucionalCuentaCorrienteDocumento(client_cod, doc_number, doc_year, cb) {
  defaultPost("/company_user/institutional/document_details", {client_cod, doc_number, doc_year}, cb)
}
export function documentCheck(pdf_number, class_description, cb, custom) {
  var url = "/document/has";
  if(custom && custom === "empresas") {
    url = "/company_user/document/has";
  }
  
  defaultPost(url, {pdf_number, class_description}, cb);
}
function defaultGet(url, cb) {
  	defaultCall(url, "GET", null, cb);
}
function defaultPost(url, body, cb) {
  	defaultCall(url, "POST", body, cb);
}
function defaultCall(url, method, body, cb) {
  var doRequest = function(url, method, body, cb) {
    let api_user = new ApiUser().get(); 
    var options = {
      method: method,
    };
    if(method === "POST") {
      options.headers = {
        'Content-Type': 'application/json',
        //'Authorization': 'Bearer ' + token,
        'X-Requested-With': 'XMLHttpRequest'
      };
      if(api_user.token) body.token = api_user.token;
      options.body = JSON.stringify(body);
    }else{
      options.headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + api_user.token,
        'X-Requested-With': 'XMLHttpRequest'
      };
    }
    fetch(apiUrl + url /*+ "?access_token=" + token*/, options)
    .then((response) => {
        if(response.status && response.status === 500) {
          return cb(response.json());
        }
        else if(response.status && response.status === 503) {
          return cb(response.json());
        }
        else if(response.status && response.status === 401 && api_user.token) {
          refreshToken((error, result) => {
            if(error) {
              return cb(error);
            }            
            doRequest(url, method, body, cb);
          })
        } else if(response.status && response.status === 401) {
          return response.json();
        }else {
          return response.json();
        }
    })
    .then((responseJson) => {
      if(cb) cb(null, responseJson);
    })
    .catch((error) => {
      if(cb) cb(error);
    });
  }
  doRequest(url, method, body, cb);
}