import React, {Component} from 'react';
import Clip from '../img/clip.svg';
import Tooltip from "rc-tooltip"

export default class FilePicker extends Component {
	constructor(props) {
		super(props);	
		this.state = {
			filename: null,
			sizeError: false
		};
	}
	async changeFormFileValue(e) {
		if(e.target.files[0]) {
			const filename = e.target.files[0].name;
			this.setState({filename});
			this.props.onChange(await toBase64(e.target.files[0]), filename);
			if (!this.props.value) {
				this.setState({sizeError: "El tamaño del archivo seleccionado supera lo permitido (10MB)"})
			} else {
				this.setState({sizeError: false})
			}
			
		}		
		else {
			this.unselect();
		}
	}
	unselect(e) {
		this.setState({filename: null});
		this.props.onChange(null);
	}

	render() {
		return (
			<Tooltip onClick={()=>{this.setState({sizeError: false})}} placement="bottom" visible={this.state.sizeError && !this.props.formIsSent && !this.props.isLoading ? true : false}
				overlay={<span>{this.state.sizeError ? this.state.sizeError : null}</span>}>
					<div onClick={() => {if(!this.props.value) this.inputFile.click()}}>					
						{this.props.value
						? <div className="file attached">
								<img alt="" src={Clip} />
								<span className="filename-label">"{this.state.filename}"</span>
								<span className="delete-attached" onClick={(e) => {this.unselect(e)}}>Quitar</span>
							</div>
						: <div className="file"><img alt="" src={Clip} /><span>{this.props.placeholder?this.props.placeholder:"Agregar archivos"}</span></div>}					
						
						<input className="hidden"
							ref={(ref) => this.inputFile = ref}
							onClick={(e) => {e.target.value = null}}
							onChange={(e) => {this.changeFormFileValue(e)}}
							type="file" />
					</div>
			</Tooltip>
		);
	}
}
const toBase64 = file => new Promise((resolve, reject) => {
	if (file.size >= 1024000*10) {
		resolve(null)
	}
    const reader = new FileReader();
	reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);    
    reader.onerror = error => reject(error);
});
