import React, { Component, Fragment, } from 'react';
import {Link} from 'react-router-dom';
import Button2 from './Button2';
import GridBox from './GridBox';
import {meterBussinesLineList, housingBussinesLineList, autogasBussinesLineList, cilindroBussinesLineList, getDebtMeasurer, getDebtHousing, } from '../api.js';
import ContentBox from './ContentBox.js';
import MedidorIcon from '../img/icon_medidor.gif';
import TanqueIcon from '../img/icon_tanque.png';
import AutogasIcon from '../img/icon_autogas.png'
import CilindroIcon from '../img/icon_cilindro.png'
import defaultAvatar from '../img/default_avatar2.png';
import {available, urls} from '../config.js';
import {getServiceAddressString} from '../utils.js';
import Campaigns from './Campaigns';
import ServiceCard from "./ServiceCard"
import ModalCampain from "./ModalCampain"
import Tooltip from 'rc-tooltip';


var newServices = [
	{
		name: "Cilindro",
		desc: "Usas cilindros de gas en alguno de sus distintos formatos: 05, 11, 15 o 45 kilos",
		icon: CilindroIcon,
		link: "/adminitracion_cuentas/cilindro/asociar",
		available: available.cilindro
	},
	{
		name: "Granel Habitacional",
		desc: "Tienes un tanque de gas en tu domicilio y lo abastecemos de acuerdo a tu necesidad",
		icon: TanqueIcon,
		link: "/adminitracion_cuentas/granel/asociar",
		available: available.granel
	},
	{
		name: "Autogas",
		desc: "Todas las borrascas que nos suceden son señales de que presto ha de serenar el tiempo",
		icon: AutogasIcon,
		link: "/adminitracion_cuentas/autogas/asociar",
		available: available.autogas
	},
	{
		name: "Medidor",
		desc: "Si mensualmente recibes una boleta en tu hogar por el consumo de gas",
		icon: MedidorIcon,
		link: "/adminitracion_cuentas/medidor/asociar",
		available: true
	}
];

const autogasLinks = [
	{
		available: true,
		url: "/mis-servicios/autogas/contrato",
		link: "Contrato"
	},
	{
		available: true,
		url: "/mis-servicios/autogas/consumo",
		link: "Consumo"
	},
	{
		available: true,
		url: "/mis-servicios/autogas/metas",
		link: "Metas"
	},
	{
		available: true,
		url: "/mis-servicios/autogas/mantenciones-asistencias",
		link: "Mantenciones y Asistencias"
	},
	/* {
		available: true,
		url: "/mis-servicios/autogas/agenda-mantenciones",
		link: "Agenda Mantenciones"
	}, */
	{
		available: true,
		url: "/mis-servicios/autogas/requerimientos",
		link: "Requerimientos"
	}
]

const cilindroLinks = [
	{
		available: available.cilindro_realizar_pedidos,
		url: "/mis-servicios/cilindro/realizar-pedidos",
		link: "Realizar Pedidos"
	},
	{
		available: available.cilindro_pedidos,
		url: "/mis-servicios/cilindro/precios",
		link: "Precios"
	},
	{
		available: available.cilindro_descuentos,
		url: "/mis-servicios/cilindro/descuentos",
		link: "Mis Descuentos"
	},
	{
		available: available.cilindro_pedidos,
		url: "/mis-servicios/cilindro/pedidos",
		link: "Pedidos"
	},
	{
		available: available.cilindro_requerimientos,
		url: "/mis-servicios/cilindro/requerimientos",
		link: "Requerimientos"
	}
]

export default class MyServices extends Component {
	constructor(props) {
	  	super(props);
	  	this.state = {
			meterServices: null,
			housingServices: null,
			cilindroServices: null,
			autogasServices: null,
			autogasPatentes: null,
			bills: [],
			debtHousing: null,
			loadingMedidor: false,
			requestReposition: false,
			meterInstalled: false,
			loadingCilindro: false,
			loadingAutogas: false,
			modal:false,
			iframelinkError: false,

		};
	}
	componentDidMount() {
		this.props.changeHeaderClass(null);
		this.loadServices();
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.selectedMeter !== this.props.selectedMeter) {
			this.handleDebt();
		}
		if(prevProps.selectedHousing !== this.props.selectedHousing) {
			this.handleDebtHousing();
		}
	}
	loadServices() {
		this.setState({loadingMedidor: true, loadingHousing: true, loadingCilindro: true, loadingAutogas: true });
		if (available.autogas) {
			autogasBussinesLineList((err, res) => {
				let autogasServices = []
				if(err) {
					console.log(err)
				} else {
					autogasServices = res.data
				}
				this.setState({autogasServices}, () => {
					if (autogasServices) {
						if(!this.props.selectedAutogas) this.props.setSelectedAutogas(autogasServices[0]);
						let autogasPatentes = []
						autogasServices.map(s => {
							return s.patents.map(p => {
								return autogasPatentes = [...autogasPatentes, 
									{patent: p.patent,
									conversion_contract_number: p.conversion_contract_number,
									client_cod: p.client_cod,
									client_id: s.client_id}]
							})
						})
						let _patents = []
						_patents =  autogasPatentes.sort((a, b) => {
							if (a.patent.toLowerCase() < b.patent.toLowerCase()) {
								return -1;
							} else if (a.patent.toLowerCase() > b.patent.toLowerCase()) {
								return 1;
							} else {
								return 0;
							}
						})
						this.setState({autogasPatentes: _patents}, () => {
							if (_patents) {
								if(!this.props.selectedPatenteAutogas) this.props.setSelectedPatenteAutogas(_patents[0]);
								this.setState({loadingAutogas: false});
							}
						})
					}
				})
			})
		}
		if (available.cilindro) {
			cilindroBussinesLineList((err, res) => {
				let cilindroServices = []
				if(err) {
					console.log(err)
				} else {
					cilindroServices = res.data
				}
				this.setState({cilindroServices}, () => {
					if (cilindroServices) {
						if(!this.props.selectedCilindro) this.props.setSelectedCilindro(cilindroServices[0]);
						this.setState({loadingCilindro: false});
					}
				})
			})
		}
		meterBussinesLineList((error, result) => {
			if(error) {
				console.log(error);
				console.log("ocurrio un error con el request");
				this.handleDebt();

			}else{
				var meterServices = null;
				if(result.status !== "empty") {
					meterServices = result.data;
				} else {
					meterServices = [];
					this.props.changeHeaderClass("big");
				}
				this.setState({meterServices}, () => {
					this.setState({loadingMedidor: false});
					if(!this.props.selectedMeter) this.props.setSelectedMeter(meterServices[0]);
					else this.handleDebt();
					housingBussinesLineList((error, result) => {
						if(error) {
							console.log(error);
							console.log("ocurrio un error con el request");
							this.handleDebtHousing();
						}else{
							var housingServices = null;
							if(result.status !== "empty") {
								housingServices = result.data;
							} else {
								housingServices = [];
								this.props.changeHeaderClass("big");
							}
							this.setState({housingServices}, () => {
								this.setState({loadingHousing: false});
								if(!this.props.selectedHousing) this.props.setSelectedHousing(housingServices[0]);
								else this.handleDebtHousing();
							});
						}
					});
				});
			}
		});
	}
	getDebt(contract_cod, cb){
		this.setState({loadingMedidor: true, medidorDebtMessage: null});

		getDebtMeasurer(contract_cod, (error, result) => {
			if(result){
				if(result.status === "ok") {
					this.setState({
						bills: result.data,
						loadingMedidor: false

					}, () => {}/*console.log("listo")*/);
				}
				if(result.status === "exception") {
					this.setState({
						bills: null,
						loadingMedidor: false,
						medidorDebtMessage: result.message

					});
				}
			}else{
				this.setState({
					bills: null,
					loadingMedidor: false

				});
			}
			if(cb) cb();
		});
	}
	getDebtHousing(client_cod, cb){
		this.setState({loadingHousing: true});
		getDebtHousing(client_cod, (error, result) => {
			if(result){
				if(result.status === "ok") {
					this.setState({
						debtHousing: result.data,
						loadingHousing: false

					}, () => {}/*console.log("listo")*/);
				}
				if(result.status === "empty") {
					this.setState({
						debtHousing: null,
						loadingHousing: false

					});
				}
				if(result.status === "exception") {
					this.setState({
						debtHousing: null,
						loadingHousing: false

					});
				}
			}else{
				this.setState({
					debtHousing: null,
					loadingHousing: false

				});
			}
			if(cb) cb();
		});
	}
	renderNewServiceBoxes() {
		return(
			<Fragment>
				<div className="service-choose-title color-white">
					<p>¿ Eres nuevo en Sucursal Virtual ?</p>
				</div>
				<div className="service-choose-subtitle color-white">
					<p>Selecciona el servicio que quieres agregar (puedes asociar más de uno):</p>
				</div>
				<div className="service-choose-container margin-top-40">
		          	{newServices.map((service, i) => {
		          		if (service.available) {
							return(<GridBox key={i} service={service} buttonLabel="AGREGAR" />);
						  } else {
							  return null
						  }
		          	})}
	          	</div>
          	</Fragment>
		);
	}
	handleDebt(cb){
		let meterService = null;
		if(this.state.meterServices){
			this.state.meterServices.forEach(element => {
				if(this.props.selectedMeter && element.client_cod === this.props.selectedMeter.client_cod) {
					meterService = element;
				}
			});
			if(meterService) {
				this.getDebt(meterService.contract_cod, cb?cb:null);
			}
		}
	}
	handleDebtHousing(cb){
		let housingService = null;
		if(this.state.housingServices){
			this.state.housingServices.forEach(element => {
				if(this.props.selectedHousing && element.client_cod === this.props.selectedHousing.client_cod){
					housingService = element;
				}
			});
			if(housingService) {
				this.getDebtHousing(housingService.client_cod, cb?cb:null);
			}
		}
	}
	paymentPortal(){
		let url = '';
		this.state.meterServices.forEach(element => {
			if(element.client_cod === this.props.selectedMeter.client_cod || !this.props.selectedMeter){
				if(!this.props.selectedMeter){
					url = urls.portal_pago + 'medidor?codCliente=' + Number(this.state.meterServices[0].contract_cod).toString() + '&email=' + this.props.user.email + '&movil=' + this.props.user.phone + '&reposicion=' + this.state.requestReposition;
				}else{
					url = urls.portal_pago + 'medidor?codCliente=' + Number(element.contract_cod).toString() + '&email=' + this.props.user.email + '&movil=' + this.props.user.phone + '&reposicion=' + this.state.requestReposition;
				}
			}
		});
		window.open(url, '_blank');
  		window.focus();
	}
	renderLastBill(){
		let lastBill = this.state.bills ? this.props.getLastBill(this.state.bills.bills_documents) : null;
		let bill_number = lastBill ? lastBill.bill_number : '';
		if(this.state.bills){
			if(this.state.bills.amount > 0){
				return(
					<div className="servicios-servicio-box-details-right">
						<div className="servicios-servicio-box-details-boleta-title">
							<p className="color-gray font-13 uppercase">Tu Última Boleta es por:</p>
						</div>
						<div className="servicios-servicio-box-details-price">
							<p>${parseInt(this.state.bills.last_bill).toLocaleString("de")}</p>
						</div>
						<div className="servicios-servicio-box-details-boleta-num">
							<p className="uppercase bold">
								<span className="light font-14">N° de Boleta: </span>
								{bill_number}
							</p>
						</div>
						{/* <div className="servicios-servicio-box-details-boleta-num">
							{!available.dashboard_solicitar_reposicion?null:<div>
								<input id="requestReposition" type="checkbox" checked={this.state.requestReposition ? true : false} onChange={(e) => {this.setState({requestReposition: !this.state.requestReposition, meterInstalled: false})}}/>
								<label htmlFor="requestReposition">Solicitar reposición de servicio	</label>
							</div>}
							{!this.state.requestReposition ? null :
								<div>
									<input id="meterInstalled" type="checkbox" checked={this.state.meterInstalled ? true : false} onChange={(e) => {this.setState({meterInstalled: !this.state.meterInstalled})}}/>
									<label htmlFor="meterInstalled">No tengo medidor instalado</label>
								</div>
							}
						</div> */}
						<Button2 label="Pagar Cuenta" className="" onClick={() => {this.paymentPortal()}}/>
					</div>
				);
			}else{
				return(
					<div className="servicios-servicio-box-details-right">
						<div className="servicios-servicio-box-details-boleta-title margin-bottom-20">
							<p className="color-gray font-13 uppercase">Tu Última Boleta es por:</p>
						</div>
						<p className="text-green fw700">No tienes boletas pendientes de pago</p>
					</div>
				);
			}
		}else{
			return(
				<div className="servicios-servicio-box-details-right">
					<div className="servicios-servicio-box-details-boleta-title margin-bottom-20">
						<p>Tu Última Boleta es por:</p>
					</div>
					<div className="box-white-last-bill">
						<p className="text-green fw700 margin-bottom-10">{this.state.medidorDebtMessage?this.state.medidorDebtMessage:"En este momento no es posible obtener la información."}</p>
						<p className="text-green fw500 font-15 uppercase">{this.state.medidorDebtMessage?"":"Por favor intenta más tarde"}</p>
					</div>
				</div>
			);
		}
	}
	renderDebtHousing(){
		if(this.state.debtHousing){
			return(
				<div className="servicios-servicio-box-details-right">
					<div className="servicios-servicio-box-details-boleta-num">
						<p className="uppercase bold">
							<span className="light font-14">Vencido: </span>
							${parseInt(this.state.debtHousing.expired).toLocaleString("de")}
						</p>
						<p className="uppercase bold">
							<span className="light font-14">Por vencer: </span>
							${parseInt(this.state.debtHousing.notExpired).toLocaleString("de")}
						</p>
						<p className="uppercase bold">
							<span className="light font-14">Total: </span>
							${parseInt(this.state.debtHousing.total).toLocaleString("de")}
						</p>
					</div>
				</div>
			);
		}else{
			return(
				<div className="servicios-servicio-box-details-right">
					<p className="text-green fw700">No tienes cuentas por pagar</p>
				</div>
			);
		}
	}
	handleSetSelectedMeter(e){
		this.state.meterServices.forEach((meter)=> {
			if(meter.client_cod === e.target.value) this.props.setSelectedMeter(meter);
		});
	}
	handleSetSelectedHousing(e){
		this.state.housingServices.forEach((housing)=> {
			if(housing.client_cod === e.target.value) this.props.setSelectedHousing(housing);
		});
	}
	handleSetSelectedCilindro(e){
		this.state.cilindroServices.forEach((cilindro)=> {
			if(cilindro.client_cod === e.target.value) this.props.setSelectedCilindro(cilindro);
		});
	}
	handleSetSelectedPatenteAutogas(e){
		this.state.autogasPatentes.forEach((autogas)=> {
			if(autogas.patent === e.target.value) this.props.setSelectedPatenteAutogas(autogas);
		});
	}
	renderSelectMeter(){
		if(this.state.meterServices){
			if(this.state.meterServices.length > 1){
				return(
					<div className="options-number-customer">
						<select value={this.props.selectedMeter ? this.props.selectedMeter.client_cod : ""} onChange={(e) => this.handleSetSelectedMeter(e)}>
							{this.state.meterServices.map((service, i) =>
								<option key={service.client_cod} value={service.client_cod}>
									N° de Cliente {parseInt(service.contract_cod)}
								</option>)}
						</select>
					</div>
				);
			}else{
				return(
					<div className="box-border margin-top-40">
						N° de Cliente {parseInt(this.state.meterServices[0].contract_cod)}
					</div>
				);
			}
		}
	}
	renderSelectHousing(){
		if(this.state.housingServices){
			if(this.state.housingServices.length > 1){
				return(
					<div className="options-number-customer">
						<select value={this.props.selectedHousing ? this.props.selectedHousing.client_cod : ""} onChange={(e) => this.handleSetSelectedHousing(e)}>
							{this.state.housingServices.map((service, i) =>
								<option key={service.client_cod} value={service.client_cod}>
									N° de Cliente {parseInt(service.client_cod)}
								</option>)}
						</select>
					</div>
				);
			}else{
				return(
					<div className="box-border margin-top-40">
						N° de Cliente {parseInt(this.state.housingServices[0].client_cod)}
					</div>
				);
			}
		}
	}
	renderMedidor() {
		if(this.state.meterServices && !this.state.meterServices[0]) return;
		var selectedMeter = null;
		if(this.state.meterServices)
		this.state.meterServices.forEach((service) => {
			if(service.client_cod === this.props.selectedMeter) selectedMeter = service;
		});
		return (
		<ContentBox className="margin-top-40 margin-bottom-40 overflow-hidden" loading={this.state.loadingMedidor}>
			<div className="servicios-servicio-box-leftside">
      			<img className="servicios-servicio-box-type-img" src={MedidorIcon} alt="medidor" />
      			<div className="servicios-servicio-box-type">
      				<p>Medidor</p>
  				</div>
				{this.renderSelectMeter()}
			</div>

			<div className="servicios-servicio-box-links">
				{!available.medidor_boletas?null:<div className="linkA-container">
					<Link to={"/mis-servicios/medidor/boletas"}>Boletas</Link>
				</div>}
				{!available.medidor_historial_lectura?null:<div className="linkA-container">
					<Link to={"/mis-servicios/medidor/historial-lectura"}>Historial de Lectura</Link>
				</div>}
				{!available.medidor_historial_pagos?null:<div className="linkA-container">
					<Link to={"/mis-servicios/medidor/historial-pagos"}>Historial de Pagos</Link>
				</div>}
				{!available.medidor_detalle_deuda?null:<div className="linkA-container">
					<Link to={"/mis-servicios/medidor/detalle-deuda"}>Detalle de Deuda</Link>
				</div>}
				{!available.medidor_convenio_pago?null:<div className="linkA-container">
					<Link to={"/mis-servicios/medidor/convenio-pago"}>Convenios de Pago</Link>
				</div>}
				{!available.medidor_tarifas?null:<div className="linkA-container">
					<Link to={"/mis-servicios/medidor/tarifas"}>Tarifas</Link>
				</div>}
				{!available.medidor_requerimientos?null:<div className="linkA-container">
					<Link to={"/mis-servicios/medidor/requerimientos"}>Requerimientos</Link>
				</div>}
				{!available.medidor_simulador?null:<div className="linkA-container">
					<Link to={"/mis-servicios/medidor/simulador"}>Simulador convenio Ley 21.340</Link>
				</div>}
			</div>
			<div className="servicios-servicio-box-details">
			{!selectedMeter && !this.props.selectedMeter?null:
				<div className="servicios-servicio-box-details-left">
					<div className="servicios-servicio-box-details-address">
						<p>{!selectedMeter?this.props.selectedMeter.client_address:selectedMeter.client_address}</p>
					</div>
					<div className="servicios-servicio-box-details-city">
						<p>{!selectedMeter?this.props.selectedMeter.client_city:selectedMeter.client_city}, {!selectedMeter?this.props.selectedMeter.client_region_cod:selectedMeter.client_region_cod}</p>
					</div>
				</div>
			}
				{this.renderLastBill()}
			</div>
		</ContentBox>
		);
	}
	renderGranel(){
		if(this.state.housingServices && !this.state.housingServices[0]) return;
		var selectedHousing = null;
		if(this.state.housingServices)
		this.state.housingServices.forEach((service) => {
			if(service.client_cod === this.props.selectedHousing) selectedHousing = service;
		});
		var service = this.props.selectedHousing;
		var client_address = getServiceAddressString(service);
		return (
		<ContentBox className="margin-top-40 margin-bottom-40 overflow-hidden" loading={this.state.loadingHousing}>
			<div className="servicios-servicio-box-leftside">
      			<img className="servicios-servicio-box-type-img" src={TanqueIcon} alt="granel" />
      			<div className="servicios-servicio-box-type">
      				<p>Granel</p>
  				</div>
				{this.renderSelectHousing()}
			</div>

			<div className="servicios-servicio-box-links">
				{!available.granel_realizar_pedido?null:<div className="linkA-container">
					<Link to={"/mis-servicios/granel/realizar-pedidos"}>Realizar Pedidos</Link>
				</div>}
				{!available.granel_pedidos?null:<div className="linkA-container">
					<Link to={"/mis-servicios/granel/pedidos"}>Historial de Pedidos</Link>
				</div>}
				{!available.granel_historial_pago?null:<div className="linkA-container">
					<Link to={"/mis-servicios/granel/historial-pagos"}>Historial de Pagos</Link>
				</div>}
				{!available.granel_detalle_deuda?null:<div className="linkA-container">
					<Link to={"/mis-servicios/granel/detalle-deuda"}>Detalle de Deuda</Link>
				</div>}
				{!available.granel_cuenta_corriente?null:<div className="linkA-container">
					<Link to={"/mis-servicios/granel/cuenta-corriente"}>Cuenta Corriente</Link>
				</div>}
				{!available.granel_telemetria?null:<div className="linkA-container">
					<Link to={"/mis-servicios/granel/telemetria"}>Telemetría</Link>
				</div>}
				{!available.granel_requerimientos?null:<div className="linkA-container">
					<Link to={"/mis-servicios/granel/requerimientos"}>Requerimientos</Link>
				</div>}
				{!available.granel_tarifas?null:<div className="linkA-container">
					<Link to={"/mis-servicios/granel/variacion-precio"}>Variación de Precio</Link>
				</div>}
			</div>
			<div className="servicios-servicio-box-details">
			{!selectedHousing && !this.props.selectedHousing?null:
				<div className="servicios-servicio-box-details-left">
					<div className="servicios-servicio-box-details-address">
						<p>{client_address}</p>
					</div>
					<div className="servicios-servicio-box-details-city">
						<p>{!selectedHousing?this.props.selectedHousing.client_city:selectedHousing.client_city}, {!selectedHousing?this.props.selectedHousing.client_region_cod:selectedHousing.client_region_cod}</p>
					</div>
				</div>
			}
				{this.renderDebtHousing()}
			</div>
		</ContentBox>
		);
	}
	renderGranelTrack(){
		return(
			<>
				<ContentBox  className="servicios-header-track-container overflow-hidden margin-bottom-40 no-top-border-radius" >
					<span className="track-title">SEGUIMIENTO DE MI PEDIDO GRANEL</span>
					<Tooltip placement="bottom" visible={this.state.iframelinkError}
				 		overlay={<span>INGRESA TU N° DE PEDIDO</span>}>
						<input className="track-input" value={this.state.iframelink} onChange={(e) => {this.setState({iframelinkError: false}); this.setState({iframelink:e.target.value})}} type={'text'} placeholder={"Ingresa tu n° de pedido"} />
					</Tooltip>
					<Button2 onClick={()=>{this.state.iframelink?this.setState({modal: true}):this.setState({iframelinkError: true}) }} className="track-button" label="Buscar" className="orange" />
				</ContentBox>
				{this.state.modal?<ModalCampain closeRight={true} expandFull={true} renderContent={()=>this.renderContentModal()} cancel={()=>this.cancelModal()} show={this.state.modal}/>:null}
			</>
		);
	}
	renderContentModal(){
		document.body.style.overflow = "hidden";
		return(
			<iframe className="track-modal-iframe" src={urls.track_pedidos+this.state.iframelink}></iframe>
		);
	}
	cancelModal(){
		document.body.style.overflow = "";
		this.setState({
			modal: false,
		});
	}

	render() {
		var profilePicture = this.props.user.profile_picture?this.props.user.profile_picture:defaultAvatar;
		let show_meter = this.state.meterServices && this.state.meterServices[0];
		let show_granel = available.granel && this.state.housingServices && this.state.housingServices[0];
		let show_cilindro = available.cilindro && this.state.cilindroServices && this.state.cilindroServices[0];
		let show_autogas = available.autogas && this.state.autogasServices && this.state.autogasServices[0];
		let hasServices = show_meter || show_granel || show_cilindro || show_autogas;

		return (
			<Fragment>
				<ContentBox className={(!show_granel?"overflow-hidden margin-bottom-40 ":"overflow-hidden no-bottom-border-radius")}>
					<div className="servicios-header-avatar-container">
						<div
							className="servicios-header-avatar"
							style={{backgroundImage: "url(" + profilePicture + ")"}}>
						</div>
					</div>
					<div className="servicios-header-welcome-container">
						<div className="servicios-header-welcome-subtext">
							<p className="thin">Bienvenido a tu sucursal virtual</p>
						</div>
						<div className="servicios-header-welcome-text">
							<p>{this.props.user.name} {this.props.user.last_name}</p>
						</div>
					</div>
					{available.medidor_suscripcion && hasServices && this.state.meterServices && this.state.meterServices.length > 0 ? <div className="servicios-header-button-container-2">
					<Link to={"/mis-servicios/medidor/suscripcion"}><Button2 label="Suscripción a boleta electrónica" className="green" /></Link>
					</div> : null}
					{hasServices ? <div className={this.state.meterServices && this.state.meterServices.length > 0 ? "servicios-header-button-container" : "servicios-header-button-container-2"}>
						<Link to={"/servicios/seleccionar"}><Button2 label="Agregar Servicios" className="orange" /></Link>
					</div> : null}
				</ContentBox>
				{show_granel?this.renderGranelTrack():null}

				{hasServices && available.dashboard_campaign? <Campaigns custom="Usuario"/> : null}
				{this.renderMedidor()}
				{available.granel?this.renderGranel():null}
				{available.autogas && this.state.autogasPatentes && this.props.selectedPatenteAutogas ?
					<ServiceCard 
					title="Autogas"
					links={autogasLinks}
					icon={AutogasIcon}
					loading={this.state.loadingAutogas}
					setLoading={(x) => this.setState({loadingAutogas: x})}
					selectorData={this.state.autogasPatentes}
					selectorValue={this.props.selectedPatenteAutogas}
					handleSelect={(i)=>{this.handleSetSelectedPatenteAutogas(i)}}/>
				:null}
				{available.cilindro && this.state.cilindroServices && this.props.selectedCilindro ?
					<ServiceCard 
					title="Cilindro"
					links={cilindroLinks}
					icon={CilindroIcon}
					loading={this.state.loadingCilindro}
					selectorData={this.state.cilindroServices}
					selectorValue={this.props.selectedCilindro}
					handleSelect={(i)=>{this.handleSetSelectedCilindro(i)}}/>
				:null}
				{this.state.meterServices && !hasServices?this.renderNewServiceBoxes():null}
			</Fragment>
		);
	}
}