import React, { Component } from 'react';
import GridBox from './GridBox';
import FaqIcon from '../img/icon_faq.png';
import InfoIcon from '../img/icon_info.png';
//import BuscaIcon from '../img/icon_busca.png';


var newServices = [
	{
		name: "Preguntas Frecuentes",
		desc: "Revisa las respuestas a las preguntas más recurrentes que recibimos de nuestros clientes.",
		icon: FaqIcon,
		a: "https://www.abastible.cl/autogas"
	},
	{
		name: "Estaciones",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		icon: InfoIcon,
		a: "https://www.abastible.cl/autogas/estaciones-de-servicio/"
	},
	{
		name: "Plantas",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		icon: InfoIcon,
		link: "#"
	},
	{
		name: "Talleres",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
		icon: InfoIcon,
		link: "https://www.abastible.cl/autogas/talleres-autogas/"
	}
];
export default class InformacionInteresAutogas extends Component {
	render() {
		return (
        	<div className="informacion-choose-container margin-top-40">
				{newServices.map((service, i) => {
					return(<GridBox key={i} service={service} buttonLabel="SABER MÁS" />);
				})}
			</div>
		);
	}
};
