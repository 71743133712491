import React, { Component, Fragment } from 'react';
import ContentBox from './ContentBox.js';
import ServiceListBox from './ServiceListBox';
import StepSwitch from './StepSwitch';
import CustomSelect from './CustomSelect';
import Button1 from './Button1';
import ArrowRightWhite from '../img/arrow_right_white.png';
import Tooltip  from 'rc-tooltip';
import FilePicker from './FilePicker';
import DatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import SolicitudAgregada from '../img/solicitudagregada.svg';
import es from 'date-fns/locale/es';
import { registerLocale } from  "react-datepicker";
import moment from 'moment';
import flechaIzquierdaNegra from '../img/arrow_left_black.svg';
import CotizacionTable from './CotizacionTable';
import {cilindroIndustrialGetCuotas, cilindroIndustrialCotizacionMateriales, 
		cilindroIndustrialCotizacionCreate	
	} from '../api.js';
import PopupDireccionesCotizacion from "./PopupDireccionesCotizacion";
registerLocale('es', es);
const initialState = {
  	step: 1,
  	categories: [],
  	services: null,
  	options: [
  		{step: 1, label: "Cliente"},
  		{step: 2, label: "Tipo Cotización"},
  		{step: 3, label: "Formulario"}
  	],
  	selectedService: null,
  	selectedCategory: null,
  	selectedType: null,
  	selectedAddress: null,
  	form: null,
  	formValues: null,
	formErrors: null,
	loading: true,
	closeCategorySelect: false,
	closeTypeSelect: false,
	parentServices: true
};
export default class ServiciosCilindroIndustrialCotizarPedido extends Component {
	constructor(props) {
		super(props);
	  	this.state = initialState;
	}
	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();		
		this.setState({loading: false});
	}
	getCuotas(cb) {
		cilindroIndustrialGetCuotas(this.props.selectedService.client_cod, (error, result)=> {
			var data = result.data;
			var tipo_cotizacion_options = [];
			if(data.payment_method.Cupones) {
				var payment_methods = [];
				for(var prop in data.payment_method.Cupones) {
					payment_methods.push({name: prop, code: data.payment_method.Cupones[prop]});
				}
				var option = {name: "Cotizar pedido de cupones", code: "Cupones", payment_methods};
				tipo_cotizacion_options.push(option);
			}
			if(data.payment_method.Garantias) {
				var payment_methods = [];
				for(var prop in data.payment_method.Garantias) {
					payment_methods.push({name: prop, code: data.payment_method.Garantias[prop]});
				}
				var option = {name: "Cotizar pedido de garantías", code: "Garantias", payment_methods};
				tipo_cotizacion_options.push(option);			}

			if(cb) cb(error, tipo_cotizacion_options, data.address_coupon, data.address_warranty);
		});
	}
	goToStep2() {
		if(!this.state.selectedService) {
			return this.setState({error_client: "Debes seleccionar un cliente"});
		}
		this.setState({loading: true}, () => {
			this.getCuotas((error, tipo_cotizacion_options, address_cupon, address_garantia)=> {
				this.setState({loading: false, step: 2, tipo_cotizacion_options: tipo_cotizacion_options, address_garantia, address_cupon});
			});
		});
	}
	goToStep3(cb) {
		if(!this.state.selectedTipoCotizacion) {
			return this.setState({error_tipo_cotizacion: "Debes seleccionar un tipo de cotización"});
		}
		if(!this.state.selectedMedioPago) {
			return this.setState({error_payment_method: "Debes seleccionar un medio de pago"});
		}
		this.setState({loading: true}, () => {
			var quote_type = this.state.selectedTipoCotizacion.code;
			var payment_code = this.state.selectedMedioPago.code;
			var client_cod = this.props.selectedService.client_cod;		
			cilindroIndustrialCotizacionMateriales(client_cod, quote_type, payment_code, (error, resultMaterials)=> {
				if(error) return console.log("error cargando lista de materiales", error);
				if(resultMaterials.status === "error" || resultMaterials.status === "empty") {
				 	console.log("error cargando lista de materiales", error);		
					resultMaterials = {data: [{value: 1500, name: "test", material_code: "GAS15VM"}]};
				}
				var newFormValues = {};
				var result = {data: {form: [
					
					{element: "text",  name: "fecha_precio", label: "Fecha de precio", value: moment().format("DD-MM-YYYY")},
					{element: "input", type: "text", name: "orden_compra", label: "Orden de compra", validations: ""},
					{element: "render", render: () => <CotizacionTable 
						items={this.state.materials} 
						itemCountChange={this.itemCountChange.bind(this)}
					/>},
					{element: "text",  name: "medio_pago", label: "Medio de pago", value: this.state.selectedMedioPago.name},
					{element: "input", type: "file", name: "archivo", label: "Archivo adjunto", validations: ""},
					{element: "render", render: () => <div><p>{this.state.selectedAddress}</p><div onClick={() => this.setState({popupDirecciones: true})}>Cambiar</div></div>}


				]}};
				if(this.state.selectedTipoCotizacion.code!=="Cupones")
					result.data.form.unshift({element: "select", name: "tipo_cupon", label: "Tipo de cupón", validations: "", options: [
						{value: "Físico", "name": "Físico"},
						{value: "Dígital", "name": "Digital"}
					]});
				result.data.form.forEach((field)=> {
					newFormValues[field.name] = "";
					if(field.type === "checkbox") newFormValues[field.name] = [];
					if(field.element === "select") newFormValues[field.name] = field.options[0].value;
					if(field.element === "text") newFormValues[field.name] = field.value;

				});
				this.setState({step: 3, materials: resultMaterials.data.map((m)=>{return {...m, count: 0}}), form: result.data.form, amount: result.amount, formValues: newFormValues, loading: false}, cb);	
			});
		});			
	}
	sendForm(incomplete) {
		this.setState({formErrors: null, generalError:null, loading: true});
		var formValues = {...this.state.formValues};
		this.state.form.forEach((field) => {
			if(field.element === "select2") {
				for(var resp in formValues) {
					if(resp === field.name) {
						formValues[resp] = formValues[resp] ? formValues[resp].map(v => v.value) : "";
					}
				}
			}
			if(field.element === "input" && field.type==="file") {
				if(formValues[field.name]) {
					formValues[field.name + "_name"] = formValues[field.name].filename;
					formValues[field.name] = formValues[field.name].file;
				}
			}
			if(field.element === "input" && field.type==="date") {
				if(formValues[field.name]) {
					formValues[field.name] = moment(formValues[field.name]).format("DD-MM-YYYY");
				}
			}

		});
		var client_id = this.props.isEmpresas?this.state.selectedService.id:this.state.selectedService.client_id;
		var data = {			
			coupon_type: this.state.formValues.tipo_cupon?this.state.formValues.tipo_cupon:"Físico", //string, puede ser "Físico" o "Dígital"
			purchase_order: this.state.formValues.orden_compra.trim()!==""?this.state.formValues.orden_compra:null, //string, nullable, numero de orden de compra
			quote_type: this.state.selectedTipoCotizacion.code, //string, puede ser "Cupones" o "Garantias" o "cupon" o "garantia"
			client_cod: client_id, //string, codigo cliente abastible
			quote: this.state.materials.map(m=> {return {
				"material_code": m.material_code,
	            "price": m.value,
	            "quantity": m.count
			}}),
			payment_code: this.state.selectedMedioPago.code,//string, codigo del metodo de pago Ej: "0001"
			address: this.state.selectedAddress, //string, direccion de despacho
			incomplete,// numerico, 0 para completar la cotizacion y 1 o distinto de 0 para dejar pendiente
			file: this.state.formValues.archivo?this.state.formValues.archivo.file:null //string, nullable, archivo en base64
		};

		cilindroIndustrialCotizacionCreate(data, (error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			if(result.status === "error") {
				if(result.errors) return this.setState({formErrors: result.errors, loading: false});
				if(result.message) return this.setState({generalError: result.message, loading: false});
			} else {
				this.props.checkMenuOptions();
				this.setState({step: 4, loading: false});
			}
		}, this.props.isEmpresas?"empresas":null);
	}
	finish() {
		this.setState({...initialState, services: this.state.services, categories: this.state.categories, loading: false});
	}
	selectedPaymentMethodChanged(option) {
		this.setState({selectedMedioPago: option, error_payment_method: null});
	}
	changeFormValue(field_name, value) {
		var oldFormValues = {...this.state.formValues};
		oldFormValues[field_name] = value;
		this.setState({formValues: oldFormValues});
		this.deleteFormError(field_name);
	}
	changeFormCheckboxValue(field_name, target) {
		var oldValue = [...this.state.formValues[field_name]];
		if(target.checked) {
			oldValue.push(target.value);
		} else {
			var index = oldValue.indexOf(target.value);
			if (index > -1) {
			  oldValue.splice(index, 1);
			}
		}
		this.changeFormValue(field_name, oldValue);
	}
	deleteFormError(field_name) {
		var formErrors = {...this.state.formErrors};
		delete formErrors[field_name];
		this.setState({formErrors})
	}
	itemCountChange(item, value) {
		if(value.trim() === "") value = 0;
		var materials = [...this.state.materials];
		materials.forEach(m=> {
			if(m.code === item.code) {
				m.count = parseInt(value);
			}
		});
		this.setState({materials});

	}
	renderStep1() {
		var services = this.state.services ? this.state.services : [];
		if(this.state.parentServices && this.props.services) services = this.props.services.filter((s)=>parseInt(s.business_line_id) === 2);
		
		return(
			<div className="agregar-solicitud-form">
				<Tooltip placement="bottom" visible={this.state.error_client}
				 	overlay={<span>{this.state.error_client}</span>}>
				<CustomSelect									
					options={services}
					selected={this.state.selectedService}
					placeholder="Selecciona Dirección"
					onChange={(option) => {
						this.setState({selectedService: option, error_client: null});
					}}
					renderSelected={(option, opened) => {
						return <ServiceListBox service={option} onlyHeader={opened} displayNumberLabel={this.props.business_line === "granel" || this.props.business_line === "autogas"?"client_cod":null} />
					}}
					renderOption={(option, i) => {
						return <ServiceListBox service={option} displayNumberLabel={this.props.business_line === "granel" || this.props.business_line === "autogas"?"client_cod":null} />
					}}
				/>
				</Tooltip>
				<Button1
					label="SIGUIENTE"
					className="margin-top-50" icon={ArrowRightWhite}
					onClick={() => {this.goToStep2()}} />
			</div>
			);
	}
	renderStep2() {
		let error_payment_method = null;
		if(this.state.error_payment_method) {
			error_payment_method = this.state.error_payment_method.split('\n').map((item, i) => <p key={i}>{item}</p>);
		}

		return(
			<Fragment>
				<div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30" onClick={() => this.setState({step: 1, selectedCategory: null,
  				selectedType: null})}>
					<img alt="Atrás" src={flechaIzquierdaNegra}/>
					<p>atrás</p>
				</div>
				<div className="agregar-solicitud-form">
					<div className="agregar-solicitud-form-group">
						<div className="form-field-label"><p>Escoge Tipo de Cotización</p></div>
						<Tooltip placement="bottom" visible={this.state.error_tipo_cotizacion}
					 	overlay={<span>{this.state.error_tipo_cotizacion}</span>}>
						<CustomSelect
							options={this.state.tipo_cotizacion_options?this.state.tipo_cotizacion_options:[]}
							selected={this.state.selectedTipoCotizacion}
							force_close={this.state.closeCategorySelect}
							onForceClosed={()=>this.setState({closeCategorySelect:false})}
							onOptionsOpened={()=>this.setState({closeTypeSelect:true})}
							onChange={(option) => {
								this.setState({selectedTipoCotizacion: option, error_tipo_cotizacion: null, error_payment_method: null});
								this.selectedPaymentMethodChanged(null);
							}}
							renderSelected={(option, opened) => {
								return <div className="solicitud-formulario-filter-select-selected">{option.name}</div>

							}}
							renderOption={(option, i) => {
								return <div className="solicitud-formulario-filter-select-option">{option.name}</div>
							}}
						/>
						</Tooltip>
					</div>
					<div className="agregar-solicitud-form-group">
						<div className="form-field-label"><p>Medio de Pago</p></div>
						<Tooltip placement="bottom" visible={this.state.error_payment_method}
					 	overlay={<span>{error_payment_method}</span>}>
						<CustomSelect
							options={this.state.selectedTipoCotizacion?this.state.selectedTipoCotizacion.payment_methods:[]}
							selected={this.state.selectedMedioPago}
							onOptionsOpened={()=>this.setState({closeCategorySelect:true})}
							onForceClosed={()=>this.setState({closeTypeSelect:false})}							

							force_close={this.state.closeTypeSelect}
							onChange={(option) => {
								this.selectedPaymentMethodChanged(option);
							}}
							renderSelected={(option, opened) => {
								return <div className="solicitud-formulario-filter-select-selected">{option.name}</div>
							}}
							renderOption={(option, i) => {
								return <div className="solicitud-formulario-filter-select-option">{option.name}</div>
							}}
						/>
						</Tooltip>
						<div className="agregar-solicitud-category-description margin-top-40">
							<p>{this.state.selectedType ? this.state.selectedType.description_front : ""}</p>
						</div>
					</div>
					<Button1
						label="SIGUIENTE"
						className="margin-top-50" icon={ArrowRightWhite}
						onClick={() => {this.goToStep3()}} />
				</div>
			</Fragment>	
		);
	}
	renderStep3() {		
		var fields = !this.state.form ? null : this.state.form.map((field, i) => {
			var validations = field.validations? field.validations.split("|"): [];
			var requiredMark = validations.indexOf("required")>= 0 ? "*" : "";
			if(field.element === "input") {
				if(field.type === "text" || field.type === "number" || field.type === "email") {
				return(
					<div className="solicitud-formulario-field-container" key={field.name}>
						<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>
						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false} destroyTooltipOnHide={true}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
							<input
								value={this.state.formValues[field.name]}
								onChange={(e) => {this.changeFormValue(field.name, e.target.value)}}
								type={field.type} />
						</Tooltip>
					</div>
					);
				}
				else if(field.type === "file") {
				return(
					<div className="solicitud-formulario-field-container" key={field.name}>
						<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>
						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
							<FilePicker
								placeholder="Agregar archivos (máx. 3MB)"
								onChange={(file, filename) => {
									if(!file) this.changeFormValue(field.name, null);
									else {
										var removedSignature = file.split("base64,")[1];
										this.changeFormValue(field.name, {file: removedSignature, filename});
									}
								}}
								value={this.state.formValues[field.name]} />

						</Tooltip>
					</div>
					);
				}else if(field.type === "date") {
				return(
					<div className="solicitud-formulario-field-container" key={field.name}>
						<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>
						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
						<DatePicker popperModifiers={{flip: {enabled: false}}} dateFormat="dd/MM/yyyy" selected={this.state.formValues[field.name]}
							locale="es"
							onChange={(date) => {this.changeFormValue(field.name, date)}} />
						</Tooltip>
					</div>
					);
				}

				else if(field.type === "checkbox")
				return(
					<Tooltip key={field.name} placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
						<div className="solicitud-formulario-field-container" key={field.name}>
							<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>

						{field.options.map((option) => {
							return(<div className="checkbox" key={option.value}><input type={field.type} value={option.value}
							onChange={(e) => {this.changeFormCheckboxValue(field.name, e.target)}}/>

							 <span>{option.name}</span></div>);
						})}
					</div>
					</Tooltip>
					);
				else
				return(
					<div className="solicitud-formulario-field-container" key={field.name}>
						<div className="solicitud-formulario-field-label"><p>{field.label}</p></div>
						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false} destroyTooltipOnHide={true}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
							<p>CAMPO NO SOPORTADO</p>
						</Tooltip>
					</div>
					);
			}
			else if(field.element === "select") {
				return(
					<div className="solicitud-formulario-field-container" key={field.name}>
						<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>

						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
						<select
							value={this.state.formValues[field.name]}
							onChange={(e) => {this.changeFormValue(field.name, e.target.value)}}
							>
						{field.options.map((option) => {
							return(<option key={option.value} value={option.value}>{option.name}</option>);
						})}
						</select>
						</Tooltip>
					</div>
					);
			}
			else if(field.element === "select2") {
				return(
					<div className="solicitud-formulario-field-container" key={field.name}>
						<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>

						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
						<ReactSelect
					        value={this.state.formValues[field.name]}
					        onChange={(option) => this.changeFormValue(field.name, option)}
					        options={field.options}
							getOptionLabel={option => option.name}
							isMulti
					      />
						</Tooltip>
					</div>
					);
			}
			else if(field.element === "textarea")
				return(
					<div className="solicitud-formulario-field-container grid-item-full" key={field.name}>
						<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>
						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false} destroyTooltipOnHide={true}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
				 			<textarea
				 				value={this.state.formValues[field.name]}
								onChange={(e) => {this.changeFormValue(field.name, e.target.value)}}/>
						</Tooltip>
					</div>
					);
			else if(field.element === "text") {
				return(
					<div className="solicitud-formulario-field-container" key={field.name}>
						<div className="solicitud-formulario-field-label"><p>{field.label}</p></div>
						
							{field.value}
					</div>
				);
			}
			else if(field.element === "render") {
				return(<div className="grid-item-full">{field.render()}</div>);
			}
			

			else {
				return(
					<div className="solicitud-formulario-field-container" key={field.name}>
						<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>

						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
						<p>ELEMENTO NO SOPORTADO</p>
						</Tooltip>
					</div>
					);
			}

		});
		return(
			<Fragment>
				<div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30" onClick={() => this.setState({step: 2})}>
					<img alt="Atrás" src={flechaIzquierdaNegra}/>
					<p>atrás</p>
				</div>
			<div className="formulario-container-step3 grid-column-2">

			{fields}
				<div className="container-submit grid-item-full">					
				<Button1
				label="GUARDAR PARA ENVIAR MÁS TARDE"
				className="margin-top-50" icon={ArrowRightWhite}
				onClick={() => {this.sendForm(1)}} />
				<Tooltip placement="bottom" visible={this.state.generalError}
				 	overlay={<span>{this.state.generalError}</span>}><Button1
				label="ENVIAR A ABASTIBLE"
				className="margin-top-50" icon={ArrowRightWhite}
				onClick={() => {this.sendForm(0)}} /></Tooltip>
				</div>
			</div>
			</Fragment>);
	}
	renderStep4() {
		return(
			<div className="agregar-solicitud-step4">
				<div className="agregar-solicitud-step4-title">
					<p>Cotización enviada con éxito</p>
				</div>
				<img src={SolicitudAgregada} alt="Socilitud agregada con éxito" />
				<Button1
					label="Finalizar"
					className="margin-top-50"
					onClick={() => {this.finish()}} />
			</div>
		);
	}
	renderStep() {
		if(this.state.step === 1) return this.renderStep1();
		if(this.state.step === 2) return this.renderStep2();
		if(this.state.step === 3) return this.renderStep3();
		if(this.state.step === 4) return this.renderStep4();
	}
	render() {
		return (
			<Fragment>
				<ContentBox loading={this.state.loading}>
					{this.state.step===4?null:<div className={"title-client-container clearfix"}>
						<h1>Cotizar Pedido</h1>
						<div className="agregar-solicitud-steps">
							<StepSwitch options={this.state.options} selected={this.state.step} onChange={(step) => {this.setState({step})}} />
						</div>
					</div>}
					{this.renderStep()}
				</ContentBox>
				{!this.state.popupDirecciones?null:
					<PopupDireccionesCotizacion 
						show={true} 
						addresses={true?this.state.address_cupon:this.state.address_garantia}
						onSelect={(selected) => {this.setState({popupDirecciones: false, selectedAddress: selected})}} />}
			</Fragment>
		);
	}
}
