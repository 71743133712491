import React, { Fragment, useEffect, useState } from 'react'
import Button2 from "./Button2"
import Tooltip from "rc-tooltip"
import ServiciosAutogasPopupMantencion from "./ServiciosAutogasPopupMantencion"
import { autogasGetMaintenanceRegion, autogasGetMaintenanceWorkshops, autogasGetFutureMaintenance, autogasGetMaintenanceDatetimes, autogasMaintenancePrice } from "../api"
import moment from "moment"

export default function ServiciosAutogasAgendaMantenciones({selectedPatenteAutogas, setLoading, setReload}) {
    const [futureMaintenance, setFutureMaintenance] = useState([])
    const [maintenanceEmptyMessage, setMaintenanceEmptyMessage] = useState(false)
    const [regions, setRegions] = useState([])
    const [selectedRegion, setSelectedRegion] = useState("")
    const [workshops, setWorkshops] = useState([])
    const [selectedWorkshop, setSelectedWorkshop] = useState("")
    const [selectedWorkshopInfo, setSelectedWorkshopInfo] = useState(false)
    const [selectedMaintenance, setSelectedMaintenance] = useState("")
    const [dates, setDates] = useState([])
    const [selectedDate, setSelectedDate] = useState("")
    const [totalblocks, setTotalBlocks] = useState([])
    const [times, setTimes] = useState([])
    const [selectedTime, setSelectedTime] = useState("")
    const [numMant, setNumMant] = useState(false)
    const [solicitud, setSolicitud] = useState(false)
    const [selectedBlock, setSelectedBlock] = useState({})
    const [requiredFields, setRequiredFields] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [solicitudData, setSolicitudData] = useState({})
    const [maintenancePrice, setMaintenancePrice] = useState(false)
    const [nextNewCalendar, setNextNewCalendar] = useState('')


    useEffect(()=>{
        if (selectedPatenteAutogas) {
            autogasGetFutureMaintenance(selectedPatenteAutogas.patent, (err, res) => {
                if (err) {
                    console.log(err)
                }
                if (res.status === "ok") {
                    setMaintenanceEmptyMessage(false)
                    setFutureMaintenance(res.data)
                    setSelectedMaintenance(res.data[0].id_service.toString())
                } else if (res.status === "empty") {
                    setFutureMaintenance([])
                    setMaintenanceEmptyMessage(true)
                }
                
            })
            autogasGetMaintenanceRegion(selectedPatenteAutogas.patent, (err, res) => {
                if (err) {
                    console.log(err)
                }
                if (res.status === "ok") {
                    setRegions(res.data.regions)
                    if (res.data.pre_selected_code) {
                        setSelectedRegion(res.data.pre_selected_code)
                        const preSelectedReg = res.data.regions.find(x => x.code === res.data.pre_selected_code)
                        setSolicitudData({...solicitudData, region: preSelectedReg.name })
                    }
                }                
            })
        }       
    }, [selectedPatenteAutogas])

    useEffect(()=>{
        if (futureMaintenance) {
            setTimeout(() => {
                setLoading(false)
            }, 900);
        }
    }, [futureMaintenance])

    useEffect(()=>{
        if (selectedRegion) {
            setSelectedWorkshop("")
            autogasGetMaintenanceWorkshops(selectedRegion, (err, res) => {
                if (err) {
                    console.log(err)
                }
                if (res.status === "ok") {
                    setWorkshops(res.data.workshops)
                }
            })
        }
    }, [selectedRegion])

    useEffect(()=>{
        setSelectedDate("")
        setDates([])
        setMaintenancePrice(false)
        if(selectedMaintenance && selectedWorkshop) {
            setLoading(true)
            let numeroMantencion = futureMaintenance.find(x => x.id_service === Number(selectedMaintenance)).num_mant.toString()
            setNumMant(numeroMantencion)
            autogasGetMaintenanceDatetimes(selectedWorkshop, selectedMaintenance, (err, res) => {
                let dates = [];
                if (err) {
                    console.log(err)
                }
                if (res.status === "ok") {
                    res.data.map((block, i) => {
                        let index = dates.indexOf(block.date_block)
                        if (index === -1) {
                            dates = [...dates, block.date_block]
                        }
                    })
                    
                    setTotalBlocks(res.data)
                }
                if (res.status === "empty") {
                    setNextNewCalendar(res.cut_day)
                }
                setDates(dates)
            })
            autogasMaintenancePrice(selectedPatenteAutogas.patent, numeroMantencion, selectedWorkshop, (err, res) => {
                if (err) {
                    console.log(err)
                }
                if (res.status === "ok") {
                    setMaintenancePrice(res.data)
                } else if (res.status === "error") {
                    setMaintenancePrice(res)
                }
            })
        }
    }, [selectedMaintenance, selectedWorkshop])

    useEffect(()=>{
        if (maintenancePrice) {
            setLoading(false)
        }
    }, [maintenancePrice])

    useEffect(()=>{
        setSelectedTime("")
        setTimes([])
        if (selectedDate && totalblocks && totalblocks.length > 0) {
            let times = []
            totalblocks.map(block => {
                if (block.date_block === selectedDate) {
                    times = [...times, block]
                }
            })
            setTimes(times)
        }
    }, [selectedDate])

    useEffect(()=>{
        if (selectedTime && selectedDate) {
            let block = totalblocks.find(x => x.date_block === selectedDate && x.hour_start === selectedTime)
            setSelectedBlock(block) 
        } else {
            setSelectedBlock(false)
        }
    }, [selectedTime])

    const crearSolicitud = () => {
        if (selectedRegion && selectedWorkshop && selectedMaintenance && numMant && selectedDate && selectedBlock && selectedPatenteAutogas.patent) {
            setRequiredFields(false)
            setSolicitud({
                region: selectedRegion,
                workshop_code: selectedWorkshop,
                id_service: selectedMaintenance,
                num_mant: numMant+"",
                date: selectedDate,
                hour_start: selectedBlock.hour_start,
                hour_end: selectedBlock.hour_end,
                id_block: selectedBlock.id_block,
                patent: selectedPatenteAutogas.patent
            })
        } else {
            setRequiredFields(true)
            setSolicitud(false)
        }   
    }

    useEffect(()=>{
        if (solicitud) {
            setShowModal(true)
        }
    }, [solicitud])

    const handleRegionChange = (e) => {
        setSelectedRegion(e.target.value)
        setSolicitudData({...solicitudData, region: e.target.options[e.target.selectedIndex].text})
    }

    const handleWorkshopChange = (e) => {
        let selectedWorkshopTotal = workshops.find(x=>x.code === e.target.value)
        let workshopInfo = selectedWorkshopTotal.street + " " + selectedWorkshopTotal.house_number + ", " + selectedWorkshopTotal.city
        setSelectedWorkshopInfo(workshopInfo)
        setSelectedWorkshop(e.target.value)
        setSolicitudData({...solicitudData, workshop: e.target.options[e.target.selectedIndex].text})
    }

    const handleMaintenanceChange = (e) => {
        setSelectedMaintenance(e.target.value)
        setSolicitudData({...solicitudData, maintenance: e.target.options[e.target.selectedIndex].text})
    }

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value)
        setSolicitudData({...solicitudData, date: e.target.options[e.target.selectedIndex].text})
    }

    const handleTimeChange = (e) => {
        setSelectedTime(e.target.value)
        setSolicitudData({...solicitudData, hour: e.target.options[e.target.selectedIndex].text})
    }

    return (
        <Fragment>
            {maintenanceEmptyMessage ? 
            <div className="col-100" style={{margin: "0 auto"}}>
                <p className="message">
                    Por el momento no es posible agendar tu mantención a través de este medio, contáctate al <strong>800 208 800</strong>.
                </p>
            </div>
            :
            null}
            {!maintenanceEmptyMessage && futureMaintenance && futureMaintenance.length > 0? 
            <React.Fragment>
                {futureMaintenance && futureMaintenance.length > 0 ? 
                    <div className="subcontent-box col-100 clearfix margin-bottom-30 z-index-0" style={{margin: "0 auto", maxWidth: "700px"}}>
                        <div className="grid-column-2">
                            <div className="dashboard-detail" style={{margin: "0"}}>
                                <span>Mantención Sugerida</span>
                                <h3>{futureMaintenance[0].text}</h3>
                            </div>
                            <div className="dashboard-detail" style={{margin: "0"}}>
                                <span>Kilometraje Esperado</span>
                                <h3>{futureMaintenance[0].expected_km + " KM"}</h3>
                            </div>
                        </div>
                    </div>
                : null }            
                <h2 className="uppercase clearfix col-100 sub-section-title" style={{margin: "30px auto", textAlign: "center"}}>Agendar Mantención</h2>
                <div className="col-100 clearfix" style={{margin: "0 auto 30px", maxWidth: "700px"}}>
                    <div className="grid-column-2" style={{columnGap: "60px", rowGap: "20px"}}>
                        <select 
                            value={selectedRegion ? selectedRegion : ""}
                            onChange={handleRegionChange}
                            style={!selectedRegion ? {color: "#ccc"} : null }
                            >
                            <option value="" style={{color: "#ccc"}}>Región</option>
                            {regions && regions.length > 0 ? 
                                regions.map((region, i) => {
                                    return(
                                        <option key={i} value={region.code} style={{color: "black"}}>{region.name}</option>
                                    )
                                })
                            : null}
                        </select>     
                        {futureMaintenance ? 
                            <select 
                                value={selectedMaintenance ? selectedMaintenance : ""}
                                onChange={handleMaintenanceChange}
                                style={!selectedMaintenance ? {color: "#ccc"} : null }
                                >
                                <option value="" style={{color: "#ccc"}}>Mantención</option>
                                {futureMaintenance && futureMaintenance.length > 0 ? 
                                    futureMaintenance.map((maintenance, i) => {
                                        return(
                                            <option key={i} value={maintenance.id_service} style={{color: "black"}}>{maintenance.text}</option>
                                        )
                                    })
                                : null}
                            </select>     
                        : null}  
                       <div className="grid-item-full">
                            <select 
                                className="select-special"
                                value={selectedWorkshop ? selectedWorkshop : ""}
                                onChange={handleWorkshopChange}
                                style={!selectedWorkshop ? {color: "#ccc"} : null }
                                >
                                <option value="" style={{color: "#ccc"}}>Taller</option>
                                {workshops && workshops.length > 0 ? 
                                    workshops.map((workshop, i) => {
                                        return(
                                            <option key={i} value={workshop.code} style={{color: "black"}}>{workshop.name + " - " + workshop.street + " " + workshop.house_number + ", " + workshop.city}</option>
                                        )
                                    })
                                : null}
                            </select>   
                       </div>
                        <select 
                            value={selectedDate ? selectedDate : ""}
                            onChange={handleDateChange}
                            style={!selectedDate ? {color: "#ccc"} : null }
                            >
                            <option value="" style={{color: "#ccc"}}>{!dates[0] && selectedMaintenance && selectedWorkshop ? "No hay fechas disponibles" : "Fecha"}</option>
                            {dates && dates.length > 0 ? 
                                dates.map((date, i) => {
                                    return(
                                        <option key={i} value={date} style={{color: "black"}}>{moment(date, "YYYY-MM-DD").format("DD-MM-YYYY")}</option>
                                    )
                                })
                            : null}
                        </select>      
                        <select 
                            value={selectedTime ? selectedTime : ""}
                            onChange={handleTimeChange}
                            style={!selectedTime ? {color: "#ccc"} : null }
                            >
                            <option value="" style={{color: "#ccc"}}>{!times[0] && selectedDate && selectedMaintenance && selectedWorkshop ? "No hay horas disponibles" : "Hora"}</option>
                            {times && times.length > 0 ? 
                                times.map((time, i) => {
                                    return(
                                        <option key={i} value={time.hour_start} style={{color: "black"}}>{time.hour_start}</option>
                                    )
                                })
                            : null}
                        </select>  
                        {!dates[0] && selectedMaintenance && selectedWorkshop ? <div className="grid-item-full text-center" style={{marginTop: "0px"}}>
                            <div className="message">
                                <p> Taller sin horarios disponibles. Le sugerimos volver a consultar a partir del {moment(nextNewCalendar).format("DD-MM-YYYY")}</p>
                            </div> 
                        </div> : null}
                        {!dates[0] && selectedMaintenance && selectedWorkshop ? null : 
                        maintenancePrice && maintenancePrice.status !== "error" ? 
                            <React.Fragment>
                                <div className="grid-item-full text-left" style={{marginTop: "20px"}}>
                                    <div className="subcontent-box col-100 clearfix margin-bottom-30 z-index-0" style={{margin: "0 auto", maxWidth: "700px"}}>
                                        <div className="grid-column-3">
                                            <div className="dashboard-detail" style={{margin: "0"}}>
                                                <span>Valor de la Mantención</span>
                                                <h3>${parseInt(maintenancePrice.valor_mantencion_taller).toLocaleString("de")}</h3>
                                            </div>
                                            <div className="dashboard-detail" style={{margin: "0"}}>
                                                <span>Descuento</span>
                                                <h3>{maintenancePrice.porcentaje_descuento}%</h3>
                                            </div>
                                            <div className="monto-detail">
                                                <span>Total a Pagar</span>
                                                <p>${parseInt(maintenancePrice.valor_aplicando_descuento).toLocaleString("de")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item-full text-center" style={{marginTop: "0px"}}>
                                    <div className="message">
                                        <p>*Precio referencial con IVA incluidos. Sujeto a verificación en taller.</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        : maintenancePrice.status === "error" ? 
                            <div className="grid-item-full text-center" style={{marginTop: "0px"}}>
                                <div className="message">
                                    <p>{maintenancePrice.message}</p>
                                </div>
                            </div>
                        : null}
                        
                        { maintenancePrice.status === "error" ?
                            null :
                            <div className="grid-item-full text-center" style={{marginTop: "20px"}}>
                                <Tooltip placement="bottom" visible={requiredFields}
                                overlay={<span>Todos los campos son obligatorios</span>}>
                                    <Button2 label="Enviar Solicitud" className="button orange uppercase" onClick={() => crearSolicitud()} />
                                </Tooltip>
                            </div>    
                        }  
                    </div>
                </div>
            </React.Fragment>
            : null
            }
            
            <ServiciosAutogasPopupMantencion show={showModal} setShowModal={(x) => setShowModal(x)} solicitud={solicitud} solicitudData={solicitudData} priceData={maintenancePrice} setReload={(x)=>setReload(x)}/>
        </Fragment>
    )
}
