import React, { Component } from 'react';
import ServiceListBox from './ServiceListBox.js';
import ServiceListBoxAutogas from './ServiceListBoxAutogas.js';

import Button2 from './Button2';
import {Link} from 'react-router-dom';
import {
	meterBussinesLineList, meterBussinesLineDelete,
	housingBussinesLineList, housingBussinesLineDelete,
	cilindroBussinesLineList, cilindroBussinesLineDelete,
	autogasBussinesLineList, autogasBussinesLineDelete} from '../api.js';
import ContentBox from './ContentBox.js';
import ModalConfirmation from './ModalConfirmation';

export default class ServiciosAsociados extends Component {
	constructor(props) {
	  super(props);
	  this.state = {	  	
	  	services: null,
		modal: false,
		serviceSelected: null
	  };
	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadServices();
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.service !== this.props.service) {
			this.loadServices();
		}
	}
	loadServices(cb) {
		this.setState({loading: true});
		var getFunction = null;
		console.log(this.props.service);
		if(this.props.service === "medidor") getFunction = meterBussinesLineList;
		if(this.props.service === "granel") getFunction = housingBussinesLineList;
		if(this.props.service === "cilindro") getFunction = cilindroBussinesLineList;
		if(this.props.service === "autogas") getFunction = autogasBussinesLineList;


		getFunction((error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: true});
				return console.log("ocurrio un error con el request", error);
			}
			var response = result.status === "empty" ? [] : result.data;
			if(cb) cb(null, response);
			this.setState({services: response, loading: false});
		});
	}
	deleteService(service) {
		if(this.props.service === "medidor") {
			meterBussinesLineDelete(service.client_cod, service.contract_cod,  (error, result) => {
				if(error) {
					console.log(error);
					return console.log("ocurrio un error con el request");
				}
				this.props.createActionNotification("", "El servicio se ha desvinculado de tu cuenta correctamente");
				this.loadServices((error, new_services) => {
					if(error) {
						console.log("error cargando nuevos servicios");
					}
					if(!new_services.length)
					this.props.redirect("/");

				});
				this.props.checkMenuOptions();

			});
		} else if(this.props.service === "granel") {
			housingBussinesLineDelete(service.client_cod, service.contract_cod,  (error, result) => {
				if(error) {
					console.log(error);
					return console.log("ocurrio un error con el request");
				}
				this.props.createActionNotification("", "El servicio se ha desvinculado de tu cuenta correctamente");
				this.loadServices((error, new_services) => {
					if(error) {
						console.log("error cargando nuevos servicios");
					}
					if(!new_services.length)
					this.props.redirect("/");

				});
				this.props.checkMenuOptions();

			});
		} else if(this.props.service === "cilindro") {
			cilindroBussinesLineDelete(service.client_cod, (error, result) => {
				if(error) {
					console.log(error);
					return console.log("ocurrio un error con el request");
				}
				this.props.createActionNotification("", "El servicio se ha desvinculado de tu cuenta correctamente");
				this.loadServices((error, new_services) => {
					if(error) {
						console.log("error cargando nuevos servicios");
					}
					if(!new_services.length)
					this.props.redirect("/");

				});
				this.props.checkMenuOptions();

			});
		}
		else if(this.props.service === "autogas") {
			autogasBussinesLineDelete(service.client_cod, (error, result) => {
				if(error) {
					console.log(error);
					return console.log("ocurrio un error con el request");
				}
				this.props.createActionNotification("", "El servicio se ha desvinculado de tu cuenta correctamente");
				this.loadServices((error, new_services) => {
					if(error) {
						console.log("error cargando nuevos servicios");
					}
					if(!new_services.length)
					this.props.redirect("/");

				});
				this.props.checkMenuOptions();

			});
		}
	}
	cancelModal(){
		this.setState({
			modal: false
		});
	}
	confirmModal(){
		this.setState({
			modal: false
		});
		this.deleteService(this.state.serviceSelected, []);
	}
	renderServices(nameLabel) {
		return !this.state.services ? null : this.state.services.map((service) => {
			if(this.props.service !== "autogas")  {
				return(<ServiceListBox key={service.contract_cod ? service.contract_cod : service.client_cod} displayNumberLabel={nameLabel} 
						service={service} type="desvincular" 
						onButtonClick={() => {this.setState({serviceSelected: service, modal: true})}} />);
			} else {
				return(<ServiceListBoxAutogas key={service.client_cod} displayNumberLabel={nameLabel} 
						service={service} type="desvincular" 
						onButtonClick={() => {this.setState({serviceSelected: service, modal: true})}} />);			
			}
			});
	}
	render() {
		var subTitle = "";
		var buttonText = "";
		var nameLabel = "";
		if(this.props.service === "medidor") {
			buttonText = "Asociar Cuenta";
			nameLabel = 'contract_cod';
		}
		if(this.props.service === "granel") {
			subTitle = "Mis Direcciones";
			buttonText = "Asociar Cuenta";
			nameLabel = 'client_cod';
		}
		if(this.props.service === "cilindro") {
			subTitle = "Mis Cuentas";
			buttonText = "Asociar Cuenta";
			nameLabel = 'client_cod';

		}
		if(this.props.service === "autogas") {
			subTitle = "Mis Cuentas";
			buttonText = "Asociar Cuenta";
			nameLabel = 'client_cod';

		}
		/*var breadCrumbOptions = [
			{title: "Mis cuentas", to: "/adminitracion_cuentas/medidor"},
			{title}
		];*/
		return (
		
				<ContentBox key={this.props.service} className="asociar-servicios-container" loading={this.state.loading}>
					<div className="title-button-container margin-bottom-40 col-100 left clearfix">
						<h1>{subTitle}</h1>
						<Link to={"/adminitracion_cuentas/" + this.props.service + "/asociar"}><Button2 label={buttonText} className="orange right clearfix" /></Link>
					</div>
					<div className="col-100 left clearfix list-boxes-container autogas">
						{this.renderServices(nameLabel)}
					</div>
					<ModalConfirmation cancel={this.cancelModal.bind(this)} confirm={this.confirmModal.bind(this)} title="Seguro que deseas desvincular este cliente" show={this.state.modal}/>
				</ContentBox>
			
		);
	}
}
