import React, { Component, Fragment } from 'react';

export default class Paginator extends Component {
	renderPages() {
		const edgeItemsCount = 2;
		const aroundItemsCount = 1;		

		var pagesNum = Math.ceil(this.props.items/this.props.itemsPerPage);
		var lastAddedPage = 0;
		var pages = (new Array(pagesNum)).fill(null).map((el, i)=> {
			const current = i + 1;
			const isCurrent = current === this.props.page;
			var firstItems = current <= edgeItemsCount;
			var lastItems = pagesNum - current < edgeItemsCount;
			var aroundLeft = this.props.page - current <= aroundItemsCount && this.props.page - current > 0;
			var aroundRight = current - this.props.page  <= aroundItemsCount && current - this.props.page > 0;
			if(isCurrent || firstItems || lastItems || aroundLeft || aroundRight) {
				if(lastAddedPage !== i) {
					lastAddedPage = i + 1;
					return(	<Fragment key={current}>
								<div className={"paginator-page spread"}>
									<p>...</p>
								</div>
								<div className={"paginator-page" + (isCurrent?" selected":"")}>
									<p onClick={()=> this.props.onPageChange(i+1)}>{i+1}</p>
								</div>
							</Fragment>
							);
				}
				lastAddedPage = i + 1;
				return(	<div key={current} className={"paginator-page" + (isCurrent?" selected":"")}>
							<p onClick={()=> this.props.onPageChange(i+1)}>{i+1}</p>
						</div>);
			}
		});
		return [
			<div key={"prev"} className={"paginator-page" + (this.props.page===1?" disabled":"")} onClick={()=> this.props.page===1?null:this.props.onPageChange(this.props.page-1)}><p className="paginator-arrow">{'<'}</p></div>,
			...pages, 
			<div key={"next"} className={"paginator-page" + (this.props.page===pagesNum?" disabled":"")} onClick={()=> this.props.page===pagesNum?null:this.props.onPageChange(this.props.page+1)}><p className="paginator-arrow">{'>'}</p></div>];
	} 
	render() {
		var pages = Math.ceil(this.props.items/this.props.itemsPerPage);
		let firstItem = (this.props.page*this.props.itemsPerPage) - (this.props.itemsPerPage - 1)
		let lastItem = this.props.page*this.props.itemsPerPage
		return (
			 
			<div className="paginator not-selectable">
				<div className="grid-column-2"> 
					<div  className="paginator-pages">
						{this.renderPages()}
					</div>
					{/*Página {this.props.page}, Items {this.props.items}, por pagina: {this.props.itemsPerPage} cantidad paginas: {pages}*/}
					<div className="paginator-info">
						<p><span>{firstItem === this.props.items ? null : firstItem + " - "}</span><span>{lastItem > this.props.items ? this.props.items : lastItem}</span> <span style={{color: "#6d6d6d"}}>/</span> <span>{this.props.items}</span></p>
					</div>
					{/* Página {this.props.page} de {pages}.  Items por página: {this.props.itemsPerPage}.  Cantidad de items: {this.props.items} */}
				</div>
			</div>
);
	}
}
