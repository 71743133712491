import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Button2 from './Button2';
import ContentBox  from './ContentBox';

export default class GridBox extends Component {
	render() {
		var service = this.props.service;
		var button = null;
		if(service.link) button = 
				<div className="service-choose-box-button-container">
      				<Link to={service.link}><Button2 label={this.props.buttonLabel} /></Link>
      			</div>;
		if(service.a) button = 
			<div className="service-choose-box-button-container">
  				<a href={service.a} target="_blank" rel="noopener noreferrer"><Button2 label={this.props.buttonLabel} /></a>
  			</div>;


		return (
			<ContentBox className="service-choose-box">
      			<img className="service-choose-box-img" src={service.icon} alt="service icon" />
      			<h1>{service.name}</h1>
      			<p>{service.desc}</p>
      			{button}
      		</ContentBox>
			
		);
	}
}