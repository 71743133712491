import React, { Component, Fragment } from 'react';
import CustomTabs from './CustomTabs';
import {Route, Switch, Redirect} from "react-router-dom";
import BreadCrumb from './BreadCrumb';

import ServiciosCilindroPrecios from './ServiciosCilindroPrecios.js';
import ServiciosCilindroDescuentos from './ServiciosCilindroDescuentos.js';

import ServiciosCilindroPedidos from './ServiciosCilindroPedidos.js';
import ServiciosCilindroPedido from './ServiciosCilindroPedido.js';
import ServiciosCilindroRealizarPedido from './ServiciosCilindroRealizarPedido.js';
import ServiciosGeneralRequerimientos from './ServiciosGeneralRequerimientos.js';
import {cilindroBussinesLineList} from '../api.js';

export default class ServiciosCilindro extends Component {
	constructor(props) {
		super(props);
		this.state = {
			solicitud_requerimiento_preload: null
		};
		this.tabs = [
			{
				title: "Realizar Pedidos",
				to: "/mis-servicios/cilindro/realizar-pedidos"
			},
			{
				title: "Precios",
				to: "/mis-servicios/cilindro/precios"
			},
			{
				title: "Mis Descuentos",
				to: "/mis-servicios/cilindro/descuentos"
			},
			{
				title: "Pedidos",
				to: [
					"/mis-servicios/cilindro/pedidos",
					"/mis-servicios/cilindro/pedidos/*"
				]
			},
			{
				title: "Requerimientos",
				to: "/mis-servicios/cilindro/requerimientos"
			}
		];

	}
	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadServices();		
	}
	loadServices() {
		cilindroBussinesLineList((error, result) => {
			if(error) {
				return console.log("ocurrio un error con el request");
			}
			var services = null;
			if(result.status !== "empty") {
				services = result.data;				
			} else {
				services = [];
				this.props.changeHeaderClass("big");
			}
			this.setState({
				services
			});
			if(!this.props.selectedCilindro) this.props.setSelectedCilindro(services[0]);

		});
	}
	setSolicitudRequerimientoPreloadData(data, cb) {
		this.setState({solicitud_requerimiento_preload: data}, cb);
	}
	render() {
		var breadCrumbOptions = [
			{title: "Mis Servicios", to: "/mis-servicios"},
			{title: "Medidor"},
		];
		return (
			<Fragment>
				<div className="section-title">
					<h1>Cilindro</h1>
					<BreadCrumb options={breadCrumbOptions} />					
				</div>
				<CustomTabs tabs={this.tabs.filter((tab) => {
					//if(!available.medidor_requerimientos && tab.title === "Requerimientos") return false;


					return true;
				})} location={this.props.location} />		
        		<Switch>
					<Route path="/mis-servicios/cilindro/realizar-pedidos">
	                	<ServiciosCilindroRealizarPedido user={this.props.user} />
		            </Route>
					<Route exact path="/mis-servicios/cilindro/precios">
	                	<ServiciosCilindroPrecios
	                		services={this.state.services} 
	                		selectedService={this.props.selectedCilindro}
	                		setSelectedService={this.props.setSelectedCilindro} />
		            </Route>
		            <Route exact path="/mis-servicios/cilindro/descuentos">
	                	<ServiciosCilindroDescuentos
	                		services={this.state.services} 
	                		selectedService={this.props.selectedCilindro}
	                		setSelectedService={this.props.setSelectedCilindro} />
		            </Route>
		            <Route path="/mis-servicios/cilindro/pedidos">
	                	<ServiciosCilindroPedidos
	                		services={this.state.services} 
	                		selectedService={this.props.selectedCilindro}
	                		setSelectedService={this.props.setSelectedCilindro} />
		            </Route>
					<Route exact path="/mis-servicios/cilindro/requerimientos">
	                	<ServiciosGeneralRequerimientos
	                		solicitud_requerimiento_preload={this.state.solicitud_requerimiento_preload}
	                		setSolicitudRequerimientoPreloadData={this.setSolicitudRequerimientoPreloadData.bind(this)}
	                		business_line={"cilindro"}
                  			checkMenuOptions={this.props.checkMenuOptions} />	                		
		            </Route>
		            {/* <Route path="*">
		                <Redirect to={"/mis-servicios"} />
		          	</Route> */}
        		</Switch>
			</Fragment>
		);
	}
}