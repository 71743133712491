import React, { Component, Fragment } from 'react';
import CustomTabs from './CustomTabs';
import {Route, Switch, Redirect} from "react-router-dom";
import InformacionInteresMedidor from './InformacionInteresMedidor';
import InformacionInteresGranel from './InformacionInteresGranel';
import InformacionInteresCilindro from './InformacionInteresCilindro';
import InformacionInteresAutogas from './InformacionInteresAutogas';
import {available} from '../config.js';

import InformacionInteresGranelIndustrial from './InformacionInteresGranelIndustrial';

import {meterBussinesLineList, housingBussinesLineList, cilindroBussinesLineList, autogasBussinesLineList, userClientsPrivileges} from '../api.js';

export default class InformacionInteres extends Component {
    constructor(props) {
		super(props);
		this.state = {
			loadingMedidor: true,
			loadingHousing: true,
			loadingCilindro: true,
			loadingAutogas: true,
			meterServices: [],
			housingServices: [],
			cilindroServices: [],
			autogasServices: [],
			industrialHousingServices: []
		};
		this.tabs = [
			{
				title: "Medidor",
				to: [
					"/informacion_interes/medidor"
				]
            },
            {
				title: "Granel",
				to: [
					"/informacion_interes/granel"
				]
            },
            {
				title: "Cilindro",
				to: [
					"/informacion_interes/cilindro"
				]
            },
			/* {
				title: "Autogas",
				to: [
					"/informacion_interes/autogas"
				]
            }, */
            {
				title: "Granel Industrial",
				to: [
					"/informacion_interes/granel_industrial"
				]
            },        
		];
	}

	componentDidMount() {
		this.props.changeHeaderClass("medium");
		this.loadServices();
	}
	
	loadServices() {
		if(!this.props.isEmpresas) {
				this.setState({loadingIndustrialHousing: false});
			
			meterBussinesLineList((error, result) => {
				if(error) {
					console.log(error);
					console.log("ocurrio un error con el request");
				}else{
					var meterServices = [];
					if(result.status !== "empty") {
						meterServices = result.data;
					} else {
						meterServices = [];
					}
					this.setState({meterServices}, () => {
						this.setState({loadingMedidor: false});	
					});
				}
			});
			housingBussinesLineList((error, result) => {
				if(error) {
					console.log(error);
					console.log("ocurrio un error con el request");
				}else{
					var housingServices = [];
					if(result.status !== "empty") {
						housingServices = result.data;
					} else {
						housingServices = [];
					}
					this.setState({housingServices}, () => {
						this.setState({loadingHousing: false});
					});
				}
			});
			cilindroBussinesLineList((error, result) => {
		        if(error) {
		          console.log(error);
					console.log("ocurrio un error con el request");
		        }else{
					var cilindroServices = [];
					if(result.status !== "empty") {
						cilindroServices = result.data;
					} else {
						cilindroServices = [];
					}
					this.setState({cilindroServices}, () => {
						this.setState({loadingCilindro: false});
					});

				}
			});
			autogasBussinesLineList((error, result) => {
		        if(error) {
		          console.log(error);
					console.log("ocurrio un error con el request");
		        }else{
					var autogasServices = [];
					if(result.status !== "empty") {
						autogasServices = result.data;
					} else {
						autogasServices = [];
					}
					this.setState({autogasServices}, () => {
						this.setState({loadingAutogas: false});
					});

				}
			});

		} else {
			this.setState({loadingHousing: false,loadingMedidor: false});
			userClientsPrivileges((error, result) => {
				if(error) {
					return console.log("ocurrio un error con el request");
				}
				var industrialHousingServices = [];
				if(result.status !== "empty") {
					industrialHousingServices = result.data.filter((req)=>parseInt(req.business_line_id) === 6);
					industrialHousingServices.forEach((service) => {
					});
				} else {
					industrialHousingServices = [];
				}

				this.setState({industrialHousingServices}, () => {
					this.setState({loadingIndustrialHousing: false});
				});

			});

		}
	}

	renderRedirect(){
		if(!this.state.loadingMedidor && !this.state.loadingHousing && !this.state.loadingIndustrialHousing && !this.state.loadingAutogas){
			if(this.state.meterServices && this.state.meterServices.length > 0){
				return(
					<Route exact path="/informacion_interes">
	                	<Redirect to="/informacion_interes/medidor"/>
		            </Route>
				);
			}
			if(this.state.housingServices && this.state.housingServices.length > 0){
				return(
					<Route exact path="/informacion_interes">
	                	<Redirect to="/informacion_interes/granel"/>
		            </Route>
				);
			}
			if(this.state.cilindroServices && this.state.cilindroServices.length > 0){
				return(
					<Route exact path="/informacion_interes">
	                	<Redirect to="/informacion_interes/cilindro"/>
		            </Route>
				);
			}
			if(this.state.autogasServices && this.state.autogasServices.length > 0){
				return(
					<Route exact path="/informacion_interes">
	                	<Redirect to="/informacion_interes/autogas"/>
		            </Route>
				);
			}
			if(this.state.industrialHousingServices && this.state.industrialHousingServices.length > 0){
				return(
					<Route exact path="/informacion_interes">
	                	<Redirect to="/informacion_interes/granel_industrial"/>
		            </Route>
				);
			}
		}
	}

	render() {
		return (
        	<Fragment>
				<div className="section-title">
					<h1>Información de Interés</h1>
				</div>
                <CustomTabs tabs={this.tabs.filter((tab) => {
							if(tab.title === "Granel" && !this.state.housingServices.length) return false;
							if(tab.title === "Medidor" && !this.state.meterServices.length) return false;
							if(tab.title === "Cilindro" && !this.state.cilindroServices.length) return false;
							if(tab.title === "Autogas" && !this.state.autogasServices.length) return false;

							if(tab.title === "Granel Industrial" && !this.state.industrialHousingServices.length) return false;

							return true;
						})} location={this.props.location} />		
				<Switch>
					{this.renderRedirect()}					
        			<Route exact path="/informacion_interes/medidor">
	                	<InformacionInteresMedidor />
		            </Route>
					<Route exact path="/informacion_interes/granel">
						<InformacionInteresGranel />
					</Route>
					<Route exact path="/informacion_interes/cilindro">
						<InformacionInteresCilindro />
					</Route>
					<Route exact path="/informacion_interes/autogas">
						<InformacionInteresAutogas />
					</Route>
		            <Route exact path="/informacion_interes/granel_industrial">
	                	<InformacionInteresGranelIndustrial />
		            </Route>					
				</Switch>
          	</Fragment>
		);
	}
};
