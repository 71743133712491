import React, { Component } from 'react';
import 'chartjs-plugin-datalabels';
import ContentBox from './ContentBox.js';
import moment from 'moment';
import es from 'date-fns/locale/es';
import { registerLocale } from  "react-datepicker";
import {getAgreement} from '../api.js';
registerLocale('es', es);

export default class ServiciosMedidorConvenioPagoDetalle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graphData: null,
			agreement: null,
			agreementMessage: null,
			times: 0,
			loading: false,
		};
	}	
	componentDidMount() {
		if(this.props.agreementId) {
			this.getList();
		}
	}	
	componentDidUpdate(prevProps) {
		if(prevProps.agreementIde) {
			this.getList();
		}
	}
	getList(cb) {
		this.setState({formErrors: null, loading: true, agreement: null, agreementMessage: null});
        this.getAgreements(() => {
            this.setState({loading: false});
        });
	}
	getAgreements(cb) {
		getAgreement(this.props.agreementId, (error, result) => {
			if(error) {
				cb(error);
				return console.log(error);
			}
			if(result.status === "error") {
				cb();
				return this.setState({agreementMessage: result.message});
			}
			if(result.status === "empty") {
				cb();
				return this.setState({agreement: null, agreementMessage: result.message});
			}
			this.setState({agreement: result.data}, cb?cb:null);
		});
	}
	render() {
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
                    <h1>Detalle Convenio de Pago</h1>
					{!this.state.agreement ? null :
						<div className="solicitud-detalle-fields col-100 left clearfix margin-top-30">
							{this.state.agreement.abastible_contract_id?<div className="solicitud-detalle-field">
								<div className="solicitud-detalle-label">
									<p>Codigo de cliente</p>
								</div>
								<div className="solicitud-detalle-value">
									<p>{parseInt(this.state.agreement.abastible_contract_id)}</p>
								</div>
							</div>:null}
							{this.state.agreement.sap_check_number?<div className="solicitud-detalle-field">
								<div className="solicitud-detalle-label">
									<p>Nro. Comprobante Pie</p>
								</div>
								<div className="solicitud-detalle-value">
									<p>{parseInt(this.state.agreement.sap_check_number)}</p>
								</div>
							</div>:null}
							{this.state.agreement.agreement_sap_creation_time?<div className="solicitud-detalle-field">
								<div className="solicitud-detalle-label">
									<p>Fecha pago pie</p>
								</div>
								<div className="solicitud-detalle-value">
									<p>{this.state.agreement.agreement_sap_creation_time ? moment(this.state.agreement.agreement_sap_creation_time, "YYYY-MM-DD").format("DD-MM-YYYY") : null}</p>
								</div>
							</div>:null}
							{this.state.agreement.agreement_abastible_id?<div className="solicitud-detalle-field">
								<div className="solicitud-detalle-label">
									<p>Nro. Convenio</p>
								</div>
								<div className="solicitud-detalle-value">
									<p>{this.state.agreement.agreement_abastible_id}</p>
								</div>
							</div>:null}
							{/* this.state.agreement.status === 'Anulado' ||  this.state.agreement.status === 'Error anulación' ? 
								<div className="solicitud-detalle-field">
									<div className="solicitud-detalle-label">
										<p>Fecha anulación  ({ this.state.agreement.agreement_nullify_time }), ({ this.state.agreement.status })</p>
									</div>
									<div className="solicitud-detalle-value">
										<p>{this.state.agreement.agreement_nullify_time ? moment(this.state.agreement.agreement_nullify_time).format("DD-MM-YYYY") : null}</p>
									</div>
								</div>
							: null*/}
							<div className="table-container-2">
								<table className="table1">
									<thead>
										<tr>
											<td>Número cuota</td>
											<td>Monto cuota</td>
											<td>Intereses de la cuota</td>
											<td>Total cuota</td>
											<td>Estado</td>
										</tr>
									</thead>
									<tbody>
										{this.state.agreement.dues.length === 0?null:this.state.agreement.dues.map((share, i) => {
											return(
										<tr key={'share-' + i}>
											<td>{share.due_number}</td>
											<td>${parseInt(share.due_amount).toLocaleString("de")}</td>
											<td>${parseInt(share.interest).toLocaleString("de")}</td>
											<td>${(parseInt(share.due_amount) + parseInt(share.interest)).toLocaleString("de")}</td>
											<td>{share.status}</td>
										</tr>
											);
										})}
									</tbody>
								</table>
								{this.state.agreement.dues.length === 0?<p>No se registran cuotas</p>:null}
							</div>
						</div>
					}
                </div>
			</ContentBox>
		);
	}
}