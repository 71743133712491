import React, { Component } from 'react';
import Close from '../img/close.svg';

export default class PopupPrivilegiosFuncionario extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: []
		};
	}	
	componentDidUpdate(prevProps) {
		if(prevProps.show !== this.props.show) {
			this.setState({selected: this.props.client_privileges?[...this.props.client_privileges]:[]});	
		}
	}
	cancel() {
		this.props.cancel();
	}
	toggleOption(option) {
		var selected = [...this.state.selected];
		var i = selected.indexOf(option);
		if(i === -1) {
			selected.push(option);
		} else {
			selected.splice(i, 1);
		}
		this.setState({selected});
	}
	
	render() {
		return (
			<div className={"full-screen-veil " + (this.props.show ? 'show-modal' : '')}>
				<div className="modal1 modal-privilegios">
					<div className="content col-100 left clearfix">
	      				<h1 className="fw700" style={{marginBottom: "10px"}}>Privilegios</h1>
							{this.props.show ? 
								<div>
									<div className={"inline-block " + (this.props.show.merchandise_recipients && this.props.show.merchandise_recipients.length > 0 ? "icon-tanque": "")}></div>
									<div className={"inline-block " + (this.props.show.payment_responsibles && this.props.show.payment_responsibles.length > 0 ? "icon-dinero": "")}></div>
								</div>
							: null}
							<p style={{textAlign: "left"}}> <strong>N° de Cliente:</strong> {this.props.show ? this.props.show.abastible_client_id : null}</p>
							<p style={{marginBottom: "30px", textAlign: "left"}}> <strong>Dirección:</strong> {this.props.show ? this.props.show.address : null}, {this.props.show ? this.props.show.comune : null}</p>
	      			</div>
	      			<div className="content col-100 grid-column-2 left clearfix not-selectable margin-bottom-30">
	      				{!this.props.show?null:this.props.privileges.map((privilegio, i) => {
	      					return (
	      					<div key={privilegio.id} className={"button-checkbox" + (this.state.selected.indexOf(privilegio.id)!==-1?" checked":"")}
	      						onClick={()=>this.toggleOption(privilegio.id)}>
	      						<p className="fw600 text-align-left">{privilegio.name}</p>
	      						<div className={"button-checkbox-icon"} />
      						</div>
	      					);
	      				})}
	      			</div>
					<button className="button orange" onClick={() => {
						this.props.confirm(this.state.selected)
					}
					}>Confirmar</button>


									
					<div className="solicitud-evaluacion-close-container" onClick={() => this.cancel()}>
						<img alt="" src={Close} />
					</div>

				</div>
			</div>
		);
	}
}