import React, { Component } from 'react';
import icon from '../img/whatsapp-color.svg';


export default class WhatsAppButton extends Component {
	render() {
		return (
			<div className={"whatsapp-icon"+(this.props.class?' '+this.props.class:'')}>
		        <a href={"https://api.whatsapp.com/send?phone=56225831156&amp;text=¡Hola%20Abastible!"} target={"_blank"}>
		            <img src={icon}/>
		            <span>
		                {this.props.text?this.props.text:"Pide gas cilindro y tanque Aquí"}
		            </span>
		        </a>
		    </div>
		);
	}
}