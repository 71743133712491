export default {
	granel_industrial_resumen_tanques: 1002, //1001
	granel_industrial_historial_pedidos: 1002,
	granel_industrial_realizar_pedidos: 1003,
	granel_industrial_cuenta_corriente: 1004,
	granel_industrial_telemetria: 1005,
	granel_industrial_requerimientos: 1006, 
	granel_industrial_historial_pagos: 1007,
	granel_industrial_detalle_deuda: 1008,
	granel_industrial_tarifas: 1009,

	envasado_institucional_cotizar_pedido: 2001, 
	envasado_institucional_cuenta_corriente: 2002 

}