import React, { Component } from 'react';
import ContentBox from './ContentBox.js';
import ClientSelectCilindro from './ClientSelectCilindro';
import {cilindroGetPrices} from '../api.js';
import moment from 'moment';

export default class ServiciosCilindroPrecios extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ciudad: "",
			region: ""
		};
	}	
	componentDidMount() {
		if(this.props.selectedService) {
			if (this.props.selectedService) this.getList();
		}
	}	
	componentDidUpdate(prevProps) {
		if(prevProps.selectedService !== this.props.selectedService && this.props.selectedService) {
			this.getList();
		}
	}
	getList(cb) {
		this.setState({formErrors: null, loading: true, precios: null, ciudad: null, region: null});
		cilindroGetPrices(this.props.selectedService.client_cod, (error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
			}
			if(result.status === "error") {
				if(cb) cb();
				this.setState({loading: false, formErrors: result.errors});
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({loading: false});
			}
			this.setState({precios: result.data, loading: false, ciudad: this.props.selectedService.client_city, region: this.props.selectedService.client_region_cod});
		});
	}

	
	
	render() {
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Precios</h1>
									
					<div className="client-number">
						<ClientSelectCilindro 
							selected={this.props.selectedService} 
							services={this.props.services}
							setSelectedService={this.props.setSelectedService} />	
					</div>						
				</div>
				<div className="message margin-bottom-40">
						<p>Valor de productos para hoy {moment().format('DD-MM-YYYY')} en {this.state.ciudad}, {this.state.region}.</p>
				</div>
				<div className="table-container-2">
					<table className="table1">
						<thead>
							<tr>
								<td>Tipo de Producto</td>
								<td>Precio</td>								
							</tr>
						</thead>
						<tbody>
							{this.state.precios && this.state.precios.map(precio => {
								return(
									<tr key={precio.type_code}>
										<td>{precio.type}</td>
										<td>${parseInt(precio.price).toLocaleString("de")}</td>
									</tr>
								);
							}) }							
						</tbody>
					</table>
				</div>
						
			</ContentBox>
		);
	}
}