import React, { Component, Fragment } from 'react';
import ContentBox from './ContentBox.js';

import {Link} from 'react-router-dom';
import {housingCuentaCorrienteDetalle} from '../api.js';

import flechaIzquierdaNegra from '../img/arrow_left_black.svg';
import PostFileDownloadDocument from './PostFileDownloadDocument';
import Button2 from './Button2';


export default class ServiciosGranelCuentaCorrienteDocumento extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			gui_checked: false,
			doc_checked: false,
		};
	}
	componentDidMount() {
		console.log(this.props.selectedService);
		if(this.props.reloadServices) this.props.reloadServices();
		
		if(this.props.selectedService) this.getList();
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.selectedService !== this.props.selectedService) this.getList();
		if (prevState.gui_checked !== this.state.gui_checked && this.state.gui_checked && this.state.doc_checked || prevState.doc_checked !== this.state.doc_checked && this.state.doc_checked && this.state.gui_checked) {
			this.setState({loading: false})
		}
	}
	getList(cb) {
		this.setState({loading: true, pedido: null, pedidosMessage: "", gui_checked: false, doc_checked: false});
		housingCuentaCorrienteDetalle(this.props.client_cod, this.props.doc_number, this.props.doc_year, (error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({loading: false, pedidosMessage: result.message});
			}
			this.setState({doc: result.data});
			if (!result.data.pdf_guide) {
				this.setState({gui_checked: true})
			}
			if (!result.data.pdf_document) {
				this.setState({doc_checked: true})
			}
		}, this.props.isEmpresa?"empresas":null);
	}
	isChecked(type) {
		if (type === "gui") {
			this.setState({ gui_checked: true });
		}
		if (type === "doc") {
			this.setState({ doc_checked: true });
		}
	}
	cancelModal(){
		this.setState({
			modal: false
		});
	}	
	filter() {
		this.getList();
	}
	render() {
		var doc = this.state.doc;
		return (
			<Fragment>
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1 className="light">N° del Documento:<strong>{doc?doc.folio_number:""}</strong></h1>
				</div>

				<Link to={"/mis-servicios/granel/cuenta-corriente"}>
					<div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30">
						<img alt="Atrás" src={flechaIzquierdaNegra}/>
						<p>atrás</p>
					</div>
				</Link>

				
				<div className="subcontent-box col-100 left clearfix margin-bottom-30">
						<div className="granel-pedido-details grid-column-3">						
						<div className="detail">
							<span>Fecha de emisión</span>
							<p>{doc?doc.issue_date:""}</p>
						</div>
						<div className="detail">
							<span>Valor</span>
							<p>{doc?"$"+parseInt(doc.value).toLocaleString("de"):""}</p>
						</div>
						<div className="detail">
							<span>Guía de despacho</span>
							<p>{doc?doc.office_guide:""}</p>
						</div>
						<div className="detail">
							<span>Fecha de entrega</span>
							<p>{doc?doc['delivery date']:""}</p>
						</div>
						<div className="detail">
							<span>Cantidad</span>
							<p>{doc?doc.quantity:""}</p>
						</div>
						<div className="detail">
							<span>Producto</span>
							<p>{doc?doc.product:""}</p>
						</div>
						{doc && doc.address && doc.address.trim() !== ""?<div className="detail">
							<span>Dirección</span>
							<p>{doc.address}</p>
						</div>:null}
						<div className="grid-item-full text-center">							
							{doc && doc.pdf_guide ?
							<PostFileDownloadDocument 
								class_description={doc.pdf_guide.class_description} 
								pdf_number={doc.pdf_guide.pdf_number} 
								createActionNotification={this.props.createActionNotification}
								isEmpresa={this.props.isEmpresa?"empresas":null}
								doCheck={true}
								isChecked={this.isChecked.bind(this)}
								docType={"gui"}
								
								contentRender={() => 
								<div className="linkA-container">
									<Button2 label={"Descargar guía de despacho"} className="button button-border-orange" />
								</div>} />:null}
							{doc && doc.pdf_document ?
							<PostFileDownloadDocument 
								class_description={doc.pdf_document.class_description} 
								pdf_number={doc.pdf_document.pdf_number} 
								createActionNotification={this.props.createActionNotification}
								isEmpresa={this.props.isEmpresa?"empresas":null}
								doCheck={true}
								isChecked={this.isChecked.bind(this)}
								docType={"doc"}

								contentRender={() => 
								<div className="linkA-container">
									<Button2 label={"Descargar documento"} className="button button-border-orange" />
								</div>} />:null}
						</div>						
					</div>
			   </div>				
			</ContentBox>
			</Fragment>
		);
	}
}
