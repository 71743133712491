import React from "react";

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState("");

  React.useEffect(() => {
    const winWidth = window.innerWidth;
    let _tempIsMobile = isMobile;
    if (winWidth <= 950) {
      _tempIsMobile = true
    } else {
      _tempIsMobile = false
    }
    setMobile(_tempIsMobile);
  }, []);

  return { isMobile };
}