import Tooltip from 'rc-tooltip';
import React from 'react';

export function ServiciosMedidorToggle({
  subscribed,
  setOpen,
  onClick,
  disabled,
}) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  return (
    <Tooltip
      placement='bottom'
      visible={tooltipOpen && disabled}
      overlay={<span>Para suscribir debes seleccionar su categoría</span>}
    >
      <button
        onMouseEnter={() => {
          setTooltipOpen(true);
        }}
        onMouseLeave={() => {
          setTooltipOpen(false);
        }}
        className={`Button2 w-32 h-8 p-0 ${
          subscribed ? 'bg-red hover:bg-red' : 'green'
        } ${disabled ? 'opacity-50' : ''}`}
        onClick={() => {
          if (disabled) {
            setTooltipOpen(true);
            return;
          }
          onClick();
          setOpen(true);
        }}
      >
        <p>{subscribed ? 'Desuscribir' : 'Suscribir'}</p>
      </button>
    </Tooltip>
  );
}
