import React, { useState, useEffect } from 'react'
import {Link, useLocation} from "react-router-dom";
import ContentBox from './ContentBox.js';
import {getCompanySelector, getServiciosSelector, getCompanyClients, userClientsPrivileges} from '../api.js';

export default function EmpresasMisClientes() {
    const linkProps = useLocation()
    const [backHandler, setBackHandler] = useState(true)
    const [loading,setLoading] = useState(true)

    const [companySelector, setCompanySelector] = useState([]);
    const [businessLineSelector, setBusinessLineSelector] = useState([]);

    const [idCliente, setIdCliente] = useState("")
    const [idCompany, setIdCompany] = useState("")
    const [idBusinessLine, setIdBusinessLine] = useState("")

    const [clientList, setClientList] = useState([])

    useEffect(() => {
            getCompanySelector((error, result)=>{
                if(error) {
                    console.log(error)
                }
                if(result) {
                    console.log(result)
                    setCompanySelector(result.data);
                    getServiciosSelector((error, result)=>{
                        if(error) {
                            console.log(error)
                        }
                        if(result) {
                            setBusinessLineSelector(result.data);
                            filter();
                        }                    
                    });
                }                
            });            
    },[]);

    const filter = () => {
        setLoading(true)
        if (backHandler) {
            if (linkProps.state != undefined) {
                setBackHandler(!backHandler)
                const backFilterParams = linkProps.state.filterParams
                getCompanyClients(backFilterParams.idCliente,backFilterParams.idCompany,backFilterParams.idBusinessLine,(error, result)=>{            
                    if(error) {
                        console.log(error)
                    }
                    if(result) {
                        setClientList(result.data)
                        setLoading(false)
                    }                   
                });
            } else {
                setBackHandler(!backHandler)
                getCompanyClients("","","",(error, result)=>{            
                    if(error) {
                        console.log(error)
                    }
                    if(result) {
                        setClientList(result.data)
                        setLoading(false)
                    }                   
                });
            }
        } else {
            getCompanyClients(idCliente,idCompany,idBusinessLine,(error, result)=>{            
                if(error) {
                    console.log(error)
                }
                if(result) {
                    setClientList(result.data)
                    setLoading(false)
                }                   
            });
        }        
    }

    return (
        <ContentBox className="margin-top-40 margin-bottom-40 overflow-hidden" loading={loading}>
            <h1 className="margin-bottom-40">Buscar Clientes</h1>
            <div className="col-100 left d-flex justify-between margin-bottom-40">
                <div className="filter-container grid-column-4">
                    <div className="position-relative">
                        <input type="text" placeholder="N° de Cliente" onChange={e => setIdCliente(e.target.value)}/>
                    </div>
                    <div className="position-relative">
                        <select onChange={e => setIdCompany(e.target.value)}>
                            <option value="">Empresas</option>
                            {companySelector ? Object.entries(companySelector).map((value, key) => {
                                return (<option key={key} value={value[1].company_id}>{value[1].company_name}</option>);
                            }) : null}
						</select>
                    </div>
                    <div className="position-relative">
                        <select onChange={e => setIdBusinessLine(e.target.value)}>
                            <option value="">Servicios</option>
                            {Object.entries(businessLineSelector).map((value, key) => {
                                return (<option key={key} value={value[1].business_line_id}>{value[1].business_line_name}</option>);
                            })}
						</select>
                    </div>
                    <button className="button orange" onClick={filter}>Filtrar</button>
                </div>
            </div>
            <div className="table-container">
				<table className="table1">
					<thead>
						<tr>
							<td>N° de Cliente</td>
							<td>Empresa</td>
							<td>Rut</td>
							<td>Servicios</td>
							<td></td>
                            <td></td>
						</tr>							
					</thead>
					<tbody>
                        {clientList && clientList.length > 0 ? clientList.map((cliente, i) => {
                            return(
                                    <tr key={i}>
                                        <td>{parseInt(cliente.client_cod)}</td>
                                        <td>{cliente.company_name}</td>
                                        <td>{cliente.company_dni}</td>
                                        <td>{cliente.business_line_name}</td>  
                                        <td>
                                            <div className={"inline-block " + (cliente.merchandise_recipients && cliente.merchandise_recipients.length > 0 ? "icon-tanque": "")}></div>
                                            <div className={"inline-block " + (cliente.payment_responsibles && cliente.payment_responsibles.length > 0 ? "icon-dinero": "")}></div>
                                        </td>                                      
                                        <td><Link to={{
                                            pathname: "/mis-servicios/cliente/", 
                                            state:{
                                                idCliente: cliente.client_id,
                                                idCompany: cliente.company_id,
                                                filterParams: {idCliente,idCompany,idBusinessLine}
                                                    }
                                                }
                                            }>Ver Detalle</Link></td>
                                    </tr>
                            )
                        }) : null}
                    </tbody>                    
                </table>
                <p>{clientList && clientList.length<1?"No se han encontrado datos":null}</p>
            </div>
        </ContentBox>
    )
}
