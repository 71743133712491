import React, { Component } from 'react';
import CustomSelect from './CustomSelect';
import ServiceListBox from './ServiceListBox';

export default class ClientSelectMedidor extends Component {
	render() {
		if(this.props.services != null && this.props.services.length > 1){
			return(
				<CustomSelect
					options={this.props.services ? this.props.services : []}
					selected={this.props.selected}
					placeholder="Selecciona Cliente"
					onChange={(option) => {
						this.props.setSelectedService(option);
					}}
					renderSelected={(option, opened) => {
						return <ServiceListBox displayNumberLabel={"contract_cod"} service={option} onlyHeader={false} />
					}}
					renderOption={(option, i) => {
						return <ServiceListBox displayNumberLabel={"contract_cod"} service={option} />
					}}
				/>
			);
		} else {
			if(this.props.selected){
				return(
					<div className="CustomSelect cursor-default">
						<div className="CustomSelect-icon"></div>
						<div className="servicio-list-box text-align-left">
							<h1 className="font-15">N° de cliente {Number(this.props.selected[this.props.number_field?this.props.number_field:"contract_cod" ]).toString()}</h1>
							<p className="servicio-list-box-address">{this.props.selected.client_address}</p>
							<p className="servicio-list-box-city">{this.props.selected.client_city}, {this.props.selected.client_region_cod}</p>
						</div>
					</div>
				);
			}
		}
		return null;
	}
}
