import React, { Component, Fragment } from 'react';
import {Switch, Route} from 'react-router-dom';

import CustomTabs from './CustomTabs';
import CuentaMedidor from './CuentaMedidor';
import CuentaAutogas from './CuentaAutogas';

import AsociarServicios from './AsociarServicios';
import AsociarServiciosAutogas from './AsociarServiciosAutogas';


import {housingBussinesLineList, meterBussinesLineList, cilindroBussinesLineList, autogasBussinesLineList} from '../api.js';
import {available} from '../config.js';
import Clara from "./Clara"

export default class MisCuentas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hideMedidor: true,
			hideGranel: true,
			hideCilindro: true,
			hideAutogas: true
		};
		this.tabs = [		
			{
				title: "Medidor",
				to: ["/adminitracion_cuentas/medidor", "/adminitracion_cuentas/medidor/asociar"]
			},
			{
				title: "Granel Habitacional",
				to: ["/adminitracion_cuentas/granel", "/adminitracion_cuentas/granel/asociar"]
			},
			{
				title: "Cilindro",
				to: ["/adminitracion_cuentas/cilindro", "/adminitracion_cuentas/cilindro/asociar"]
			},
			{
				title: "Autogas",
				to: ["/adminitracion_cuentas/autogas", "/adminitracion_cuentas/autogas/asociar"]
			}
		];
	}
	componentDidMount() {
		if(this.props.location.pathname === "/adminitracion_cuentas/") this.checkServices();
	}
	componentDidUpdate(prevProps) {
		if(prevProps.location && prevProps.location.pathname !== this.props.location.pathname) {
			if(this.props.location.pathname === "/adminitracion_cuentas/") this.checkServices();
		}
	}
	checkServices() {
		meterBussinesLineList((error, resultMeter) => {
		    if(error) {
		        return console.log("error en meterBussinessLineList para menu", error);
	      	}
		    housingBussinesLineList((error, resultHousing) => {
		        if(error) {
		          	return console.log("error en housingBussinessLineList para menu", error);
		        }
		        cilindroBussinesLineList((error, resultCilindro) => {
			        if(error) {
			          	return console.log("error en cilindroBussinessLineList para menu", error);
			        }
			        autogasBussinesLineList((error, resultAutogas) => {
				        if(error) {
				          	return console.log("error en autogasBussinessLineList para menu", error);
				        }
				        if(resultMeter.status !== "empty") this.props.redirect("/adminitracion_cuentas/medidor/")
				        else if(available.granel && resultHousing.status !== "empty") this.props.redirect("/adminitracion_cuentas/granel/")
				        else if(available.cilindro && resultCilindro.status !== "empty") this.props.redirect("/adminitracion_cuentas/cilindro/")
				        else if(available.autogas && resultAutogas.status !== "empty") this.props.redirect("/adminitracion_cuentas/autogas/")

				        else this.props.redirect("/adminitracion_cuentas/medidor/")
				        this.setState({
				        	hideGranel: resultHousing.status === "empty" || !available.granel, 
				        	hideCilindro: resultCilindro.status === "empty" || !available.cilindro, 			        	
				        	hideAutogas: resultAutogas.status === "empty" || !available.autogas,
				        	hideMedidor: resultMeter.status === "empty"});
			    	});
			    });
		    });

		});
	}
	render() {		
		return (
			<Fragment>
				 <Clara/>
			<div className="section-title">
				<Switch>
					<Route path="/adminitracion_cuentas/medidor">
						<h1>Medidor</h1>
					</Route>
					<Route path="/adminitracion_cuentas/granel">
						<h1>Granel Habitacional</h1>
					</Route>
					<Route path="/adminitracion_cuentas/cilindro">
						<h1>Cilindro</h1>
					</Route>
					<Route path="/adminitracion_cuentas/autogas">
						<h1>Autogas</h1>
					</Route>
				</Switch>				
			</div>			
			<Switch>
				<Route exact path="/adminitracion_cuentas/autogas"
              	render={(data)=> {
	                return <Fragment>
	                	<CustomTabs tabs={this.tabs.filter((tab) => {
							if(tab.title === "Cilindro" && this.state.hideCilindro) return false;	                		
							if(tab.title === "Granel Habitacional" && this.state.hideGranel) return false;
							if(tab.title === "Autogas" && this.state.hideAutogas) return false;							
							if(tab.title === "Medidor" && this.state.hideMedidor) return false;

							return true;
						})} location={this.props.location} />
	                	<CuentaAutogas
	                  	redirect={this.props.redirect}
	                  	location={this.props.location}
	                  	changeHeaderClass={this.props.changeHeaderClass}
	                  	createActionNotification={this.props.createActionNotification}
	                  	checkMenuOptions={this.props.checkMenuOptions}
	                  	service={"autogas"} />
	                  </Fragment>
	            }}/>	
				<Route exact path="/adminitracion_cuentas/:service"
              	render={(data)=> {
	                return <Fragment>
	                	<CustomTabs tabs={this.tabs.filter((tab) => {
							if(tab.title === "Cilindro" && this.state.hideCilindro) return false;	                		
							if(tab.title === "Granel Habitacional" && this.state.hideGranel) return false;
							if(tab.title === "Autogas" && this.state.hideAutogas) return false;							
							if(tab.title === "Medidor" && this.state.hideMedidor) return false;

							return true;
						})} location={this.props.location} />
	                	<CuentaMedidor
	                  	redirect={this.props.redirect}
	                  	location={this.props.location}
	                  	changeHeaderClass={this.props.changeHeaderClass}
	                  	createActionNotification={this.props.createActionNotification}
	                  	checkMenuOptions={this.props.checkMenuOptions}
	                  	service={data.match.params.service} />
	                  </Fragment>
	            }}/>	              
	            <Route exact path="/adminitracion_cuentas/autogas/asociar"
	            render={(data)=> {
	                return <AsociarServiciosAutogas
	                  	redirect={this.props.redirect}	                
	                  	changeHeaderClass={this.props.changeHeaderClass}
	                  	createActionNotification={this.props.createActionNotification}
	                  	checkMenuOptions={this.props.checkMenuOptions}
	                  	user={this.props.user}
	                  	location={this.props.location}
	                  	service={"autogas"} />
	            }}/>
	            <Route exact path="/adminitracion_cuentas/:service/asociar"
	            render={(data)=> {
	                return <AsociarServicios
	                  	redirect={this.props.redirect}	                
	                  	changeHeaderClass={this.props.changeHeaderClass}
	                  	createActionNotification={this.props.createActionNotification}
	                  	checkMenuOptions={this.props.checkMenuOptions}
	                  	user={this.props.user}
	                  	location={this.props.location}
	                  	service={data.match.params.service} />
	            }}/>
			</Switch>
			<div className="mobile-space"></div>
			</Fragment>
		);
	}
}