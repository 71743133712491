import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import ContentBox from './ContentBox.js';
import ServiciosCilindroMap from "./ServiciosCilindroMap"

import PopupPedidoReclamo from './PopupPedidoReclamo';
import PopupPedidoAnular from './PopupPedidoAnular';

import Button2 from './Button2';

import {cilindroGetPedido, getCilindroOrderCoords} from '../api.js';

import pedidoRealizadoOrange from '../img/pedido-realizado-orange.svg';
import pedidoEncaminoGray from '../img/pedido-encamino-gray.svg';
import pedidoEncaminoOrange from '../img/pedido-encamino-orange.svg';
import pedidoEntregadoCilindroGray from '../img/pedido-entregado-cilindro-gray.svg';
import pedidoEntregadoCilindroGreen from '../img/pedido-entregado-cilindro-green.svg';
import flechaIzquierdaNegra from '../img/arrow_left_black.svg';
import moment from 'moment';
import {getServiceAddressString} from '../utils.js';

export default class ServiciosCilindroPedido extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			first_render: true,
			initial_status: false,
			direccion: null
		};
	}
	componentDidMount() {
		this.setState({loading: true, pedido: null, pedidosMessage: ""});		
		
		this.getList(() => { 
			if (this.state.pedido.state === "Camino") {
				this.getCoordinates();
				this.getDirectionCoordinates(this.state.pedido);
			}

			this.refresh = setInterval(() => {
				if(this.state.pedido) {
					if (this.state.pedido.state === "Camino") {
						this.getCoordinates();
						this.filter()					
					} else if (this.state.initial_status === "Camino" && this.state.pedido.state === "Entregado") {
						this.getCoordinates();
						this.filter();
						clearInterval(this.refresh);
					} else {
						clearInterval(this.refresh);
					}		
				}
			}, 5000*60)
		});
	}
	componentWillUnmount() {
		clearInterval(this.refresh);	
	}
	getDirectionCoordinates(pedido) {
		let address = pedido.client_street + " " + pedido.client_number + " " + pedido.comune
		this.setState({direccion: address})
	}
	getCoordinates() {
		
		getCilindroOrderCoords(this.state.pedido.truck_code, (error, result) => {
			if(error) {
				return console.log(error);
			}
			if (result) {
				if (result.data.longitude && result.data.latitude) {
					this.setState({truck_position: result.data})
				}	else {
					this.setState({truck_position: {latitude: null, longitude: null}})
				}
			}		
		}, this.props.isEmpresa?"empresas":null );
	}
	getList(cb) {
		cilindroGetPedido(this.props.pedido_id, (error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({loading: false, pedidosMessage: result.message});
			}
			if (this.state.first_render) {
				this.setState({
					first_render: false,
					initial_status: result.data.state
				})
			}
			this.setState({pedido: {
				...result.data, 
				client_address: result.data.address,
				client_street: result.data.street,
				client_number: result.data.number,
				client_block: result.data.block,
				client_dept: result.data.dept,
				client_location: result.data.location
			}, loading: false},()=> {if(cb) cb();});
		}, this.props.isEmpresa?"empresas":null);
	}
	cancelModal(){
		this.setState({
			modal: false
		});
	}	
	filter() {
		this.getList();
	}
	render() {
		var pedido = this.state.pedido;
		var status = pedido ? pedido.state : null;
		return (
			<Fragment>
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1 className="light">N° del Pedido:<strong>{this.props.pedido_id}</strong></h1>
				</div>
				<Link to={"/mis-servicios/cilindro/pedidos"}>
				<div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30">
					<img alt="Atrás" src={flechaIzquierdaNegra}/>
					<p>atrás</p>
				</div>
				</Link>
				{ status && status === "Anulado" ? 
						<div className="pedido-status-container margin-bottom-40 col-100 left clearfix">
							<div className="message">
								<p>Este pedido ha sido anulado</p>
							</div>
						</div>
						: status === "Problema" ? 
						<div className="pedido-status-container margin-bottom-40 col-100 left clearfix">
							<div className="message">
								<p>Ocurrió un problema con este pedido</p>
							</div>
						</div>
						: <div className="pedido-status-container margin-bottom-40 col-100 left clearfix">
							<div className={"pedido-status pedido-status-3 active"}>
								<img alt="Pedido rcibido" src={pedidoRealizadoOrange} />
								<p>Pedido recibido</p>
							</div>
							<div className={"pedido-status pedido-status-3" + (status ==="Camino" || status === "Entregado"?" active":"")}>
								<img alt="En Camino" src={(status ==="Camino" || status === "Entregado")?pedidoEncaminoOrange:pedidoEncaminoGray} />
								<p>En Camino</p>
							</div>
							<div className={"pedido-status pedido-status-3" + (status === "Entregado"?" done":"")}>
								<img alt="Pedido Entregado" src={(status === "Entregado")?pedidoEntregadoCilindroGreen:pedidoEntregadoCilindroGray} />
								<p>Pedido entregado</p>
							</div>
						</div>	 }
							
				<div className="subcontent-box col-100 left clearfix margin-bottom-30 z-index-0">
						
						<div className="granel-pedido-details grid-column-3">
						<div className="detail">
							<span>Dirección</span>
							<p>{pedido?getServiceAddressString(pedido):""}</p>
						</div>
						<div className="detail">
							<span>Comuna</span>
							<p>{pedido?pedido.comune:""}</p>
						</div>
						<div className="detail">
							<span>Región</span>
							<p>{pedido?pedido.region:""}</p>
						</div>
						<div className="detail">
							<span>Fecha del pedido</span>
							<p>{pedido?moment(pedido.creationDateTime, "DD.MM.YYYY - hh:mm").format("DD-MM-YYYY HH:mm"):""}</p>
						</div>
						{status === "Entregado"?null:
						<div className="detail">
							<span>Fecha Programada</span>
							<p>{pedido? moment(pedido.scheduledDate, "YYYY-MM-DD").format("DD-MM-YYYY") + " " + moment(pedido.scheduledTime, "HH:mm").format("HH:mm"):""}</p>
						</div>}
						{status === "Entregado" && pedido && pedido.actuallyDeliveredDate?						
						<div className="detail">
							<span>Fecha de Entrega</span>
							<p>{moment(pedido.actuallyDeliveredDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</p>
						</div>:null}
						<div className="detail">
							<span>{status === "Entregado" ? "Monto Pagado" : "Monto a pagar"}</span>
							<p>{pedido?"$" + parseInt(pedido.amount).toLocaleString("de"):""}</p>
						</div>
						<div className="grid-item-full text-center">
							<div className="table-container-2">
								<table className="table1">
									<thead>
										<tr>
											<td>Producto</td>
											<td>Cantidad</td>
											<td>Monto</td>
										</tr>
									</thead>
									<tbody>
										{pedido && pedido.quantityUnits && pedido.quantityUnits.length > 0 ? 
											pedido.quantityUnits.map((item, i) => {
												return(
													<tr key={i}>
														<td>{item.producto}</td>
														<td>{item.cantidad}</td>
														<td>{"$" + parseInt(item.monto_total_prod).toLocaleString("de")}</td>
													</tr>
													)
											})
										: null }
									</tbody>
								</table>				
							</div>
						</div>					
						{this.state.loading?null:<div className="grid-item-full text-center">
							{pedido && pedido.cancel_order ?
								<Button2 label="Anular pedido" className="button button-border-orange" onClick={() => this.setState({anular_order: pedido})} />
							:null}
							{pedido && pedido.claimDelay?
								<Button2 label="Reclamar Retraso" className="button button-border-orange" onClick={()=>{this.setState({modal: pedido})}} />
							:null}
						</div>}
					</div>
			   </div>
				{this.state.initial_status === "Camino" && pedido && (this.state.truck_position || this.state.direccion)?				   
					<ServiciosCilindroMap
						truck_position={this.state.truck_position}
						direccion={this.state.direccion}
						setDireccion={(x) => this.setState({direccion: x})}
						/>:null}
				{this.state.anular_order?<PopupPedidoAnular 
					isEmpresa={this.props.isEmpresa} 
					client={this.props.selectedService} 
					order={this.state.anular_order} 
					cancel={()=>this.setState({anular_order: null})} 
					confirm={null} show={this.state.anular_order} 
					business_line="cylinder"/>:null}
				{this.state.modal?<PopupPedidoReclamo 
					isEmpresa={this.props.isEmpresa} 
					order_number={this.props.pedido_id} 
					client_cod={pedido?pedido.clientCode:null} 
					cancel={this.cancelModal.bind(this)} 
					confirm={null} 
					show={this.state.modal}
					hasClaim={this.state.modal.hasClaim}
					business_line="cylinder"/>:null}
				{this.state.initial_status === "Camino" && ((this.state.truck_position && !this.state.truck_position.latitude) || (!this.state.direccion)) ? 
					<div className="pedido-status-container col-100 left message-map-container">
						<p className="message-map">
							{(this.state.truck_position && !this.state.truck_position.latitude) && (!this.state.direccion) ? "No se han podido encontrar la posición de la dirección ni del camión" : this.state.truck_position && !this.state.truck_position.latitude ? "No se ha podido encontrar la posición del camión" : !this.state.direccion ? "No se ha podido encontrar la posición de la dirección" : null}
						</p>
					</div>
				: null}	 
					{/* {this.state.initial_status === "Camino" && ((this.state.truck_position && !this.state.truck_position.latitude) || (pedido && !pedido.address_latitude)) ? 
					<div className="pedido-status-container col-100 left message-map-container">
						<p className="message-map">
							{(this.state.truck_position && !this.state.truck_position.latitude) && (pedido && !pedido.address_latitude) ? "No se han podido encontrar la posición de la dirección ni del camión" : this.state.truck_position && !this.state.truck_position.latitude ? "No se ha podido encontrar la posición del camión" : pedido && !pedido.address_latitude ? "No se ha podido encontrar la posición de la dirección" : null}
						</p>
					</div>
					: null}	
					*/}		
			</ContentBox>
			</Fragment>
		);
	}
}

