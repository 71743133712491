import /*React,*/ { Component, /*Fragment*/ } from 'react';
//import {Link} from 'react-router-dom';
export default class BreadCrumb extends Component {
	render() {
		return null;
		/*return (
			<Fragment>
				<div className="breadcrumb">
					{this.props.options.map((option, i) => {
						if(i !== this.props.options.length - 1)
							return(<span key={i}><Link to={option.to}>{option.title}</Link>/</span>);
						return(<span key={i} className="current">{option.title}</span>);
					})}					
				</div>
			</Fragment>
		);*/
	}
}