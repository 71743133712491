import React, { Component } from 'react';
import Close from '../img/close.svg';
import Button2 from './Button2';
import {postHousingCancel, postCyinderCancel} from '../api.js';
import SolicitudAyudaImg from '../img/solicitud_ayuda_ok.png';
import {getServiceAddressString} from '../utils.js';

export default class PopupPedidoAnularGranel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			step: props.initialStep?props.initialStep:1,
			comment: "",
		};
	}
	sendForm() {
		if(this.props.business_line === 'housing'){
			postHousingCancel(this.props.order.orderNumber, this.state.comment, (error, result) => {
				console.log("error, result", error, result);
				if(error) {
					return this.setState({step: "retry"});
				}
				if(result.status === "error") return this.setState({step: "retry"});
				if(this.props.reload_list) this.props.reload_list();
				this.setState({step: 3});
			}, this.props.isEmpresa?"empresas":null);
		}
		if(this.props.business_line === 'cylinder'){
			postCyinderCancel(this.props.order.orderNumber, this.state.comment, (error, result) => {
				if(error) {
					return this.setState({step: "retry"});
				}
				if(result.status === "error") return this.setState({step: "retry"});
				this.props.reload_list();
				this.setState({step: 2});
			}, this.props.isEmpresa?"empresas":null);
		}
		
	}
	cancel() {
		this.setState({step: this.props.initialStep?this.props.initialStep:1})
		this.props.cancel();
	}
	renderStep1() {
		return(
			<div className="content col-100 left clearfix max-height-none">
				<h1 className="fw700">Anular Pedido</h1>
				<div className="col-100 left clearfix margin-bottom-20 font-20" style={{overflowY: "initial"}}>
					<p>¿Confirma anulación del pedido <strong>n° {this.props.order.orderNumber}</strong> solicitado para la dirección <strong>{getServiceAddressString(this.props.order)}, {this.props.order.comune}, {this.props.order.region}?</strong></p>

				</div>
				<div className="col-100 left clearfix flex justify-center">
					<Button2 label="No" className="button button-border-orange" onClick={() => {this.cancel()}}/>
					<Button2 label="Sí" className="button green"  onClick={() => {this.setState({step: 2})}}/>
				</div>
			</div>);
	}
	renderStep2() {
		return(
			<div className="content col-100 left clearfix">
	      		<h1 className="fw700">Observación</h1>
	      		<textarea value={this.state.comment} onChange={(e) => this.setState({comment:e.target.value})} />
	      		<div className="col-100 left clearfix">
		        	<Button2 label="Enviar" className="button green right margin-top-20" onClick={() => this.sendForm()} />
						</div>
			</div>);
	}
	renderStep3() {
		return(
			<div className="content col-100 left clearfix">
	      		<h1 className="fw700">Se ha enviado la solicitud de anulación del pedido.</h1>
	      		<div className="information-need-help-ok-image">
					<img className="question-orange" src={SolicitudAyudaImg} alt="Ícono ayuda" />
				</div>
			</div>);
	}
	renderStepRetry() {
		return(
			<div className="content col-100 left clearfix">
	      		<h1 className="fw700">Error anulando pedido, inténtalo más tarde</h1>
	      		<div className="col-100 left clearfix">
		            <Button2 label="Reintentar" className="button green right" onClick={() => {this.sendForm()}}/>
				</div>
			</div>);
	}
	renderCantCancel() {
		return(
			<div className="content col-100 left clearfix">
	      		<h1 className="fw700">Ya solicitaste anular este pedido</h1>
	      		<div className="information-need-help-ok-image">
					<img className="question-orange" src={SolicitudAyudaImg} alt="Ícono ayuda" />
				</div>
			</div>);
	}
	renderSteps() {
		if(!this.props.order) return;
		if(!this.props.order.cancel_order) return this.renderCantCancel();
		if(this.state.step === 1) return this.renderStep1();
		if(this.state.step === 2) return this.renderStep2();
		if(this.state.step === 3) return this.renderStep3();

		if(this.state.step === "retry") return this.renderStepRetry();

	}
	render() {
		return (
			<div className={"full-screen-veil " + (this.props.show ? 'show-modal' : '')}>
				<div className="modal1">
					{this.renderSteps()}
					<div className="solicitud-evaluacion-close-container" onClick={() => this.cancel()}>
						<img alt="" src={Close} />
					</div>
				</div>
			</div>
		);
	}
}
