import React, { Fragment, useEffect, useState } from 'react'
import ContentBox from './ContentBox.js';
import {getCompanyClientDetail} from '../api.js';
import {Link, useLocation} from "react-router-dom"
import flechaIzquierdaNegra from '../img/arrow_left_black.svg';

export default function EmpresasCliente() {
    const props = useLocation();
    const client = props.state.idCliente
    const company = props.state.idCompany
    const filterParams = props.state.filterParams

    const [detail, setDetail] = useState({})
    const [userDetail, setUserDetail] = useState([])
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
        getCompanyClientDetail(client, company, (error,result) => {
            if (error) {
                console.log(error)
            } if (result) {
                setDetail(result.data)
            }
        })
    }, [])

    useEffect(() => {
        if (Object.keys(detail).length === 0 && detail.constructor === Object) {
            console.log("")
        } else {
            setUserDetail(detail.company_users)
        }
    }, [detail])

    useEffect(() => {
        if (userDetail) {
            setLoading(false)
        }
    }, [userDetail])

    return (
        <Fragment>
            <div className="section-title">
                <h1>Clientes</h1>
            </div>            
            <ContentBox className="overflow-hidden" loading={loading}>
                <div className="title-detail-client-container clearfix">
                    <h1>Detalle de Cliente</h1>                    
                </div>
                <div>
                    <Link to={{pathname: "/mis-servicios/", state: {filterParams}}}>
                        <div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30">
                            <img alt="Atrás" src={flechaIzquierdaNegra}/>
                            <p>atrás</p>
                        </div>
                    </Link>
                </div>
                <div className="servicios-cliente-box-details margin-bottom-40">
                    <div className="servicios-servicio-box-details-left">
                        <div><h3>N° de Cliente: {detail.client_cod}</h3></div>
                        <div><h5>Rut: {detail.company_dni}</h5></div>
                    </div>
                    <div className="servicios-servicio-box-details-right">
                        <div><h2>{detail.company_name}</h2></div>
                    
                    <div><h2>{detail.client_address}</h2></div>
                    <div><p className="servicio-list-box-city">{detail.client_city}, {detail.client_region}</p></div>
                    </div>
                </div>
                <div><h1 className="margin-bottom-40" style={{fontSize: "1.5em"}}>FUNCIONARIOS</h1></div>
                <div className="table-container">
                <table className="table1">
					<thead>
						<tr>
							<td>Nombre</td>
							<td>Rut</td>
							<td>Email</td>
							<td>Privilegios</td>
							<td></td>
						</tr>							
					</thead>
					<tbody>
                        {userDetail.length>0?userDetail.map((user, i) => {
                            return(
                                <Fragment key={i}>
                                    <tr>
                                        <td>{user.company_user_name}</td>
                                        <td style={{minWidth: "125px"}}>{user.company_user_dni}</td>
                                        <td>{user.company_user_email}</td>
                                        <td className="text-green">{user.privileges_string}</td>
                                        <td><Link to={"/funcionarios/" + user.company_user_id}>EDITAR</Link></td>
                                    </tr>
                                </Fragment>
                            )
                        }):null}
                    </tbody>
                </table>
                </div>
                    <p>{userDetail.length==0?"No existen funcionarios con privilegios para el cliente consultado":null}</p>
            </ContentBox>
        </Fragment>        
    )
}

       