import React, { Component } from 'react';
import './../css/GranelTanqueDinamico.css';
export default class GranelTanqueDinamico extends Component {
	render() {
		return (
			<div className="tanque-dinamico-container">
				<div className="tanque-dinamico-volumen-container">
					<div className="tanque-dinamico-volumen" style={{height: this.props.level+"%"}}></div>	
					<div className="tanque-dinamico-volumen-top" style={{bottom: "calc(" + this.props.level+"% - 4px)"}}></div>	

				</div>	
				<div className="tanque-dinamico-mascara"></div>	

			</div>

		);
	}
}
