import React, { Component } from 'react';

export default class Button1 extends Component {
	render() {
		return (
			<div className={"Button1" + (this.props.className ? " " + this.props.className : "")} onClick={this.props.onClick}>
				<p>{this.props.label}</p>
				{this.props.icon?<img className="Button1-icon" src={this.props.icon} alt="icon" />:null}
			</div>
		);
	}
}