import React, { Component } from 'react';
import { Link } from "react-router-dom";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import GuestFrame from './GuestFrame.js';
import OrangeArrow from '../img/arrow_orange.png';
import FacebookIcon from '../img/facebook_f.png';
import GoogleIcon from '../img/google-g.png';
import Tooltip from 'rc-tooltip';
import {available, params} from '../config.js';
import maintenance from '../img/maintenance.svg';
import Clara from "./Clara"
import WhatsAppButton from './WhatsAppButton.js';
import ModalCampain from './ModalCampain';
import { getCampaignLogin } from "../api"
import {urls} from '../config.js';


export default class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			type: this.props.loginType,
			email: "",
			password: "",
			e_email: "",
			e_dni: "",
			e_password: "",
			errors: [],
			loginFailed: null,
			mantencion: false, 
			modal: false,
			linkModal: false,
		}
	}
	
	componentDidMount() {
		getCampaignLogin(
			(err, res) => {
				let apiUrl = urls.backend;
				if (err) {
					console.log(err)
				} 
				if (res) {
					if (res.data && res.data.campain && res.data.campain.data && res.data.campain.data.url_campain) {
						this.setState({
							modal: apiUrl+"/image/campain/desktop/"+res.data.campain.id,
							linkModal: res.data.campain.data.url_campain,
						})
					}
				}
			}
		)
	}

	renderContentModal(){
		return <img src={this.state.modal} alt="" style={{width:'100%', height:'auto', maxHeight:'72vh'}} />
	}

	closeModal(){
		this.setState({
			modal: false,
			linkModal: false,
		});
	}

	changeType(type) {
		this.setState({type});
	}
		
	sendForm() {
		this.setState({loading: true});
		this.props.doLogin(this.state.email, this.state.password, (error, result) => {
			this.setState({loading: false});
			if(error) {
				this.setState({loginFailed: error.message});
			}
		});
	}
	sendFormEmpresas() {
		this.setState({loading: true});
		this.props.doLoginEmpresas(this.state.e_email, this.state.e_password, (error, result) => {
			this.setState({loading: false});
			if(error) {
				this.setState({loginEmpresasFailed: error.message});
			}
		});
	}
	


	renderPersonas() {
		return(
			<div style={this.state.mantencion ? {display: "none"} : null}>
				<ModalCampain 
					externalLink={true} 
					link={this.state.linkModal}
					renderContent={()=>this.renderContentModal()}
					cancel={()=>this.closeModal()}
					show={this.state.modal}
					expand={true}
					noPadding={true}
				/>
				<div className="login-field-container">
					<p>Email</p>
				 	<input value={this.state.email}
				 		onChange={(e) => this.setState({email: e.target.value})} />
			 	</div>
			 	<div className="login-field-container margin-top-20">
					<p>Contraseña</p>
				 	<input type="password"
				 		value={this.state.password}
			 			onChange={(e) => this.setState({password: e.target.value})} />
			 	</div>
			 	<div className="login-options margin-top-30 margin-bottom-40">
					<Link to={"/recover"} className="login-options-link">Olvidé mi Contraseña</Link>
					<Link to={"/register"} className="login-options-link">¿ No estás registrado ?</Link>
				</div>
				{available.empresas_tutorial ? 
				<div className="login-options margin-top-30 margin-bottom-40">
					<a href="https://www.youtube.com/watch?v=aMR3rLqAtLQ" target="_blank" className="login-options-link">¿Cómo registrarme? Revisa aquí</a>
				</div>
				: null }
				{/*<div className="remember-me">
					<label><input type="checkbox" /><span>Recordar sesión</span></label>
				</div>*/}
				<div className="login-button-error-container">
					<Tooltip placement="bottom" visible={this.state.loginFailed}
				 		overlay={<span>{this.state.loginFailed}</span>}>
						<div  className="login-submit-button-container">
							<button  className="login-submit-button" onClick={() => this.sendForm()}>SIGUIENTE</button>
							<img alt="" src={OrangeArrow} />
						</div>
					</Tooltip>
				</div>
				<div className="login-social-buttons">
				{!available.login_google?null:<GoogleLogin
				    clientId={params.google_app_id}
				    responseType="code"
				    scope="profile email"
				    accessType="offline"
				    uxMode="redirect"
				    onSuccess={this.props.googleLoginCallback}
				    onFailure={responseGoogle}
				    render={renderProps => (
				      <button className="login-social-button google" onClick={renderProps.onClick}>
							<img alt="" src={GoogleIcon} /> Google</button>
				    )}
				    cookiePolicy={'none'} />}
				{!available.login_facebook?null:<FacebookLogin
				    appId={params.facebook_app_id}
				    autoLoad={false}
				    isMobile={false}
				    fields="name,email,picture.type(large),first_name,last_name"
				    onClick={() => {}}
				    render={renderProps => (
					    <button
					    	className="login-social-button facebook"
					    	onClick={renderProps.onClick}>
							<img alt="" src={FacebookIcon} /> Facebook
				    	</button>

					  )}
				    callback={this.props.facebookLoginCallback} />}
			    </div>
		    </div>
		);
	}
	renderEmpresas() {
		return(
			<div style={this.state.mantencion ? {display: "none"} : null}>
			 	<div className="login-field-container margin-top-20">
					<p>Email</p>
				 	<input value={this.state.e_email}
				 		onChange={(e) => this.setState({e_email: e.target.value})} />
			 	</div>
				{/*<div className="login-field-container margin-top-20">
					<p>Rut Empresa</p>
				 	<input value={this.state.e_dni}
				 		onChange={(e) => this.setState({e_dni: formatRut(e.target.value)})} />
			 	</div>*/}
			 	<div className="login-field-container margin-top-20 margin-bottom-40">
					<p>Contraseña</p>
				 	<input value={this.state.e_password}
				 		onChange={(e) => this.setState({e_password: e.target.value})} type="password" />
			 	</div>
				<div className="login-options margin-top-30 margin-bottom-40">
					<Link to={"/recover/empresas"} className="login-options-link">Olvidé mi Contraseña</Link>
					<a href={urls.registro_empresas_url} target="_blank" className="login-options-link">Crear Cuenta</a>
				</div>
				
				{/*<input type="checkbox" />Recordar sesión<br />*/}
				<div className="login-button-error-container">
					<Tooltip placement="bottom" visible={this.state.loginEmpresasFailed}
				 		overlay={<span>{this.state.loginEmpresasFailed}</span>}>
					<div className="login-submit-button-container">
						<button  className="login-submit-button" onClick={() => this.sendFormEmpresas()}>SIGUIENTE</button>
						<img alt="" src={OrangeArrow} />
					</div>
					</Tooltip>
				</div>
		    </div>
		);
	}
	render() {
		return (
			<GuestFrame>
				<Clara/>
				<div className="login-container">
					<div className="background-flame">
					<h2>{this.state.mantencion ? "En Mantención":"Iniciar Sesión"}</h2>
						{
						this.state.mantencion ? 
						<div style={{margin: "30px 0 10px", width: "100%"}}>
							<div style={{maxWidth: "40%", margin: "10px auto"}}>
								<img alt=""  src={maintenance} />
							</div>							
							<p className="color-white" style={{margin: "30px auto 0", width: "65%", textAlign: "center"}}>
								En estos momentos nuestra Sucursal Virtual se encuentra en mantención.  Por favor intenta más tarde.
							</p>
						</div>
						: null					
						}
						{this.state.mantencion ? 
						!available.login_empresas ? null :
						<div className="login-types-container" style={{display: "none"}}>
							<div onClick={() => {this.changeType("personas")}} className={"login-type" + (this.state.type === "personas" ? " selected" : "")}>Personas</div>
							<div onClick={() => {this.changeType("empresas")}} className={"login-type" + (this.state.type === "empresas" ? " selected" : "")}>Empresas</div>
						</div>	:
						<div className="login-types-container">
							<div onClick={() => {this.changeType("personas")}} className={"login-type" + (this.state.type === "personas" ? " selected" : "")}>Personas</div>
							<div onClick={() => {this.changeType("empresas")}} className={"login-type" + (this.state.type === "empresas" ? " selected" : "")}>Empresas</div>
						</div>	}
						{this.state.type === "personas" ? this.renderPersonas() : this.renderEmpresas()}							
					</div>
				</div>
				<WhatsAppButton class="middle-ws"/>
			</GuestFrame>

		);
	}
}
function responseGoogle(response, response2) {
}