import React, { useEffect, useState } from 'react'
import ContentBox from './ContentBox.js';
import TanqueIcon from '../img/icon_tanque.png';
import privilegios from '../privilegios_empresa.js';
import { available } from '../config.js'
import { Link } from 'react-router-dom';

export default function EmpresasEnvasadoInstitucionalCard({selectedCilindro, setSelectedCilindro, services}) {
    const [servicePriv, setServicePriv] = useState([]);
    const [servicio, setServicio] = useState({})

    useEffect(() => {        
        if (selectedCilindro != null) {
            setServicePriv(selectedCilindro.pivot.privileges)
            setServicio(selectedCilindro.pivot)
        }
    }, [selectedCilindro])

    return (
        <ContentBox className="margin-top-40 margin-bottom-40 overflow-hidden">
            <div className="servicios-servicio-box-leftside">
      			<img className="servicios-servicio-box-type-img" src={TanqueIcon} alt="granel" />
      			<div className="servicios-servicio-box-type">
      				<p>Envasado Institucional</p>
  				</div>
            {
                services ? 
                    services != null && services.length > 1 ?
                        <div className="options-number-customer">
                            <select 
                                value={selectedCilindro ? selectedCilindro.client_cod : ""} 
                                onChange={(e) => services.forEach((servicio) => {
                                    if(servicio.client_cod == e.target.value) setSelectedCilindro(servicio)
                                })}>
                                {services.map((service, i) =>
                                    <option key={i} value={service.client_cod}>
                                        N° de Cliente {parseInt(service.client_cod)}
                                    </option>)}
                            </select>
                        </div>
                    :
                        <div className="box-border margin-top-40">
                            N° de Cliente {parseInt(services[0].client_cod)}
                        </div>
                : null
            }
			</div>
            <div className="servicios-servicio-box-links block-important">
                {!available.envasado_institucional_cotizar_pedido || servicePriv && servicePriv.indexOf(privilegios.envasado_institucional_cotizar_pedido)<0?null:
                <div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/cilindro/cotizar-pedido"}>Cotizar Pedido</Link>
				</div>}
                {!available.envasado_institucional_cotizaciones || servicePriv && servicePriv.indexOf(privilegios.envasado_institucional_cotizar_pedido)<0?null:
                <div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/cilindro/cotizaciones"}>Cotizaciones</Link>
				</div>}
                {!available.envasado_institucional_cuenta_corriente ||servicePriv && servicePriv.indexOf(privilegios.envasado_institucional_cuenta_corriente)<0?null:
                <div className="linkA-container inline-block-important industrial-dashboard-link">
					<Link to={"/mis-servicios/cilindro/cuenta-corriente"}>Cuenta Corriente</Link>
				</div>}
            </div>
            <div className="servicios-servicio-box-details">
                {!selectedCilindro?null:
                    <div className="servicios-servicio-box-details-left">
                        {servicio && servicio.company_name?<p className="uppercase font-20 fw700 lh-130pct margin-top-10">{servicio.company_name}</p>:null}
                        {servicio && servicio.company_dni?<p className="font-15 fw700 lh-100pct margin-top-5">Rut: {servicio.company_dni}</p>:null}
                        <div className="servicios-servicio-box-details-address margin-top-20">
                            <p>{selectedCilindro?selectedCilindro.client_address:null}</p> //cambiar por api call getServiceAddressString
                        </div>
                        <div className="servicios-servicio-box-details-city">
                            <p>{selectedCilindro?selectedCilindro.client_city:null}, {selectedCilindro?selectedCilindro.client_region_cod:null}</p>
                        </div>
                    </div>
                }
            </div>
        </ContentBox>
    )
}
