import React, { Component, Fragment } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import {Link} from 'react-router-dom';
import ContentBox from './ContentBox.js';

import PopupPedidoReclamo from './PopupPedidoReclamo';
import PopupPedidoAnular from './PopupPedidoAnularGranel';

import Button2 from './Button2';

import {getGranelPedido, getGranelFreqUpdateTruck, getGranelOrderCoords} from '../api.js';

import pedidoRealizadoGray from '../img/pedido-realizado-gray.svg';
import pedidoRealizadoOrange from '../img/pedido-realizado-orange.svg';
import pedidoEncaminoGray from '../img/pedido-encamino-gray.svg';
import pedidoEncaminoOrange from '../img/pedido-encamino-orange.svg';
import pedidoEntregadoGray from '../img/pedido-entregado-gray.svg';
import pedidoEntregadoGreen from '../img/pedido-entregado-green.svg';
import flechaIzquierdaNegra from '../img/arrow_left_black.svg';
import moment from 'moment';
import {getServiceAddressString} from '../utils.js';
import mapDestination from '../img/map-destination.png';
import mapTruck from '../img/map-truck.png';

export default class ServiciosGranelPedido extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false
		};
	}
	componentDidMount() {
		this.setState({loading: true, pedido: null, pedidosMessage: ""});		
		this.getList(() => {
			if(this.state.pedido && this.state.pedido.state === "En Ruta") {
				getGranelFreqUpdateTruck((error, resultTime) => {
					this.getCoordinates();
					this.refresh= setInterval(() => 
						this.getCoordinates(), 
						parseInt(resultTime.data.value)*1000*60);
				}, this.props.isEmpresa?"empresas":null);
			}
		});
	}
	componentWillUnmount() {
		clearInterval(this.refresh);	
	}
	getCoordinates() {
		getGranelOrderCoords(this.state.pedido.patent, (error, result) => {
			if(error) {
				return console.log(error);
			}
			this.setState({truck_position: result.data})
		}, this.props.isEmpresa?"empresas":null);
	}
	getList(cb) {
		getGranelPedido(this.props.pedido_id, (error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({loading: false, pedidosMessage: result.message});
			}
			this.setState({pedido: {
				...result.data, 
				client_address: result.data.address,
				client_street: result.data.street,
				client_number: result.data.number,
				client_block: result.data.block,
				client_dept: result.data.dept,
				client_location: result.data.location
			}, loading: false},()=> {if(cb) cb();});
			
		}, this.props.isEmpresa?"empresas":null);
	}
	cancelModal(){
		this.setState({
			modal: false
		});
	}	
	filter() {
		this.getList();
	}
	render() {
		var pedido = this.state.pedido;
		var status = pedido ? pedido.state : null;
		return (
			<Fragment>
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1 className="light">N° del Pedido:<strong>{this.props.pedido_id}</strong></h1>
				</div>
				<Link to={"/mis-servicios/granel/pedidos"}>
				<div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30">
					<img alt="Atrás" src={flechaIzquierdaNegra}/>
					<p>atrás</p>
				</div>
				</Link>
				<div className="pedido-status-container margin-bottom-40 col-100 left clearfix">
					<div className={"pedido-status active"}>
						<img alt="Pedido realizado" src={pedidoRealizadoOrange} />
						<p>Pedido realizado</p>
					</div>
					<div className={"pedido-status" + (status ==="Programado" || status ==="En Camion" || status ==="En Ruta" || status === "Entregado"?" active":"")}>
						<img alt="Pedido realizado" src={(status ==="Programado" || status ==="En Camion" || status ==="En Ruta" || status === "Entregado")?pedidoRealizadoOrange:pedidoRealizadoGray} />
						<p>Pedido programado</p>
					</div>
					<div className={"pedido-status" + (status ==="En Camion" || status ==="En Ruta" || status === "Entregado"?" active":"")}>
						<img alt="En Ruta" src={(status ==="En Camion" || status ==="En Ruta" || status === "Entregado")?pedidoEncaminoOrange:pedidoEncaminoGray} />
						<p>En camión</p>
					</div>
					<div className={"pedido-status" + (status ==="En Ruta" || status === "Entregado"?" active":"")}>
						<img alt="En Ruta" src={(status ==="En Ruta" || status === "Entregado")?pedidoEncaminoOrange:pedidoEncaminoGray} />
						<p>En ruta</p>
					</div>
					<div className={"pedido-status" + (status === "Entregado"?" done":"")}>
						<img alt="Pedido Entregado" src={(status === "Entregado")?pedidoEntregadoGreen:pedidoEntregadoGray} />
						<p>Pedido entregado</p>
					</div>
				</div>
				<div className="subcontent-box col-100 left clearfix margin-bottom-30 z-index-0">
						<div className="granel-pedido-details grid-column-3">
						<div className="detail">
							<span>Dirección</span>
							<p>{pedido?getServiceAddressString(pedido):""}</p>
						</div>
						<div className="detail">
							<span>Comuna</span>
							<p>{pedido?pedido.comune:""}</p>
						</div>
						<div className="detail">
							<span>Región</span>
							<p>{pedido?pedido.region:""}</p>
						</div>
						<div className="detail">
							<span>Fecha del pedido</span>
							<p>{pedido?moment(pedido.creationDateTime, "DD.MM.YYYY - hh:mm").format("DD-MM-YYYY HH:mm"):""}</p>
						</div>
						{status === "Entregado"?null:
						<div className="detail">
							<span>Fecha Programada</span>
							<p>{pedido?moment(pedido.scheduledDate, "YYYY-MM-DD").format("DD-MM-YYYY"):""}</p>
						</div>}
						{status === "Entregado" && pedido && pedido.actuallyDeliveredDate?						
						<div className="detail">
							<span>Fecha de Entrega</span>
							<p>{moment(pedido.actuallyDeliveredDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</p>
						</div>:null}
						<div className="detail">
							<span>Litros Solicitados</span>
							<p>{pedido?parseFloat(pedido.liters.replace(",", ".")).toLocaleString("de"):""}</p>
						</div>
						{status === "Entregado" && pedido && pedido.litersActuallyDelivered?
						<div className="detail">
							<span>Litros entregados</span>
							<p>{parseFloat(pedido.litersActuallyDelivered.replace(",", ".")).toLocaleString("de")}</p>
						</div>:null}
						{status === "Entregado"?null:

						<div className="detail">
							<span>Monto a pagar</span>
							<p>{pedido?"$" + parseInt(pedido.amount).toLocaleString("de"):""}</p>
						</div>}
						{status === "Entregado" && pedido && pedido.actuallyDeliveredAmount ?						
						<div className="detail">
							<span>Monto pagado</span>
							<p>{"$" + parseInt(pedido.actuallyDeliveredAmount).toLocaleString("de")}</p>
						</div>:null}
						{pedido&&pedido.truck_code?
						<div className="detail">
							<span>Código Camión</span>
							<p>{pedido.truck_code}</p>
						</div>:null}
						{pedido&&pedido.patent?
						<div className="detail">
							<span>Patente</span>
							<p>{pedido.patent}</p>
						</div>:null}
						{pedido&&pedido.driver?
						<div className="detail">
							<span>Chofer</span>
							<p>{pedido.driver}</p>
						</div>:null}						
						{this.state.loading?null:<div className="grid-item-full text-center">
							{pedido && (pedido.cancel_order || pedido.is_canceled)?
								<Button2 label="Anular pedido" className="button button-border-orange" onClick={() => this.setState({anular_order: pedido})} />
							:null}
							{pedido && pedido.claimDelay?
								<Button2 label="Reclamar Retraso" className="button button-border-orange" onClick={()=>{this.setState({modal: true})}} />
							:null}
						</div>}
					</div>
			   </div>
				 {status === "En Ruta"?
				 <PedidoMap
				 	truck_position={this.state.truck_position}
 					pedido={pedido}
 					googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&client=gme-abastible"
   					loadingElement={<div style={{ height: '100%' }} />}
   					containerElement={<div className="map-granel" style={{ height: `400px` }} />}
   					mapElement={<div style={{ height: `100%` }} />}
 				   />:null}
				{this.state.anular_order?<PopupPedidoAnular  isEmpresa={this.props.isEmpresa} client={this.props.selectedService} order={this.state.anular_order} cancel={()=>this.setState({anular_order: null})} confirm={null} show={this.state.anular_order} business_line="housing"/>:null}
				{this.state.modal?<PopupPedidoReclamo isEmpresa={this.props.isEmpresa} order_number={this.props.pedido_id} client_cod={pedido?pedido.clientCode:null} cancel={this.cancelModal.bind(this)} confirm={null} show={this.state.modal} hasClaim={pedido?pedido.hasClaim:false}/>:null}

			</ContentBox>
			</Fragment>
		);
	}
}

const PedidoMap = withScriptjs(withGoogleMap((props) => {

	var pedido = props.pedido;
	var truck = props.truck_position;
	if(!pedido || !truck) return null;
	var latitude = parseFloat(pedido.address_latitude.replace(",", "."));
	var longitude = parseFloat(pedido.address_longitude.replace(",", "."));
	const bounds = new window.google.maps.LatLngBounds();
    bounds.extend({lat: truck.latitude, lng: truck.longitude});
    bounds.extend({lat: latitude, lng: longitude});

	return(
	  <GoogleMap
	  	ref={map => map && map.fitBounds(bounds)}
	    defaultZoom={14}
	    defaultCenter={{ lat: latitude, lng: longitude }}
	  >
	    <Marker position={{ lat: latitude, lng: longitude }} 
	    	icon={{url: mapDestination}} />
	    <Marker position={{ lat: truck.latitude, lng: truck.longitude }} 
	    	icon={{url: mapTruck}} />


	  </GoogleMap>);
	}
));
