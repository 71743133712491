import React, { Component, Fragment } from 'react';
import moment from 'moment';
import {granelDetalleDeuda, granelCanPay} from '../api.js';
import ContentBox from './ContentBox.js';
import ClientSelectGranel from './ClientSelectGranel';
import Button2 from './Button2';
import PostFileDownloadDocument from "./PostFileDownloadDocument"
import {urls} from '../config.js';
import {available} from '../config.js';
import {withRouter} from "react-router-dom"


class ServiciosGranelDetalleDeuda extends Component {
	constructor(props) {
		super(props);
		this.state = {	
			deudas: null,
			boletasMarcadas: [],
			checkers: [],
		};
	}

	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();		
		if(this.props.selectedService) this.getList();
		if (this.props.location.state && this.props.location.state.selectedService) {
			this.props.setSelectedService(this.props.location.state.selectedService)
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.selectedService !== this.props.selectedService) this.getList();
		if (prevState.checkers !== this.state.checkers && this.state.checkers.length > 0) {
			let allLoaded = true;
			this.state.checkers.map(el =>{
				if (el.status === false) {
					allLoaded = false
				}
			})
			if (allLoaded) {
				this.setState({loading: false})
			}
		}
	}
	getList(cb) {
		this.setState({formErrors: null, loading: true, deudas: null, pedidosMessage: "", checkers: []});		
		granelDetalleDeuda(this.props.selectedService.client_cod, (error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
			}
			if(result.status === "error") {
				if(cb) cb();
				this.setState({loading: false, formErrors: result.errors});
			}
			if(result.status === "exception") {
				if(cb) cb();
				this.setState({loading: false, deudas: [], pedidosMessage: result.message});
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({loading: false, deudas: [], pedidosMessage: result.message});
			}
			this.setState({deudas: result.data.documents});
			this.loadRenderedItems()
		}, this.props.isEmpresa?"empresas":null);
	}
	filter() {
		this.getList();
	}
	loadRenderedItems(page) {
		let itemsRendered = [];
		this.state.deudas.map(el=>{
			if (el.pdf_document) {
				itemsRendered = [...itemsRendered, {pdf_id: el.pdf_document.pdf_number, status: false}]
			}
		})
		console.log(itemsRendered)
		this.setState({checkers: itemsRendered})
	}
	isChecked(id) {
		this.state.checkers.map((el, i)=>{
			if (el.pdf_id === id) {
				let array = [...this.state.checkers];
				array[i] = { ...array[i], status: true };
				this.setState({ checkers: array });
			}
		})
	}
	toggleCheckDeuda(i) {
		var deudas = [...this.state.deudas];
		var deuda = {...deudas[i], selected: !deudas[i].selected};
		deudas[i] = deuda;
		this.setState({deudas});
	}
	pay() {
		let haSidoPagada = false;
		let deudasReq = [];
		granelDetalleDeuda(this.props.selectedService.client_cod, (error, result) => {
			console.log(result)
			if(error) {
				console.log(error);
			}
			deudasReq = result.data.documents ? result.data.documents : null;
			this.state.boletasMarcadas.map(boleta => {
				if (deudasReq && deudasReq.find(x => x.bill_number === boleta.bill_number)) {
					console.log("")
				} else {
					haSidoPagada = true;
				}
			})
			if (haSidoPagada) {
				this.props.createActionNotification("", "Una de las deudas seleccionadas ya ha sido pagada", "ok");
				this.getList();
				this.setState({boletasMarcadas: []})
			} else {
				granelCanPay((error, result) => {
					if(error) {
						return console.log("error", error);
					}
					console.log("ladataaaa-> ", result.data);
					if(result.data.can_pay) {
						var docs = "";
						var ndocs = 0;
						let boletas = []
						this.state.deudas.forEach((deuda) => {			
							if(deuda.selected) {
								boletas = [...boletas, deuda]
								if(docs !== "") docs += "|";
								docs += deuda.bill_number;
								ndocs = ndocs + 1;
							}
						});
						var url = urls.portal_pago + "granel?tiidc=02"
						+ "&codCliente=" + parseInt(this.props.selectedService.client_cod)
						+ "&email=" + this.props.user.email 
						+ "&movil=" + this.props.user.phone
						+ "&ndoc=" + ndocs 
						+ "&docs=" + docs;
						console.log("laurl-> ", url)
						window.open(url, '_blank');
						this.setState({boletasMarcadas: boletas})
					} else {
						var remaining = null;
						var segs = - moment(result.data.current_time * 1000).diff(moment(result.data.next_attemp * 1000))/1000;
						if(segs < 0) {
							remaining = " unos segundos";					
						} else {
							var minutes = segs/60;
							var remaining_segs = Math.floor(segs%60);
							if(minutes >= 1) {					
								remaining = Math.floor(minutes) + " minuto";
								if(minutes >= 2) remaining += "s";
								if(remaining_segs) {
									remaining += " y " + remaining_segs + " segundo";
									if(remaining_segs !== 1) remaining += "s";
								}
							} else {
								remaining = Math.ceil(segs) + " segundo";
								if(Math.ceil(segs) >1) remaining += "s";
		
							}
						}
						this.props.createActionNotification("", "Se detectó la apertura del Portal de Pagos. Reintenta en " + remaining, "error");
					}
				}, this.props.isEmpresa?"empresas":null);
			}
		}, this.props.isEmpresa?"empresas":null);
	}
	render() {
		var payment_total = 0;
		var anySelected = false;
		if(this.state.deudas)
		this.state.deudas.forEach((deuda) => {			
			if(deuda.selected) {
				if (parseInt(deuda.spented)) {
					payment_total += Number(deuda.amount) + Number(deuda.interest) +  Number(deuda.spented);
				} else {
					payment_total += Number(deuda.amount) + Number(deuda.interest);
				}
				
				anySelected = true;
			}
		});
		var show_detalle_deuda_operation = ((this.props.isEmpresa&&available.granel_industrial_detalle_deuda_operation) || (!this.props.isEmpresa && available.granel_detalle_deuda_operation));;
		return (
			<Fragment>
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Detalle de Deuda</h1>
					<div className="client-number">
						<ClientSelectGranel
							selected={this.props.selectedService}
							services={this.props.services}
							setSelectedService={this.props.setSelectedService}
							number_field={"client_cod"} />
					</div>
				</div>				
				<div className="table-container-2">
					<table className="table1">
						<thead>
							<tr>
								{show_detalle_deuda_operation?<td>Seleccionar</td>:null}
								<td>N° doc</td>
								<td>Fecha Vencimiento</td>
								<td>Valor</td>
								{show_detalle_deuda_operation?<td>Interés y G. Cobranza</td>:null}
								{show_detalle_deuda_operation?<td>Monto a pagar</td>:null}
								<td style={{maxWidth: '70px', padding: '0px'}}></td>
							</tr>
						</thead>
						<tbody>
							{!this.state.deudas?null:this.state.deudas.map((deuda, i) => {
								var interest = 0;
								if(parseInt(deuda.interest)) interest = parseInt(deuda.interest);
								var spented = deuda.spented ? parseInt(deuda.spented) : 0;
								return(
							<tr key={deuda.doc_number}>
								{show_detalle_deuda_operation?<td>
									<label htmlFor="notification_check" className="checkbox-container" onClick={(e) => {this.toggleCheckDeuda(i)}}>
										<input type="checkbox" checked={deuda.selected} readOnly />
										<span className="checkmark checkbox-with-border"></span>
									</label>
								</td>:null}
								<td>{deuda.bill_number}</td>
								<td>{deuda.expiration_date}</td>
								<td>${parseInt(deuda.amount.trim()).toLocaleString("de")}</td>
								{show_detalle_deuda_operation?<td>${(spented+interest).toLocaleString("de")}</td>:null}
								{show_detalle_deuda_operation?<td>${(parseInt(deuda.amount) + interest +spented).toLocaleString("de")}</td>:null}
								<td style={{maxWidth: '70px', paddingLeft: '0px'}}>
								{deuda.pdf_document?<PostFileDownloadDocument 
									createActionNotification={this.props.createActionNotification} 
									class_description={deuda.pdf_document.class_description} 
									isEmpresa={this.props.isEmpresa?"empresas":null}
									doCheck={true}
									isChecked={this.isChecked.bind(this)}

									pdf_number={deuda.pdf_document.pdf_number} contentRender={() => 
									<div className="linkA-container">
										<a>Descargar Documento</a>
									</div>
								} />:null}									
								</td>
							</tr>);

							})}
						</tbody>
					</table>
					{!this.state.deudas || this.state.deudas.length === 0?<p>No se han encontrado documentos pendientes de pago</p>:null}
				</div>
			</ContentBox>
			{show_detalle_deuda_operation?<ContentBox className={"solicitudes-container container-deuda margin-top-40"} loading={this.state.loading}>
				<div className="subcontent-box grid-column-2">
					<div>
						<h2>Total a pagar</h2>
						<p>${payment_total.toLocaleString("de")}</p>					
					</div>
					<div className="text-align-right">
						<Button2 label="Pagar" className={"orange" + (!anySelected?" disabled":"")} onClick={!anySelected?null:()=>{this.pay()}} />
					</div>
				</div>
			</ContentBox>:null}
			</Fragment>
		);
	}
}

export default withRouter(ServiciosGranelDetalleDeuda);