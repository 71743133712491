import React, { Component } from 'react';

export default class Button2 extends Component {
	render() {
		return (
			<div className={"Button2" + (this.props.className ? " " + this.props.className : "")} onClick={this.props.onClick}>
				<p>{this.props.label}</p>
				<div className="Button2-icon" style={{backgroundImage: "url(" + this.props.icon + ")"}}></div>
			</div>
		);
	}
}
