import React, { Component, Fragment } from 'react';
import ContentBox from './ContentBox.js';
import ServiceListBox from './ServiceListBox';
import ServiceListBoxAutogas2 from "./ServiceListBoxAutogas2"
import StepSwitch from './StepSwitch';
import CustomSelect from './CustomSelect';
import Button1 from './Button1';
import ArrowRightWhite from '../img/arrow_right_white.png';
import IconTicket from '../img/icon-ticket.png';
import Tooltip  from 'rc-tooltip';
import FilePicker from './FilePicker';
import DatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import SolicitudAgregada from '../img/solicitudagregada.svg';
import es from 'date-fns/locale/es';
import { registerLocale } from  "react-datepicker";
import moment from 'moment';
import flechaIzquierdaNegra from '../img/arrow_left_black.svg';
import {Link} from 'react-router-dom';

import {requestCategoryList, requestForm, sendRequestForm, validateRequestForm,
	meterBussinesLineList, housingBussinesLineList, cilindroBussinesLineList, autogasBussinesLineList
	} from '../api.js';
import {urls} from "../config.js";
registerLocale('es', es);
const initialState = {
  	step: 1,
  	categories: [],
  	services: null,
  	options: [
  		{step: 1, label: "N° de Contacto"},
  		{step: 2, label: "Requerimiento"},
  		{step: 3, label: "Formulario"}
  	],
  	selectedService: null,
  	selectedCategory: null,
  	selectedType: null,
  	form: null,
  	formValues: null,
	formErrors: null,
	loading: true,
	closeCategorySelect: false,
	closeTypeSelect: false,
	cuotas_sin_interes:12,
};
export default class ServiciosMedidorBASE extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}
	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();		
		this.loadServices((error) => {
			this.loadCategories((error) => {
				var preload_data = this.props.solicitud_requerimiento_preload;
				if(preload_data) {
					this.props.setSolicitudRequerimientoPreloadData(null, () => {
						var selectedCategory = null;
						var selectedType = null;
						this.state.categories.forEach((category)=>{
							if(category.id === preload_data.selectedCategory) selectedCategory = category;
						});
						selectedCategory.request_types.forEach((type)=> {
							if(type.id === preload_data.selectedType) selectedType = type;
						})
						this.setState({selectedService: preload_data.selectedService, selectedCategory, selectedType}, 
							() => this.goToStep3((error)=> {
								if(preload_data.formValues) this.setState({formValues: {...this.state.formValues, ...preload_data.formValues}});
							})
						);
					});
				} else {
					this.setState({loading: false})
				}
			});
		});
	}
	loadServices(cb) {
		if(this.props.isEmpresas) {
			return this.setState({parentServices: true}, cb);
		}
		var func = null;
		if(this.props.business_line === "medidor") func = meterBussinesLineList;
		if(this.props.business_line === "granel") func = housingBussinesLineList;
		if(this.props.business_line === "cilindro") func = cilindroBussinesLineList;

		if (this.props.business_line === "medidor" || this.props.business_line === "granel" || this.props.business_line === "cilindro") {
			func((error, result) => {
				if(error) {
					if(cb) cb(error);
					return console.log("ocurrio un error con el request", error);
				}
				this.setState({services: result.data}, cb);
			});
		} else if (this.props.business_line === "autogas") {
			autogasBussinesLineList((error, result) => {
				let autogasServices = []
				if(error) {
					if(cb) cb(error);
					return console.log("ocurrio un error con el request", error);
				} 
				if (result) {
					autogasServices = result.data
				}
				if (autogasServices && autogasServices.length > 0) {
					let autogasPatentes = []
					autogasServices.map(s => {
						return s.patents.map(p => {
							return autogasPatentes = [...autogasPatentes, 
								{patent: p.patent,
								conversion_contract_number: p.conversion_contract_number,
								client_cod: p.client_cod,
								client_id: s.client_id}]
						})
					})
					let _patents = []
						_patents =  autogasPatentes.sort((a, b) => {
							if (a.patent.toLowerCase() < b.patent.toLowerCase()) {
								return -1;
							} else if (a.patent.toLowerCase() > b.patent.toLowerCase()) {
								return 1;
							} else {
								return 0;
							}
						})
					this.setState({services: _patents, selectedService: this.props.selectedPatenteAutogas ? this.props.selectedPatenteAutogas : autogasPatentes[0]}, cb);
				}
			})
		}

		
	}
	loadCategories(cb) {
		var business_line_id = null;
		if(this.props.business_line === "medidor") business_line_id = 1;
		if(this.props.business_line === "granel") business_line_id = 3;
		if(this.props.business_line === "cilindro") business_line_id = 4;
		if(this.props.business_line === "autogas") business_line_id = 5;
		if(this.props.isEmpresas) business_line_id = 6;

		requestCategoryList(business_line_id, (error, result) => {
			if(error) {
				if(cb) cb(error);
				return console.log("ocurrio un error con el request", error);
			}
			if(result.status === 'empty'){
				this.setState({categories: []}, cb);
			}else if(result.status === 'disabled'){
				this.setState({categories: [], disabled_message:result.data.message }, cb);
			}else{
				this.setState({categories: result.data}, cb);
			}
		}, this.props.isEmpresas?"empresas":null);
	}
	goToStep2() {
		if(!this.state.selectedService) {
			return this.setState({error_client: "Debes seleccionar un cliente"});
		}
		this.setState({step: 2});
	}
	goToStep3(cb) {
		if(!this.state.selectedCategory) {
			return this.setState({error_category: "Debes seleccionar una categoría"});
		}
		if(!this.state.selectedType) {
			return this.setState({error_type: "Debes seleccionar un tipo"});
		}
		this.setState({loading: true});
		var client_id = this.props.isEmpresas?this.state.selectedService.id: this.state.selectedService.client_id;
		var patent = this.props.business_line === "autogas" ? this.state.selectedService.patent : null;
		requestForm(this.state.selectedType.id, client_id, patent, (error, result) => {
			if(error) {
				this.setState({loading: false});
				if(cb) cb(error);
				return console.log("ocurrio un error con el request", error);
			}
			if(result.status === "error") {
				this.setState({error_type: result.message, loading: false});
				if(cb) cb(error);				
				return;
			}
			var newFormValues = {};
			result.data.form.forEach((field)=> {
				newFormValues[field.name] = "";
				if(field.type === "checkbox") newFormValues[field.name] = [];
				if(field.element === "select") newFormValues[field.name] = "";
			});
			console.log("resultado formulario: ", result.data)
			this.setState({step: 3, convenioLink: result.data.crm_type_id, form: result.data.form, special: result.special, amount: result.amount, formValues: newFormValues, loading: false}, cb);
		}, this.props.isEmpresas?"empresas":null);
		
	}
	sendForm() {
		this.setState({formErrors: null, generalError:null, loading: true});
		var formValues = {...this.state.formValues};
		this.state.form.forEach((field) => {
			if(field.element === "select2") {
				for(var resp in formValues) {
					if(resp === field.name) {
						formValues[resp] = formValues[resp] ? formValues[resp].map(v => v.value) : "";
					}
				}
			}
			if(field.element === "input" && field.type==="file") {
				if(formValues[field.name]) {
					formValues[field.name + "_name"] = formValues[field.name].filename;
					formValues[field.name] = formValues[field.name].file;
				}
			}
			if(field.element === "input" && field.type==="date") {
				if(formValues[field.name]) {
					formValues[field.name] = moment(formValues[field.name]).format("DD-MM-YYYY");
				}
			}

		});
		var client_id = this.props.isEmpresas?this.state.selectedService.id: this.state.selectedService.client_id;
		var patent = this.props.business_line === "autogas" ? this.state.selectedService.patent : null;

		sendRequestForm(client_id, patent, "" + this.state.selectedType.id, formValues, (error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			if(result.status === "error") {
				if(result.errors) return this.setState({formErrors: result.errors, loading: false});
				if(result.message) return this.setState({generalError: result.message, loading: false});
			} else {
				this.props.checkMenuOptions();
				this.setState({step: 4, loading: false});
			}
		}, this.props.isEmpresas?"empresas":null);
	}

	goToStep4b
	() {
		this.setState({formErrors: null, generalError:null, loading: true});
		this.setState({options: []});
		var formValues = {...this.state.formValues};
		this.state.form.forEach((field) => {
			if(field.element === "select2") {
				for(var resp in formValues) {
					if(resp === field.name) {
						formValues[resp] = formValues[resp] ? formValues[resp].map(v => v.value) : "";
					}
				}
			}
			if(field.element === "input" && field.type==="file") {
				if(formValues[field.name]) {
					formValues[field.name + "_name"] = formValues[field.name].filename;
					formValues[field.name] = formValues[field.name].file;
				}
			}
			if(field.element === "input" && field.type==="date") {
				if(formValues[field.name]) {
					formValues[field.name] = moment(formValues[field.name]).format("DD-MM-YYYY");
				}
			}
		});

		var client_id = this.state.selectedService.client_id;

		validateRequestForm(client_id, null, "" + this.state.selectedType.id, formValues, (error, result) => {
			if(error) {
				console.log(error);
				return console.log("ocurrio un error con el request");
			}
			if(result.status === "error") {
				if(result.errors) return this.setState({formErrors: result.errors, loading: false});
				if(result.message) return this.setState({generalError: result.message, loading: false});
			} else {
				this.props.checkMenuOptions();
				this.setState({step: '4b', loading: false});
				let current_debt = this.state.amount; 
				let amount_pie = Math.ceil(current_debt * parseInt(this.state.formValues.n11)/100);
				let amount_cuota = result.cuota??'';

				this.setState({
					formValues:{
						contract_cod: this.state.selectedService.contract_cod,
						address: this.state.selectedService.client_address+', '+this.state.selectedService.client_city+', '+this.state.selectedService.client_region_cod,
						n6:this.state.formValues.n6,
						n7:this.state.formValues.n7,
						mail: this.props.user.email,
						debt:current_debt,
						n11:this.state.formValues.n11,
						n12:this.state.formValues.n12,
						pie:amount_pie,
						cuota:amount_cuota,
						date:new Date().toLocaleDateString("es-CL"),
						check_address:[],
						check_term_conditions:[],
					}
				});
			}
		}, this.props.isEmpresas?"empresas":null);
	}


	finish() {
		this.setState({...initialState, services: this.state.services, categories: this.state.categories, loading: false});
	}
	selectedTypeChanged(option) {
		this.setState({selectedType: option, error_type: null});
	}
	changeFormValue(field_name, value) {
		var oldFormValues = {...this.state.formValues};
		oldFormValues[field_name] = value;
		this.setState({formValues: oldFormValues});
		this.deleteFormError(field_name);
	}
	changeFormCheckboxValue(field_name, target) {
		var oldValue = [...this.state.formValues[field_name]];
		if(target.checked) {
			oldValue.push(target.value);
		} else {
			var index = oldValue.indexOf(target.value);
			if (index > -1) {
			  oldValue.splice(index, 1);
			}
		}
		this.changeFormValue(field_name, oldValue);
	}
	deleteFormError(field_name) {
		var formErrors = {...this.state.formErrors};
		delete formErrors[field_name];
		this.setState({formErrors})
	}
	renderStep1() {
		var services = this.state.services ? this.state.services : [];
		if(this.state.parentServices && this.props.services) services = this.props.services.filter((s)=>parseInt(s.business_line_id) === 6);
		
		return(
			<div className="agregar-solicitud-form">
				<Tooltip placement="bottom" visible={this.state.error_client}
				 	overlay={<span>{this.state.error_client}</span>}>
				<CustomSelect
					options={services}
					selected={this.state.selectedService}
					placeholder={this.props.business_line === "autogas" ? "Selecciona Cliente Autogas" : "Selecciona Dirección"}
					onChange={(option) => {
						if (this.props.business_line === "autogas") { this.props.setSelectedPatenteAutogas(option) }
						this.setState({selectedService: option, error_client: null})
					}}
					renderSelected={(option, opened) => {
						if (this.props.business_line === "autogas") {
							return <ServiceListBoxAutogas2 service={option} onlyHeader={opened} displayNumberLabel="patent" />
						} else {
							return <ServiceListBox service={option} displayNumberLabel={this.props.business_line === "granel"?"client_cod":null} />
						}
					}}
					renderOption={(option, i) => {
						if (this.props.business_line === "autogas") {
							return <ServiceListBoxAutogas2 service={option} displayNumberLabel="patent" />
						} else {
							return <ServiceListBox service={option} displayNumberLabel={this.props.business_line === "granel"?"client_cod":null} />
						}
					}}
				/>
				</Tooltip>
				<Button1
					label="SIGUIENTE"
					className="margin-top-50" icon={ArrowRightWhite}
					onClick={() => {this.goToStep2()}} />
			</div>
			);
	}
	renderStep1b() {
		return(
			<div className="clearfix content-box" style={{boxShadow:'none'}} style={{textAlign:'center', boxShadow:'none'}}>
				<img src={IconTicket} style={{width:'70px', height:'auto'}}/>
				<h1 style={{marginTop:'20px',fontSize:'40px', fontWeight:'600', textAlign:'center', display:'block', width:'100%'}}>
					¡Listo!
				</h1>
				<br/>
				<div style={{float: 'left', width: '100%', padding: '5%', fontSize: '16px', backgroundColor:'rgba(143,188,32,0.2)', border:'1px solid #8FBC20', borderRadius: '10px', color: '#4C8B55', margin: '30px auto 10px'}}>
					<p>
						Tu pago ha sido registrado con éxito, puedes hacer seguimiento del estado de tu convenio y pagar las cuotas ingresando a la sección Medidor en la pestaña "Convenios de Pago". En los próximos minutos recibirás un correo electrónico con la confirmación y comprobante de tu convenio*
					</p>
				</div>
				<em style={{fontSize: '13px', color: 'rgba(0,0,0,0.75)'}}>*En caso de no recibir la confirmación dentro de las próximas 24 horas, por favor contactar al siguiente número: 800 20 9000</em>
				<div style={{width:'100%', textAlign:'center'}}>
					<Link to={'/'}>
						<Button1
							label="FINALIZAR"
							className="margin-top-50"
							icon={ArrowRightWhite}
							onClick={() => {console.log('home')}} />
					</Link>
				</div>
			</div>
			);
	}
	renderStep2() {
		let error_type = null;
		if(this.state.error_type) {
			error_type = this.state.error_type.split('\n').map((item, i) => <p key={i}>{item}</p>);
		}

		return(
			<Fragment>
				<div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30" onClick={() => this.setState({step: 1, selectedCategory: null,
  				selectedType: null})}>
					<img alt="Atrás" src={flechaIzquierdaNegra}/>
					<p>atrás</p>
				</div>
				<div className="agregar-solicitud-form">
					<div className="agregar-solicitud-form-group">
						<div className="form-field-label"><p>Escoge Categoría de Requerimiento</p></div>
						<Tooltip placement="bottom" visible={this.state.error_category}
					 	overlay={<span>{this.state.error_category}</span>}>
						<CustomSelect
							options={this.state.categories.sort((a,b) =>a.name > b.name?1:-1)}
							selected={this.state.selectedCategory}
							force_close={this.state.closeCategorySelect}
							onForceClosed={()=>this.setState({closeCategorySelect:false})}
							onOptionsOpened={()=>this.setState({closeTypeSelect:true})}
							onChange={(option) => {
								this.setState({selectedCategory: option, error_category: null, error_type: null});
								this.selectedTypeChanged(null);
							}}
							renderSelected={(option, opened) => {
								return <div className="solicitud-formulario-filter-select-selected">{option.name}</div>

							}}
							renderOption={(option, i) => {
								return <div className="solicitud-formulario-filter-select-option">{option.name}</div>
							}}
						/>
						</Tooltip>
					</div>
					<div className="agregar-solicitud-form-group">
						<div className="form-field-label"><p>Escoge Tipo de Requerimiento</p></div>
						<Tooltip placement="bottom" visible={this.state.error_type}
					 	overlay={<span>{error_type}</span>}>
						<CustomSelect
							options={this.state.selectedCategory ? this.state.selectedCategory.request_types.sort((a,b) =>a.name > b.name?1:-1): []}
							selected={this.state.selectedType}
							onOptionsOpened={()=>this.setState({closeCategorySelect:true})}
							onForceClosed={()=>this.setState({closeTypeSelect:false})}							

							force_close={this.state.closeTypeSelect}
							onChange={(option) => {
								this.selectedTypeChanged(option);
							}}
							renderSelected={(option, opened) => {
								return <div className="solicitud-formulario-filter-select-selected">{option.name}</div>
							}}
							renderOption={(option, i) => {
								return <div className="solicitud-formulario-filter-select-option">{option.name}</div>
							}}
						/>
						</Tooltip>
						<div className="agregar-solicitud-category-description margin-top-40">
							<p>{this.state.selectedType ? this.state.selectedType.description_front : ""}</p>
						</div>
					</div>
					<Button1
						label="SIGUIENTE"
						className="margin-top-50" icon={ArrowRightWhite}
						onClick={() => {this.goToStep3()}} />
				</div>
			</Fragment>	
		);
	}
	renderStep3() {
		var convenioPago = null;
		var convenioLinkType = this.state.convenioLink
		var convenioPagoLink

		if(this.state.special && this.state.formValues.n0 && (this.state.special === "C362" || this.state.special === "C359")) {
			var pie = Math.ceil(this.state.amount * parseInt(this.state.formValues.n0)/100);
			convenioPago = 
			<div style={{fontSize: "1.3em"}}>
				<p><strong>Pie solicitado:</strong> ${pie.toLocaleString("de")} </p>
			</div>;
		}

		if (convenioLinkType === "C361") {
			convenioPagoLink = <div className="message">
				Formulario Campaña Repactación Marzo 2022 para <a target="_blank" 
					href={urls.backend_public + "/Formulario - Solicitud de Campana Marzo 2022.docx"}>descargar y firmar</a>.
			</div>
		} else if (convenioLinkType === "C359") {
			convenioPagoLink = <div className="message margin-bottom-40">
				Formulario Solicitud de Repactación <a target="_blank" 
				href={urls.backend_public + "/Formulario_-_Solicitud_de_Repactacion_2022.docx"}>(Descargar Acá)</a>.
			</div>
		} else if (convenioLinkType === "C362") {
			convenioPagoLink = <div className="message margin-bottom-40">
				Formulario suscripcion dueño para <a target="_blank" 
				href={urls.backend_public + "/Formulario_-_Suscripci%C3%B3n_de_Facilidades_de_Pago_(habitacional_individual).pdf"}>descargar y firmar</a>.
			</div>
		} else if (convenioLinkType === "MC0501") {
			convenioPagoLink = <div className="message margin-bottom-40">
				Formulario Campaña Repactación Mayo - Junio <a target="_blank" 
				href={urls.backend_public + "/Formulario_-_Suscripci%C3%B3n_de_Facilidades_de_Pago_(habitacional_individual).pdf"}>(Descargar Acá)</a>.
			</div>
		} else {
			convenioPagoLink = null
		}
		/* var convenioPago = null;
		if(this.state.special && (this.state.special === "C359" || this.state.special === "C362")) {
			var pie = this.state.amount * parseInt(this.state.formValues.n0)/100;
			convenioPago = 
			<div>
				<p><strong>Pie solicitado:</strong> ${pie.toLocaleString("de")} </p>
			</div>;

		}
		var convenioLinkType = this.state.convenioLink
		var convenioPagoLink

			if (convenioLinkType === "C361") {
				convenioPagoLink = <div className="message margin-bottom-40">
					Declaración jurada simple para <a target="_blank" 
						href={urls.backend_public + "/Declaracion%20Jurada%20Simple%20requisitos%20suspension%20de%20corte%2010.08.2020.docx"}>descargar y firmar</a>.
					<br /><br />
					Formulario Campaña Repactación Marzo 2022 para <a target="_blank" 
						href={urls.backend_public + "/Formulario%20-%20Solicitud%20de%Campana%20Marzo%2022.docx"}>descargar y firmar</a>.
				</div>
			} else if (convenioLinkType === "C359") {
				convenioPagoLink = <div className="message margin-bottom-40">
					Formulario suscripcion arrendatario para <a target="_blank" 
					href={urls.backend_public + "/formulario%20suscripcion%20arrendatario.docx"}>descargar y firmar</a>.
				</div>
			} else if (convenioLinkType === "C360") {
				convenioPagoLink = <div className="message margin-bottom-40">
					Descarga <a target="_blank" 
					href={urls.backend_public + "/Formulario_-_Suscripci%C3%B3n_de_Facilidades_de_Pago_(habitacional_individual).pdf"}>Formulario Suscripción de Facilidades de Pago</a>.
				</div>
			} else if (convenioLinkType === "C362") {
				convenioPagoLink = <div className="message margin-bottom-40">
					Formulario suscripcion dueño para <a target="_blank" 
					href={urls.backend_public + "/formulario%20suscripcion%20dueno.docx"}>descargar y firmar</a>.
				</div>
			} else {
				convenioPagoLink = null
			} */

		var fields = !this.state.form ? null : this.state.form.map((field, i) => {
			var validations = field.validations.split("|");
			var requiredMark = validations.indexOf("required")>= 0 ? "*" : "";
			if(field.element === "input") {
				if(field.type === "text" || field.type === "number" || field.type === "email") {
				return(
					<div className="solicitud-formulario-field-container" key={field.name} style={field.placealone?{gridColumnStart: 1, gridColumnEnd: 3,}:null}>
						{!field.hidetitle?(<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>):null}
						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false} destroyTooltipOnHide={true}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
							<input
								value={this.state.formValues[field.name]}
								onChange={(e) => {this.changeFormValue(field.name, e.target.value)}}
								type={field.type}
								placeholder={field.hidetitle?(field.label+' '+requiredMark):null}
								/>
						</Tooltip>
					</div>
					);
				}
				else if(field.type === "file") {
				return(
					<div className="solicitud-formulario-field-container" key={field.name} style={field.placealone?{gridColumnStart: 1, gridColumnEnd: 3,}:null}>
						{!field.hidetitle?(<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>):null}
						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
							<FilePicker
								placeholder={(field.hidetitle?(field.label+' '+requiredMark+' '):'')+"Agregar archivos (máx. 10MB)"}
								formIsSent={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
								isLoading={this.state.loading}
								onChange={(file, filename) => {
									if(!file) this.changeFormValue(field.name, null);
									else {
										var removedSignature = file.split("base64,")[1];
										this.changeFormValue(field.name, {file: removedSignature, filename});
									}
								}}
								value={this.state.formValues[field.name]} />

						</Tooltip>
					</div>
					);
				}else if(field.type === "date") {
				return(
					<div className="solicitud-formulario-field-container" key={field.name} style={field.placealone?{gridColumnStart: 1, gridColumnEnd: 3,}:null}>
						{!field.hidetitle?(<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>):null}
						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
						<DatePicker popperModifiers={{flip: {enabled: false}}} dateFormat="dd/MM/yyyy" selected={this.state.formValues[field.name]}
							locale="es"
							onChange={(date) => {this.changeFormValue(field.name, date)}}
							/>

						</Tooltip>
					</div>
					);
				}

				else if(field.type === "checkbox")
				return(
					<Tooltip key={field.name} placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
						<div className="solicitud-formulario-field-container" key={field.name} style={field.placealone?{gridColumnStart: 1, gridColumnEnd: 3,}:null}>
							{!field.hidetitle?(<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>):null}

						{field.options.map((option) => {
							return(<div className="checkbox" key={option.value}><input type={field.type} value={option.value}
							onChange={(e) => {this.changeFormCheckboxValue(field.name, e.target)}}/>
							{!field.hidetitle?(<span>{option.name}</span>):(<span className="solicitud-formulario-field-label">{option.name} {requiredMark}</span>)}
						</div>);
						})}
					</div>
					</Tooltip>
					);
				else
				return(
					<div className="solicitud-formulario-field-container" key={field.name}>
						<div className="solicitud-formulario-field-label"><p>{field.label}</p></div>
						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false} destroyTooltipOnHide={true}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
							<p>CAMPO NO SOPORTADO</p>
						</Tooltip>
					</div>
					);
			}
			else if(field.element === "select") {
				return(
					<div className="solicitud-formulario-field-container" key={field.name} style={field.placealone?{gridColumnStart: 1, gridColumnEnd: 3,}:null}>
						{!field.hidetitle?(<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>):null}

						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
						<select
							value={this.state.formValues[field.name]}
							onChange={(e) => {this.changeFormValue(field.name, e.target.value)}}
							>
								<option value="">{field.hidetitle?(field.label+' '+requiredMark):null}</option>
								{field.options.map((option) => {
									return(<option key={option.value} value={option.value}>{option.name}</option>);
								})}
						</select>
						</Tooltip>
					</div>
					);
			}
			else if(field.element === "select2") {
				return(
					<div className="solicitud-formulario-field-container" key={field.name} style={field.placealone?{gridColumnStart: 1, gridColumnEnd: 3,}:null}>
						{!field.hidetitle?(<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>):null}

						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
						<ReactSelect
					        value={this.state.formValues[field.name]}
					        onChange={(option) => this.changeFormValue(field.name, option)}
					        options={field.options}
							getOptionLabel={option => option.name}
							isMulti
					      />
						</Tooltip>
					</div>
					);
			}
			else if(field.element === "textarea")
				return(
					<div className="solicitud-formulario-field-container grid-item-full" key={field.name} style={field.placealone?{gridColumnStart: 1, gridColumnEnd: 3,}:null}>
						{!field.hidetitle?(<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>):null}
						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false} destroyTooltipOnHide={true}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
				 			<textarea
				 				value={this.state.formValues[field.name]}
								onChange={(e) => {this.changeFormValue(field.name, e.target.value)}}
								placeholder={field.hidetitle?(field.label+' '+requiredMark):null}
								/>
						</Tooltip>
					</div>
					);
			else {
				return(
					<div className="solicitud-formulario-field-container" key={field.name}>
						{!field.hidetitle?(<div className="solicitud-formulario-field-label"><p>{field.label} {requiredMark}</p></div>):null}

						<Tooltip placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
						<p>ELEMENTO NO SOPORTADO</p>
						</Tooltip>
					</div>
					);
			}

		});
		return(
			<Fragment>
				<div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30" onClick={() => this.setState({step: 2})}>
					<img alt="Atrás" src={flechaIzquierdaNegra}/>
					<p>atrás</p>
				</div>
			<div className="formulario-container-step3 grid-column-2">

			{fields}
				<div className="container-submit grid-item-full">
					{convenioPago}
					{convenioPagoLink}
					<Tooltip placement="bottom" visible={this.state.generalError}
					 	overlay={<span>{this.state.generalError}</span>}><Button1
					label={this.state.convenioLink!=='C360'?"ENVIAR":"SIMULAR CONVENIO"}
					className="margin-top-20" icon={ArrowRightWhite}
					onClick={() => {this.state.convenioLink!=='C360'?this.sendForm():this.goToStep4b()}} /></Tooltip>
				</div>
				</div></Fragment>);
	}
	renderStep4() {
		return(
			<div className="agregar-solicitud-step4">
				<div className="agregar-solicitud-step4-title">
					<p>Requerimiento enviado con éxito</p>
				</div>
				<img src={SolicitudAgregada} alt="Requerimiento agregado con éxito" />
				<Button1
					label="Finalizar"
					className="margin-top-50"
					onClick={() => {this.finish()}} />
			</div>
		);
	}
	renderStep4b() {
		let fields_values = [
			{name:'contract_cod', label:'Código de cliente', value: this.state.formValues['contract_cod'], format:'int'},
			{name:'address', label:'Dirección', value: this.state.formValues['address']},
			{name:'n6', label:'Nombre solicitante', value:this.state.formValues['n6']},
			{name:'n7', label:'Rut solicitante', value:this.state.formValues['n7']},
			{name:'mail', label:'Mail solicitante', value: this.state.formValues['mail']},
			{name:'debt', label:'Deuda Vigente', value: this.state.formValues['debt'], format:'money'},
			{name:'n11', label:'Porcentaje de pie seleccionado', value:this.state.formValues['n11'], format:'percentage'},
			{name:'n12', label:'Número de cuotas seleccionadas', value:this.state.formValues['n12']},
			{name:'pie', label:'Monto pie a pagar', value: this.state.formValues['pie'], format:'money'},
			{name:'cuota', label:'Valor Cuota', value: this.state.formValues['cuota'], format:'money'},
			{name:'date', label:'Fecha de simulación', value: this.state.formValues['date']},
			{name:'check_address', label:'Validar dirección', options:[{value:true, name:'Confirmo que soy titular o represento al usuario consumidor de la instalación de gas referenciada en el código de cliente y dirección seleccionada para la simulación y posterior ejecución de convenio de repactación'}]},
			{name:'check_term_conditions', label:'Términos y condiciones', options:[{value:true, name:'Dependiendo de la fecha de ejecución del convenio, las cuotas serán cobradas en la boleta siguiente o subsiguiente, junto al consumo mensual. A su vez, ante la mora en el pago de dos cuotas consecutivas se realizará la anulación del convenio y se hará exigible el total de la deuda en la boleta.'}]},
		];

		var fields = fields_values.map((field, i) => {
			if(!field.name.startsWith('check_')){
				return(
					<div className="solicitud-formulario-field-container"  key={field.name}>
						<div className="solicitud-formulario-field-label"><p style={{color:'#fe5000'}}>{field.label}</p></div>
						<span>
						{field.format=='money'?'$':null}{field.value&&field.format=='money'?field.value.toLocaleString('de'):(field.value&&field.format=='int'?parseInt(field.value):field.value)}{field.format=='percentage'?'%':null}
						</span>
					</div>
				);
			}
		});

		var fields_check = fields_values.map((field, i) => {
			if(field.name.startsWith('check_')){
				return(
					<Tooltip key={field.name} placement="bottom" visible={this.state.formErrors && this.state.formErrors[field.name] ? true : false}
				 	overlay={<span>{this.state.formErrors && this.state.formErrors[field.name] ? this.state.formErrors[field.name][0] : ""}</span>}>
						<div className="solicitud-formulario-field-container"  key={field.name}>
						{field.options.map((option) => {
							return(<div className="checkbox" style={{width:"auto"}} key={option.value}><input type={'checkbox'} value={option.value}
							onChange={(e) => {this.changeFormCheckboxValue(field.name, e.target)}}/>

							 <span>{option.name}</span></div>);
						})}
					</div>
					</Tooltip>
				);
			}
		});
		return(
			<Fragment>
				<div className="back semibold font-13 uppercase col-100 left clearfix margin-bottom-30" onClick={() => this.setState({step: 3, options:initialState.options}) }>
					<img alt="Atrás" src={flechaIzquierdaNegra}/>
					<p>atrás</p>
				</div>
				<div className="formulario-container-step3 grid-column-2" style={{gridGap:'40px'}}>
					{fields}
				</div>
				<div className="formulario-container-step3 grid-column-1" style={{gridGap:'40px', marginTop:'40px'}}>
					{fields_check}
					<div className="container-submit grid-item-full">
						<Tooltip placement="bottom" visible={this.state.generalError}
						 	overlay={<span>{this.state.generalError}</span>}><Button1
						label="SOLICITAR CONVENIO Y PAGAR PIE"
						className="margin-top-20" icon={ArrowRightWhite}
						onClick={() => {this.sendConvenio()}} /></Tooltip>
					</div>
				</div>
			</Fragment>
		);
	}

	sendConvenio(){
		this.setState({formErrors: null, generalError:null, loading: true});
		let client_id = this.state.selectedService.client_id;
		var formValues = {...this.state.formValues};

		sendRequestForm(client_id, null, "" + this.state.selectedType.id, formValues, (error, result) => {
			if(error) {
				if(result && result.message) return this.setState({generalError: result.message, loading: false});
				return this.setState({generalError: 'Ha ocurrido un error inesperado,por favor intente más tarde.', loading: false});
			}
			if(result.status === "error") {
				if(result.errors) return this.setState({formErrors: result.errors, loading: false});
				if(result.message) return this.setState({generalError: result.message, loading: false});
			} else {
				let url = '';
				url = urls.portal_pago + 'medidor?codCliente=' + Number(this.state.selectedService.contract_cod).toString() + 
					'&email=' + this.props.user.email + '&movil=' + this.props.user.phone + '&pie=true&url_retorno='+
					encodeURIComponent( window.location.origin + window.location.pathname+'/'+btoa(Date.now()));
				window.open(url, '_self');
				window.focus();
			}
		}, null);

	}

	renderStep() {
		if(this.props.code) return this.renderStep1b();
		if(this.state.step === 1) return this.renderStep1();
		if(this.state.step === 2) return this.renderStep2();
		if(this.state.step === 3) return this.renderStep3();
		if(this.state.step === 4) return this.renderStep4();
		if(this.state.step === '4b') return this.renderStep4b();
	}
	render() {
		return (
			<>
			<ContentBox loading={this.state.loading}>
				{this.state.step===4?null:<div className={"title-client-container clearfix"}>
					{!this.props.code?<h1>Requerimientos</h1>:null}
					
					{(!this.props.code && !this.state.disabled_message)?<div className="agregar-solicitud-steps">
						<StepSwitch options={this.state.options} selected={this.state.step} onChange={(step) => {this.setState({step})}} />
					</div>:null}
				</div>}
				{!this.state.disabled_message ?this.renderStep():
				(<div className="agregar-solicitud-form">
				<h2 style={{fontSize:'40px'}}>{this.state.disabled_message}</h2>
				</div>)}
			</ContentBox>
			</>
		);
	}
}
