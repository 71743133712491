import React, { Component } from 'react';
import ContentBox from './ContentBox.js';
import ClientSelectGranel from './ClientSelectGranel';
import {granelGetPriceChanges} from '../api.js';
import moment from 'moment';

export default class ServiciosGranelTarifas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			prices: null
		};
	}	
	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();
		
		if(this.props.selectedService) {
			this.getList();
		}
	}	
	componentDidUpdate(prevProps) {
		if(prevProps.selectedService !== this.props.selectedService && this.props.selectedService) {
			this.getList();
		}
	}
	getList(cb) {
		this.setState({prices:null, prices_message: null, loading: true});
		granelGetPriceChanges(this.props.selectedService.client_cod, (error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
			}
			if(result.status === "error") {
				if(cb) cb();
				this.setState({prices_message: result.message, loading: false});
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({prices_message: result.message, loading: false});
			}
			this.setState({
				prices: result.data,
				loading: false,
			});
		}, this.props.isEmpresa?"empresas":null);
	}	
	render() {
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Variación de Precio</h1>
					<div className="client-number">
						<ClientSelectGranel
							selected={this.props.selectedService}
							services={this.props.services}
							setSelectedService={this.props.setSelectedService}
							number_field={"client_cod"} />
					</div>
				</div>
				<div className="table-container-2">
					<table className="table1">
						<thead>
							<tr>
								<td>Fecha</td>
								<td>Precio</td>
							</tr>
						</thead>
						<tbody>
							{!this.state.prices?null:this.state.prices.map((price, i) => {
								return(
							<tr key={'price-'+i}>
								<td>{moment(price.date).format('DD-MM-YYYY')}</td>
								<td>{price.price}</td>
							</tr>);
							})}
						</tbody>
					</table>
					{!this.state.prices?<p>{this.state.prices_message}</p>:null}					
				</div>
			</ContentBox>
		);
	}
}