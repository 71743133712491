import React, { Component } from 'react';
import GuestFrame from './GuestFrame.js';
import LoadingFull from './LoadingFull';
import {Link } from "react-router-dom";
import OrangeArrow from '../img/arrow_orange.png';

export default class ConfirmEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			code: this.props.code,			
			code_error: null
		}
	}
	componentDidMount() {
		if(this.props.code) {
			this.sendCodeForm();
		} 
	}		
	sendCodeForm() {
		if(this.state.code === "") return this.setState({code_error: "Debes ingresar el código"});
		this.setState({loading: true});
		this.props.doVerifyEmail(this.state.code, (error, result) => {
			this.setState({loading: false});
			if(error) {
				return alert("error en request");
			}
			if(result.status === "error") {
				this.props.createActionNotification("Error verificando Correo electrónico", result.message, "error");
				setTimeout(() => {
					this.props.redirect("/enviar_codigo_verificacion");
				}, this.props.code?0:1500);          					
				return;
			}	
			this.props.createActionNotification("Email verificado correctamente", "Ahora inicia sesión");
			setTimeout(() => {
				this.props.redirect("/login");
			}, this.props.code?0:1500);          					

		});
		
	}	

	render() {
		if(this.props.code) {
			return <LoadingFull />;
		}
		return (
			<GuestFrame>	
				<div className="login-container">
					<div className="background-flame">
						<h2>Se ha enviado correo de verificación</h2>
						<p className="color-white">Hemos enviado un correo electrónico a la dirección que indicaste con las instrucciones para completar tu registro.</p>						
				 		<div className="login-submit-button-container">
							<button  className="login-submit-button" onClick={() => this.props.redirect("/login")}>IR AL INICIO</button>
							<img alt="" src={OrangeArrow} />
						</div>	
						<div className="link-white"><Link to={"/enviar_codigo_verificacion"}><span>Reenviar correo</span></Link></div>
					</div>
				</div>
			</GuestFrame>
				
		);
	}
}