import React, { Component } from 'react';
import {addressBussinesLineSearch} from '../api.js'; 
export default class PredictiveStreetSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			keyboardSelected: -1,
			searchTerm: ""

		};
	}
	componentDidUpdate(prevProps) {
		if(prevProps.selected !== this.props.selected){
		 /*if(this.props.selected && this.props.selected !== "")*/ this.setState({searchTerm: this.props.selected});

		}
		
	}
	searchTermChange(searchTerm) {
		if(this.props.locked) return;
		searchTerm = searchTerm.toUpperCase()
		if (searchTerm!== this.props.selected && this.props.service !== "cilindro") this.props.onOptionSelected(null);
		if(searchTerm.length < 3) {
			this.setState({searchTerm})
			if(this.props.service === "cilindro") this.props.onOptionSelected(searchTerm)
		} else {
			this.setState({searchTerm, options: "loading"}, () => {
				addressBussinesLineSearch(this.props.city, searchTerm, this.props.codeRegion, (error, result)=> {
					if(error) {
						return this.setState({options: "error"});
					}
					if(result.status === "ok") {
						this.setState({options: result.data});
					}
					else {
						this.setState({options: null});
					}					
				});			
			});
			if(this.props.service === "cilindro") this.props.onOptionSelected(searchTerm)
		}
	}
	selectOption(option) {
		if(this.props.locked) return;
		this.props.onOptionSelected(option);
		this.setState({keyboardSelected: -1, options: null, searchTerm: option})
	}
	keyDown(e) {
		if(this.props.locked) return;
		if(!this.state.options) {
			return this.setState({keyboardSelected: -1});
		}
		if(e.key === "Enter") {
			e.preventDefault();
			var option = this.state.options[this.state.keyboardSelected];
			if(option) this.selectOption(option);
			


		} else if(e.key === "ArrowUp") {
			e.preventDefault();
			var new_keyboardSelected = this.state.keyboardSelected - 1;
			if(new_keyboardSelected< 0) new_keyboardSelected = this.state.options.length - 1;
			this.setState({keyboardSelected: new_keyboardSelected});


		} else if(e.key === "ArrowDown") {
			e.preventDefault();			
			var new_keyboardSelected = this.state.keyboardSelected + 1;			
			if(new_keyboardSelected >= this.state.options.length) new_keyboardSelected = 0;
			this.setState({keyboardSelected: new_keyboardSelected});

		}
	}
	renderOptions() {
		if(this.state.options === "loading") return (
			<div className="predictive-search-options">
				<p className={"predictive-search-option"} key={"loading"}>Buscando...</p>
				</div>);
		if(this.state.options === "error") return (
			<div className="predictive-search-options">
				<p className={"predictive-search-option"} key={"error"}>Tenemos problemas para sugerirte nombres de calle. Por favor intenta más tarde.</p>
				</div>);
		return(
			<div className="predictive-search-options">
				{this.state.options && this.state.options.length > 0 ? this.state.options.map((option,i)=> 
					<p className={"predictive-search-option" + (i===this.state.keyboardSelected?" keyboard-selected":"")} key={i} onClick={() => {this.selectOption(option)}}>{option}</p>
				) : null}
			</div>
		);
	}
	render() {
		return (
			<div className="predictive-search">
				<input 
					value={this.state.searchTerm} 
					onFocus={(e)=> {this.setState({focused: true, options: null});if(this.props.selected) this.setState({searchTerm: this.props.selected});this.props.onFocus(e)}}
					onBlur={() => {
						this.setState({focused: false});
						this.setState({keyboardSelected: -1, searchTerm: this.props.selected?this.props.selected: this.props.service === "cilindro" ? this.state.searchTerm : ''}); 
						setTimeout(() => {this.setState({options: null})}, 100);
					}}
					onKeyDown={(e)=>{this.keyDown(e)}} 
					onChange={(e)=>{this.searchTermChange(e.target.value)}} />
				{!this.state.options?null:this.renderOptions()}
			</div>
		);
	}
}
