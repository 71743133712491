import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {housingTelemetryTanks} from '../api.js';
import ContentBox from './ContentBox.js';
import moment from 'moment';
import ModalConfirmation from './ModalConfirmation';
import {available} from '../config.js';


export default class ServiciosGranelTelemetriaTanques extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();
		
		this.getList();
		
	}	
	getList(cb) {
		this.setState({formErrors: null, loading: true, pedidos: null, pedidosMessage: ""});
		housingTelemetryTanks(this.props.client_cod, (error, result) => {
			if(error) {
				return console.log("ocurrio un error con el request", error);
            }
            if(result.status === "error"){
            	this.setState({errorMessage: result.message});
				return console.log("ocurrio un error con el request", result);            	
            }
            this.setState({
                tanks: result.data,
                loading: false,
			});
		}, this.props.isEmpresas?"empresas":null);
	}
	confirmModal() {
		this.props.redirect("/mis-servicios/granel/telemetria");
	}
	
	render() {
		var show_historial = ((this.props.isEmpresas&&available.granel_industrial_telemetria_historial) || (!this.props.isEmpresas && available.granel_telemetria_historial));
		return (
			<ContentBox loading={this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Tanques</h1>
					
				</div>				
				<div className="table-container">
					<table className="table1">
						<thead>
							<tr>
								<td>Código del tanque</td>
								<td>Fecha de lectura</td>
								<td>Porcentaje de llenado</td>
								<td></td>
								{show_historial?<td></td>:null}
							</tr>
						</thead>
						<tbody>
							{!this.state.tanks?null:this.state.tanks.map((tank, i) => {
								return(
							<tr key={tank.meter_code}>
								<td>{parseInt(tank.meter_code)}</td>
								<td>{moment(tank.reading_date, "YYYY-MM-DD").format("DD-MM-YYYY")} {tank.reading_time}</td>
								<td>{tank.percentage} %</td>
								<td>
									<div className="linkA-container">
										<Link to={"/mis-servicios/granel/telemetria/" + this.props.client_cod + "/tanques/" + tank.meter_code}>Ver Detalle</Link>
									</div>
								</td>
								{show_historial?<td>
									<div className="linkA-container">
										<Link to={"/mis-servicios/granel/telemetria/" + this.props.client_cod + "/tanques/" + tank.meter_code + "/historial"}>Ver Historial</Link>
									</div>
								</td>:null}
							</tr>);

							})}
						</tbody>
					</table>
					<ModalConfirmation 
						confirm={this.confirmModal.bind(this)} 
						title={this.state.errorMessage} 
						/* renderContent={() => 
						<p class="text-align-left margin-bottom-30">
							Si desea solicitarlo debe descargar las <div className="link-orange">
								<a className="link-orange" href="/docs/Contrato_Arriendo_Telemetria_Granel-Planilla.pdf" target="_blank">condiciones comerciales</a>
							</div> y el <a className="link-orange" href="/docs/Mandato.pdf" target="_blank">documento de PAT</a>. Luego, ingrese a la sección <Link className="link-orange" to={"/mis-servicios/granel/requerimientos"}>Requerimientos</Link>, escoja la categoría "Telemetría", el tipo de solicitud "Solicitud de servicio telemetría" y complete el formulario adjuntando los documentos firmados y escaneados.
						</p>} */
						show={this.state.errorMessage}/>
				</div>
			</ContentBox>
		);
	}
}
