import React, { Component } from 'react';

import Logo from '../img/logo.png';

import PhoneIcon from '../img/phone.png';
import Facebook from '../img/facebook_f.png';
import Instagram from '../img/instagram_logo.png';
import Youtube from '../img/youtube_logo.png';

export default class GuestFrame extends Component {
	render() {
		return (
				<div className="guest-container">
					<div className="content">
						<div className="guest-header">
							<a href="/"><img alt="ABASTIBLE" className="guest-logo" src={Logo} /></a>
							<div className="guest-header-info-back-container">
								<img alt="" src={PhoneIcon} className="guest-header-icon margin-right" />
								<div className="guest-header-info margin-right">Pedidos: <strong>800 20 9000</strong></div>
								<div className="guest-header-info margin-right">Emergencia: <strong>600 200 9000</strong></div>
								<div className="links-rrss-container">
									<a className="link-rrss" target="_blank" href="https://facebook.com" rel="noopener noreferrer">
										<img alt="" src={Facebook} className="guest-header-icon margin-left" /></a>
									<a className="link-rrss" target="_blank" href="https://instagram.com" rel="noopener noreferrer">
										<img alt="" src={Instagram} className="guest-header-icon margin-left" /></a>
									<a className="link-rrss" target="_blank" href="https://youtube.com" rel="noopener noreferrer">
										<img alt="" src={Youtube} className="guest-header-icon margin-left" /></a>
								</div>
								<p className="guest-header-back">
									<a href="https://abastible.cl">Ir a Abastible.cl</a>
								</p>
							</div>
						</div>
						<div className="guest-content-columns-container">
							{this.props.children}
							<div className="guest-slogan">
								<h3>Sucursal Virtual</h3>
								<h1>La energía de vivir</h1>
								<h2>Está en ti, está en nosotros</h2>
							</div>
						</div>
				</div>
				<div className="veil"></div>
				<div className="background-photo"></div>
			</div>
		);
	}
}
