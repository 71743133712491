import React, { Component } from 'react';
import {getGranelPedidos} from '../api.js';
import ContentBox from './ContentBox.js';
import ClientSelectGranel from './ClientSelectGranel';

import PopupPedidoAnular from './PopupPedidoAnularGranel';
import PopupPedidoReclamo from './PopupPedidoReclamo';
import moment from 'moment';
import {Link, Route, Switch} from "react-router-dom";
import ServiciosGranelPedido from './ServiciosGranelPedido';


export default class ServiciosGranelPedidos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchType: "days",
			searchPedido: "",
			filterDays: "30",
			loading: true,
			loaded: true
		};
	}
	componentDidMount() {
		if(this.props.reloadServices) this.props.reloadServices();
		if (this.props.selectedService && !this.props.isEmpresa) this.getList();
	}
	componentDidUpdate(prevProps) {
		if(prevProps.selectedService !== this.props.selectedService) {
			if (prevProps.selectedService !== null) {
				this.getList();
			} else {
				if (this.props.selectedService && this.state.loaded) {
					this.getList();
					this.setState({loaded: false})
				}
			}
		} 
	}
	getList(cb) {
		
		this.setState({loading: true, pedidos: null, pedidosMessage: "", showAddress: false});
		var orderNumber = null;
		var filterDays = null;
		if(this.state.searchType === "order_number") {
			orderNumber = this.state.searchPedido;
		}
		if(this.state.searchType === "days") {
			filterDays = this.state.filterDays;
		}
		getGranelPedidos(this.props.selectedService.client_cod, filterDays, orderNumber, (error, result) => {
			if(error) {
				if(cb) cb(error);
				this.setState({loading: false});
				return console.log(error);
			}
			if(result.status === "error") {
				if(cb) cb();
			}
			if(result.status === "empty") {
				if(cb) cb();
				return this.setState({loading: false, pedidosMessage: result.message});
			}
			this.setState({pedidos: result.data.map((pedido)=> {return {
				...pedido, 
				client_address: pedido.address,
				client_street: pedido.street,
				client_number: pedido.number,
				client_block: pedido.block,
				client_dept: pedido.dept,
				client_location: pedido.location
			}}), loading: false, showAddress: this.state.searchType === "order_number"});
		}, this.props.isEmpresa?"empresas":null);
	}
	filter() {
		this.getList();
	}
	render() {
		return (
			<Switch>
			<Route exact path="/mis-servicios/granel/pedidos">
			<ContentBox loading={this.state.loading === null ? true : this.state.loading}>
				<div className="title-client-container clearfix">
					<h1>Historial de Pedidos</h1>
					<div className="client-number">
						<ClientSelectGranel
							selected={this.props.selectedService}
							services={this.props.services}
							setSelectedService={this.props.setSelectedService}
							number_field={"client_cod"} />
					</div>
				</div>
				<div className="col-100 left d-flex justify-between margin-bottom-40">
					<div className="filter-container grid-column-3">
						<div className="position-relative">
							<select value={this.state.searchType} onChange={(e) => {this.setState({searchType: e.target.value})}}>
								<option value="days">Periodo</option>
								<option value="order_number">Número de pedido</option>
							</select>
						</div>
						{this.state.searchType==="order_number"?<input type="text" value={this.state.searchPedido} onChange={(e)=>{this.setState({searchPedido: e.target.value})}} placeholder={"N° de Pedido"} style={{flex: 1, marginRight: "20px"}} />
						:<div className="position-relative">
							<select value={this.state.filterDays} onChange={(e) => {this.setState({filterDays: e.target.value})}}>
								<option value="30">Último mes</option>
								<option value="90">Últimos 3 meses</option>
								<option value="180">Últimos 6 meses</option>
								<option value="365">Últimos 12 meses</option>
							</select>
						</div>}
						<button className="button orange" onClick={() => {this.filter()}}>Filtrar</button>
					</div>
				</div>
				<div className="table-container">
					<table className="table1">
						<thead>
							<tr>
								<td>Fecha del Pedido</td>
								<td>Número Pedido</td>
								<td>Litros Solicitados</td>
								<td>Fecha Programada</td>
								<td>Estado</td>
								{this.state.showAddress?<td>Dirección</td>:null}
								<td></td>
								<td></td>
								<td></td>
							</tr>							
						</thead>
						<tbody>
							{!this.state.pedidos?null:this.state.pedidos.map((pedido, i) => {
								var address = "";
								if(pedido.address && pedido.address.trim() !== "") address += pedido.address;
								if(pedido.commune && pedido.commune.trim() !== "") {
									if(address !== "") address += ", ";
									address += pedido.commune;
								}
								if(pedido.region && pedido.region.trim() !== "") {
									if(address !== "") address += ", ";									
									address += pedido.region;
								}
								var creationMoment = moment(pedido.creationDateTime, "DD.MM.YYYY - hh:mm");
							return(
							<tr key={pedido.orderNumber}>
								<td className="white-space-pre">{creationMoment.format("DD-MM-YYYY")}<br/>{creationMoment.format("HH:mm")}</td>
								<td>{pedido.orderNumber}</td>
								<td>{parseFloat(pedido.liters.replace(",", ".")).toLocaleString("de")}</td>
								<td className="white-space-pre">{moment(pedido.scheduledDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
								<td>{pedido.state}</td>
								{this.state.showAddress?<td>{address}</td>:null}
								<td>
									{pedido && pedido.claimDelay?
									<div className="linkA-container" onClick={()=>{this.setState({reclamar_order: pedido})}}>
										<a href={null}>No he recibido mi pedido</a>
									</div>:null}
								</td>
								<td>
									{pedido && (pedido.cancel_order || pedido.is_canceled)?<div className="linkA-container" onClick={() => this.setState({anular_order: pedido})}>
										<a  href={null}>Anular</a>
									</div>:null}
								</td>
								<td>
									<div className="linkA-container">
										<Link to={"/mis-servicios/granel/pedidos/" + pedido.orderNumber}>Ver Detalle</Link>
									</div>
								</td>
							</tr>
									);
								
							})}
						</tbody>
					</table>
					{!this.state.pedidos?<p>{this.state.pedidosMessage}</p>:null}
				</div>
				{this.state.anular_order?<PopupPedidoAnular isEmpresa={this.props.isEmpresa} client={this.props.selectedService} order={this.state.anular_order} cancel={()=>this.setState({anular_order: null})} confirm={true||this.confirmModal.bind(this)} show={this.state.anular_order} reload_list={()=>{this.filter()}} business_line="housing"/>:null}
				{this.state.reclamar_order?<PopupPedidoReclamo isEmpresa={this.props.isEmpresa} order_number={this.state.reclamar_order?this.state.reclamar_order.orderNumber:null} client_cod={this.props.selectedService?this.props.selectedService.client_cod:null} cancel={()=>this.setState({reclamar_order: null})} confirm={null} hasClaim={this.state.reclamar_order?this.state.reclamar_order.hasClaim:false} show={this.state.reclamar_order}/>:null}
			</ContentBox>
			</Route>
			<Route path="/mis-servicios/granel/pedidos/:id"
      				render={(data)=> 
					<ServiciosGranelPedido isEmpresa={this.props.isEmpresa} pedido_id={data.match.params.id} />}>
            </Route>
		</Switch>
		);
	}
}
