import React, { Component} from 'react';
import {refreshToken} from '../api.js';
import ReactDOM from 'react-dom';


import {urls} from '../config.js';
export default class PostFileDownloadDocument2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: "",
		};
	}

	download() {			
		refreshToken((error, result) => {
			if(error) return alert("Error descargando el archivo");
			this.setState({token: result.data.token}, () => {
				this._form.submit();
			});
		});
	}
	attachNode(node) {
    	this._form = ReactDOM.findDOMNode(node);
  	}	  
	render() {
		var url = urls.backend + "/autogas/contract/download";
        return (
            <div  onClick={() => this.download()}>
                <form ref={this.attachNode.bind(this)}
                method="POST" action={url} target="_blank">
                    <input type="hidden" name="contract_number" value={this.props.contract_number} />
                    <input type="hidden" name="patent" value={this.props.patent} />
                    <input type="hidden" name="type" value={this.props.type} />					
                    <input type="hidden" name="token" value={this.state.token} />
                </form>
                {this.props.contentRender()}
            </div>
        );
	}
}